@use 'sis-assets/foundations/colors/colors' as colors;

sis-menu-button {
  display: inline-block;
  vertical-align: top;

  & .menu-button {
    & [ngbDropdownToggle] {
      display: inline-flex;

      &::after {
        display: none !important;
      }
    }

    & .dropdown-header {
      & > em {
        color: colors.$dark-gray;
      }
    }

    & .dropdown [ngbdropdownmenu].dropdown-menu,
    & .dropup [ngbdropdownmenu].dropdown-menu {
      max-height: unset;
      overflow: visible;
    }
  }
}
