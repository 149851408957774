@use '../breakpoints/breakpoints' as breakpoints;
@use '../colors/colors' as colors;
@use '../spacing/tokens' as spacing;
@use '../typography/tokens' as typography;

// TODO remove this and use instead fudis-hr
.sis-hr {
  border: 0;
  border-top: spacing.$pixel-1 solid colors.$middle-gray;
  height: spacing.$pixel-1;
}

.sis-hidden {
  display: none;
}

.sis-sr-only {
  position: absolute;
  margin: -0.1rem;
  border: 0;
  padding: 0;
  width: spacing.$pixel-1;
  height: spacing.$pixel-1;
  overflow: hidden;
  clip:rect(0, 0, 0, 0)
}

.sis-period-indicator {
  display: inline-block;
  margin-right: 0.5rem;
  margin-bottom: spacing.$spacing-xxs;
  margin-left: 0.5rem;
  border: spacing.$pixel-1 solid colors.$light-gray;
  border-radius: spacing.$pixel-2;
  background-color: colors.$extra-light-gray;
  padding: spacing.$pixel-3 1.2rem;
  min-height: spacing.$spacing-md;
  vertical-align: middle;
  text-transform: uppercase;
  line-height: typography.$body-text-md-line-height;
  color: colors.$dark-gray;
  font-size: typography.$body-text-md-font-size;
  font-weight: typography.$font-weight-regular;
}

ul.no-list-style-type {
  margin: 0;
  padding: 0 !important;
  list-style-type: none !important;
}

// TODO: Get rid of this and replace usages with sis-mt-md or sis-mt-sm helper classes
.top-spacing {
  margin-top: 2rem;
}

.search-view-left-column {
  margin: 0;
  border-right: spacing.$pixel-1 solid colors.$extra-light-gray;
  padding: 2rem 5rem 0 3.5rem;

  @media (max-width: breakpoints.$screen-xs-max) {
    padding: 1rem 2rem 0 1.5rem;
  }
}

.search-view-right-column {
  margin: 0 0 0 -0.1rem;
  border-left: spacing.$pixel-1 solid colors.$extra-light-gray;
  padding: 2rem 3.5rem 0;

  @media (max-width: breakpoints.$screen-xs-max) {
    padding: 1rem 1.5rem;
  }
}

// Student, Tutor, Teacher and Staff index.html layout utilities

.secondary-background {
  background-color: colors.$extra-light-gray;

  & + footer { // student
    margin-top: 0;
    padding-top: 0;
  }

  & + .tutor-footer,
  & + .teacher-footer,
  & + .staff-footer {
    & footer {
      margin-top: 0;
      padding-top: 0;
    }
  }

  & sis-breadcrumbs {
    & ol.breadcrumb {
      background-color: colors.$extra-light-gray;
    }
  }
}

.student-header {
  &.fixed {
    background-color: colors.$extra-light-gray;
    height: 6rem;
  }
}
