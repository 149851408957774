@use 'sis-assets/foundations/breakpoints/breakpoints' as breakpoints;
@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/focus/mixins' as focus;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

sis-legacy-local-date-editor {
  & input.form-control {
    &::-ms-clear {  //IE, hide cross that clears input field
      display: none;
    }
  }

  .inner-addon {
    position: relative;

    .form-control {
      padding-right: spacing.$spacing-lg;
      text-overflow: ellipsis;
    }

    .addon-button {
      position: absolute;
      top: spacing.$pixel-3;
      right: spacing.$spacing-xxs;
      background-color: colors.$white;
      padding: 0;

      &:disabled {
        background-color: colors.$extra-light-gray;
        cursor: default;

        & svg {
          fill: colors.$middle-gray;
        }
      }

      // As with some other older components, the focus does not look good if focused on the link itself, so exceptionally show focus on the inner sis-icon.
      &:focus {
        @include focus.fudis-hide-focus;

        border-color: transparent;

        & sis-icon {
          @include focus.fudis-focus-generic;

          outline-offset: -0.5rem;
          box-shadow: none;
        }
      }

      & svg {
        fill: colors.$primary-color;
      }
    }
  }
}
