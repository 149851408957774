@use 'sis-assets/foundations/breakpoints/breakpoints' as breakpoints;
@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

sis-custom-code-urns-edit {
  & .form-group {
    & > .row {
      margin-bottom: 0.7rem;

      & .link-button {
        margin-top: 0.9rem;
        padding-top: spacing.$pixel-1;
      }
    }
  }
}
