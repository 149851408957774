@use 'sis-assets/foundations/breakpoints/breakpoints' as breakpoints;
@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

sis-message {
  & .message-content {
    padding-left: 1.3rem;
  }

  & .additional-title-name-type-group {
    line-height: 1.4rem;
    font-size: 1.4rem;

    @media (max-width: breakpoints.$screen-md-max) {
      line-height: 1.8rem;
      font-size: 1.2rem;
    }
  }

  & .message-additional-title {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: flex-end;
    margin-left: 4.5rem;

    @media (max-width: breakpoints.$screen-md-max) {
      flex-direction: column;
      align-items: flex-start;
      padding-top: spacing.$spacing-xs;
    }
  }

  & .additional-title-message-date-time {
    margin-left: auto;
    font-size: 1.4rem;

    @media (max-width: breakpoints.$screen-md-max) {
      margin-left: 0;
      border-left: spacing.$pixel-1 solid colors.$dark-gray;
      padding-left: spacing.$spacing-xs;
      line-height: 1.4rem;
      font-size: 1.2rem;
    }
  }

  & .additional-title-type-border {
    border-left: spacing.$pixel-1 solid colors.$dark-gray;
    padding-left: spacing.$spacing-xs;
  }

  & .additional-title-sender-border {
    padding-right: spacing.$spacing-xs;

    @media (max-width: breakpoints.$screen-md-max) {
      border-left: spacing.$pixel-1 solid colors.$dark-gray;
      padding-left: spacing.$spacing-xs;
    }
  }

  & .message-unread {
    border-left: 0.5rem solid colors.$primary-color;
  }

  & .message-read {
    border-left: 0.5rem solid transparent;
  }

  & .additional-title-buffer {
    padding-top: 0.4rem;
  }
}
