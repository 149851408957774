@use 'sis-assets/foundations/breakpoints/breakpoints' as breakpoints;
@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;
@use 'sis-assets/foundations/focus/mixins' as focus;
@use 'sis-assets/foundations/typography/mixins' as typography;
@use 'sis-assets/foundations/typography/tokens' as typographyTokens;

sis-expandable {
  .expandable {
    position: relative;
    margin-bottom: spacing.$spacing-sm;
    border: spacing.$pixel-2 solid colors.$extra-light-gray;
    border-top: 0;

    &__variant {
      &__link,
      &__naked,
      &__naked-dotted,
      &__naked-dotted-transparent {
        border: 0;
      }

      &__link {
        margin: 1rem 0;
      }

      &__primary {
        border-color: colors.$primary-color;
      }

      &__primary-light {
        border-color: colors.$primary-color-light;
      }

      &__dark-gray {
        border-color: colors.$dark-gray;
      }

      &__middle-gray {
        border-color: colors.$middle-gray;
      }

      &__naked-dotted,
      &__naked-dotted-transparent {
        &:not(.closed) {
          &::before {
            display: block;
            position: absolute;
            bottom: 1.1rem;
            left: spacing.$spacing-md;
            border-right: spacing.$pixel-1 dotted colors.$middle-gray;
            padding: 0;
            width: spacing.$pixel-1;
            max-width: spacing.$pixel-1;
            height: calc(100% - 4.8rem);
            content: '';
          }

          &::after {
            display: block;
            position: absolute;
            bottom: spacing.$pixel-2;
            left: 2.1rem;
            border-radius: 50%;
            background-color: colors.$middle-gray;
            width: 0.7rem;
            height: 0.7rem;
            content: "";
          }
        }
      }
    }

    &.closed {
      border-bottom: 0;
    }

    &__header {
      display: block;
      padding: spacing.$spacing-xs;

      &__variant {
        &__regular {
          background-color: colors.$extra-light-gray;
        }

        &__primary {
          background-color: colors.$primary-color;
        }

        &__primary-light {
          background-color: colors.$primary-color-light;
        }

        &__dark-gray {
          background-color: colors.$dark-gray;
        }

        &__middle-gray {
          background-color: colors.$middle-gray;
        }

        &__naked-dotted,
        &__naked-dotted-transparent {
          position: relative;
        }

        &__naked-dotted-transparent {
          &:not(.closed)::before {
            border-color: colors.$extra-light-gray;
          }
        }
      }

      &__slim {
        padding: 0;
      }

      &__heading {
        margin: 0;
        width: 100%;

        + button,
        + sis-button,
        + sis-menu-button,
        + sis-tooltip {
          margin-left: spacing.$spacing-xs;

          @media (max-width: breakpoints.$screen-sm-max) {
            margin-left: 4.5rem;
          }
        }


      }

      &__buttons {
        button {
          vertical-align: sub;

          @media (max-width: breakpoints.$screen-sm-max) {
            margin-top: spacing.$spacing-xs;
          }

          @media (min-width: breakpoints.$screen-sm-max) {
            display: inline-flex;
          }
        }
      }

      @media (min-width: breakpoints.$screen-sm-max) {
        display: flex;
      }
    }

    &__content {
      &__padding {
        &__default {
          padding: spacing.$spacing-md;

          @media (min-width: breakpoints.$screen-sm-min) {
            padding-left: spacing.$spacing-xl;
          }
        }

        &__small {
          padding: spacing.$spacing-xs;
        }

        &__slim {
          padding: 0 0 0 spacing.$spacing-md;
        }
      }

      &__dotted {
        position: relative;
        padding-left: 4.8rem;
      }

      &__transparent {
        color: colors.$white;

        &::before {
          border-color: colors.$extra-light-gray;
        }

        &::after {
          background-color: colors.$extra-light-gray;
        }
      }
    }

    &__title {
      display: flex;
      border: none;
      background-color: transparent;
      cursor: pointer;
      padding: 0;
      width: 100%;
      text-align: left;
      color: colors.$dark-gray;
      font-weight: 400;

      &:focus {
        @include focus.fudis-focus-generic;
      }

      &__icon {
        margin-right: spacing.$spacing-xs;
      }

      &__variant {
        &__primary,
        &__dark-gray,
        &__middle-gray,
        &__naked-dotted-transparent {
          color: colors.$white;

          & sis-icon svg {
            fill: colors.$white;
          }
        }

        &__link {
          color: colors.$primary-color-dark;
        }
      }

      &__size {
        line-height: typographyTokens.$heading-sm-line-height;

        &__xxs {
          font-size: typographyTokens.$heading-xxs-font-size;
          font-weight: typographyTokens.$font-weight-light;
        }

        &__xs {
          font-size: typographyTokens.$heading-xs-font-size;
        }

        &__sm {
          font-size: typographyTokens.$heading-sm-font-size;
        }

        &__md {
          @include typography.heading-md;

        }

        &__lg {
          @include typography.heading-lg;
        }

        &__xl {
          @include typography.heading-xl;
        }

        &__xxl {
          @include typography.heading-xxl;
        }
      }

      &__text {
        margin-right: spacing.$spacing-xs;
        @media (max-width: breakpoints.$screen-sm-max) {
          overflow-wrap: anywhere;
        }
      }

      &__container{
        flex-grow: 1;
        align-self: center;
      }

      &__sub-title {
        display: block;
        margin-top: spacing.$spacing-xs;

        &__size {
          &__sm {
            line-height: 1.2rem;
            font-size: 1.2rem;
          }

          &__md {
            line-height: 1.4rem;
            font-size: 1.4rem;
          }
        }
      }
    }

    @media (max-width: breakpoints.$screen-xs-max) {
      border: 0;
    }
  }
}
