@use 'sis-assets/foundations/spacing/tokens' as spacing;

sis-prior-learning-substitutions {
  & .substitution {
    display: block;

    &:first-child {
      margin-top: 0;
    }
  }

  & .equivalent-courses-info {
    padding-top: spacing.$spacing-sm;
  }
}
