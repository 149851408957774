@use '@funidata/ngx-fudis' as fudis;
@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

sis-selection-box {
  display: block;

  &:has(sis-radio-button input:focus) {
    @include fudis.focus-generic;
  }

  & .selection-box-container {
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    z-index: 99;
    border: spacing.$pixel-1 solid colors.$middle-gray;
    padding: 1rem;
    min-height: 6.2rem;
  }

  & .selection-box-clickable-area {
    position: absolute;
    top: 0%;
    left: 0%;
    z-index: 999;
    cursor: pointer;
    width: 100%;
    height: 100%;
  }

  .selection-box-inputs:has(input[aria-disabled="true"]) + .selection-box-clickable-area {
      cursor: not-allowed;
  }

  & .selected-highlight {
    margin: 0;
    border: spacing.$pixel-3 solid colors.$primary-color;
  }

  & .group-is-full-element {
    float: right;
    text-transform: uppercase;
    color: colors.$red;
    font-weight: bolder;
  }

  & label.control.control-radio {
    margin-bottom: 0;
  }
}
