@use 'sis-assets/foundations/spacing/tokens' as spacing;

search-filter-range {
  & .search-filter.credit-range-filter {
    input[type="number"] {
      max-width: 10rem;
    }

    & .search-filter-gutter {
      display: flex;
      align-items: flex-end;
      padding: 0;

      & .hyphen {
        display: block;
        position: relative;
        top: spacing.$spacing-xs;
        width: 100%;
        height: 3.6rem;
        text-align: center;
      }
    }
  }
}
