@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

sis-language-pills {
  .nav.nav-pills-localize {
    display: inline-block;
    position: relative;
    bottom: spacing.$spacing-xxs;
    margin-left: 0.8rem;
    min-width: 9rem;
    text-transform: lowercase;

    & .language-pill {
      display: inline;
      float: none;

      + .language-pill {
        margin-left: spacing.$spacing-xs;
      }

      & .language-pill-button {
        display: inline-block;
        border: spacing.$pixel-1 solid colors.$extra-light-gray;
        border-radius: 50%;
        background-color: colors.$white;
        padding: 1rem 0;
        width: spacing.$spacing-md;
        height: spacing.$spacing-md;
        text-align: center;
        text-decoration: none;
        line-height: 0;
        color: colors.$middle-gray;
        font-size: 1.2rem;
        font-weight: 300;

        &:not(.active) {
          &:hover, &:active {
            background-color: colors.$extra-light-gray;
            cursor: pointer;
          }
        }

        &.active,
        &.active:hover,
        &.active:active {
          border-color: colors.$silver;
          background-color: colors.$white;
          cursor: default;
          color: colors.$middle-gray;

          &.gray {
            border-color: colors.$middle-gray;
          }
        }

        &.invalid,
        &.invalid:hover,
        &.invalid:active {
          color: colors.$red;
        }

        &.gray {
          border-color: colors.$light-gray;
        }
      }
    }
  }
}
