@use 'sis-assets/foundations/spacing/tokens' as spacing;

app-student-person-groups {
  display: block;
  margin-top: spacing.$spacing-md;

  & .styled-table {
    & tbody {
      & tr {
        & td {
          & ul.responsibility-infos {
            margin: 0;
            padding: 0;
            list-style: none;

            & li {
              padding-bottom: 0.5rem;

              &:last-of-type {
                padding-bottom: 0;
              }
            }

            & + a {
              display: block;
              padding-top: 0.5rem;
            }
          }
        }
      }
    }
  }
}
