@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

student-timing-template {
  display: block;
  border-bottom: spacing.$pixel-1 solid colors.$middle-gray;
  padding: 0 2rem 2rem;

  & .guidance {
    display: block;
    margin-bottom: spacing.$spacing-sm;
    padding-top: 1rem;
    color: colors.$white;
  }

  & .button-holder {
    margin-top: 2rem;
    text-align: right;

    &.btn-delete {
      margin-top: 3rem;
    }

    & .btn {
      margin-right: 0;
    }
  }
}
