@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

sis-read-more-ng {
  & .read-more {
    position: relative;
    margin-top: 0.5rem;
    color: colors.$white;
    font-weight: 400;
    font-style: italic;

    &.truncated::after {
      display: inline-block;
      position: relative;
      top: -4.6rem;
      background: linear-gradient(to bottom, rgb(71 71 71 / 0%) 0%, rgb(71 71 71 / 1%) 10%, rgb(72 72 72 / 100%) 80%, rgb(72 72 72 / 100%) 100%);
      width: 100%;
      height: spacing.$spacing-md;
      content: '';
    }

    & .visible-content {
      overflow: hidden;

      & .full-content {
        & sis-icon[icon="info-circle"] {
          position: absolute;
        }

        & > div {
          margin-left: 3.8rem;
          padding-top: spacing.$pixel-2;
          overflow-wrap: break-word;
        }
      }
    }

    & .link-button {
      float: right;
      text-decoration: underline;
      color: colors.$white;
    }
  }
}
