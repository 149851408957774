credit-phrase,
count-phrase {
  display: block;
  clear: both;
  padding-top: 1rem;
  min-height: 3.6rem;

  .selected {
    span {
      display: block;
      width: 100%;
      text-align: center;
    }
  }
}
