@use "@funidata/ngx-fudis" as fudis;

app-free-edit-course-unit-expandable {
  & .course-unit-search {
    &__label {
      margin: fudis.$spacing-xs 0;
      /* stylelint-disable-next-line property-disallowed-list */
      line-height: fudis.$spacing-sm;
      p {
        margin-bottom: 0.5rem;
      }

      &__main {
        display: block;
      }

      &__sub {
        @include fudis.body-text-sm-regular;

        display: block;
        margin-top: fudis.$spacing-xxs;
      }
    }
  }
}
