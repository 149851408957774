@use 'sis-assets/foundations/breakpoints/breakpoints' as breakpoints;
@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

messages, message {
  display: block;
  background-color: colors.$extra-light-gray;

  & .messages {
    padding-bottom: 6rem;

    & h1.page-title {
      margin: 0;
    }

    & .message-info-header-collapsed {
      display: none;
    }

    & .messages-container {
      margin-top: 1rem;
      border: spacing.$pixel-2 solid colors.$light-gray;
      background-color: colors.$white;

      & .top-button-container {
        margin-bottom: 2rem;
        border-bottom: spacing.$pixel-1 solid colors.$extra-light-gray;
        padding-left: 1.5rem;
        height: 5rem;
        line-height: 5rem;
      }

      & .all-messages {
        padding-top: 1rem;

        & .single-message {
          margin: 0 1rem;
          border-bottom: spacing.$pixel-1 solid colors.$extra-light-gray;
          padding: spacing.$spacing-xs 0;
          height: 6rem;
          text-decoration: none;
          color: colors.$dark-gray;

          &:hover, &:active {
            background-color: colors.$extra-light-gray;
          }

          &.unread-message {
            border-bottom: spacing.$pixel-1 solid colors.$extra-light-gray;
            background-color: colors.$extra-light-gray;

            & .message-sender, & .message-sender-student-number, & .message-title {
              font-weight: 600;
            }
          }

          &.no-pagination-last-message {
            margin-bottom: 1rem;
          }

          & .message-header {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          & .message-sender, & .message-sender-student-number, & .message-title {
            font-weight: 400;
          }

          & .message-snipped {
            color: colors.$dark-gray;
          }

          & .message-type, & .message-cur-name {
            color: colors.$middle-gray;
            font-size: 1.2rem;
          }

          & .message-overview {
            display: inline-block;
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          & .message-date-time {
            margin-top: 0.7rem;
            text-align: right;
            line-height: spacing.$spacing-sm;

            & .message-date {
              display: block;
              font-weight: 600;
            }

            & .message-time {
              color: colors.$middle-gray;
              font-size: 1.2rem;
              font-weight: 600;
            }
          }
        }
      }

      & .opened-message {
        padding-bottom: 3rem;

        & .opened-message-content {
          padding: 0 3rem;

          & .message-info-header, & .message-info-header-collapsed {
            margin-bottom: 2rem;
            border-bottom: spacing.$pixel-1 solid colors.$extra-light-gray;

            & .message-sender, .message-cur-name-header {
              & h4 {
                display: inline-block;
                margin: 0;
                padding-right: 1rem;
              }
            }

            & .message-type-and-date {
              text-align: right;

              & > div {
                display: inline-block;
              }

              & .message-type-header {
                border-right: spacing.$pixel-1 solid colors.$silver;
                padding: 0 1.5rem;
              }

              & .message-date {
                padding-left: 1.5rem;
              }
            }
          }

          & .message-content {
            & .suggestion-for-addition {
              & .parent-module-info {
                margin-top: 0.9rem;
              }

              & .message-comment-title {
                margin: 2rem 0 0;
              }

              & em {
                font-weight: 400;
              }
            }

            & .contextual-notification {
              margin-top: 2rem;
            }

            & .message-comment {
              margin-bottom: 3rem;
              white-space: pre-line;
              overflow-wrap: break-word;
            }

            & .button-holder {
              margin-top: 1rem;
            }
          }
        }
      }

      & .no-messages {
        margin: 1.5rem 1.5rem 2.5rem;
      }
    }
  }

  @media (max-width: breakpoints.$screen-sm-max) {
    & .messages .messages-container {
      & .opened-message {
        & .opened-message-content {
          & .message-info-header {
            & .message-type-and-date {
              text-align: left;

              & .message-type-header {
                padding-left: 0;
              }
            }
          }
        }
      }
    }
  }
}
