@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

assessment-item-selector,
attach-course-unit-realisation-modal,
app-open-university-attach-cur-to-ai-modal {
  .assessment-item-list,
  .course-unit-list,
  .cur-list {
    .form-control {
      padding: 0;
    }

    .list-group {
      margin-bottom: 0;
      height: 18rem;
      overflow: auto;

      .list-group-item {
        margin: 0 spacing.$pixel-2;
        border-top: 0;
        border-right: 0;
        border-bottom: spacing.$pixel-1;
        border-left: 0;
        padding: spacing.$pixel-3 1rem;

        &.link-button {
          text-decoration: underline;
          color: colors.$dark-gray;
          font-weight: 400;
        }

        &.active {
          background-color: colors.$primary-color;
          color: colors.$white;
        }

        &.title {
          color: colors.$middle-gray;
          font-style: italic;
        }

        &.disable {
          color: colors.$middle-gray;
        }
      }

      .assessment-item-title {
        padding: 0.5rem spacing.$pixel-2 spacing.$pixel-2;
        color: colors.$dark-gray;
        font-weight: 700;
      }

      .assessment-item-group-info {
        padding-left: 1.5rem;
      }

      .list-group-item:first-child {
        margin-top: spacing.$pixel-2;
      }

      .list-group-item:last-child {
        margin-bottom: spacing.$pixel-3;
      }

      a:visited {
        text-decoration: initial;
        color: colors.$dark-gray;
      }
    }
  }

  .row {
    div:first-child {
      padding-right: 0;
    }
  }

  .course-unit-search {
    .form-group {
      margin-bottom: 0.7rem !important;
      padding: 0;
    }
  }

  .to-bottom {
    position: relative;

    label {
      position: absolute;
      bottom: 0;
    }
  }
}

/* stylelint-disable no-descending-specificity */
attach-course-unit-realisation-modal {
  & .list-group-item {
    width: calc(100% - 0.4rem);
  }
}
/* stylelint-enable no-descending-specificity */
