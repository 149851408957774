@use 'sis-assets/foundations/colors/colors' as colors;

inline-search {
  & .inverted-guidance {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  & .ui-select-bootstrap { // overwrites ui-select styles, because ui-select is used in inlineSearch as a typeahead
    & .ui-select-toggle {
      & .ui-select-placeholder {
        display: inline-block !important;
      }

      & .ui-select-match-text {
        display: none !important;
      }
    }

    & .ui-select-choices {
      & .ui-select-choices-row {
        &.active {
          &,
          & .ui-select-choices-row-inner {
            background-color: colors.$primary-color-light;
            color: colors.$dark-gray;

            & .additional-data {
              color: colors.$middle-gray;
            }
          }

          &.disabled {
            &,
            & .ui-select-choices-row-inner {
              background-color: colors.$white;
            }
          }
        }

        & .ui-select-choices-row-inner {
          & .status {
            & sis-icon svg {
              height: 2.5rem;
            }
          }
        }
      }
    }
  }
}
