@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

app-enrolment-state-course-units {
  display: flex;
  flex-direction: column;

  & .list-title {
    align-self: center;
    margin-bottom: spacing.$spacing-lg;
    width: 100%;
    max-width: 40rem;
  }

  & .checkbox-container {
    margin-bottom: 3rem;
    height: 10rem;
  }

  & .enrolment-course-unit {
    margin-bottom: spacing.$spacing-sm;
  }

  & .guidance {
    line-height: 1.8rem;
    white-space: pre-line;
    font-size: 1.4rem;
    font-weight: 400;
    font-style: normal;
  }

  & .show-more {
    display: flex;
  }

  & .group-main-title {
    margin: spacing.$spacing-sm;
  }

  & .external-link {
    color: colors.$primary-color-dark;
  }

  & .requirements-list {
    padding-left: spacing.$spacing-xs;
  }

  & .rule-list-item {
    display: flex;
    align-items: center;
    margin-bottom: spacing.$spacing-xs;
    list-style-type: none;
    line-height: 1.7rem;
    font-size: 1.4rem;

    & p {
      margin-bottom: 2rem;
    }
  }
}
