@use 'sis-assets/foundations/colors/colors' as colors;

sis-code-select-editor {
  & .invalid-selection-editor {
    sis-code-selection-editor, code-selection-editor {
      & .btn.btn-default.form-control {
        border-color: colors.$red;
      }
    }
  }

  sis-code-selection-editor {
    & .btn.btn-default.form-control {
      &[disabled='disabled'] {
        opacity: 1;
        background: colors.$extra-light-gray;
        color: colors.$middle-gray;
      }
    }
  }
}
