@use 'sis-assets/foundations/breakpoints/breakpoints' as breakpoints;
@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

sis-common-attainment-details {
  dl {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 3rem;

    & .full-width {
      grid-column: span 2;

      @media(max-width: breakpoints.$screen-sm-max) {
        grid-column: 1;
      }
    }

    @media(max-width: breakpoints.$screen-sm-max) {
      grid-template-columns: 1fr;
    }
  }

  dt {
    text-transform: uppercase;
    line-height: spacing.$spacing-md;
    font-size: 1.4rem;
    font-weight: 400;
  }

  div.form-group-mimic {
    break-inside: avoid-column;
  }

  .form-group-mimic {
    margin-bottom: 3.8rem;

    dt.label {
      display: block;
      margin: 0 0 0.9rem;
    }

    dd {
      & >, & > span, & > p, & li, & > div {
        margin-bottom: 0;
        padding-top: 0;
        padding-bottom: 0.7rem;
        min-height: spacing.$spacing-lg;
        line-height: 2rem;
        font-size: spacing.$spacing-sm;
        font-weight: 300;
        overflow-wrap: break-word;

        & p {
          margin-bottom: 0;
        }

        @media (max-width: breakpoints.$screen-sm-max) {
          font-size: 1.4rem;
        }
      }

      & > span.bold, & > p.bold {
        font-weight: 400;
      }

      & > span.compact, & > p.compact, & {
        padding-bottom: spacing.$spacing-xxs;
        min-height: 2rem;
      }

      li.compact {
        padding-bottom: spacing.$spacing-xxs;
        min-height: 2rem;
      }
    }
  }

  & sis-organisation-role-share .form-group-mimic {
    margin-bottom: 0;

    & > div {
      padding-bottom: spacing.$spacing-zero;
    }
  }

}
