@use 'sis-assets/foundations/spacing/tokens' as spacing;

sis-enrolment-right-add-modal {
  .modal-body {
    .row {
      margin-top: spacing.$spacing-md;
    }

    .row:first-child {
      margin-top: 0;
    }
  }
}
