@use 'sis-assets/foundations/breakpoints/breakpoints' as breakpoints;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

app-student-attainments {
  .app-student-attainments {
    @media (max-width: breakpoints.$screen-md-max) {
      & sis-expandable .expandable {

        &__variant__naked-dotted {
          &:not(.closed) {
            &::before {
              left: spacing.$spacing-sm;
            }
            &::after {
              left: 1.35rem;
            }
          }
        }

        &__header {
          &__variant__naked-dotted {
            padding-left: 0;
          }
        }

        &__title {
          &__icon {
            margin-right: 0;
          }
        }

        &__content {
          &__dotted {
            padding-left: spacing.$spacing-sm;
          }
        }
      }
    }
  }
}
