@use 'sis-assets/foundations/spacing/tokens' as spacing;

.sis-current-organisation-role-share-editor-container {
  & .btn.btn-naked.btn-sm {
    position: relative;
    top: -0.8rem;
    float: right;
    margin-right: 0;

    & + .label-row {
      clear: both;
      margin-top: 0.9rem;
    }
  }

  & .button-col {
    margin-top: spacing.$spacing-md;
  }

  & .percent-label {
    white-space: nowrap;
  }

  & .percent-editor {
    display: inline-block;
    width: 80%;
  }

  & .row {
    & .form-control.form-control-fake, sis-search-organisation {
      display: block;
    }

    & .percent-editor .form-control {
      margin-bottom: 1rem;
    }
  }

  & ul.error-list {
    &[ng-messages] {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .form-control-fake {
    padding: 0;

    .form-control {
      transition: none;
      margin: 0;
      border: 0;
      box-shadow: none;
    }
  }

  & sis-search-organisation {
    & sis-validation-errors {
      display: none;
    }

    & sis-typeahead {
      & sis-validation-errors {
        display: none;
      }
    }
  }

  & sis-code-select-editor {
    ul.error-list {
      display: none;
    }
  }
}
