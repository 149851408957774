@use 'sis-assets/foundations/breakpoints/breakpoints' as breakpoints;

app-open-university-enrolment-modal {
  .section-header {
    display: flex;
    flex-direction: column;
    margin-top: 1.8rem;
    margin-bottom: 0.9rem;

    .section-title {
      margin: 0;
    }

    .attainment-info {
      margin-left: -0.5rem;
    }

    @media(min-width: breakpoints.$screen-md-min) {
      flex-direction: row;
      column-gap: 2.5rem;
      align-items: center;

      .enrolment-count-indicator {
        flex-grow: 2;
        text-align: right;
      }
    }
  }
}
