sis-study-right-selector {
  & .label-flex {
    display: flex;

    & label {
      flex-grow: 1;
    }

    & sis-checkbox {
      padding-left: 1.2rem;
    }
  }

  & sis-dropdown-select {
    & sis-validation-errors {
      & .row {
        margin-bottom: 2rem;
      }
    }
  }
}
