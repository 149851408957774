literature-editor {
  & .literature-item {
    &, & + .row {
      padding-bottom: 0.7rem;
    }

    & .button-col {
      padding-left: 0;
    }
  }
}
