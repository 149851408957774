sis-module-content-workflow-structure {
  .header>.title {
    background-color: transparent !important;
  }

  .accordion-panel>.content {
    padding: 0;
  }
}

