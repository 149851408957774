@use 'sis-assets/foundations/spacing/tokens' as spacing;

is-edit-application-attachments-modal {
  & .buttons {
    display: flex;
    justify-content: flex-end;
    padding-top: spacing.$spacing-xl;
    padding-bottom: 2rem;
  }
}
