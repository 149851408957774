@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

.wrap-contents {
  flex-flow: row nowrap;
}

.remove-all-dialog-checkbox {
  margin: 1rem 2rem;
  cursor: pointer;
  width: 2rem;
  height: 2rem;
}

.remove-all-dialog-checkbox-text {
  padding-top: spacing.$spacing-xs;
}

.color-red {
  outline: spacing.$pixel-1 solid colors.$red;
}
