sis-attained-credits-by-study-term {
  & .listing-summary {
    margin-top: 2rem;
    margin-bottom: 1rem;
    text-align: right;

    & .credits {
      font-weight: 600;
    }
  }
}
