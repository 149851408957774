@use 'sis-assets/foundations/breakpoints/breakpoints' as breakpoints;
@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

@mixin attainment-tree-parent-responsive-screen($counter) {
  @if $counter < 10 {
    attainment-tree-parent {
      & .name-parent {
        padding-left: 4.6rem + 2.7rem * $counter;

        &.with-icon {
          padding-left: 4.2rem + 2.7rem * $counter;
        }
        &.without-icon {
          padding-left: 4.6rem + 2.7rem * $counter;
        }
      }
    }

    & .name-child {
      padding-left: 4.6rem + 2.7rem * $counter;
    }

    & attainment-tree-parent {
      @include attainment-tree-parent-responsive-screen($counter + 1);
    }
  }
}

attainment-tree {
  & [class^='col-'], div > .col-xl-9, div > .col-xl-1 {
    padding: 0 0.5rem;
  }

  & .non-button-inherit {
    border: none;
    background: none;
    cursor: pointer;
    padding-left: 0;
    width: 100%;
    text-align: left;
    color: inherit;
    font: inherit;
  }

  & .row {
    margin: 0;
    padding: 0;

    &.header {
      margin: 0;
      border-bottom: spacing.$pixel-1 solid colors.$light-gray;
      padding: 0.5rem 0;
      font-weight: bold;
      user-select: none;

      & button {
        display: flex;
        align-items: center;
        overflow: hidden;
        color: colors.$dark-gray;
        font-weight: 400;
      }

      & + attainment-tree-parent:first-of-type {
        border-top-width: 0;
      }

      & .date {
        white-space: nowrap;
      }
    }
  }

  & .credit, .grade, .date, .attainment-row-menu-button {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  & .attainment, .parent {
    padding: 0.5rem 0;
  }

  & attainment-tree-parent {
    display: block;
    border-top: spacing.$pixel-1 solid colors.$extra-light-gray;

    &.hide-child {
      & > attainment-tree-parent, & > attainment-tree-child {
        display: none;
        margin: 0;
        padding: 0;
      }
    }

    & span.rotatable {
      transform: rotate(90deg);
      transition: all .6s ease;
      margin-right: spacing.$spacing-xxs;
      background-color: rgb(255 255 255 / 30%);
      color: colors.$dark-gray;
    }

    & .parent {
      &.module, &.grouping-module {
        padding: 1rem 0;
        font-weight: 400;

        & .name {
          text-transform: uppercase;
        }

        & .credit, .grade, .date {
          text-transform: none;
          font-weight: 400;
        }

        & .attainment-arrow {
          display: block;
        }
      }

      & .name-parent {
        padding: 0 0.5rem;

        &.with-icon {
          display: flex;
          align-items: center;
        }

        & .code-parent {
          display: block;
          line-height: 2rem;
          color: colors.$middle-gray;
          font-size: 1.2rem;
          font-weight: 400;
          user-select: none;
        }

        & .attainment-arrow {
          display: block;
        }
      }
    }

    @media (min-width: breakpoints.$screen-md-min) {
      @include attainment-tree-parent-responsive-screen(0);
    }

    @media (max-width: breakpoints.$screen-md-max) {
      @include attainment-tree-parent-responsive-screen(0);
    }
  }

  & attainment-tree-child {
    display: block;
    border-top: spacing.$pixel-1 solid colors.$extra-light-gray;

    & .attainment {
      transition: all .6s ease, outline 0ms;

      &.module, &.grouping-module {
        padding: 1rem 0;
        font-weight: 400;

        & .name {
          text-transform: uppercase;
        }

        & .credit, .grade, .date {
          text-transform: none;
          font-weight: 400;
        }
      }

      &.valid-attainment {
        background-color: colors.$extra-light-gray;
        color: colors.$dark-gray;
      }
    }

    & .name-child {
      & .valid-attainment {
        float: right;
      }
    }

    & .code-child {
      display: block;
      line-height: 2rem;
      color: colors.$middle-gray;
      font-size: 1.2rem;
    }
  }
}
