@use 'sis-assets/foundations/breakpoints/breakpoints' as breakpoints;
@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/focus/mixins' as focus;
@use 'sis-assets/foundations/spacing/tokens' as spacing;
@use 'sis-assets/foundations/typography/tokens' as typography;

@mixin settings-menu-group {
  margin: spacing.$spacing-xxs 0;
  border-bottom: spacing.$pixel-1 solid colors.$light-gray;
  background-color: colors.$white;

  &--mobile {
    margin: 0;
    border: spacing.$pixel-1 solid colors.$dark-gray;
    border-top: 0;
    border-bottom-color: colors.$light-gray;
  }

  &__header {
    padding: spacing.$pixel-2 spacing.$spacing-xs;
    color: colors.$middle-gray;
    font-size: 1.2rem;
    font-weight: 400;

    // Depending on user OS, there are either one or two scrollbars on mobile menu. By adding padding we ensure that headers are always visible when menu is taking the whole view width.
    @media (max-width: breakpoints.$screen-xs-max) {
      padding: spacing.$pixel-2 spacing.$spacing-md;
    }
  }

  &__item {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    padding: spacing.$spacing-xs spacing.$spacing-md;
    white-space: normal;
    color: colors.$dark-gray;
    font-size: typography.$body-text-md-font-size;

    &:focus, {
      @include focus.fudis-focus-generic;
    }

    &:hover,
    &:focus,
    &:active {
      background-color: colors.$primary-color-light !important;
      color: colors.$dark-gray !important;
    }
  }
}

@mixin top-navigation-mobile-width {
  width: 32rem;

  &__item {
    width: 100%;
  }

  @media (max-width: breakpoints.$screen-xs-max) {
    width: 100vw;
  }
}

@mixin login-mobile {
  @include settings-menu-group;
  @include top-navigation-mobile-width;

  margin: 0;
  border: spacing.$pixel-1 solid colors.$dark-gray;
  border-top: 0;
}
