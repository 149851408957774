@use 'sis-assets/foundations/spacing/tokens' as spacing;

app-apply-for-module-content-workflow {
  & .apply-for-mca-header {
    margin-top: 0.8rem !important;
  }

  & .mca-buttons {
    margin-top: spacing.$pixel-3;
  }
}
