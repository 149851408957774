@use 'sis-assets/foundations/breakpoints/breakpoints' as breakpoints;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

timeline-controls {
  position: relative;
  right: 2rem;
  white-space: nowrap;

  & > ul {
    margin-left: spacing.$spacing-sm;
    padding: 0;
    width: 100%;
    list-style-type: none;

    & > li {
      display: inline-block;
    }
  }

  & .control.control-inline,
  & .control.control--inline { /* stylelint-disable-line */
    margin-bottom: 0;
    margin-left: spacing.$spacing-xl;

    @media(max-width: breakpoints.$screen-xs-max) {
      margin-right: spacing.$spacing-xs;
      margin-left: spacing.$spacing-sm;
    }
  }

  & sis-icon svg {
    cursor: pointer;
  }

  .align-right {
    text-align: right;
  }
}
