@use 'sis-assets/foundations/spacing/tokens' as spacing;

sis-handler-panel {
  .handler, .value {
    font-weight: 400;
  }

  .icon-height {
    padding-top: spacing.$pixel-3;
    height: spacing.$spacing-lg;
  }
}
