@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/focus/mixins' as focus;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

@mixin button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: spacing.$spacing-xs; // Default button receives right margin, this margin is removed from sis-button
  cursor: pointer;
  min-width: 5.5rem;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: spacing.$pixel-1;
}

@mixin color-button($color) {
  border-color: $color;
  background-color: $color;
  color: colors.$white;

  svg {
    fill: colors.$white;
  }

  &.btn-hollow {
    @include hollow-button($color);
  }

  &.btn-naked {
    @include naked-button($color);
  }
}

@mixin hollow-button($color) {
  background-color: colors.$white;
  color: $color;

  svg {
    fill: $color;
  }
}

@mixin naked-button($color) {
  border-color: transparent;
  background-color: transparent;
  color: $color;

  svg {
    fill: $color;
  }
}

@mixin non-button {
  border: 0;
  background-color: transparent;
  cursor: pointer;
  padding: 0;
  text-align: left;
  font-weight: 300;
}

@mixin disabled {
  color: colors.$middle-gray;

  svg {
    fill: colors.$middle-gray;
  }
}

button {
  &.non-button {
    @include non-button;
  }

  &.link-button {
    @include non-button;

    text-decoration: underline colors.$primary-color-dark solid;
    color: colors.$primary-color-dark;
    font: inherit;

    &:disabled {
      @include disabled;

      cursor: not-allowed;
      text-decoration: underline colors.$middle-gray solid;
    }

    svg {
      fill: colors.$primary-color-dark;
    }

    &.dashed-add-box {
      border: spacing.$pixel-1 dashed colors.$middle-gray;
      padding: spacing.$spacing-xs;
      width: 100%;
      text-align: center;

      & sis-icon[icon="ring-plus"] {
        margin-right: spacing.$spacing-xxs;
      }
    }
  }
}

.btn {
  @include button;

  &.form-control {
    border-radius: 0;
    padding: 0;
  }

  &.btn-lg {
    height: 3.5rem;
    font-size: 1.4rem;

    &.form-control {
      &:not(.btn-hollow, .btn-search) {
        border: 0 !important;
      }

      &:focus {
        @include focus.deprecated-sisu-focus-generic-inner;
      }

      &.simulate-label {
        margin-top: spacing.$spacing-md;
      }
    }
  }

  &.btn-sm {
    height: 2.5rem;
    font-size: 1.2rem;
  }

  &.btn-white {
    @include color-button(colors.$white);

    color: colors.$primary-color;

    svg {
      fill: colors.$primary-color;
    }

    &.btn-hollow {
      background-color: transparent;
    }
  }

  &.btn-primary {
    @include color-button(colors.$primary-color);
  }

  &.btn-secondary {
    @include color-button(colors.$dark-gray);
  }

  &.btn-success {
    @include color-button(colors.$green);
  }

  &.btn-just-icon {
    justify-content: center;
    min-width: 3.5rem;
  }

  &.btn, &.btn-primary, &.btn-naked, &.btn-hollow, &.btn-secondary, &.btn-white, &.btn-success {
    &.disabled, &:disabled {
      @include disabled;

      opacity: 1;
      border-color: colors.$light-gray;
      background-color: colors.$light-gray;
    }
  }
}

sis-button {
  &:not(:last-of-type) {
    margin-right: spacing.$spacing-xs !important
  }

  & .btn {
    @include button;

    margin-right: 0 !important;
  }

  & + sis-menu-button {
    margin-left: spacing.$spacing-xs;
  }

  &.form-control {
    border-radius: 0;
    padding: 0;

    &:not(.btn-hollow, .btn-search) {
      border: 0 !important;
    }

    .btn {
      height: spacing.$spacing-xl;
    }

    &.simulate-label {
      margin-top: 2.9rem;
    }
  }
}

