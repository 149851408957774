@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

sis-study-action-box {
  & .action-box {
    border-top: spacing.$pixel-1 solid colors.$dark-gray;
    background-color: colors.$extra-light-gray;
    font-size: 1.2rem;

    & .link-button {
      outline-offset: -0.3rem;
      // fix focus overflow
      box-shadow: none;
      padding-right: 0.6rem;
    }

    & sis-checkbox {
      margin: 0;
      padding: 0.6rem;

      & label {
        vertical-align: middle;
        line-height: 1.4rem;
        font-size: 1.2rem;
        font-weight: 400;
      }
    }
  }
}
