@use 'sis-assets/foundations/animation/animation' as animation;
@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

sis-open-university-product-creation-form {
  & sis-language-pills {
    & ul.nav.nav-pills-localize {
      bottom: spacing.$pixel-1;
    }
  }

  & .help-block-after-error-list {
    & ul.error-list {
      margin-bottom: 0 !important;
    }
  }

  & .registration-filter {
    & sis-radio-button {
      display: block;

      & + .help-block {
        margin: 0 0 1.5rem 3.5rem;
      }
    }
  }

  & .enrolment-limit {
    width: 10rem !important;
  }

  & .offered-realisations {
    margin-bottom: 2rem;

    & .help-block {
      margin-top: 0;
    }

    & .offered-realisations-give-all-rights-wrapper{
      display: flex;
      flex-wrap: nowrap;
    }

    & sis-form-checkbox {
      display: inline-block;

      & .control-indicator{
        min-width: 2rem;
      }
    }

    & sis-tooltip {
      position: relative;
      bottom: spacing.$pixel-3;
      left: 0.5rem;

      & .sis-tooltip-trigger-content {
        display: inline-block;
      }
    }
  }

  & .table.styled-table.table-condensed {
    & thead tr th {
      padding: 1.5rem 0.5rem 1rem; // to even out the available-during-title's margin-bottom
      &.available-during {
        padding-left: spacing.$spacing-xl;

        & .available-during-title {
          display: inline-block;
          margin: 0 1rem 0.5rem 0;
        }

        & sis-tooltip {
          vertical-align: top;

          & .sis-tooltip-trigger-content {
            display: inline-block;
            margin-top: -0.4rem;
          }

          & .treat-as-a-block-element {
            position: relative;
            top: -0.4rem;
            height: 0;
          }
        }

        & sis-form-toggle {
          display: inline-block;
          height: 0;
          vertical-align: top;

          & .checkbox-toggle.sis-toggle {
            display: flex;
            margin: -0.1rem 0 0;
          }
        }
      }
    }

    & tbody tr td {
      &.available-during {
        & sis-form-toggle {
          & .checkbox-toggle.sis-toggle {
            display: flex;
            margin: 0 0 0 50%;
          }
        }
      }
    }
  }

  & .custom-warning-label {
    margin: 0 0 0 spacing.$spacing-xxs;
    text-transform: none;
    color: colors.$red;
  }

  & .custom-warning-wrapper {
    @include animation.sis-animation-delay;

    display: flex;
    align-items: center;
  }
}
