@use 'sis-assets/foundations/breakpoints/breakpoints' as breakpoints;
@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

app-frontpage {
  display: block;

  & .front-page-title {
    color: black;
  }

  & .banner-content {
    @media (min-width: breakpoints.$screen-md-min) {
      padding-left: 3.5rem !important;
      padding-right: 3.5rem !important;
    }
  }

  & .frontpage-toggle {
    display: flex;
    justify-content: flex-end;
  }


  & .notifications-container {
    display: flex;
    flex-direction: column;
  }

  & .banner {
    width: 100%;
    background-color: #57B2C0 !important;
    background-image: url('../../../../../../../sis-assets/webapp/sis-assets/logo/Banner_transparent.svg');
    background-position: 35% center;
    height: 12rem;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (min-width: breakpoints.$screen-xl-min) {
      background-position: center center;
      height: 14rem;
    }
  }

  & .frontpage-container {
    & .no-components {
      text-align:center;
      font-size: 1.6rem;

      @media (max-width: breakpoints.$screen-sm-max) {
        text-align: left;
        font-size: 1.4rem;
      }
    }

    & .eiku {
      margin-top: spacing.$spacing-xxl;
      margin-bottom: spacing.$spacing-lg;
      text-align: center;

      & svg {
        width: 35rem;

        @media (max-width: breakpoints.$screen-sm-max) {
          width: 20rem;
        }
      }

      @media (max-width: breakpoints.$screen-sm-max) {
        margin-top: spacing.$spacing-lg;
      }
    }

    & .components {
      margin-top: spacing.$spacing-md;

      & .component {
        border: spacing.$pixel-1 solid colors.$middle-gray;
        padding: spacing.$spacing-md;
      }
    }

    & .alert-top-spacing, & .service-breaks {
      margin-top: spacing.$spacing-sm;
    }

  }
}
