localized-markup-string-editor, localized-markup-string,
localized-string-editor, localized-string,
sis-legacy-localized-string-editor {
  & ng-transclude {
    display: inline-block;
    position: relative;
    left: unset;
    float: none;
    clear: none;
    vertical-align: bottom;
    line-height: 2.8rem;

    & label {
      vertical-align: middle;
    }
  }
}
