@use 'sis-assets/foundations/breakpoints/breakpoints' as breakpoints;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

sis-plan-structure-module {
  .sis-plan-structure-module {
    @media (max-width: breakpoints.$screen-md-max) {
      & sis-expandable .expandable {

        &__variant__naked-dotted {
          &:not(.closed) {
            &::before {
              left: spacing.$spacing-sm;
            }
            &::after {
              left: 1.35rem;
            }
          }
        }

        &__header {
          &__variant__naked-dotted {
            padding-left: 0;
          }
        }

        &__title {
          &__icon {
            margin-right: 0;
          }
        }

        &__content {
          &__dotted {
            padding-left: spacing.$spacing-sm;

            & .open-module-info,
            & .study-credit-info {
              padding-left: spacing.$spacing-sm;
            }
          }
        }
      }

      .w-max-md-100 {
        width: 100%;
      }
    }

    @media (min-width: breakpoints.$screen-lg-min) {
      & .study-credit-info {
        & .fill-by-row {
          display: flex;

          & .tiny-static-form-group {
            padding-right: 1rem;
          }
        }
      }
    }
  }
}
