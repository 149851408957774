@use 'sis-assets/foundations/breakpoints/breakpoints' as breakpoints;
@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

plan-study-right-modal,
.plan-study-right-modal {
    .structure-preview-title {
      display: inline-block;
      margin-bottom: 0;
    }

  & .explanations {
    margin: 3rem 0 2rem 10rem;

    & .icon {
      display: inline-block;
      width: 2.8rem;
      text-align: center;
    }

    & .blue-text {
      color: colors.$primary-color;
    }

    & .blue-background {
      display: inline-block;
      position: relative;
      top: spacing.$pixel-3;
      margin-right: 0.9rem;
      background-color: colors.$primary-color-light;
      width: spacing.$spacing-sm;
      height: spacing.$spacing-sm;
    }

    & sis-icon[icon="hourglass"] {
      display: inline-block;
      width: 2.5rem;
      text-align: center;
    }

    & .guidance {
      margin-left: 0.5rem;
    }
  }

  & .study-right-part {
    margin: 2.5rem -1.5rem 2rem;
    border-top: spacing.$pixel-1 solid colors.$extra-light-gray;
    padding-top: 0.6rem;

    & .study-right-table {
      width: 100%;
    }

    & .selection-change-info {
      margin-top: 2rem;
      font-weight: 600;
    }
  }

  & .contextual-notification {
    margin-bottom: 1rem;
  }
}
