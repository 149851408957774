@use 'sis-assets/foundations/colors/colors' as colors;

sis-open-university-product-view {
  & .page-title-container {
    margin-bottom: 1.7rem;

    & h1 {
      margin: -1.5rem 0 0;
    }

    & .code-panel {
      color: colors.$middle-gray;
      font-size: 1.2rem;
      font-weight: 400;
    }
  }
}
