@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

custom-course-unit-attainment-box {
  .courseunit {
    display: block;
    margin-top: 0.5em;

    .cu-notification-bar {
       .course-unit-attainment-notification {
         div {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: space-between;
          width: 100%;
        }
      }
    }

    &.attainment-data-not-found {
      & .credits-box sis-icon {
        display: block;
        margin-top: 1rem;
        margin-left: spacing.$pixel-3;
      }

      & .grade-box sis-icon[icon="achievement"] {
        margin-top: 1rem;
      }

      & .courseunit-code {
        height: 1.9rem;
      }
    }
  }
}
