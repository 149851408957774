@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

sis-checkbox-iconified {
  label {
    display: flex;
    align-items: center;
    margin: 0;
    cursor: pointer;

    &.disabled {
      cursor: unset;
    }

    & .icon-checkbox {
      margin-right: 1rem;
      border: spacing.$pixel-1 solid colors.$middle-gray;
      padding: spacing.$pixel-1;
      width: 2rem;
      min-width: 2rem;
      height: 2rem;

      & svg {
        vertical-align: baseline;
      }

      &.disabled {
        border-color: colors.$light-gray;
        background-color: colors.$extra-light-gray;

        & svg {
          fill: colors.$middle-gray;
        }
      }
    }

    .labels {
      font-weight: 300;
      user-select: none;

      span {
        text-transform: none;
      }

      .secondary-label {
        margin-left: 0.5rem;
        font-size: 1.2rem;
        font-weight: 300;
      }
    }
  }
}
