// OLD Expandable styles for the old native expandables, DO NOT SIMPLY COPY PASTE STYLES FROM HERE!!

// TODO: Remove these styles when there is no longer native html using the style classes

@use 'sis-assets/foundations/breakpoints/breakpoints' as breakpoints;
@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

/* stylelint-disable */

@mixin accordion-panel-noblock {
  margin-bottom: 0;

  & > .header {
    background-color: transparent;

    & > .collapse {
      background-color: transparent;

      &.disabled {
        cursor: default;
        pointer-events: none;

        &:hover, &:active {
          box-shadow: none !important;
          background-color: colors.$white !important;
        }

        & sis-icon[icon="chevron-ring"] svg {
          &::before {
            border-radius: 60%;
            background-color: colors.$extra-light-gray;
          }
        }
      }
    }

    & > .title-line > .title-line-liner {
      border-bottom: spacing.$pixel-1 dotted colors.$light-gray;
      background-color: transparent;
    }

    & > .badge {
      background-color: colors.$extra-light-gray;
      color: colors.$dark-gray;
    }
  }
}

@mixin accordion-panel-inverted {
  & > .header {
    background-color: colors.$dark-gray;
    color: colors.$white;

    & > .collapse {
      background-color: colors.$dark-gray;
      color: colors.$white;

      & sis-icon[icon="chevron-ring"] svg {
        fill: colors.$extra-light-gray;
      }

      &.disabled {
        pointer-events: none;

        & sis-icon[icon="chevron-ring"] svg {
          fill: colors.$middle-gray;
        }
      }
    }

    & > .title {
      color: colors.$white;

      & > .additional {
        color: colors.$silver;
      }
    }

    & > .title-line > .title-line-liner {
      border-bottom: spacing.$pixel-1 solid colors.$middle-gray;
      background-color: colors.$middle-gray;
    }

    & > .badge {
      background-color: colors.$middle-gray;
      color: colors.$extra-light-gray;

      & sis-icon[icon="rule"] svg {
        fill: colors.$white;
      }
    }

    & > .cell {
      color: colors.$extra-light-gray;
    }
  }
}

.accordion-panel {
  margin: 0 0 0.7rem;
  padding: 0;
  width: 100%;

  &.no-expander {
    & .content {
      & .content-row {
        & .content-text {
          padding: 0;
          overflow-x: hidden;
        }
      }
    }
  }

  & > .header {
    display: flex;
    align-items: center;
    margin: 0;
    background-color: colors.$extra-light-gray;
    padding: 0.9rem 0 0.9rem 1rem;
    width: 100%;
    height: unset;
    table-layout: fixed;
    color: colors.$dark-gray;

    &.has-header-items {
      align-items: flex-start;
    }

    & > div {
      height: 100%;
    }

    & > .collapse {
      display: table-cell;
      background-color: colors.$extra-light-gray;
      cursor: pointer;
      text-align: center;

      &:last-child {
        float: left;
        padding-left: spacing.$pixel-2;
      }

      &:disabled {
        cursor: default;
      }
    }

    & > .icon {
      padding-left: 0.5rem;
      width: 5rem;
      color: inherit;
      font-size: 2.2rem;
      font-weight: 100;
    }

    & > .title {
      display: table-cell;
      margin: 0;
      padding-left: 0.7rem;
      overflow: hidden;
      text-transform: none;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: colors.$dark-gray;
      font-size: spacing.$spacing-sm;
      font-weight: 400;

      & .plan-module-name {
        overflow: hidden;
      }

      & > .additional {
        display: table-cell;
        clear: both;
        line-height: spacing.$spacing-sm;
        color: colors.$middle-gray;
        font-size: 1.4rem;
      }

      & h4 {
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    & > .title-line {
      display: flex;
      flex-grow: 1;
      align-items: center;
      min-height: 3.3rem;

      & > .title-line-liner {
        display: block;
        margin-left: 1rem;
        border-bottom: spacing.$pixel-1 dotted colors.$light-gray;
        background-color: transparent;
        width: 100%;
        height: spacing.$pixel-1;
        content: "";
      }
    }

    & > .badge {
      display: flex;
      align-items: center;
      margin-top: 0.5rem;
      margin-right: spacing.$pixel-2;
      border-radius: 0;
      background-color: colors.$white;
      padding: 0 1.2rem;
      height: 2.1rem;
      text-transform: uppercase;
      line-height: 1.4rem;
      color: colors.$dark-gray;
      font-size: 1.2rem;
      font-weight: 400;
    }

    & > .cell {
      display: table-cell;
      padding-right: 1rem;
      padding-left: 1rem;
      text-align: center;
      white-space: nowrap;
      color: colors.$dark-gray;

      &.credit {
        min-width: 8rem;
        font-size: 1.4rem;
        font-weight: 400;
      }

      &.status {
        min-width: 8rem;
        font-size: 1.2rem;
        font-weight: 400;
      }

      &.info-box {
        position: relative;
        right: 1rem;
        border: spacing.$pixel-1 solid colors.$light-gray;
        border-radius: 0.6rem;
        background-color: colors.$extra-light-gray;
        padding: 0;
        font-size: 1.2rem;
        font-weight: 400;

        & > .info-text {
          padding: spacing.$spacing-xxs 0.6rem;
        }
      }
    }
  }

  &.accordion-panel-noblock {
    @include accordion-panel-noblock;
  }

  &.accordion-panel-noblock-dotted-content {
    @include accordion-panel-noblock;

    & > .header {
      & .collapse {
        z-index: 1;
      }
    }

    & > .content {
      & > .content-row {
        & > .content-text {
          &::before {
            display: block;
            position: absolute;
            top: -1.2rem;
            left: 2.5rem;
            border-right: spacing.$pixel-1 dotted colors.$dark-gray;
            padding: 0;
            width: spacing.$pixel-1;
            max-width: spacing.$pixel-1;
            height: 100%;
            content: '';
          }

          &::after {
            display: block;
            position: absolute;
            bottom: spacing.$spacing-xxs;
            left: 2.2rem;
            border-radius: 50%;
            background-color: colors.$silver;
            width: 0.7rem;
            height: 0.7rem;
            content: "";
          }
        }
      }
    }
  }

  &.accordion-panel-primary {
    background-color: colors.$primary-color;

    & > .header {
      background-color: colors.$primary-color;

      & > .collapse {
        background-color: colors.$primary-color;
      }

      & > .title {
        color: colors.$extra-light-gray;

        & > .additional {
          color: colors.$extra-light-gray;
        }
      }

      & > .title-line > .title-line-liner {
        border: spacing.$pixel-1 solid colors.$primary-color-light;
        background-color: colors.$primary-color-light;
      }

      & > .badge {
        background-color: colors.$primary-color-dark;
        color: colors.$extra-light-gray;

        & sis-icon[icon="rule"] svg {
          fill: colors.$white;
        }
      }

      & > .cell {
        color: colors.$extra-light-gray;
      }
    }

    & .content {
      background-color: colors.$white;
    }
  }

  &.accordion-panel-inverted {
    @include accordion-panel-inverted;
  }

  &.accordion-panel-inverted-light {
    @include accordion-panel-inverted;

    & > .header {
      & > .collapse {
        & > sis-icon svg {
          margin-left: -0.3rem;
          margin-top: -0.2rem;
        }
      }

      & > .title {
        transition: all 0.4s linear;
        background: colors.$middle-gray;
      }
    }
  }

  &.accordion-panel-sm {
    & > .header {
      & > .title {
        font-size: 1.4rem;
      }
    }
  }

  &.accordion-panel-slimline {
    & > .header {
      display: block;
      position: relative;
      z-index: 1;
      margin-bottom: spacing.$spacing-xs;
      background-color: transparent;
      height: 1.9rem;
      overflow: visible;

      & > .collapse {
        display: inline-block;
        margin-left: spacing.$spacing-xxs;
        border-color: colors.$middle-gray;
        border-radius: 50%;
        background-color: colors.$middle-gray;
        padding-top: 0;
        padding-bottom: 0;
        height: 2.7rem;
        width: 2.7rem;
      }

      & > .title {
        display: inline-block;
        position: absolute;
        top: 1.2rem;
        left: 3.3rem;
        padding-left: 1.4rem;
        width: calc(100% - 3.3rem);
        text-transform: uppercase;
        line-height: 2rem;
        color: colors.$white;
        font-size: 1.2rem;
      }
    }

    & > .content {
      & > .content-row {
        & > .content-text {
          &::before {
            display: block;
            position: absolute;
            top: 0;
            left: 2.6rem;
            border-right: spacing.$pixel-1 dotted colors.$silver;
            padding: 0;
            width: spacing.$pixel-1;
            max-width: spacing.$pixel-1;
            height: 100%;
            content: '';
          }

          &::after {
            display: block;
            position: absolute;
            bottom: 0;
            left: 2.3rem;
            border-radius: 50%;
            background-color: colors.$silver;
            width: 0.7rem;
            height: 0.7rem;
            content: "";
          }
        }
      }
    }
  }

  // currently made to be used with accordion-panel-noblock or accordion-panel-primary,
  // shows the full title - no white-space: nowrap or overflow: hidden
  &.accordion-panel-long-title {
    &.open:not(.active) {
      &::before {
        display: block;
        position: absolute;
        top: 3.5rem;
        left: 2.6rem;
        border-right: spacing.$pixel-1 dotted colors.$dark-gray;
        padding: 0;
        width: spacing.$pixel-1;
        max-width: spacing.$pixel-1;
        height: calc(100% - 3.8rem);
        content: '';
      }

      &::after {
        display: block;
        position: absolute;
        bottom: spacing.$pixel-2;
        left: 2.3rem;
        border-radius: 50%;
        background-color: colors.$silver;
        width: 0.7rem;
        height: 0.7rem;
        content: "";
      }
    }

    & > .header {
      &.clickable:hover {
        background-color: colors.$primary-color-light;

        & .name, .plan-module-name, .additional {
          background-color: colors.$primary-color-light;
        }
      }

      & .collapse {
        margin-right: 0.7rem;
        vertical-align: top;
      }

      & .title {
        background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVQYV2O4cuXKfwAIeAN82rNzbgAAAABJRU5ErkJggg==') repeat-x;
        padding-left: 0;
        width: 100%;
        overflow: visible;
        vertical-align: top;
        white-space: normal;
        background-position-y: 50%;

        & .name, .plan-module-name, .custom-module-attainment-data-not-found {
          display: inline-block;
          position: relative;
          top: spacing.$pixel-2;
          background-color: colors.$white;
          padding-right: 1rem;
        }

        & .custom-module-attainment-data-not-found {
          position: absolute;
          top: spacing.$spacing-xs;
        }

        & .structure-edit {
          background: colors.$white;
          padding-left: 1rem;
        }

        & > .additional {
          position: relative;
          top: -0.1rem;
          background-color: colors.$white;
          padding-right: 1rem;
          line-height: spacing.$spacing-md;
        }
      }
    }

    & .content {
      & .content-row {
        & .content-text {
          &::before, &::after {
            content: none;
          }
        }
      }
    }

    &.active {
      & > .header {
        & .title {
          left: 0;
          background: none;

          & .name, .additional {
            background-color: colors.$primary-color;
          }
        }
      }
    }
  }

  .content {
    transition: all .4s linear;
    opacity: 1;
    margin: 0 0 spacing.$spacing-xs 0;
    padding: 0;

    &::-webkit-scrollbar {
      display: none;
    }

    .content-row {
      display: table;
      position: relative;
      width: 100%;
      table-layout: fixed;
      text-align: left;

      .contextual-notification .content-text {
        padding: spacing.$spacing-xs 0 0.5rem 0;
      }

      .content-text {
        padding: 1.5rem 1.5rem 1rem 5.5rem;
        width: 100%;

        @media(max-width: breakpoints.$screen-sm-max) {
          padding: spacing.$spacing-xs spacing.$spacing-xs spacing.$spacing-xxs spacing.$spacing-lg;
        }
      }
    }
  }
}


