.select-course-unit-and-completion-method-modal {
  & course-unit-box {
    display: block;
    margin-top: 1em;

    &, & * {
      cursor: pointer !important;
    }
  }
}
