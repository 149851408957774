@use 'sis-assets/foundations/spacing/tokens' as spacing;

sis-course-unit-cross-study {
  p {
    font-size: spacing.$spacing-sm;
  }

  .no-results {
    font-style: italic;
  }

  .network-info {
    margin-bottom: spacing.$spacing-md;
  }

  dt {
    margin-bottom: spacing.$spacing-xs;
  }
}
