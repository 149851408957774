sis-degree-programme-attainment-application-answer {
  .application-question-guidance {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    margin-bottom: 0.5em;
  }

  .application-question-guidance-info-icon {
    display: inline-block;
  }

  .application-question-guidance-text {
    align-self: center;
    line-height: 2rem;
    font-size: 1.2rem;
  }

  .application-question-guidance-text,
  .show-more-text-content {
    margin-bottom: 0;
  }

}
