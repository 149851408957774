@use 'sis-assets/foundations/breakpoints/breakpoints' as breakpoints;
@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

h1.fake-crumbs,
.page-title-container.fake-crumbs h1 {
  & .tiny-badge {
    vertical-align: 50%;
  }
}

sis-page-title {
  .page-title-container {
    min-height: 4.2rem;

    &.no-crumbs {
      padding-top: spacing.$spacing-md;
      min-height: 6.6rem;
    }

    & .page-sub-title {
      line-height: spacing.$spacing-md !important;
      font-size: 1.5rem !important;
    }

    & .page-title {
      display: inline-block;
      transition: all 0.3s ease, font-size 0.4s ease, padding-left 0.3s ease, border-bottom 0.1s ease;
      border-bottom: spacing.$pixel-1 solid rgb(255 255 255 / 0%);
      padding-right: spacing.$spacing-md;
      max-width: 100vw;
      overflow-wrap: break-word;

      & .badge-container {
        display: inline;
      }

      & .tiny-badge {
        vertical-align: middle;
      }
    }
  }
}

.page-title-container.fake-crumbs { // in case of h1 with fake-crumbs
  margin-top: spacing.$spacing-xxl;
  min-height: 3.6rem;

  @media (max-width: breakpoints.$screen-xs-max) {
    margin-top: 4.8rem;
  }

}
