@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

toggle-box.transparent {
  background-color: transparent;

  .panel-default, .panel-heading, .panel-body, .panel-body, .list-group, .list-group-item {
    border-color: transparent;
    box-shadow: none;
    background-color: transparent;
  }

  .list-group-item {
    padding: 0;
  }
}

toggle-box {
  .panel-heading {
    -webkit-touch-callout: none; /* iOS Safari */
    user-select: none; /* Chrome/Safari/Opera */
    cursor: pointer;

    label {
      cursor: pointer;
    }
  }

  .icon-spacing-right {
    padding-right: 0.6rem;
  }

  .left-spacing {
    margin-left: 0;
  }

  &.primary-styling {
    .panel-heading {
      border-color: colors.$primary-color;
      background-color: colors.$primary-color;
      color: colors.$white;

      .glyphicon {
        top: 0.6rem;
        margin-top: -0.5rem;
        margin-right: 1rem;
        border: 0;
        border-radius: 1.5rem;
        background-color: rgba(255 255 255 / 30%);
        padding: 0.5rem;
        color: colors.$white;
      }
    }

    .panel-default {
      border-color: colors.$primary-color;
    }

  }
}
