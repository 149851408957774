@use "@funidata/ngx-fudis" as fudis;
@use 'sis-assets/foundations/colors/colors' as colors;

app-plan-structure-free-edit {
  & .free-edit-dialog .fudis-dialog {
    margin: 1rem;
    background-color: colors.$white;
    padding: 1rem 3rem;

    @include fudis.breakpoint-max('md') {
      width:auto;
    }
  }
}

.mat-mdc-dialog-container .mdc-dialog__surface:has(.free-edit-dialog) {
  background: repeating-linear-gradient(45deg, colors.$primary-color-dark, colors.$primary-color-dark 1rem, colors.$primary-color 1rem, colors.$primary-color 2rem);
}
