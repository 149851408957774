@use 'sis-assets/foundations/breakpoints/breakpoints' as breakpoints;
@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;
@forward './controls/timelineControls.scss';
@forward './line/courseUnit/timelineCourseUnit.scss';
@forward './line/note/timeline-note/timeline-note.component.scss';
@forward './line/plannedTeachingPeriod/plannedTeachingPeriod.scss';
@forward './line/studyRightIndicator/studyRightIndicator.scss';
@forward './line/terms.scss';
@forward './line/timeline.scss';
@forward './untimedCourseUnits/untimedCourseUnits.scss';
@forward './controls/removeAllDialog.scss';

timing { /* stylelint-disable-line */
  .container-fluid {

    & .collapsible-columns-container {
      & .left-column {
        transition: top 0.4s ease, opacity 0.3s linear, width 0.3s ease-in-out;
        transition-delay: 0.1s;

        &.closed {
          transition: top 0.4s ease, opacity 0.3s linear, width 0.2s ease-in-out;
          transition-delay: 0s;
        }
      }

      & .collapsible-column-content {
        padding: 1.2rem 2rem 50rem spacing.$spacing-zero;
      }

      & .plan-select {
        h1 {
          margin-top: 0;
          margin-bottom: 0;
        }
      }
    }
  }

  .breadcrumb-container {
    padding-top: spacing.$spacing-md;
    padding-left: spacing.$spacing-xxs;
  }

  sis-contextual-notification {
    & a {
      cursor: pointer;
    }
  }

  .top {
    margin: 0;
  }

  .timeline {
    position: relative;
    margin: 1rem 0 14rem;

    & .contextual-notification {
      margin: 0 1.5rem 0 2rem;
    }
  }

  .timeline-controls-row {
    padding: 3rem 0 0 spacing.$spacing-sm;

    .timeline-control-buttons {
      float: right;
      padding: 2rem spacing.$spacing-md 0 0;
      text-align: right;

      button {
        margin-left: 2rem;
        text-transform: uppercase;
        font-weight: bolder;
      }
    }

    @media (max-width: breakpoints.$screen-sm-max) {
      padding-left: spacing.$spacing-xxs;
    }

  }

  .control-box {
    display: block;
    position: fixed;
    right: 0;
    bottom: 15.8rem;
    left: 0;
    opacity: 0.9;
    margin: auto;
    border-radius: 0.5rem;
    background-color: colors.$primary-color;
    padding: 1rem;
    width: 20rem;
    height: auto;
    text-align: center;

    & span {
      display: block;
      line-height: 2rem;
      color: colors.$white;
      font-style: italic;

      & .active-course-unit-name {
        padding-bottom: 1rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-weight: 600;
        font-style: normal;
      }
    }

    & .btn-white.btn-hollow {
      margin-right: 0;
    }
  }

  .untimed-bottom-holder {
    position: fixed;
    left: 0;
    transition: bottom 0.2s linear;
    transition-delay: 0.1s;
    padding-right: 0;
    padding-left: 0;

    &:not(.focused) {
      bottom: 0;

      & ul.nav.nav-tabs li.active {
        > a {
          background-color: colors.$dark-gray;
        }
      }
    }

    &.hidden-by-scroll {
      bottom: -6.5rem;
      transition: bottom 0.2s linear;
      transition-delay: 0.05s;

      & .info-timed-cus {
        bottom: 3rem;
      }
    }

    &.focused {
      top: 10.5rem;

      & untimed-course-units {
        & .row.top {
          /* stylelint-disable-next-line alpha-value-notation */
          /* stylelint-disable-next-line color-function-notation */
          background-color: rgba(255, 255, 255, 90%);
          padding-top: 2.5rem;

          &::before {
            display: block;
            position: absolute;
            top: -10.5rem;
            left: 0;
             /* stylelint-disable-next-line alpha-value-notation */
             /* stylelint-disable-next-line color-function-notation */
            background-color: rgba(255, 255, 255, 90%);
            width: 100%;
            height: 10.5rem;
            content: "";
          }

          & .info-timed-cus {
            width: auto;
            max-width: 99vw;
          }
        }

        & .row.bottom {
          background-color: colors.$dark-gray;

          & ul > li {
            display: block;
          }

          & .tab-pane.active {
            & > .row {
              height: calc(100vh - 29.3rem);
              overflow: auto;
            }
          }
        }
      }
    }
  }

  & .collapsible-columns-sidebutton {
    position: fixed;
    bottom: calc(-12rem + 40vh); // so this side button stays on the screen when using reasonable vertical screen sizes and some of the unreasonable ones also
    left: 0;
    -webkit-transform: rotate(270deg);
    -ms-transform: rotate(270deg);
    transform: rotate(270deg);
    -webkit-transform-origin: left top 0;
    -ms-transform-origin: left top 0;
    transform-origin: left top 0;
    z-index: 2;
    margin: 0;

    &.closed {
      display: none;
    }

    /* stylelint-disable-next-line no-descending-specificity */
    & button {
      -webkit-transition: background-color 350ms;
      transition: background-color 350ms;
      border: none;
      border-radius: 0 0 spacing.$spacing-xxs spacing.$spacing-xxs;
      background-color: colors.$primary-color;
      cursor: pointer;
      padding: spacing.$spacing-xs 3rem;
      letter-spacing: 0.03rem;
      color: colors.$white;
      font-weight: 600;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
  }

  & .collapsed {
    & .timing-header {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 1003;
      margin: 0;
      border-bottom: spacing.$pixel-1 solid colors.$light-gray;
      background: colors.$white;
      width: 100vw;

      & .plan-select {
        padding-left: 1rem;

        & sis-breadcrumbs {
          position: absolute;
          top: -999.9rem;
          left: -999.9rem;
          visibility: hidden;
        }
      }

      & .choose-plan {
        position: relative;
        white-space: nowrap;

        & h1 {
          margin: spacing.$pixel-1 0 0 0;
          line-height: 4.4rem;

          @media (min-width: breakpoints.$screen-sm-min) {
            font-size: spacing.$spacing-md;
          }
        }

        & > .dropdown {
          & > .dropdown-menu {
            margin-top: -1.7rem;
          }
        }

        & .plan-description {
          display: none;
        }

      }
    }

    & .timeline-controls {
      & timeline-controls {
        z-index: 1003;
        background-color: colors.$white;

        &::before {
          display: block;
          position: absolute;
          top: 0;
          left: -2rem;
          background-color: transparent;
          background-image: linear-gradient(to right, rgba(241, 241, 241, 0.000) 0%, colors.$white 75%, colors.$white 100%);
          width: 2rem;
          height: 4.1rem;
          content: "";
        }

        & ul.timeline-controls {
          margin: 0.8rem -0.8rem 0.8rem 0;
          padding-left: 0;

          & .non-button {
            overflow: hidden;
          }
        }
      }
    }
  }
}

.teaching-matches {
  color: colors.$green;
}

.timeline-icon-required,
.timeline-icon-alert {
  margin-left: -3.2rem;
}
