@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

localized-string-editor {
  .localized-string-label {
    display: flex;
    align-items: end;
    justify-content: space-between;

    & .actions {
      display: inline-block;
    }

    & label:only-child {
      display: block;
    }
  }

  .string-input {
    position: absolute;
    left: -500rem;

    &.active {
      position: relative;
      left: auto;
    }

    &[readonly] {
      color: colors.$middle-gray;
      pointer-events: none;

      &.active {
        border-color: colors.$light-gray;
      }
    }
  }

  & ul.error-list {
    margin-top: spacing.$spacing-sm;
    margin-bottom: 0;
    list-style-type: none;
    line-height: spacing.$spacing-sm;
    color: colors.$red;
    font-size: 1.2rem;
  }
}
