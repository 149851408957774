@use 'sis-assets/foundations/colors/colors' as colors;

// Not sure if this is still necessary?
// Select2 overrides and extensions.

/**
 * Adjust Select2's choices hover and selected styles to match Bootstrap 3's default dropdown styles.
 * https://fk.github.io/select2-bootstrap-css/
 */

.select2-match {
  text-decoration: underline;
}

// Highlighted state for search results
// Highlighted state for search results
.select2-results {
    b {
        color: colors.$primary-color;
        font-weight: normal;
    }
}

// Highlighted state.
.select2-results .select2-highlighted {
    background-color: colors.$bg-color-alt;
    color: inherit;
}

// Disabled state.
.select2-results .select2-disabled {
  background-color: inherit;
  color:  colors.$middle-gray;

  // "Restore" highlighted state. Select2 doesn't add the .select2-highlighted
  // class for disabled items.
  &:hover {
    background-color: colors.$bg-color-alt;
  }

  // Make cursor not allowed
  &, .select2-result-label {
    cursor: not-allowed;
  }

  // Make labels look disabled
  .label {
    opacity: 0.5;
  }
}
