@use 'sis-assets/foundations/spacing/tokens' as spacing;

div.assessment-item-table-container {
  padding-top: spacing.$spacing-md;
}

span.title {
  display: block;
}

.description-text {
  font-weight: 400;
}
