@use 'sis-assets/foundations/breakpoints/breakpoints' as breakpoints;
@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

sis-wizard-progress {
  & .sis-wizard-progress {
    margin-top: spacing.$spacing-md;
    padding: 0;

    &__step {
      display: inline-flex;
      position: relative;
      align-items: center;
      margin-right: spacing.$spacing-xs;
      margin-bottom: spacing.$spacing-xs;
      background-color: colors.$light-gray;
      padding: spacing.$spacing-xs spacing.$spacing-sm spacing.$spacing-xs spacing.$spacing-xs;
      min-height: spacing.$spacing-lg;
      list-style: none;
      line-height: 1.4rem;
      letter-spacing: 0.15rem;
      color: colors.$dark-gray;
      font-size: 1.2rem;
      font-weight: 400;
      -webkit-font-smoothing: subpixel-antialiased;
      -moz-osx-font-smoothing: grayscale;

      &::after,
      &::before {
        display: inline-block;
        position: absolute;
        top: 0;
        border-width: spacing.$spacing-sm 0.6rem;
        border-style: solid;
        width: 0.5rem;
        min-height: spacing.$spacing-lg;
        content: "";
      }

      &::after {
        right: -1.2rem;
        border-color: transparent transparent transparent colors.$light-gray;
        filter: drop-shadow(spacing.$spacing-xxs 0 colors.$white);
      }

      &::before {
        left: 0;
        border-color: transparent transparent transparent colors.$white;
      }

      &:first-of-type {
        &::before {
          display: none;
        }
      }

      &:last-of-type {
        &::after {
          display: none;
        }
      }

      &:not(:first-of-type) {
        padding-left: spacing.$spacing-sm;
      }

      &:last-child {
        &::after {
          display: none;
        }
      }

      &__arrow-mobile__tail, &__arrow-mobile__head {
        display: none;
      }

      &--current {
        background-color: colors.$primary-color;
        color: colors.$white;
        font-weight: 600;

        &::after {
          border-left-color: colors.$primary-color;
        }
      }

      &--completed {
        background-color: colors.$middle-gray;
        color: colors.$white;

        &::after {
          border-left-color: colors.$middle-gray;
        }
      }
    }

    @media (min-width: breakpoints.$screen-sm-min) {
      &__step {
        &--current {
          & .sis-wizard-progress__step__title {
            text-decoration: underline;
          }
        }
      }
    }

    @media (max-width: breakpoints.$screen-xs-max) {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;

      &__step {
        margin-bottom: 0;
        background-color: transparent;
        padding: 0;

        &::before, &::after {
          display: none;
        }

        &:not(:first-of-type) {
          padding-left: 0;
        }

        &__arrow-mobile__tail, &__arrow-mobile__head {
          display: block;
        }

        &__index {
          display: flex;
          position: relative;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          margin-right: spacing.$spacing-xs;
          background-color: colors.$light-gray;
          min-width: spacing.$spacing-lg;
          height: spacing.$spacing-md;

          & svg {
            position: absolute;

            &.sis-wizard-progress__step__arrow-mobile__tail {
              bottom: 100%
            }

            &.sis-wizard-progress__step__arrow-mobile__head {
              top: 100%
            }
          }
        }

        &--current {
          & .sis-wizard-progress__step__content {
            background-color: initial;
          }

          & .sis-wizard-progress__step__index {
            background-color: colors.$primary-color;
            text-decoration: underline;
          }

          & .sis-wizard-progress__step__title {
            text-decoration: none;
          }

          & svg path {
            fill: colors.$primary-color;
          }
        }

        &--completed {
          & .sis-wizard-progress__step__index {
            background-color: colors.$middle-gray;
            color: colors.$white;
          }

          & svg path {
            fill: colors.$middle-gray;
          }
        }

        &__space {
          width: 0;
        }

        &__title {
          color: colors.$dark-gray;
        }
      }
    }
  }
}

// TODO: Convert all native code wizards to use sis-wizard-progress component and remove these styles

ul.wizard-progress {
  li {
    display: inline-block;
    position: relative;
    margin-right: 0.6rem;
    margin-bottom: 1rem;
    background-color: colors.$light-gray;
    padding: spacing.$spacing-xs 1.5rem spacing.$spacing-xs 0.9rem;
    height: 2.8rem;
    list-style: none;
    line-height: 1.4rem;
    letter-spacing: 0.15rem;
    color: colors.$dark-gray;
    font-size: 1.2rem;
    font-weight: 400;
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: grayscale;

    &::after {
      display: inline-block;
      position: absolute;
      top: 0;
      right: -1rem;
      border-width: 1.4rem 0.5rem;
      border-style: solid;
      border-color: transparent transparent transparent colors.$light-gray;
      width: 0.5rem;
      height: 2.8rem;
      content: "";
      filter: drop-shadow(0.4rem 0 colors.$white);
    }

    &:last-child {
      &::after {
        display: none;
      }
    }

    & + li {
      padding-left: 2rem;

      &::before {
        display: inline-block;
        position: absolute;
        top: 0;
        left: -0.1rem;
        border-width: 1.4rem 0.5rem;
        border-style: solid;
        border-color: transparent transparent transparent colors.$white;
        width: 0.5rem;
        height: 2.8rem;
        content: "";
      }
    }

    &.completed-progress {
      background-color: colors.$light-gray;

      &::after {
        border-left-color: colors.$light-gray;
      }
    }

    &.current-progress {
      background-color: colors.$primary-color;
      color: colors.$white;
      font-weight: 600;

      &::after {
        border-left-color: colors.$primary-color;
      }

      & ~ li {
        background-color: colors.$extra-light-gray;

        &::after {
          border-left-color: colors.$extra-light-gray;
        }
      }
    }
  }
}
