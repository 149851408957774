@use 'sis-assets/foundations/colors/colors' as colors;

@mixin grouping-node-remove-loop($counter) {
  @if $counter < 10 {
    & .name {
      position: relative;
      left: -6rem * $counter;
    }
  }
}

sis-module-attainment {
  & h2 {
    margin-bottom: 2rem;
  }

  & sis-attainment-tree-nodes {
    &.grouping-node-removed {
      @include grouping-node-remove-loop(1);
    }

    & sis-attainment-tree-group-node {
      & .editable-attainment-tree-group-node-row {
        & .name {
          color: colors.$dark-gray;
        }
      }
    }
  }

  & .confirmation-summary {
    margin-top: 5rem;
  }
}
