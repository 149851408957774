@use 'sis-assets/foundations/breakpoints/breakpoints' as breakpoints;
@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

@mixin plan-structure-loop($counter) {
  @if $counter < 10 {
    & .content {
      @include plan-structure-loop($counter + 1);

      & .title {
        @media (min-width: breakpoints.$screen-xs-min) {
          max-width: calc(34rem - 4.5rem * $counter);
        }

        @media (min-width: breakpoints.$screen-sm-min) {
          max-width: calc(32rem - 4.5rem * $counter);
        }

        @media (min-width: breakpoints.$screen-md-min) {
          max-width: calc(54rem - 4.5rem * $counter);
        }

        @media (min-width: breakpoints.$screen-lg-min) {
          max-width: calc(74rem - 4.5rem * $counter);
        }

        @media (min-width: breakpoints.$screen-xl-min) {
          max-width: calc(113rem - 4.5rem * $counter);
        }
      }
    }
  }
}

plan-structure {
  & .state-credits-header {
    margin-bottom: -2rem;

    & h4 {
      margin: 0;
    }

    & .module-grade-average-header {
      float: left;
      min-width: 12rem;

      @media (max-width: breakpoints.$screen-md-max) {
        min-width: 11rem;
      }
    }

    & .module-state-header {
      float: left;
      min-width: 10rem;

      @media (max-width: breakpoints.$screen-md-max) {
        min-width: 6rem;
      }
    }

    & .module-credits-header {
      display: inline-block;
      min-width: 11rem;
      text-align: center;

      & .sub-headers {
        line-height: 1rem;
        font-size: 1rem;
        font-weight: 400;

        & .credits-done {
          border-left: spacing.$pixel-1 solid colors.$middle-gray;
          padding-left: spacing.$pixel-3;
          color: colors.$middle-gray;
        }
      }
    }
  }

  & .accordion-panel {
    margin-top: spacing.$spacing-md;

    & .header {
      padding: 0;

      & > .title-line {
        margin-right: spacing.$spacing-lg;
      }

      & .title {
        & .additional {
          padding-top: spacing.$pixel-2;
        }

        @media (max-width: breakpoints.$screen-sm-max) {
          max-width: 30rem;
        }

        @media (min-width: breakpoints.$screen-md-min) {
          max-width: 53.5rem;
        }

        @media (min-width: breakpoints.$screen-lg-min) {
          max-width: 73.5rem;
        }

        @media (min-width: breakpoints.$screen-xl-min) {
          max-width: 112.5rem;
        }
      }

      & .cell {
        display: inline-flex;
        align-items: center;
        font-weight: 400;
      }

      & .average {
        min-width: 9rem;

        @media (max-width: breakpoints.$screen-md-max) {
          min-width: 4.5rem;
        }
      }

      & .status {
        line-height: 2rem;
        font-size: 1.2rem;

        & .state-attained {
          flex-direction: column;
          align-items: flex-start;
          text-align: left;
          color: colors.$green;

          > span:first-of-type {
            display: block;
          }
        }

        & .state-invalid {
          display: flex;
          align-items: center;
          width: 9rem;
          color: colors.$accent;

          @media (min-width: breakpoints.$screen-lg-min) {
            margin-left: -3.2rem;
          }
        }

        & .state {
          display: table-cell;
        }

        @media (min-width: breakpoints.$screen-lg-min) {
          min-width: 10rem;
        }
      }

      & .module-credits {
        flex-direction: column;
        min-width: 11rem;
      }
    }

    & .content {
      @include plan-structure-loop(1);

      margin: 0;

      & .content-row {
        padding-bottom: 2rem;

        & .content-text {
          display: inline-block;
          padding: 0 0 0 4.4rem;

          & common-plan-module,
          & sis-plan-module {
            & common-course-unit,
            & sis-plan-course-unit,
            & custom-course-unit-attainment-box,
            & sis-plan-custom-course-unit-attainment,
            & custom-study-draft,
            & sis-plan-custom-study-draft {
              display: inline-block;
              margin: 0 spacing.$spacing-md spacing.$spacing-md 0;
              width: 28rem;
            }
          }

          & .courseunit-substitution-group {
            display: inline-block;
            margin: 0 spacing.$spacing-md spacing.$spacing-md 0;
            width: 28rem;

            common-course-unit, sis-plan-course-unit {
              &:first-child {
                margin-top: 0;
              }

              & .courseunit {
                width: 27.2rem;
              }
            }
          }

          .substituted-course-unit {
            display: inline;
          }
        }
      }
    }

    &.accordion-panel-noblock-dotted-content {
      & > .content {
        & > .content-row {
          & > .content-text {
            &::before {
              left: spacing.$spacing-sm;
            }

            &::after {
              left: 1.3rem;
            }
          }
        }
      }
    }
  }
}

.plan-structure-study-tooltip {
  text-align: left;

  & .study-name {
    text-transform: uppercase;
    font-weight: 400;
  }

  & .first-level {
    margin-top: 0.5rem;
  }

  & .second-level {
    margin-top: 1rem;
  }

  & .second-level-title {
    margin-bottom: spacing.$pixel-3;
    text-transform: uppercase;
    font-weight: 400;
  }
}
