@use 'sis-assets/foundations/breakpoints/breakpoints' as breakpoints;
@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/focus/mixins' as focus;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

sis-message-conversation-list-item {
  & ul.message-conversations {
    padding: 0;

    & li {
      border-bottom: spacing.$pixel-1 solid colors.$extra-light-gray;
      list-style: none;

      & .list-item {
        display: flex;
        border-left: 0.5rem solid transparent;

        &:hover, &:active {
          background-color: colors.$primary-color-light;
        }

        &.unread-message {
          border-left: 0.5rem solid colors.$primary-color;

          & .message-sender, & .message-title {
            font-weight: 600;
          }
        }

        & .checkbox-container {
          padding-top: 1.2rem;
          padding-left: spacing.$spacing-xs;
        }

        & .message {
          flex-grow: 1;
          margin: 0;
          cursor: pointer;
          padding: 0.8em 0 0;
          min-height: 9rem;
          line-height: 1.8rem;
          color: colors.$dark-gray;

          &:focus {
            @include focus.deprecated-sisu-focus-generic-inner;
          }

          & .message-content {
            display: grid;

            & .message-preview-header {
              display:flex;
              flex-direction: row;
              align-items: center;
              justify-content: start;
              padding-right: spacing.$spacing-sm;
              padding-left: spacing.$spacing-sm;

              & .message-conversation-date {
                margin-left: auto;

                @media (max-width: breakpoints.$screen-md-max) {
                  margin-left: 0;
                }
              }

              & .message-preview-header-additional {
                margin-bottom: spacing.$spacing-xxs;
                border-right: spacing.$pixel-1 solid colors.$dark-gray;
                padding-right: spacing.$spacing-xs;
                padding-left: spacing.$spacing-xs;

                @media (max-width: breakpoints.$screen-md-max) {
                  border-right: none;
                  border-left: spacing.$pixel-1 solid colors.$dark-gray;
                  line-height: 1.8rem;
                }
              }

              & > .message-preview-header-additional {
                &:first-child {
                  border-left: none;
                  padding-left: 0;
                }

                &:nth-last-child(2) {
                  border-right: none;
                }

                &:last-child {
                  border-right: none;
                }
              }

              @media (max-width: breakpoints.$screen-md-max) {
                flex-direction: column;
                align-items: flex-start;
              }
            }
          }

          & .message-snipped {
            display: inline-block;
            padding-right: spacing.$spacing-sm;
            padding-left: spacing.$spacing-sm;
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            & .message-title {
              padding-top: spacing.$spacing-xs;
              overflow: hidden;
              text-overflow: ellipsis;
              font-weight: 400;
            }

            & .message-comment {
              padding-top: spacing.$spacing-xs;
              padding-bottom: spacing.$spacing-xs;
              overflow: hidden;
              text-overflow: ellipsis;
              font-weight: 300;
            }
          }
        }
      }
    }
  }
}
