@use 'sis-assets/foundations/breakpoints/breakpoints' as breakpoints;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

organisation-role-share-validity-group-editor {
  display: block;
  margin-left: 0 !important;

  & .form-group-mimic {
    & span {
      margin-left: spacing.$spacing-sm;
    }
  }

  & ul.error-list {
    &[ng-messages] {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  & .grouping-panel-content {
    & .organisation-row {
      & .button-col {
        margin-top: 0;

        &.first {
          margin-top: spacing.$spacing-md;
        }

        & .btn-primary{
          height: 3.6rem;
        }
      }
    }
  }

  @media (max-width: breakpoints.$screen-sm-max) {
    & .grouping-panel-content {
      & .organisation-row {
        margin-bottom: 3.8rem;

        & .button-col {
          margin-top: 3rem;
        }
      }
    }
  }

  @media (min-width: breakpoints.$screen-md-min) {
    & .grouping-panel-content {
      & .organisation-row {
        margin-bottom: 1rem;

        & .form-group, .button-col {
          padding-right: 0;
        }

        & .form-group:not(.form-group-help, .form-group-invalid) {
          margin-bottom: 0;
        }
      }
    }
  }
}
