@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

.grouping-panel {
  position: relative;
  margin-right: -1.5rem !important;
  margin-left: -1.5rem !important;
  padding-bottom: spacing.$pixel-1;

  &::before {
    display: block;
    position: absolute;
    top: 1rem;
    border-width: spacing.$pixel-1 0 0 spacing.$pixel-1;
    border-style: dotted;
    border-color: colors.$middle-gray;
    width: 0.9rem;
    height: calc(100% - 1rem);
    content: '';
  }

  &::after {
    display: block;
    position: absolute;
    bottom: 0;
    left: -0.2rem;
    border-radius: 0.5rem;
    background: colors.$middle-gray;
    width: 0.5rem;
    height: 0.5rem;
    content: '';
  }

  &:not(.form-group) {
    margin-bottom: 3.8rem;
  }

  > *:not(.grouping-panel-content),
  > .form-group:not(.grouping-panel-content) {
    margin-left: 1.5rem;
  }

  & .header-badge {
    margin-left: spacing.$pixel-3;
  }

  > .row {
    margin-left: 0;
  }

  & h4,
  & .grouping-panel-header {
    display: inline-block;
  }

  > label,
  > h4,
  > .grouping-panel-header {
    &.required,
    &.valid,
    &.invalid {
      &::before {
        margin-bottom: spacing.$pixel-3;
        background-color: colors.$white;
        height: spacing.$spacing-sm;
      }
    }

    + sis-menu-button {
      & .btn {
        margin-right: 1.5rem;
      }
    }

    + sis-tooltip {
      margin-left: 1rem !important;

      & .icons-wrapper {
        display: inline-block;
      }
    }
  }

  + .add-new,
  + .add-new-block {
    margin-top: 2rem;
  }

  & .add-new-block,
  + .add-new-block {
    display: block;
    margin-right: 0;
    margin-left: -1.5rem;
    border: spacing.$pixel-1 dashed colors.$middle-gray;
    padding: spacing.$spacing-xs;
    width: 100%;
    text-align: center;
    font-weight: 300;

    & sis-icon[icon="ring-plus"] {
      margin-right: spacing.$spacing-xxs;
    }
  }

  & .grouping-panel-content {
    display: block;
    padding-left: 3.5rem;
    width: calc(100% - 1.5rem);

    > .add-new,
    > .add-new-block {
      display: block;
      margin-bottom: 2rem;
    }

    > .add-new-block {
      margin-left: -1.5rem;
    }

    & ul.error-list[ng-messages]:last-child {
      margin-bottom: -1.8rem;
    }

    // errors relating to the whole grouping panel, not to a form-group
    > ul.error-list {
      & li {
        margin-left: -4rem;
        list-style: none;
        line-height: spacing.$spacing-sm;
        color: colors.$red;
        font-size: 1.2rem;

        &:last-of-type {
          margin-bottom: 2.8rem;
        }
      }
    }
  }

  &.row {
    > *:not(.grouping-panel-content),
    > .form-group:not(.grouping-panel-content) {
      margin-left: 0;
    }

    > label,
    > h4 {
      padding-left: 0;
    }

    & > [class*="col-"] {
      & > .grouping-panel-content {
        padding-left: 2rem;
        width: 100%;
      }

      & > label, & > h4 {
        &.required, &.valid, &.invalid {
          &::before {
            background-color: colors.$white;
          }
        }
      }
    }
  }
}

/* stylelint-disable no-descending-specificity */
.edit-mode {
  & .grouping-panel,
  & .grouping-panel-content {
    & .form-group:last-of-type {
      margin-bottom: 1rem !important;
    }
  }

  &.editing {
    & .grouping-panel,
    & .grouping-panel-content {
      & .form-group:last-of-type {
        margin-bottom: 3.8rem !important;
      }
    }
  }
}
