@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

.sis-radiobutton-tree {
  &__node {
    display: flex;
    align-items: center;
    margin-top: spacing.$pixel-1;
    min-height: spacing.$spacing-lg;

    &__button-container {
      // The SVG icon component has vertical whitespace that messes up the margins -> unset with negative margins.
      margin-top: -1rem;
      margin-bottom: -1rem;
      min-width: spacing.$spacing-lg;
    }

    &__selection {
      border-bottom: spacing.$pixel-1 solid colors.$light-gray;
      width: 100%;
      min-height: spacing.$spacing-lg;

      &--unselectable {
        padding: spacing.$spacing-xs spacing.$spacing-zero;
      }

      &__label {
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        padding-right: spacing.$spacing-sm;
      }

      // Include global class names to increase specificity and override the global styles (from checkbox.scss)
      &__radio.control.control-radio {
        margin-bottom: spacing.$spacing-xxs;
        padding-left: spacing.$spacing-md;
      }
    }
  }

  &__children {
    margin-left: spacing.$spacing-sm;
    border-left: spacing.$pixel-1 dotted colors.$middle-gray;
    padding-left: spacing.$spacing-xs;
    list-style: none;

    &--collapsed {
      display: none;
    }
  }
}
