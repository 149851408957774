sis-responsibility-info {
  & .person {
    margin-bottom: 0.5rem;

    &__name {
      font-weight: 400;
    }

    &__by-text {
      font-weight: 300;
    }
  }
}
