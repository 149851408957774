@forward './right-panel/choose-plan/choose-plan.component';
@forward './dialog/add-own-calendar-event-modal/add-own-calendar-event-modal.component';
@forward "./right-panel/calendar-enrolments/calendar-enrolments.component";
@use 'sis-assets/foundations/breakpoints/breakpoints' as breakpoints;
@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

.calendar-view-container {
  & .calendar-right-bar-button {
    float: right;

    @media (min-width: breakpoints.$screen-md-min) {
      display: none;
    }
  }

  .calendar-right-bar {
    position: fixed;
    top: 15.7rem;
    right: 0;
    transition: top 0.4s ease;
    margin: 0;
    border-left: spacing.$pixel-1 solid colors.$primary-color;
    border-radius: 0.5rem 0 0;
    background-color: colors.$dark-gray;
    padding: 0;
    height: calc(100vh - 12.3rem);

    &.collapsed {
      top: 9.5rem;
      transition: top 0.4s ease;
      height: calc(100vh - 9.5rem);

      & .calendar-right-bar-content {
        height: calc(100vh - 20.1rem);
      }
    }

    & course-unit-realisation {
      display: block;
      margin-bottom: 0.5em;
    }

    .course-units-not-in-calendar {
      padding-bottom: 5rem;
      color: colors.$extra-light-gray;

      .courseunit {
        margin-top: 0.5em;
      }

      .period {
        margin-top: spacing.$spacing-md;
        margin-bottom: spacing.$spacing-xs;
        border-bottom: dotted spacing.$pixel-2 colors.$silver;
        padding-bottom: spacing.$pixel-2;
        width: 100%;
        color: colors.$extra-light-gray;
        font-weight: 400;
      }

      & .all-courseunits-scheduled {
        margin-top: spacing.$spacing-md;
      }
    }

    & .collapsible-column-dismiss {
      display: inline-block;
      position: absolute;
      top: spacing.$spacing-xs;
      right: 1.5rem;
      z-index: 50;
      border: spacing.$pixel-1 solid colors.$white;
      border-radius: spacing.$pixel-2;
      background-color: colors.$primary-color;
      cursor: pointer;
      vertical-align: middle;
      line-height: 1.8rem; // $magic
      font-size: 2.8rem;

      @media (min-width: breakpoints.$screen-md-min) {
        display: none;
      }
    }

    & .calendar-right-bar-header {
      margin-top: spacing.$spacing-xs;
      margin-bottom: spacing.$spacing-xs;
      margin-left: spacing.$spacing-xs;
      border-radius: 0.5rem;
      width: calc(100% - 1.6rem);
    }

    .calendar-right-bar-content {
      position: relative;
      padding: 2.2rem;
      height: calc(100vh - 26.3rem);
      overflow-x: hidden;
      overflow-y: auto;

      & .calendar-enrolment-filters {
        margin-bottom: 3rem;
        margin-left: -1rem;
        width: calc(100% + 1rem);

        & .checkbox.checkbox-toggle.sis-toggle {
          margin-bottom: 1.1rem;

          &:first-child {
            margin-top: 0;
          }

          &:nth-last-of-type(2) {
            margin-top: 2.5rem;
          }

          & label {
            & .filter-name {
              color: colors.$extra-light-gray;
              font-size: 1.4rem;
              font-weight: 300;

              & em {
                color: colors.$silver;
              }
            }
          }
        }
      }

      & .course-unit-enrolments {
        margin-bottom: 2.2rem;
      }

      & course-unit-realisation {
        & .courseunit-realisation-bottom-row {
          & .event-groups {
            & > * {
              @media (max-width: breakpoints.$screen-md-max) {
                padding-left: 0.5rem;
              }
            }
          }
        }

        & button.btn.btn-sm {
          max-width: calc(100% - 2rem);
          overflow: hidden;
          text-overflow: ellipsis;

          & sis-icon[icon="delete"] svg {
            height: 2.8rem;
          }
        }
      }
    }
  }

  @media (max-width: breakpoints.$screen-sm-max) {
    & .calendar-right-bar {
      top: 13.5rem;
      z-index: 4;

      &.closed {
        display: none;
      }

      & .calendar-right-bar-header {
        width: calc(100% - 6.8rem);
      }

      & .calendar-right-bar-content {
        height: calc(100% - 11.2rem);
      }
    }
  }

  @media (max-width: breakpoints.$screen-xs-max) {
    & .calendar-right-bar.collapsed {
      top: 8.3rem;
      height: calc(100% - 8.3rem);

      & .calendar-right-bar-content {
        height: calc(100% - 10.6rem);
      }
    }
  }
}

.calendar-event-menu {
  margin-top: 0;
}
