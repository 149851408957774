@use 'sis-assets/foundations/breakpoints/breakpoints' as breakpoints;

app-open-university-studies-activate-wizard-step3 {
  & .suomi-fi-activate-button {
    display: flex;
    justify-content: center;

    @media only screen and (max-width: breakpoints.$screen-sm-max) {
      justify-content: right;
    }
  }
}
