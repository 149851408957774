@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

.badge {
  line-height: 1.2rem;

  &:empty {
    display: inline-block;
  }

  &.badge-danger {
    background-color: colors.$red;
  }
}

.tiny-badge {
  display: inline-block;
  margin-right: spacing.$spacing-xxs;
  margin-left: spacing.$pixel-3;
  border-radius: spacing.$pixel-2;
  background: rgb(255 255 255 / 90%);
  padding: spacing.$pixel-2 spacing.$pixel-3 spacing.$pixel-1 spacing.$pixel-3;
  vertical-align: 20%;
  text-transform: uppercase;
  line-height: 1.2rem;
  letter-spacing: initial;
  color: colors.$dark-gray;
  font-size: 1.0rem;
  font-weight: 400;

  &.accent {
    border: spacing.$pixel-1 solid colors.$yellow;
    background-color: colors.$auringonkukka-extra-light;
  }

  &.success {
    border: spacing.$pixel-1 solid colors.$green;
    background-color: colors.$minttu-extra-light;
  }

  &.danger {
    border: spacing.$pixel-1 solid colors.$red;
    background-color: colors.$karviainen-extra-light;
  }

  &.primary {
    border: spacing.$pixel-1 solid colors.$primary-color;
    background-color: colors.$primary-color-light;
  }

  &.secondary {
    border: spacing.$pixel-1 solid colors.$middle-gray;
    background-color: colors.$extra-light-gray;
  }
}
