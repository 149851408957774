@use 'sis-assets/foundations/breakpoints/breakpoints' as breakpoints;
@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

// Action bar
.action-bar-header {
  display: block;
  z-index: 1;
  background: colors.$bg-color-alt;
  min-width: 100%;

  .plan-select {
    display: inline-block;

    h1 {
      margin: 0;
      padding: 0;
    }
  }

  .floating-right-panel {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    padding-top: spacing.$pixel-3;
    padding-bottom: 1.8rem;
    text-align: right;

    & .dropdown-menu {
      margin-top: 1.2rem;
      margin-right: -1.2rem;
    }

    & button {
      margin-top: 1rem;
    }

    & a.btn {
      vertical-align: bottom;
    }

    @media (max-width: breakpoints.$screen-md-max) {
      margin-top: 2rem;
      white-space: nowrap;
    }
  }

  &.collapsed {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 120;
    border-bottom: spacing.$pixel-1 solid colors.$light-gray;
    padding-bottom: spacing.$spacing-xs;

    & .floating-right-panel {
      float: none !important;
      flex: unset;
      margin-top: 0;
      padding-top: spacing.$spacing-xxs;
      max-width: 100%;
      text-align: left;

      & button {
        margin-top: 0.5rem;
      }

      & .dropdown-menu {
        margin-top: 0.7rem;
      }
    }

    & .plan-select {
      display: block;
      width: 100%;

      & app-plan-select {
        clear: both;

        & .choose-plan {
          & > .dropdown {
            & .dropdown-toggle {
              margin-top: spacing.$spacing-sm;
              line-height: 2.8rem;
              font-size: spacing.$spacing-md;
            }

            & > .plan-description {
              display: none;
              white-space: nowrap;
            }
          }
        }
      }
    }

    & + .study-plan-container {
      transition: padding-top 0s;
      padding-top: 8.5rem;
    }
  }
}
