@use 'tokens' as tokens;

.page-student,
.page-teacher,
.page-staff,
.page-admin,
.page-tutor {
  /* Margin helper classes */

  .sis-m-zero {
    margin: tokens.$spacing-zero;
  }

  .sis-m-xxs {
    margin: tokens.$spacing-xxs;
  }

  .sis-m-xs {
    margin: tokens.$spacing-xs;
  }

  .sis-m-sm {
    margin: tokens.$spacing-sm;
  }

  .sis-m-md {
    margin: tokens.$spacing-md;
  }

  .sis-m-lg {
    margin: tokens.$spacing-lg;
  }

  .sis-m-xl {
    margin: tokens.$spacing-xl;
  }

  .sis-m-xxl {
    margin: tokens.$spacing-xxl;
  }

  /* Margin bottom helper classes */

  .sis-mb-zero {
    margin-bottom: tokens.$spacing-zero;
  }

  .sis-mb-xxs {
    margin-bottom: tokens.$spacing-xxs;
  }

  .sis-mb-xs {
    margin-bottom: tokens.$spacing-xs;
  }

  .sis-mb-sm {
    margin-bottom: tokens.$spacing-sm;
  }

  .sis-mb-md {
    margin-bottom: tokens.$spacing-md;
  }

  .sis-mb-lg {
    margin-bottom: tokens.$spacing-lg;
  }

  .sis-mb-xl {
    margin-bottom: tokens.$spacing-xl;
  }

  .sis-mb-xxl {
    margin-bottom: tokens.$spacing-xxl;
  }

  /* Margin top helper classes */

  .sis-mt-zero {
    margin-top: tokens.$spacing-zero;
  }

  .sis-mt-xxs {
    margin-top: tokens.$spacing-xxs;
  }

  .sis-mt-xs {
    margin-top: tokens.$spacing-xs;
  }

  .sis-mt-sm {
    margin-top: tokens.$spacing-sm;
  }

  .sis-mt-md {
    margin-top: tokens.$spacing-md;
  }

  .sis-mt-lg {
    margin-top: tokens.$spacing-lg;
  }

  .sis-mt-xl {
    margin-top: tokens.$spacing-xl;
  }

  .sis-mt-xxl {
    margin-top: tokens.$spacing-xxl;
  }

  /* Margin left helper classes */

  .sis-ml-zero {
    margin-left: tokens.$spacing-zero;
  }

  .sis-ml-xxs {
    margin-left: tokens.$spacing-xxs;
  }

  .sis-ml-xs {
    margin-left: tokens.$spacing-xs;
  }

  .sis-ml-sm {
    margin-left: tokens.$spacing-sm;
  }

  .sis-ml-md {
    margin-left: tokens.$spacing-md;
  }

  .sis-ml-lg {
    margin-left: tokens.$spacing-lg;
  }

  .sis-ml-xl {
    margin-left: tokens.$spacing-xl;
  }

  .sis-ml-xxl {
    margin-left: tokens.$spacing-xxl;
  }

  /* Margin right helper classes */

  .sis-mr-zero {
    margin-right: tokens.$spacing-zero;
  }

  .sis-mr-xxs {
    margin-right: tokens.$spacing-xxs;
  }

  .sis-mr-xs {
    margin-right: tokens.$spacing-xs;
  }

  .sis-mr-sm {
    margin-right: tokens.$spacing-sm;
  }

  .sis-mr-md {
    margin-right: tokens.$spacing-md;
  }

  .sis-mr-lg {
    margin-right: tokens.$spacing-lg;
  }

  .sis-mr-xl {
    margin-right: tokens.$spacing-xl;
  }

  .sis-mr-xxl {
    margin-right: tokens.$spacing-xxl;
  }

  /* Margin x-axis helper classes */

  .sis-mx-zero {
    margin-right: tokens.$spacing-zero;
    margin-left: tokens.$spacing-zero;
  }

  .sis-mx-xxs {
    margin-right: tokens.$spacing-xxs;
    margin-left: tokens.$spacing-xxs;
  }

  .sis-mx-xs {
    margin-right: tokens.$spacing-xs;
    margin-left: tokens.$spacing-xs;
  }

  .sis-mx-sm {
    margin-right: tokens.$spacing-sm;
    margin-left: tokens.$spacing-sm;
  }

  .sis-mx-md {
    margin-right: tokens.$spacing-md;
    margin-left: tokens.$spacing-md;
  }

  .sis-mx-lg {
    margin-right: tokens.$spacing-lg;
    margin-left: tokens.$spacing-lg;
  }

  .sis-mx-xl {
    margin-right: tokens.$spacing-xl;
    margin-left: tokens.$spacing-xl;
  }

  .sis-mx-xxl {
    margin-right: tokens.$spacing-xxl;
    margin-left: tokens.$spacing-xxl;
  }

  /* Margin y-axis helper classes */

  .sis-my-zero {
    margin-top: tokens.$spacing-zero;
    margin-bottom: tokens.$spacing-zero;
  }

  .sis-my-xxs {
    margin-top: tokens.$spacing-xxs;
    margin-bottom: tokens.$spacing-xxs;
  }

  .sis-my-xs {
    margin-top: tokens.$spacing-xs;
    margin-bottom: tokens.$spacing-xs;
  }

  .sis-my-sm {
    margin-top: tokens.$spacing-sm;
    margin-bottom: tokens.$spacing-sm;
  }

  .sis-my-md {
    margin-top: tokens.$spacing-md;
    margin-bottom: tokens.$spacing-md;
  }

  .sis-my-lg {
    margin-top: tokens.$spacing-lg;
    margin-bottom: tokens.$spacing-lg;
  }

  .sis-my-xl {
    margin-top: tokens.$spacing-xl;
    margin-bottom: tokens.$spacing-xl;
  }

  .sis-my-xxl {
    margin-top: tokens.$spacing-xxl;
    margin-bottom: tokens.$spacing-xxl;
  }

  /* Padding helper classes */

  .sis-p-zero {
    padding: tokens.$spacing-zero;
  }

  .sis-p-xxs {
    padding: tokens.$spacing-xxs;
  }

  .sis-p-xs {
    padding: tokens.$spacing-xs;
  }

  .sis-p-sm {
    padding: tokens.$spacing-sm;
  }

  .sis-p-md {
    padding: tokens.$spacing-md;
  }

  .sis-p-lg {
    padding: tokens.$spacing-lg;
  }

  .sis-p-xl {
    padding: tokens.$spacing-xl;
  }

  .sis-p-xxl {
    padding: tokens.$spacing-xxl;
  }

  /* Padding bottom helper classes */

  .sis-pb-zero {
    padding-bottom: tokens.$spacing-zero;
  }

  .sis-pb-xxs {
    padding-bottom: tokens.$spacing-xxs;
  }

  .sis-pb-xs {
    padding-bottom: tokens.$spacing-xs;
  }

  .sis-pb-sm {
    padding-bottom: tokens.$spacing-sm;
  }

  .sis-pb-md {
    padding-bottom: tokens.$spacing-md;
  }

  .sis-pb-lg {
    padding-bottom: tokens.$spacing-lg;
  }

  .sis-pb-xl {
    padding-bottom: tokens.$spacing-xl;
  }

  .sis-pb-xxl {
    padding-bottom: tokens.$spacing-xxl;
  }

  /* Padding top helper classes */

  .sis-pt-zero {
    padding-top: tokens.$spacing-zero;
  }

  .sis-pt-xxs {
    padding-top: tokens.$spacing-xxs;
  }

  .sis-pt-xs {
    padding-top: tokens.$spacing-xs;
  }

  .sis-pt-sm {
    padding-top: tokens.$spacing-sm;
  }

  .sis-pt-md {
    padding-top: tokens.$spacing-md;
  }

  .sis-pt-lg {
    padding-top: tokens.$spacing-lg;
  }

  .sis-pt-xl {
    padding-top: tokens.$spacing-xl;
  }

  .sis-pt-xxl {
    padding-top: tokens.$spacing-xxl;
  }

  /* Padding left helper classes */

  .sis-pl-zero {
    padding-left: tokens.$spacing-zero;
  }

  .sis-pl-xxs {
    padding-left: tokens.$spacing-xxs;
  }

  .sis-pl-xs {
    padding-left: tokens.$spacing-xs;
  }

  .sis-pl-sm {
    padding-left: tokens.$spacing-sm;
  }

  .sis-pl-md {
    padding-left: tokens.$spacing-md;
  }

  .sis-pl-lg {
    padding-left: tokens.$spacing-lg;
  }

  .sis-pl-xl {
    padding-left: tokens.$spacing-xl;
  }

  .sis-pl-xxl {
    padding-left: tokens.$spacing-xxl;
  }

  /* Padding right helper classes */

  .sis-pr-zero {
    padding-right: tokens.$spacing-zero;
  }

  .sis-pr-xxs {
    padding-right: tokens.$spacing-xxs;
  }

  .sis-pr-xs {
    padding-right: tokens.$spacing-xs;
  }

  .sis-pr-sm {
    padding-right: tokens.$spacing-sm;
  }

  .sis-pr-md {
    padding-right: tokens.$spacing-md;
  }

  .sis-pr-lg {
    padding-right: tokens.$spacing-lg;
  }

  .sis-pr-xl {
    padding-right: tokens.$spacing-xl;
  }

  .sis-pr-xxl {
    padding-right: tokens.$spacing-xxl;
  }

  /* Padding x-axis helper classes */

  .sis-px-zero {
    padding-right: tokens.$spacing-zero;
    padding-left: tokens.$spacing-zero;
  }

  .sis-px-xxs {
    padding-right: tokens.$spacing-xxs;
    padding-left: tokens.$spacing-xxs;
  }

  .sis-px-xs {
    padding-right: tokens.$spacing-xs;
    padding-left: tokens.$spacing-xs;
  }

  .sis-px-sm {
    padding-right: tokens.$spacing-sm;
    padding-left: tokens.$spacing-sm;
  }

  .sis-px-md {
    padding-right: tokens.$spacing-md;
    padding-left: tokens.$spacing-md;
  }

  .sis-px-lg {
    padding-right: tokens.$spacing-lg;
    padding-left: tokens.$spacing-lg;
  }

  .sis-px-xl {
    padding-right: tokens.$spacing-xl;
    padding-left: tokens.$spacing-xl;
  }

  .sis-px-xxl {
    padding-right: tokens.$spacing-xxl;
    padding-left: tokens.$spacing-xxl;
  }

  /* Padding y-axis helper classes */

  .sis-py-zero {
    padding-top: tokens.$spacing-zero;
    padding-bottom: tokens.$spacing-zero;
  }

  .sis-py-xxs {
    padding-top: tokens.$spacing-xxs;
    padding-bottom: tokens.$spacing-xxs;
  }

  .sis-py-xs {
    padding-top: tokens.$spacing-xs;
    padding-bottom: tokens.$spacing-xs;
  }

  .sis-py-sm {
    padding-top: tokens.$spacing-sm;
    padding-bottom: tokens.$spacing-sm;
  }

  .sis-py-md {
    padding-top: tokens.$spacing-md;
    padding-bottom: tokens.$spacing-md;
  }

  .sis-py-lg {
    padding-top: tokens.$spacing-lg;
    padding-bottom: tokens.$spacing-lg;
  }

  .sis-py-xl {
    padding-top: tokens.$spacing-xl;
    padding-bottom: tokens.$spacing-xl;
  }

  .sis-py-xxl {
    padding-top: tokens.$spacing-xxl;
    padding-bottom: tokens.$spacing-xxl;
  }
}
