@use 'sis-assets/foundations/breakpoints/breakpoints' as breakpoints;
@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

plan-module, attainment-group-node {
  & .tree-list {
    clear: both;
    margin: 0;
    padding: 0;
    list-style: none;

    & li.tree-list-item-level-1 {
      margin-bottom: spacing.$spacing-sm;

      @media(max-width: breakpoints.$screen-xs-max) {
        margin-left: -1.6rem;
      }
    }

    & .tree-list-item, .tree-list-item-level-1 {
      & .module-border {
        position: relative;
        margin-top: 1.25rem;
        padding-left: 0;

        &:not(.module-border-selected, .collapsed)::before {
          position: absolute;
          top: 2.25rem;
          left: 2.6rem;
          border-left: spacing.$pixel-1 dotted colors.$dark-gray;
          height: calc(100% - 2rem);
          content: ' ';
        }

        &:not(.module-border-selected, .collapsed)::after {
          position: absolute;
          left: spacing.$spacing-md;
          border-radius: 0.5rem;
          background-color: colors.$silver;
          width: 0.5rem;
          max-width: 0.5rem;
          height: 0.5rem;
          max-height: 0.5rem;
          content: '';
        }

        &.module-border-selected {
          margin-bottom: -1.25rem;
          border: spacing.$pixel-1 solid colors.$primary-color;
          border-radius: spacing.$spacing-xs spacing.$spacing-xs 0 0;
          padding-bottom: 1.25rem;
        }

        &.collapsed {
          margin-bottom: -1.25rem;
        }

        & .accordion-panel {
          & sis-icon[icon="junction"] {
            position: relative;
            top: spacing.$spacing-xs;
          }

          & .title {
            &.grouping-module {
              vertical-align: middle;
            }

            > .additional {
              & .additional-alert {
                color: colors.$red;
              }
            }

            @media (max-width: breakpoints.$screen-xs-max) {
              & > span {
                font-size: 1.2rem;
                hyphens: auto;
              }
            }
          }

          & .cell {
            display: flex;
            align-items: center;
            margin-left: spacing.$spacing-xs;
            min-height: 2.7rem;
            font-weight: 400;

            @media (max-width: breakpoints.$screen-xs-max) {
              margin-left: spacing.$spacing-zero;
              padding: spacing.$spacing-zero;
            }
          }

          & .plan-course-unit-instructions {
            display: none;
          }

          & .graduate {
            & sis-tooltip {
              margin-right: spacing.$spacing-lg;

              & sis-icon[icon="rosette"] svg {
                fill: colors.$auringonkukka;
              }
            }
          }

          & .status {
            min-width: 6.87rem;
            line-height: 2rem;

            & .state-invalid, .state-incomplete, .state-with-icon {
              display: flex;
              align-items: center;
            }
          }

          & .status-text {
            white-space: normal;
          }

          & .module-credits {
            flex-direction: column;
            min-width: 6.87rem;

            & .credit-range {
              display: block;
              text-align: center;
              line-height: 2rem;
              white-space: nowrap;
            }

            & .planned-attained-credits {
              min-width: 6.25rem;
              text-align: center;
              line-height: 1.2rem;

              & .planned-credits, .attained-credits {
                display: inline-block;
                font-size: 1rem;
              }

              & .planned-credits {
                min-width: spacing.$spacing-xl;
                text-align: right;
              }

              & .attained-credits {
                border-width: 0 0 0 spacing.$pixel-1;
                border-style: solid;
                border-color: colors.$middle-gray;
                padding-left: spacing.$pixel-3;
                min-width: 2.9rem;
                text-align: left;
                color: colors.$middle-gray;
              }
            }
          }

          &.accordion-panel-noblock {
            border-radius: spacing.$spacing-xxs spacing.$spacing-xxs 0 0;

            &.clickable:hover {
              background-color: colors.$primary-color-light;

              & .title {
                & .plan-module-name, .additional {
                  background-color: colors.$primary-color-light;
                }
              }
            }

            & > .header {
              & > .collapse {
                position: relative;
                z-index: 10;

                &:active {
                  background-color: colors.$primary-color-light;
                }
              }

              & .title {
                > .additional {
                  & .link-button {
                    color: colors.$primary-color-dark;
                    font-weight: 400;

                    & sis-icon[icon="brochure"] svg {
                      margin-right: -0.4rem;
                    }
                  }
                }
              }
            }
          }

          &.accordion-panel-primary {
            margin: 0;

            & .header {
              & .title {
                background: none;

                & .plan-module-name, .additional {
                  background-color: colors.$primary-color;
                }
              }

              & .additional, button {
                color: colors.$extra-light-gray;

                & .additional-alert {
                  color: colors.$extra-light-gray;
                }
              }

              & sis-icon[icon="rosette"] svg, sis-icon[icon="junction"] svg, sis-icon[icon="brochure"] svg {
                fill: colors.$white;
              }

              & .cell {
                color: colors.$white;

                & .attained-credits {
                  border-color: colors.$primary-color-light;
                  color: colors.$primary-color-light;
                }

                & .state-gray {
                  display: flex;
                  align-items: center;
                  margin-left: -1.4rem;
                  color: colors.$white;
                }

                & .btn.btn-secondary.btn-hollow {
                  border-color: colors.$white;
                  background-color: transparent;
                  color: colors.$white;
                }
              }

              & .title-line-liner {
                border-color: colors.$primary-color;
                background-color: colors.$primary-color;
              }
            }
          }
        }

        & .tree-list-heading {
          border-radius: spacing.$spacing-xxs spacing.$spacing-xxs 0 0;
          overflow: hidden;

          &.no-padding {
            margin-top: 0;
            padding-top: 0;
            padding-left: 0;
          }

          &.is-active {
            background-color: colors.$light-gray;
          }

          &.is-hovered {
            background-color: colors.$light-gray;
          }

          &.tree-list-heading-has-children {
            padding-top: 0;
            padding-right: 0;
            padding-bottom: 0;
          }

          & .module-header {
            span {
              float: left;
            }

            .flat-description {
              display: block;
              clear: both;
              color: colors.$middle-gray;
              font-size: 1.4rem;
            }
          }

          & .state-attained {
            color: colors.$green;

            & span:first-of-type {
              display: block;
            }
          }

          & .state-invalid {
            color: colors.$accent;
          }

          & .state-gray {
            color: colors.$silver;
          }
        }

        & .tree-list-body {
          padding: 0 2.75rem 0 3.37rem;

          & .col-sm-6 {
            &:nth-of-type(2n + 1) {
              clear: both;
            }

            &.left {
              padding-right: 0.1em;
            }

            &.right {
              padding-left: 0.1em;
            }

            & course-unit-box, custom-course-unit-attainment-box, custom-study-draft {
              display: block;
              margin-right: 0.625rem;

              > .courseunit {
                margin-top: 1em;
                margin-right: 0.625rem;
              }
            }
          }

          @media (max-width: breakpoints.$screen-sm-max) {
            padding-right: 0.625rem;
            padding-left: spacing.$spacing-xl;
          }
        }
      }
    }
  }

  & .tree-list-item {
    display: inline-block;
    transition: all 0.2s linear;
    margin-bottom: 1.25rem;
    padding-left: 3rem;
    width: 100%;

    @media (max-width: breakpoints.$screen-sm-max) {
      transition: all 0.2s linear;
      padding-left: 1.25rem;
    }
  }
}
