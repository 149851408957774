@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

app-select-course-unit-realisation {
  sis-tiny-badge {
    display: flex;
    align-items: center;
  }

  .realisation-container {
    padding: spacing.$spacing-xs;
  }

  .unselected {
    border: spacing.$pixel-2 solid colors.$extra-light-gray;
  }

  .selected {
    border: spacing.$pixel-2 solid colors.$primary-color;
  }

  .flex-container {
    display: flex;
    align-items: center;
  }

  .badge-container {
    width: fit-content;
    min-width: 0;
  }
}

