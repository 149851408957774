@use 'sis-assets/foundations/breakpoints/breakpoints' as breakpoints;
@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;
@use '../timing-helpers' as helper;

timeline {
  .scroll-horizontal {
    overflow-x: scroll;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
  }

  .terms-row {
    & td:last-of-type, th:last-of-type {
      border-right: spacing.$pixel-3 solid colors.$middle-gray;
    }
  }

  .terms-table {
    &.zoom-level-0, &.zoom-level-1 {
      padding-right: 19rem;
    }

    &.zoom-level-2, &.zoom-level-3 {
      padding-right: 16rem;
    }

    &.zoom-level-4 {
      padding-right: 13rem;
    }

    & th.terms-col-period {
      position: relative;

      .term-title {
        margin: 0.3em;
        overflow: hidden;
        text-align: center;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-weight: 600;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
    }

    & th.terms-col-term {
      border-top: spacing.$pixel-1 solid colors.$silver;
      background-color: colors.$extra-light-gray;

      .term-title {
        border: 0;
        text-align: center;
        font-weight: 600;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
    }

    & .terms-row-credits-header {
      & .terms-col-term, & .terms-col-year, & .terms-col-period {
        background-color: colors.$extra-light-gray;
      }
    }

    & .terms-credits {
      display: inline-block;
      margin: 0 0.5em 0.5em;
      line-height: 1.7rem;
      font-weight: 400;

      > span {
        font-size: 1.2rem;
        font-weight: 300;
      }

      & .text-success {
        color: colors.$green;
      }
    }
  }

  .passive {
    .note-box,
    .box {
      opacity: 0.3;
    }
  }


  //courseunits' styles depending on the table row they're in
  .courseUnit, .customStudyDraft {
    timeline-course-unit.active:not(.first), timeline-custom-course-unit-attainment.active:not(.first), timeline-custom-study-draft.active:not(.first) {
      &:hover {
        & div.courseunit {
          & .courseunit-top-row {
            & .credits-box, & .title-box, & .credits-or-grade.credits-box {
              display: none;
            }
          }
        }
      }

      & div.courseunit {
        text-align: center;

        & .courseunit-top-row {
          display: inline-block;

          & .credits-box, & .title-box, & .credits-or-grade.credits-box {
            display: none;
          }

          & .box-right {
            margin-top: 1.3rem;

            & .courseunit-status {
              display: inline-block;
            }
          }
        }
      }
    }

    timeline-course-unit.active.first, timeline-custom-course-unit-attainment.active.first, timeline-custom-study-draft.active.first {
      &:hover {
        & div.courseunit {
          width: 100%;
          min-width: 100%;

          & .courseunit-top-row {
            display: block;

            & .credits-box, & .title-box {
              display: inline-block;
              opacity: 0.3;
            }

            & .credits-or-grade.credits-box {
              display: none;
            }

            & .box-right {
              display: inline-block;
              width: 100%;
              line-height: 5.2rem;

              & .courseunit-status {
                display: inline-block;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
              }
            }
          }
        }
      }

      & div.courseunit {
        & .courseunit-top-row {
          display: inline-block;

          & .box-right {
            margin-top: 1.3rem;

            & .courseunit-status {
              display: inline-block;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }
          }
        }
      }
    }

    &.right:not(.left) {
      &, &.unit-minimal, &.unit-minimal-bigger {
        & div.courseunit {
          & .courseunit-top-row {
            & .credits-box, & .credits-or-grade, & .title-box {
              display: none;
            }
          }

          & .box-right {
            width: 100%;

            & .additional-box {
              position: absolute;
              width: 100%;

              &::before {
                display: none;
              }

              & button.btn-hollow {
                width: 100%;
              }
            }

            & .courseunit-status {
              display: inline-block;
              width: 100%;
              text-align: center;

              & .unit-minimal-hide {
                display: inline-block;
              }
            }
          }
        }
      }

      timeline-course-unit, timeline-custom-course-unit-attainment, timeline-custom-study-draft {
        &:hover {
          & .courseunit, & .courseunit-top-row {
            width: inherit;
            min-width: inherit;
          }
        }

        &.last, &.last.unit-minimal-bigger {
          &:hover {
            width: 100%;
            min-width: 100%;
          }
        }
      }
    }

    &.middle {
      &, &.unit-minimal, &.unit-minimal-bigger {
        & div.courseunit {
          & .courseunit-top-row {
            & .credits-box,
            & .credits-or-grade.credits-box,
            & .box-right {
              display: none;
            }

            & .title-box {
              & > * {
                display: none;
              }
            }
          }

          & .additional-box {
            display: none;
          }
        }
      }

      timeline-course-unit, timeline-custom-course-unit-attainment, timeline-custom-study-draft {
        height: helper.$timing-box-height;

        &:hover {
          width: 100%;

          & .courseunit, & .courseunit-top-row {
            width: inherit;
            min-width: inherit;
          }
        }

        &:hover.middle {
          width: 100%;
          min-width: 100%;

          & .courseunit, & .courseunit-top-row {
            width: 100%;
            min-width: 100%;

            & .credits-box {
              display: none;
            }
          }
        }

        &.middle {
          & div.courseunit {
            height: helper.$timing-box-height;
          }
        }
      }
    }
  }


  //basic styles for each breakpoint and hovers
  timeline-course-unit, timeline-custom-course-unit-attainment, timeline-custom-study-draft {
    position: relative;
    float: left;
    background-color: colors.$extra-light-gray;
    width: 100%;
    height: helper.$timing-box-height;

    &:hover {
      & div.courseunit {
        z-index: 2;
        width: 100%;
        min-width: 17rem;

        & .courseunit-top-row {
          & .credits-box {
            > span {
              display: block;
            }
          }
        }
      }
    }

    & .courseunit, & div.courseunit.cu-color-study-draft {
      border-width: spacing.$pixel-2 0;
      border-style: solid;
      border-color: colors.$extra-light-gray;
    }

    //hover borders
    &.hover, &:hover {
      &.middle {
        & .courseunit {
          border: colors.$primary-color solid spacing.$pixel-2;
          border-right-width: 0;
          border-left-width: 0;
          background-color: colors.$extra-light-gray;
          height: 5.6rem;
        }
      }

      &.last {
        & .courseunit {
          border: colors.$primary-color solid spacing.$pixel-2;
          border-left-width: 0;
        }
      }

      &.first {
        & .courseunit {
          border: colors.$primary-color solid spacing.$pixel-2;
          border-right-width: 0;
        }
      }

      &.first.last {
        & .courseunit {
          border: colors.$primary-color solid spacing.$pixel-2;
        }
      }
    }

    &.first:not(.last) {
      & .courseunit .title-box {
        border-right-width: 0;
      }
    }

    &.middle {
      & .courseunit .title-box {
        border-right-width: 0;
        border-left-width: 0;
        width: 100% !important;
      }
    }

    &.last {
      & .courseunit .title-box {
        border-left-width: 0;
      }
    }

    & div.courseunit {
      & .courseunit-top-row {
        .credits-or-grade {
          display: none;
        }

        & .grade-box {
          & ~ .title-box {
            width: calc(100% - 7.6rem);
          }
        }

        & .title-box {
          width: calc(100% - 3.8rem);
        }
      }
    }

    //smallest breakpoint
    &.unit-minimal {
      &:hover {
        min-width: 12.5rem;

        & div.courseunit {
          z-index: 2;
          width: 12.5rem;
          min-width: 9rem;

          & .courseunit-top-row {
            & .credits-box {
              width: 3.8rem;

              > span {
                display: block;
              }
            }

            & .grade-box ~ .title-box {
              width: calc(100% - 7.6rem);
            }

            & .title-box {
              width: 10rem;
            }
          }
        }
      }

      & div.courseunit {
        & .courseunit-top-row {
          & .credits-box {
            width: spacing.$spacing-xs;

            > span {
              display: none;
            }
          }

          & .credits-or-grade {
            display: none;
          }

          & .title-box {
            width: calc(100% - 0.8rem);
          }

          & .box-right {
            & .courseunit-status {
              & .additional-box {
                min-width: unset;
              }
            }
          }
        }
      }
    }

    //the second, medium breakpoint
    &.unit-minimal-bigger {
      &:hover {
        & div.courseunit {
          z-index: 2;
          width: 100%;

          & .courseunit-top-row {
            & .credits-box, & .grade-box {
              display: inline-block;

              > span {
                display: block;
              }
            }

            & .grade-box ~ .title-box {
              width: calc(100% - 7.6rem);
            }

            & .credits-or-grade {
              display: none;
            }

            & .title-box {
              width: calc(100% - 3.8rem);
            }

            & .courseunit-status {
              display: inline-block;
            }
          }
        }
      }

      & div.courseunit {
        & .courseunit-top-row {
          & .credits-box, & .grade-box {
            display: none;
          }

          & .credits-or-grade {
            &.credits-box {
              display: inline-block;
              width: 2rem;

              > .credit-amount {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                margin: auto;
                width: 2rem;
                line-height: 100%;
                white-space: normal;
                word-break: normal;
                overflow-wrap: break-word;
              }

              > .grade-text {
                margin-top: 1.2rem;
              }
            }
          }

          & .title-box {
            width: calc(100% - 2rem);
          }

          & .courseunit-status {
            display: none;
          }
        }
      }
    }

    //courseunit's dropdown
    & .additional-box {
      & button.dropdown-toggle.btn-hollow {
        border: 0;
        padding: 0;

        &::after {
          margin: 0;
          padding: 0;
          content: '';
        }

        &:hover {
          background-color: inherit;
        }
      }
    }


    &.last.hover .box,
    &.last .box:hover {
      border-right-width: spacing.$pixel-1;
    }

    &.first.hover .box,
    &.first .box:hover {
      border-left-width: spacing.$pixel-1;

      .box-right {
        width: 100%;
      }
    }

    .box-right {
      @include helper.fix-dropdown-button;

      button.btn-hollow.btn-secondary {
        border-width: 0;
        background-color: transparent;
        padding-left: 1.4rem;
      }
    }

    &.active {
      .box-right {
        width: auto;
      }
    }
  }

  .timeline-period-row {
    margin-bottom: spacing.$spacing-xxs;
    height: helper.$timing-box-height;

    &.courseUnit-gap, &.note-gap, .customStudyDraft-gap {
      border-width: spacing.$pixel-1 0;
      border-style: solid;
      border-color: colors.$extra-light-gray;
      background: repeating-linear-gradient(-45deg, colors.$white, colors.$white 0.5rem, rgb(235 235 235 / 100%) 0.5rem, rgb(235 235 235 / 100%) 1rem);
      height: helper.$timing-box-height;
    }

    &.plannedTeaching-gap {
      margin-top: spacing.$pixel-3;
      border-width: spacing.$pixel-1 0 spacing.$pixel-1 0;
      border-style: dashed;
      border-color: colors.$primary-color-light;
      background: repeating-linear-gradient(-45deg, colors.$white, colors.$white 0.5rem, rgb(238 243 249 / 100%) 0.5rem, rgb(238 243 249 / 100%) 1rem);
    }
  }

  .columns-1 {
    width: 100%;
  }

  .columns-2 {
    width: 50%;
  }

  .columns-3 {
    width: 33.33333333%;
  }

  .columns-4 {
    width: 25%;
  }

  .columns-5 {
    width: 20%;
  }

  .columns-6 {
    width: 16.66666667%;
  }

  .columns-7 {
    width: 14.28571429%;
  }

  .columns-8 {
    width: 12.5%;
  }

  .columns-9 {
    width: 11.11111111%;
  }

  .columns-10 {
    width: 10%;
  }

  table.terms-table .term-courses {
    min-height: 18rem;

    & .not-visible {
      visibility: hidden;
    }
  }

  .timing-target {
    .note-period,
    .timeable-teaching-period {
      display: block;
    }
  }

  .note-period {
    border: spacing.$pixel-1 dashed colors.$ui-border-color;
    width: 100%;
    height: 6.2rem;
  }

  .zoom-level-0, .zoom-level-1, .zoom-level-2, .zoom-level-3 .hidden-by-default {
    .note-period, .timeable-teaching-period div.hover {
      padding-left: 0;
    }
  }

  .zoom-level-0, .zoom-level-1 {
    .note-period, .timeable-teaching-period {
      & div.hover {
        & .sis-tooltip-trigger-content span {
          display: none;
        }
      }
    }

    .planned-slot-title {
      ~ div.hover {
        & span {
          display: none;
        }
      }
    }
  }

  .note-period,
  .timeable-teaching-period {
    margin: spacing.$pixel-1 0;
    border: spacing.$pixel-1 dashed helper.$planned-box-border-color;
    background: repeating-linear-gradient(-45deg, colors.$white, colors.$white 0.5rem, rgb(235 235 235 / 100%) 0.5rem, rgb(235 235 235 / 100%) 1rem);
    width: 100%;
    height: 6rem;

    &:hover {
      border-color: colors.$primary-color;
      background: colors.$white;
      cursor: pointer;
    }

    & div.hover {
      display: none;
      position: relative;
      top: 50%;
      transform: translateY(-50%);
      opacity: .6;
      margin: auto;

      & span {
        display: block;
        line-height: spacing.$spacing-sm;
        font-size: 1rem;
      }
    }

    &.hover div.hover,
    &:hover div.hover {
      display: block;
    }
  }
}
