@use 'sis-assets/foundations/breakpoints/breakpoints' as breakpoints;
@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

app-study-sub-group-container {
  & .study-sub-group-container {
    & .study-sub-group-container__header {
      border-bottom: spacing.$pixel-1 solid colors.$light-gray;
      padding: 1rem 2rem;

      & sis-checkbox:last-child {
        margin-bottom: 0;
      }

      & .study-sub-group-container__header__container {
        display: flex;
        align-items: center;
        min-height: spacing.$spacing-lg;

        & .study-sub-group-container__header__container__checkbox {
          margin-right: spacing.$spacing-xs;
        }

        & sis-form-checkbox {
          margin-bottom: 0 !important;
        }

        & .study-sub-group-container__header__container__enrolled-count {
          margin-left: auto;
          font-size: 1.6rem;

          @media (max-width: breakpoints.$screen-xs-max) {
            padding-left: 1rem;
            max-width: spacing.$spacing-lg;
          }
        }

        & .study-sub-group-container__header__container__title {
          font-size: 1.6rem;
          font-weight: 400;
        }
      }

      & .study-sub-group-container__header__secondary-select label {
        margin-top: 1rem;
        margin-left: 2.5rem;
        font-size: 1.4rem;
        font-weight: 300;
      }
    }

    & .header__selected {
      background-color: colors.$primary-color-light;
    }

    & .header__cancelled {
      border-bottom: spacing.$pixel-1 solid colors.$light-gray;
      background-color: colors.$extra-light-gray;
    }

    & .header__mandatory {
      border-bottom: spacing.$pixel-1 solid colors.$light-gray;
    }

    & .study-sub-group-container__body {
      padding: 0.5rem 2rem;
    }
  }

  & .container__not-selected {
    border: spacing.$pixel-2 solid colors.$light-gray;
  }

  & .container__selected {
    border: spacing.$pixel-2 solid colors.$primary-color;
  }
}
