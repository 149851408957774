@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/focus/mixins' as focus;
@use 'sis-assets/foundations/spacing/tokens' as spacing;
@use 'sis-assets/foundations/typography/tokens' as typography;

sis-university-iframe {
  display: inline-block;
  align-content: center;
  background-color: colors.$white;
  width: 100%;
  min-height: 5.6rem;
  color: colors.$dark-gray;

  & .university-iframe-toggle {
    padding: spacing.$spacing-xs;
    line-height: typography.$body-text-lg-line-height;
    color: colors.$dark-gray;
    font-weight: 400;

    &:focus {
      @include focus.fudis-focus-generic;
    }
  }

  & iframe#universityNavIFrame { /* stylelint-disable */
    position: absolute;
    top: -100rem;
    left: 0;
    transition: height 0.2s, top 0.2s;
    z-index: 999;
    border: none;
    width: 100%;

    &.opened {
      top: 0;
      transition: top 0.2s;
    }

    &.closed {
      top: -100rem;
      transition: top 0.2s;
    }
  }

  & div#close-university-iframe {
    position: absolute;
    top: 20rem;
    z-index: 999;
    width: 100%;
    text-align: center;

    & button {
      margin: 0 auto;
    }
  }

  & div#university-iframe-backshroud {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 998;
    background-color: rgb(255 255 255 / 85%);
    width: 100vw;
    height: 100vh;
  }

  #close-iframe {
    position: fixed;
    top: 0.5rem;
    right: 0.5rem;
    z-index: 999999;
    color: black;
  }

  & .only-ie-10-11 {
    display: none;
    visibility: hidden;
  }

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    & .only-ie-10-11 {
      display: inline;
      visibility: visible;
    }
  }
}

@keyframes nonoface {
  from {
    transform: rotateY(-50deg) translateZ(1rem);
  }

  to {
    transform: rotateY(50deg) translateZ(1rem);
  }
}
