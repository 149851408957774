sis-personal-info-history-dialog {
  & .personal-history-dialog-table {
    width: 100%;

    & .personal-history-dialog-table__table-pic-column-text {
      display: inline-block;
      margin-top: 1rem;
      min-width: 8rem;
    }
  }
}
