@use 'sis-assets/foundations/breakpoints/breakpoints' as breakpoints;
@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

app-search-result-row, app-search-cooperation-network-result-row {
  display: block;
  border-bottom: spacing.$pixel-1 solid colors.$middle-gray;
  padding: 0.5rem 0 1.5rem;

  & .course-unit-heading {
    margin-bottom: 1.5rem;

    & .results-name {
      font-weight: 400;

      & b,
      & mark {
        border-width: spacing.$pixel-2;
        border-style: dotted;
        background-color: colors.$primary-color-light;
        padding: 0;
        color: unset;
        font-weight: 400;
      }

      & .title-box {
        display: inline-block;
        position: relative;
        vertical-align: top;

        & a {
          display: inline-block;
        }

        & .courseunit-title, .courseunit-code {
          display: block;
          margin-top: spacing.$pixel-2;
          padding-top: spacing.$pixel-2;
        }

        & .courseunit-title {
          margin-bottom: spacing.$pixel-2;
          font-size: 1.8rem;
          overflow-wrap: break-word;

          & b,
          & mark {
            font-weight: 500;
          }
        }

        & .courseunit-code {
          line-height: 1.4rem;
        }

        @media (min-width: breakpoints.$screen-md-min) {
          padding-right: 7.5rem;
        }
      }

      & .language-match {
        margin-top: 0.5rem;

        & .language-match-title {
          font-weight: 300;
        }
      }
    }
  }

  & .course-cart-actions {
    margin-top: spacing.$pixel-2;

    @media (min-width: breakpoints.$screen-sm-min) and (max-width: breakpoints.$screen-sm-max) {
      margin-top: 1rem;
      text-align: right;
    }

    @media (max-width: breakpoints.$screen-xs-max) {
      margin-top: 1rem;
      text-align: center;
    }
  }

  & .tweet-text {
    font-weight: 400;
  }

  .tiny-static-form-group sis-tooltip {
    top: 0;
  }

  & .course-unit-search-result-organisations {
    display: inline-block;

    & .show-more-text-container, .show-more-text-content, .show-more-text-button {
      display: inline;
    }

    & .show-more-text-content {
      margin-right: 0.25em;
    }
  }
}

@media (max-width: breakpoints.$screen-xs-max) {
  app-search-result-row {
    & .course-unit-heading {
      margin-bottom: 1rem;

      & .results-name {
        & .credits-box {
          width: 5rem;
        }

        & .title-box {
          & .courseunit-title {
            padding-top: spacing.$pixel-3;
            line-height: 1.8rem;
            font-size: 1.4rem;
          }

          & .courseunit-code {
            line-height: 1.2rem;
          }
        }
      }
    }
  }
}
