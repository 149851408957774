@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

sis-notification-badge {
  & .notification-badge {
    z-index: 2;
    border-radius: 1rem;
    background-color: colors.$red;
    padding: spacing.$pixel-3 0.6rem spacing.$pixel-3 0.6rem;
    line-height: 1.2rem;
    color: colors.$white;
    font-size: 1.2rem;
    font-weight: 600;
  }
}
