// OLD STUDY MODULE BOX STUFF - DO NOT SIMPLY COPY PASTE STYLES FROM HERE TO NEW COMPONENTS!!

@use 'sass:color';
@use 'sis-assets/foundations/breakpoints/breakpoints' as breakpoints;
@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;
@use 'sis-assets/foundations/focus/tokens' as focus;

@mixin cu-bg-color($color, $color-light) {
  & .credits-box {
    background-color: $color;
  }

  & .grade-box {
    background-color: $color-light;
  }
}

div.studymodule {
  display: inline-block;
  position: relative;
  width: 100%;
  min-height: 5.2rem;
  vertical-align: top;
  color: colors.$dark-gray;

  &:not(.studymodule-narrow) {
    & .studymodule-top-row {
      & .studymodule-title-wrapper {
        display: inline-flex;
        align-items: center;
        width: 100%;
        overflow: hidden;
      }

      & .control.control-checkbox, & .control.control--checkbox {
        position: relative;
        margin-bottom: 0;
        margin-left: spacing.$spacing-xs;
        padding-right: 1.5rem;
        padding-left: 0;

        & input[type="checkbox"] {
          position: initial;
        }

        & + .title-box {
          & > span {
            &.studymodule-code {
              max-width: calc(100% - 3rem);
            }
          }
        }
      }
    }
  }

  & .studymodule-top-row {
    display: flex;
    position: relative;
    align-items: center;
    background: colors.$extra-light-gray;
    height: 5.2rem;
    text-overflow: clip;
    line-height: spacing.$spacing-md;

    & > div {
      display: inline-block;
      margin: 0;
      border: 0;
    }

    & .studymodule-title-wrapper {
      display: inline-flex;
      width: 100%;
      overflow: hidden;
    }

    & .title-box {
      display: flex;
      top: 0;
      flex-direction: column;
      justify-content: center;
      background: colors.$extra-light-gray;
      padding-left: 0.6rem;
      width: 100%;
      height: 5.2rem;
      text-overflow: clip;

      & > sis-tooltip {
        & .studymodule-title {
          display: block;
          margin-left: 0.6rem;
          cursor: default;
          padding-top: 0.9rem;
          width: 100%;
          max-width: calc(100% - 0.6rem);
          overflow: hidden;
          text-overflow: clip;
          line-height: spacing.$spacing-sm;
          white-space: nowrap;
          font-size: 1.4rem;
          font-weight: 500;
        }
      }

      & > span {
        display: block;
        width: 100%;
        text-overflow: clip;
        white-space: nowrap;

        &.studymodule-code {
          max-width: calc(100% - 0.6rem);
          line-height: spacing.$spacing-md;
          color: colors.$dark-gray;
          font-size: 1.2rem;
          font-weight: 400;

          & sis-icon[icon="brochure"] svg {
            margin: -0.4rem -0.6rem -0.4rem -0.8rem;
          }

          & .tiny-badge {
            vertical-align: 10%;
          }
        }

        &.studymodule-title {
          cursor: default;
          padding-top: 0.6rem;
          max-width: calc(100% - 0.6rem);
          overflow: hidden;
          line-height: spacing.$spacing-sm;
          font-size: 1.4rem;
          font-weight: 500;

          & sis-icon[icon="check"] svg {
            fill: colors.$primary-color-dark;
          }
        }
      }
    }

    & .extra-items-holder {
      position: absolute;
      top: 0;
      right: 0;
      height: 5.2rem;

      &::before {
        display: block;
        position: absolute;
        top: 0;
        left: -2rem;
        background-color: transparent;
        background-image: linear-gradient(to right, rgb(241 241 241 / 0%) 0%, colors.$extra-light-gray 75%, colors.$extra-light-gray 100%);
        width: 2rem;
        height: 100%;
        content: "";
      }

      & > div {
        display: inline-block;
        margin: 0;
        border: 0;
      }

      // additional box basic stuff
      & .additional-box {
        position: relative;
        top: 0;
        right: 0;
        background: colors.$extra-light-gray;
        cursor: pointer;
        min-width: 5.2rem;
        height: 5.2rem;

        &.button-box {
          cursor: pointer;
        }

        &.disabled {
          cursor: initial;

          & * {
            cursor: initial;
          }
        }

        & button.button-box.non-button,
        & sis-button.button-box .link-button {
          display: flex;
          align-items: center;
          justify-content: center;
          outline-offset: -0.1rem;
          width: 100%;
          height: 100%;

          &:focus {
            outline: focus.$fudis-focus-generic-outline;
          }
        }

        // make the buttons a bit tighter because space might be at a premium
        & .btn {
          position: relative;
          top: 1.3rem;
          margin-right: 1rem;
          margin-left: 1rem;
        }

        // toggle switch placement, and fix positioning between background and actual toggle circle
        &.toggle-box {
          & .checkbox-toggle.sis-toggle {
            position: absolute;
            padding: 1rem;
          }
        }
      }

      // credits box defaults
      & .credits-box {
        position: relative;
        top: 0;
        background: colors.$silver;
        width: 5.6rem;
        height: 5.2rem;
        overflow: hidden;
        vertical-align: top;
        text-overflow: clip;
        color: colors.$dark-gray;

        & > span {
          display: block;
          cursor: default;
          text-align: center;
        }

        // top text - amount of credits
        & .credit-amount {
          margin-top: spacing.$spacing-xs;
          margin-bottom: -0.4rem;
          overflow: hidden;
          white-space: nowrap;
          font-size: 1.4rem;
          font-weight: 500;
        }

        // bottom text - what is the abbreviation of opintopiste in languages
        & .credit-abbreviation {
          opacity: 1;
          line-height: 1.8rem;
          font-size: 1.4rem;
          font-weight: 300;
        }
      }

      // grade box stuff
      & .grade-box {
        position: relative;
        top: 0;
        background: colors.$light-gray;
        width: 3.8rem;
        height: 5.2rem;
        overflow: hidden;
        vertical-align: top;
        text-overflow: clip;
        color: colors.$dark-gray;

        & > * {
          display: block;
          width: 3.8rem;
          text-align: center;
        }

        & sis-icon[icon="achievement"] {
          margin-top: spacing.$spacing-xxs;
        }

        // text of the grade
        & .grade-text {
          line-height: spacing.$spacing-xxs;
          font-size: 1.4rem;
          font-weight: 300;
        }

        // title box width if there is a grade box
        & + .title-box {
          width: calc(100% - 8.6rem);
        }
      }
    }
  }

  // notification bar defaults
  & .studymodule-notification-bar {
    position: relative;
    text-overflow: clip;
    font-size: 1.2rem;

    // single notification
    & .studymodule-notification {
      display: flex;
      height: 2.2rem;
      vertical-align: bottom;

      // top border for the first notification bar
      &:first-child {
        & .studymodule-notification-header {
          border-top: spacing.$pixel-2 solid color.adjust(colors.$silver, $lightness: -5%);
        }

        & .studymodule-notification-content {
          border-top: spacing.$pixel-2 solid color.adjust(colors.$light-gray, $lightness: -5%);
        }
      }

      // cool rounded corner for the last notification
      &:last-child {
        & .studymodule-notification-header {
          border-bottom-right-radius: 0.7rem;
        }
      }

      // the text of the notification
      & .studymodule-notification-content {
        display: inline-block;
        position: relative;
        border-top: spacing.$pixel-1 solid color.adjust(colors.$light-gray, $lightness: -5%);
        background-color: colors.$light-gray;
        cursor: default;
        padding-left: 1.2rem;
        width: calc(100% - 5.6rem);
        height: 2.2rem;
        overflow: hidden;
        vertical-align: top;
        line-height: 2rem;
        white-space: nowrap;

        // gradient fadeout if text is too long for box
        &::after {
          display: block;
          position: absolute;
          top: 0;
          right: 0;
          background: linear-gradient(to right, rgb(212 212 212 / 0%) 0%, colors.$light-gray 75%, colors.$light-gray 100%);
          width: 2rem;
          height: 100%;
          content: "";
        }
      }

      // right block of notification bar - contains icons or a number
      & .studymodule-notification-header {
        display: flex;
        position: relative;
        top: 0;
        align-items: center;
        background-color: colors.$silver;
        padding-left: 1.2rem;
        width: 5.6rem;
        height: 2.2rem;
        text-align: center;

        // if an icon looks wrong, fix it here. remember to check selected outline on firefox - line-height will move the outline downwards

        & button {
          text-decoration: underline;
          color: colors.$white;
        }
      }

      &.notification-continued {
        & .studymodule-notification-content {
          border-top: 0;
        }
      }
    }
  }

  &.studymodule-narrow {
    display: block;
    padding-left: spacing.$spacing-md;
    height: spacing.$spacing-md;
    min-height: spacing.$spacing-md;

    & .studymodule-top-row {
      height: spacing.$spacing-md;

      & > .control {
        padding-top: 1rem;
        padding-left: 2.9rem;

        & .control-indicator, & .control__indicator {
          left: spacing.$spacing-xxs;
        }
      }

      & .title-box {
        height: spacing.$spacing-md;

        &::after {
          display: block;
          position: absolute;
          top: 0;
          right: 0;
          background-color: transparent;
          background-image: linear-gradient(to right, rgb(241 241 241 / 0%) 0%, colors.$extra-light-gray 75%, colors.$extra-light-gray 100%);
          width: 2rem;
          height: 100%;
          content: "";
        }

        & .studymodule-title {
          text-transform: none;

          &:last-child {
            display: flex;
            align-items: center;
            padding-top: spacing.$spacing-xxs;
          }
        }
      }
    }
  }

  & + .studymodule-narrow {
    margin-top: spacing.$spacing-xxs;
  }

  // our 11 fabulous colors and their darkened versions for the grade boxes
  &.cu-color-0 .studymodule-top-row {
    @include cu-bg-color(colors.$silver, colors.$light-gray);
  }

  &.cu-color-1 .studymodule-top-row {
    @include cu-bg-color(colors.$appelsiini, colors.$appelsiini-light);
  }

  &.cu-color-2 .studymodule-top-row {
    @include cu-bg-color(colors.$kaakao, colors.$kaakao-light);
  }

  &.cu-color-3 .studymodule-top-row {
    @include cu-bg-color(colors.$minttu, colors.$minttu-light);
  }

  &.cu-color-4 .studymodule-top-row {
    @include cu-bg-color(colors.$flamingo, colors.$flamingo-light);
  }

  &.cu-color-5 .studymodule-top-row {
    @include cu-bg-color(colors.$auringonkukka, colors.$auringonkukka-light);
  }

  &.cu-color-6 .studymodule-top-row {
    @include cu-bg-color(colors.$topaasi, colors.$topaasi-light);
  }

  &.cu-color-7 .studymodule-top-row {
    @include cu-bg-color(colors.$karviainen, colors.$karviainen-light);
  }

  &.cu-color-8 .studymodule-top-row {
    @include cu-bg-color(colors.$kirva, colors.$kirva-light);
  }

  &.cu-color-9 .studymodule-top-row {
    @include cu-bg-color(colors.$ametisti, colors.$ametisti-light);
  }

  &.cu-color-10 .studymodule-top-row {
    @include cu-bg-color(colors.$hiekka, colors.$hiekka-light);
  }

  &.cu-color-11 .studymodule-top-row {
    @include cu-bg-color(colors.$kesataivas, colors.$kesataivas-light);
  }

  &.dimmed {
    & .studymodule-top-row {
      &::after {
        display: inline-block;
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgb(0 0 0 / 50%);
        width: 100%;
        height: 100%;
        content: "";
      }
    }

    & .studymodule-notification-bar {
      &::after {
        display: inline-block;
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgb(0 0 0 / 50%);
        width: 100%;
        height: 100%;
        content: "";
      }

      // disable cool rounded corner when dimmed
      & .studymodule-notification {
        &:last-child {
          & .studymodule-notification-header {
            border-bottom-right-radius: unset;
          }
        }
      }
    }

    &.white {
      & .studymodule-top-row {
        &::after {
          background-color: rgb(255 255 255 / 50%);
        }
      }

      & .studymodule-notification-bar {
        &::after {
          background-color: rgb(255 255 255 / 50%);
        }
      }
    }
  }

  &.studymodule-highlighted {
    & .studymodule-top-row {
      align-items: center;
      background: colors.$primary-color-light;
      overflow: hidden;

      & .title-box {
        display: flex;
        background: colors.$primary-color-light;
        width: 100%;

        &::after {
          background-color: transparent;
          background-image: linear-gradient(to right, rgb(218 237 255 / 0%) 0%, colors.$primary-color-light 75%, colors.$primary-color-light 100%);
        }
      }

      & .extra-items-holder {
        &::before {
          background-color: transparent;
          background-image: linear-gradient(to right, rgb(218 237 255 / 0%) 0%, colors.$primary-color-light 75%, colors.$primary-color-light 100%);
        }
      }
    }
  }

  &.selected {
    outline: colors.$primary-color solid spacing.$pixel-1;

    &.studymodule-narrow {
      outline: none;

      & .studymodule-top-row {
        outline: colors.$primary-color solid spacing.$pixel-1;
      }
    }

    & .studymodule-notification-bar {
      & .studymodule-notification {
        &:last-child {
          & .studymodule-notification-header {
            border-bottom-right-radius: 0;
          }
        }
      }
    }
  }
}
