.current-organisation-role-share-editor-container {
  & .btn.btn-naked.btn-sm {
    position: relative;
    top: -0.8rem;
    float: right;
    z-index: 1;
    margin-right: 0;

    & + .label-row {
      clear: both;
      margin-top: 0.9rem;
    }
  }

  & percent-editor {
    display: inline-block;
    width: 80%;
  }

  & .button-col {
    margin: 0 -1.5rem;
    padding: 0;

    & > .btn {
      padding: 0;
      min-width: unset;
    }
  }

  & .row {
    & .form-control.form-control-fake, search-organisation {
      display: block;
      margin-bottom: 1rem;
    }

    & percent-editor .form-control {
      margin-bottom: 1rem;
    }
  }

  & ul.error-list {
    &[ng-messages] {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .form-control-fake {
    padding: 0;

    .form-control {
      transition: none;
      margin: 0;
      border: 0;
      box-shadow: none;
      height: 3.8rem;
    }
  }
}
