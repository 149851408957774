@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

$skip-link-height: spacing.$spacing-md;

sis-skip-link {
  & .skip-link {
    position: absolute;
    z-index: 1011;
    background-color: colors.$white;
    width: 0;
    height: 0;
    overflow: hidden;
    text-align: center;
    line-height: $skip-link-height;
  }
}

/* The following styles are made for top navigation, study progress graph, alerts and for the header section of each
student main pages (Plan, Timing, Calendar, Profile). These ensure that whenever skip-link is active, following elements
are positioned correctly on scroll. */

body.skip-link-active {
  & .skip-link {
    width: 100%;
    height: initial;
  }

  &.page-student {
    /* Top navigation */
    & .nav-div {
      padding-top: $skip-link-height;
    }

    /* Student Frontpage */
    & study-progress-graph .study-status {
      top: calc($skip-link-height + 12rem);
    }

    /* Alerts */
    & sis-alerts {
      top: calc($skip-link-height + 11.2rem);
    }

    & sis-alerts.collapsed {
      top: 0;
    }

  }
}
