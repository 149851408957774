@use 'sis-assets/foundations/breakpoints/breakpoints' as breakpoints;

sis-plan-structure-customom-module-attainment {
  @media (min-width: breakpoints.$screen-lg-min) {
    & .study-credit-info {
      & .fill-by-row {
        display: flex;

        & .tiny-static-form-group {
          padding-right: 1rem;
        }
      }
    }
  }
}
