prerequisite-group-editor {
  & .prerequisite-group-container {
    & .prerequisites-edit-button {
      & > button {
        margin-right: 0;
      }
    }
  }

  & .add-new {
    margin-bottom: 2rem;
  }
}
