sis-planned-studies-by-study-term {
  & hr {
    margin: 0;
  }

  & .study-term-title {
    text-transform: uppercase;
    font-weight: 500;
  }

  & .code {
    font-weight: 400;
  }

  & .listing-summary {
    text-align: right;

    & .credits {
      font-weight: 600;
    }
  }
}
