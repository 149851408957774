app-module-content-workflow {
  & .revoke-cancel-button-holder {
    position: relative;
    top: -1rem;
  }

  & .button-row {
    margin-bottom: 2rem;
    width: 100%;
    text-align: right;
  }

  & .contextual-notification {
    margin-bottom: 1rem;
  }
}
