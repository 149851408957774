@use 'sis-assets/foundations/breakpoints/breakpoints' as breakpoints;
@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;
@use 'sis-assets/foundations/focus/tokens' as focus;

untimed-course-units {
  #js-untimed-section {
    .padded {
      padding: 0.6rem 1.5rem;
    }

    &.has-focus {
      & .course-sets {
        height: auto;

        @media (max-width: breakpoints.$screen-lg-max) {
          margin-top: 6rem;
        }
      }
    }
  }

  .row.top {
    position: relative;

    .info-timed-cus {
      position: absolute;
      right: 0;
      bottom: 1rem;
      opacity: 0.8;
      margin: 0.6rem 2.5rem;
      border-radius: spacing.$spacing-xxs;
      background-color: colors.$middle-gray;
      padding: 1rem 0.9rem;
      width: auto;
      color: colors.$white;
      font-size: 1.2rem;

    }

    .control-box {
      display: block;
      position: relative;
      margin-bottom: 1rem;
      padding: 0.5rem 0;
      width: 16rem;
    }
  }

  .row.bottom {
    background-color: colors.$dark-gray;
  }

  & .courses-list-wrapper {
    position: relative;
    transition: all 0.3s linear;
    height: 3.5rem;
    min-height: 3.5rem;

    & .scroller {
      height: calc(
        100vh - 11rem - 14rem - 4.4rem
      ); // magic numbers: top nav and extras 11rem, white space 14rem, tab 4.4rem
      & > div.row {
        height: 100%;
        overflow-y: scroll;
      }
    }

    & .timeline-expander {
      &--open {
        display: flex;
        flex-direction: row-reverse;
        align-items: flex-end;
        border-top: spacing.$pixel-1 solid colors.$primary-color;
        background-color: colors.$primary-color;
        height: 4.8rem;

        @media (max-width: breakpoints.$screen-lg-max) {
          display: block;
        }
      }

      &__button {
        border: none;
        border-radius: 0;
        text-transform: none;
        letter-spacing: 0.03rem;
        font-size: 1.4rem;
        font-weight: normal;

        &:active,
        &:focus {
          outline: focus.$fudis-focus-generic-outline;
          outline-offset: 0;
          background-color: colors.$primary-color;
        }

        &--open {
          flex-grow: 1;
          margin-right: 0;
          height: 4.8rem;

          @media (max-width: breakpoints.$screen-lg-max) {
            width: 100%;
          }
        }

        &--closed {
          z-index: 9;
          width: 100vw;

          // Draws a small blue line between arrow and the button itself, so button's border-top stays behind it when focusing.
          &::before {
            position: absolute;
            top: 0;
            right: 50%;
            transform: translateX(50%);
            z-index: 10;
            background-color: colors.$primary-color;
            width: 6.9rem;
            height: spacing.$spacing-xxs;
            content: "";
          }

          // Earlier this arrow was done with ::before pseudo class, but those aren't clickable or focusable.
          // Added an inline SVG arrow image and some styling for the focus, as it was pretty hard to see before.
          > .timeline-expander-arrow {
            box-sizing: content-box;
            display: block;
            position: absolute;
            top: -60%;
            left: 50%;
            transform: translate(-50%, 0);
            transform-origin: center;
            margin-top: spacing.$pixel-2;
            border-top: spacing.$pixel-2 solid transparent;
            border-right: spacing.$pixel-2 solid transparent;
            border-left: spacing.$pixel-2 solid transparent;
          }

          > sis-icon[icon="chevron"] {
            position: absolute;
            top: -2.1rem;
            left: 50%;
            transform: rotate(-90deg);
            z-index: 10;
            margin-left: -1.6rem;
            cursor: pointer;
          }

          &:active,
          &:focus {
            overflow: initial;

            & .timeline-expander-arrow {
              border-top: spacing.$pixel-2 dashed black;
              border-right: spacing.$pixel-2 dashed black;
              border-left: spacing.$pixel-2 dashed black;
              background: linear-gradient(to top, colors.$white 12%, transparent 0%);
            }
          }
        }
      }

      & ul.nav.nav-tabs {
        border-bottom-width: 0;
        background-color: colors.$primary-color;

        & li {
          z-index: 10;

          @media (max-width: breakpoints.$screen-lg-max) {
            &.active a {
              border-color: colors.$white;
              background-color: colors.$dark-gray;
            }

            &:not(.active) a {
              border-color: colors.$white;
              background-color: colors.$primary-color;
              color: colors.$white;

              &:hover {
                background-color: colors.$primary-color-dark;
              }
            }
          }
        }

        @media (min-width: breakpoints.$screen-xl-min) {
          flex-wrap: nowrap;
          border-bottom-width: 0;
          background-color: colors.$primary-color;
        }
      }
    }

    & .course-sets {
      position: relative;
      height: 0;
      overflow-y: hidden;

      & .overlay {
        position: absolute;
        inset: 0;
        z-index: 2;
        background-color: rgb(0 0 0 / 50%);

        & + .additional-box {
          & a::before {
            z-index: 3;
          }
        }
      }

      & .course-unit-col {
        display: inline-block;
        float: left !important;
        width: 100%;
        max-width: 20%;
        vertical-align: top;

        & .courses-list-col {
          margin: 0.5rem;
          color: colors.$extra-light-gray;

          & course-unit-box,
          & custom-study-draft {
            margin: 0.5rem 0;
            width: 100%;

            & .overlay {
              display: none;

              & + .additional-box {
                & a::before {
                  z-index: 3;
                }
              }
            }

            & div.courseunit.cu-color-study-draft {
              border: 0;
            }
          }

          & .module-status {
            display: block;
            width: 100%;
            text-align: center;
          }

          & .courses-list-title {
            margin: 1rem 0;
            border-bottom: spacing.$pixel-1 solid colors.$middle-gray;
            text-align: left;
          }

          &.timing course-unit-box,
          &.timing custom-study-draft {
            position: relative;

            & .additional-box:hover {
              background-color: colors.$extra-light-gray;
              cursor: default;
            }

            & .overlay {
              display: block;
              position: absolute;
              inset: 0;
              background-color: rgb(0 0 0 / 50%);
            }
          }

          &.timing course-unit-box.active .overlay,
          &.timing custom-study-draft.active .overlay {
            display: none;
          }
        }
      }
    }
  }
}

.focused untimed-course-units {
  & .row.bottom {
    height: calc(100vh - 7rem);
  }

  & .row.top {
    position: relative;
    height: 14rem;
  }
}
