local-date-editor {
  & input.form-control {
    &::-ms-clear {  // IE, hide cross that clears input field
      display: none;
    }
  }

  .inner-addon {
    position: relative;
  }
}
