@use 'sis-assets/foundations/spacing/tokens' as spacing;

app-open-university-cur-selection-final-step {
  & .edit-selections {
    padding-right: spacing.$spacing-md;

    sis-button {
      font-weight: bold;

      & button {
        float: right;
        padding: spacing.$spacing-xs;
        text-transform: uppercase;
        font-size: 1.4rem;
      }
    }
  }
}
