@use 'sis-assets/foundations/breakpoints/breakpoints' as breakpoints;

sis-version-selector {
  & ngb-popover-window {
    width: 35rem;

    @media (max-width: breakpoints.$screen-xs-max) {
      width: 18rem;
    }

    @media (min-width: breakpoints.$screen-sm-min) and (max-width: breakpoints.$screen-sm-max) {
      width: 32rem;
    }

    @media (min-width: breakpoints.$screen-md-min) and (max-width: breakpoints.$screen-md-max) {
      width: 35rem;
    }
  }
}
