@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

show-view-module-content-application-link {
  display: block;

  & .show-application-request-link {
    background-color: colors.$primary-color-light;
    padding: 0.5rem 2rem;
    width: 100%;
    overflow: hidden;
    color: colors.$dark-gray;

    & a {
      float: right;
      padding-top: spacing.$spacing-xxs;
      text-decoration-line: underline;
      text-decoration-style: dotted;
      color: colors.$dark-gray;
      font-weight: 400;
    }
  }

  & .btn.btn-primary.btn-sm {
    margin: 1rem 0 0;
  }
}
