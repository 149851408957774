@use 'sis-assets/foundations/breakpoints/breakpoints' as breakpoints;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

app-payments {
  & .study-year {
    margin: 2rem 0 spacing.$spacing-xl 0;

    & sis-language-pills {
      position: absolute;

      @media (max-width: breakpoints.$screen-sm-max) {
        display: table-cell;

        & .nav.nav-pills-localize {
          bottom: -0.4rem;
          margin-left: 0;
        }
      }
    }

    & .invalidated-payments {
      & td.info-icon {
        padding: spacing.$spacing-xs 0 0 spacing.$spacing-md;
      }
    }
  }
}
