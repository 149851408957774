@use '@funidata/ngx-fudis' as fudis;

$simple-radio-input-size: calc(1.25rem / var(--fudis-rem-multiplier));
$simple-radio-indicator-size: calc(0.75rem / var(--fudis-rem-multiplier));

sis-simple-radio-button {

  .simple-radio-button {
    @include fudis.body-text-md-regular;
    @include fudis.text-color('gray-dark');

    display: inline-flex;
    position: relative;
    align-items: center;
    margin-bottom: 0;
    width: auto;
    min-height: fudis.$spacing-lg;

    &__label {
      margin-bottom: 0;
      text-transform: none;
      padding-right: fudis.$spacing-xs;
      padding-left: fudis.$spacing-xs;
    }

    /* Radio button circle */
    &__content {
      display: flex;
      align-items: center;
      transform: translateX(calc(-0.1rem / var(--fudis-rem-multiplier)));
      padding-left: 0;

      &-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: fudis.$spacing-lg;
        min-height: fudis.$spacing-lg;
      }

      &__value {
        @include fudis.border('1px', 'solid', 'gray-middle');

        display: inline-flex;
        top: 0;
        left: 0;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        width: $simple-radio-input-size;
        min-width: $simple-radio-input-size;
        height: $simple-radio-input-size;
        background: fudis.$color-white;


        &__indicator {
          @include fudis.bg-color('gray-dark');

          border-radius: 50%;
          width: $simple-radio-indicator-size;
          height: $simple-radio-indicator-size;
        }
      }
    }

    &__input {
      position: absolute;
      left: 0;
      opacity: 0;
      margin: 0;
      cursor: pointer;
      width: 100%;
      height: 100%;

      /* stylelint-disable-next-line */
      &:focus + .simple-radio-button__content .simple-radio-button__content__value {
        @include fudis.focus-form-field;
      }
    }
  }
}
