@use 'sis-assets/foundations/colors/colors' as colors;

sis-changes-and-details-tooltip {
  & .changes-and-details {
    & .info-cell {
      & .from-to {
        color: colors.$silver;
      }

      & .value {
        font-style: italic;
      }
    }
  }
}
