sis-selected-locations-view {
  & .location {
    & sis-location {
      display: inline-block;
      font-weight: 400;

      & > div {
        margin-bottom: 0;
      }

      & sis-icon[icon="pin-small"] svg {
        vertical-align: text-top;
      }
    }
  }
}
