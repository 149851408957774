@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

ngb-pagination {
  & ul.pagination {
    display: inline-block;
    margin: 1.8rem 0;
    width: 100%;
    text-align: center;

    & li.page-item {
      display: inline-block;
      text-align: center;
      font-weight: 400;

      &:first-of-type, &:last-of-type {
        vertical-align: bottom;

        & a.page-link {
          margin: 0 1.1rem 0 spacing.$spacing-xxs;
          border: spacing.$pixel-2 solid colors.$white;
          border-radius: spacing.$pixel-2;
          padding: 0;
          width: 6.5rem;
          text-align: center;

          &:hover {
            border-color: colors.$primary-color;
            background-color: colors.$white;
          }
        }
      }

      &.active {
        & a.page-link {
          &, &:hover {
            border-color: colors.$dark-gray;
            background-color: colors.$white;
            cursor: default;
            color: colors.$dark-gray;
          }
        }
      }

      &.disabled {
        cursor: not-allowed;

        & a.page-link {
          color: colors.$silver;
        }
      }

      & a.page-link {
        margin-right: 0.6rem;
        border: spacing.$pixel-1 solid colors.$white;
        border-radius: 50%;
        box-shadow: none;
        padding: spacing.$spacing-xs 0 0.9rem 0;
        width: spacing.$spacing-xl;
        height: spacing.$spacing-xl;
        font-size: 1.4rem;

        &:hover {
          border-color: colors.$extra-light-gray;
          background-color: colors.$extra-light-gray;
          color: colors.$primary-color;
        }
      }
    }
  }
}
