@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

app-open-university-enrolled-info {
  & .cur-separator .cur-enrolment-container {
    border-bottom: spacing.$pixel-1 solid colors.$dark-gray;
  }

  & .cur-separator:last-child .cur-enrolment-container {
    border-bottom: none;
  }
}
