@use 'sis-assets/foundations/breakpoints/breakpoints' as breakpoints;
@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

app-cart-confirmation {
  & .thank-you-texts {
    padding: spacing.$spacing-sm 0 spacing.$spacing-sm 0;
  }

  & .confirm-enrolments {
    margin: 0 0 spacing.$spacing-sm 0;
    background-color: colors.$primary-color-light;
    padding: spacing.$spacing-sm;

    & h2 {
      margin: 0 0 spacing.$spacing-sm 0;
    }

    & .confirm-button-div {
      padding-left: spacing.$spacing-sm;

      @media (max-width: breakpoints.$screen-xl-min) {
        padding: spacing.$spacing-sm 0 spacing.$spacing-sm 0;
      }
    }
  }

  & .notification-icon {
    display: flex;
    float: left;
    align-items: center;
    justify-content: center;
    width: 5%;
    min-width: spacing.$spacing-xl;
  }

  & .guidance-div {
    margin: spacing.$spacing-sm 0 0 spacing.$spacing-xs;
  }

  & .guidance-text {
    float: left;
    width: 90%;

    & > .guidance {
      font-size: 1.2rem;
    }

    @media (max-width: breakpoints.$screen-sm-min) {
      width: 75%;
    }
  }

  & .price {
    border-top: spacing.$pixel-1 solid colors.$middle-gray;
  }
}
