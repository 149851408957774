@forward './read-only-course-unit-info-modal/read-only-course-unit-info-modal.component';
@forward './course-unit-version-selector/course-unit-version-selector.component';
@use 'sis-assets/foundations/breakpoints/breakpoints' as breakpoints;
@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

@mixin cu-modal-outline($color){ // cu-color-n specific rules are at the end of this file
  outline: spacing.$pixel-1 solid $color;
  overflow: hidden;
}

.course-unit-info-modal, .course-unit-info-as-page {
  $credits-box-width: 3.8rem;

  & .course-unit-info-modal-header {
    display: flex;
    background-color: colors.$extra-light-gray;

    & .credits-box {
      display: inline-block;
      position: relative;
      flex-shrink: 0;
      background: colors.$silver;
      padding-top: 3rem;
      width: $credits-box-width;
      text-align: center;

      & .credit-amount, & .credit-abbreviation {
        display: block;
        line-height: 1.4rem;
        color: colors.$dark-gray;
        font-weight: 600;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }

      & .grade-box {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 4.5rem;
        color: colors.$dark-gray;

        & > span {
          display: block;
          position: relative;
          top: -1.2rem;
          left: -0.1rem;
        }
      }
    }

    & .grade-box { // this is separately outside .credits-box, so the rule won't become too strong
      background: colors.$light-gray;
    }

    & .info-box {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      padding-top: 0.5rem;
      padding-left: 1.7rem;
      max-width: calc(100% - $credits-box-width);

      & .top-section {
        display: flex;
        padding-right: 1.5rem;

        & .code-and-name {
          flex-grow: 1;

          & .course-unit-code {
            margin-top: 0.9rem;
            margin-bottom: spacing.$pixel-3;
            line-height: 1.2;
            font-weight: 400;
          }

          & .modal-title {
            margin-bottom: 0.5rem;
            line-height: 1.1;
          }
        }

        & .top-icons {
          flex-shrink: 0;
          margin-top: spacing.$spacing-sm;
          margin-right: spacing.$spacing-xs;

          & .dropdown-toggle {
            margin-right: spacing.$spacing-xs;
            background: transparent;
          }

          & .btn-close {
            border-width: 0;
            background: transparent;
            cursor: pointer;

            &:hover {
              & svg {
                fill: colors.$dark-gray;
              }
            }

            & svg {
              fill: colors.$middle-gray;
            }
          }
        }
      }

      & .middle-section {
        padding-right: 1.5rem;

        & .bottom-margin {
          margin-bottom: 0.5rem;
        }

        & sis-course-unit-version-selector {
          max-width: calc(100% - 2.5rem); // Prevent overflow
        }
      }

      & .bottom-section {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        padding-right: 1.5rem;

        & .nav-pills {
          padding: 2rem 0 0;

          & li {
            margin-bottom: 0;

            &:first-child {
              margin-left: 0;
            }

            & > a::after {
              z-index: 100;
            }
          }
        }

        & .language-nav {
          padding-bottom: spacing.$spacing-xxs;
          white-space: nowrap;
        }
      }
    }
  }

  & .modal-body {
    clear: both;
    padding: spacing.$spacing-xl 2rem spacing.$spacing-lg 2rem;
  }

  & .tweet-text {
    display: block;
    padding-bottom: spacing.$spacing-xl;
    line-height: spacing.$spacing-sm;
    font-size: spacing.$spacing-sm;
    overflow-wrap: break-word;

    & p {
      margin: 0;
    }

    & .sis-placeholder {
      display: inline-block;
      height: 1.4rem;
      line-height: spacing.$spacing-sm;
    }
  }

  & course-unit-info-modal-text-field, & sis-course-unit-info-modal-text-field {
    & sis-icon {
      & + p {
        line-height: 1.5rem;
      }
    }
  }

  & .basic-info {
    padding: 0 0 3rem 5.5rem;

    & .tiny-static-form-group {
      & course-unit-info-modal-text-field, & sis-course-unit-info-modal-text-field {
        & p {
          display: inline;
        }
      }
    }
  }

  & .form-group-mimic {
    margin-bottom: 2.8rem;
  }

  & .langugage.value {
    & course-unit-info-modal-text-field, & sis-course-unit-info-modal-text-field {
      display: inline-block;
    }
  }

  & .assessment-item-info {
    margin-top: 2rem;
    padding: spacing.$spacing-sm spacing.$spacing-sm;

    & + h3 {
      margin-bottom: 2rem;
    }
  }

  & .cur-tabs .nav.nav-pills {
    margin-bottom: 2rem;

    & li:first-of-type {
      margin-left: 0;
    }
  }

  & .accordion-panel {
    & .header {
      & .title {
        & .flow-state.tiny-badge {
          display: inline-block;
        }
      }
    }

    & .content {
      & .content-text {
        & .key-text {
          margin-bottom: 3rem;
          font-size: spacing.$spacing-sm;
          font-weight: 300;
        }

        & ul.person-rules {
          &.ordering-person-rules {
            list-style-type: decimal;
          }

          & > li {
            white-space: pre-line;
          }
        }

        & .search-tags {
          & > span {
            display: inline-block;
          }
        }

        & .teacher {
          & h4 {
            display: block;
            margin: 0 0 0.6rem;
          }

          & .teacher-data {
            margin-bottom: 3.7rem;
            font-size: spacing.$spacing-sm;

            &:nth-of-type(2n + 1) {
              clear: both;
            }

            & > * {
              display: block;
              line-height: spacing.$spacing-md;
            }

            & .teacher-name {
              font-weight: 600;
            }

            & .additional-data-no-person {
              font-weight: 600;
            }

            & .additional-data {
              color: colors.$middle-gray;
            }
          }
        }

        & .form-group:not(.form-group-help, .form-group-invalid) {
          margin-bottom: 2.6rem;

          & .guidance-normal {
            display: block;
            margin-top: 0.9rem;
            color: colors.$middle-gray;
          }
        }

        & learning-environment {
          & a {
            color: colors.$primary-color;
          }
        }
      }
    }
  }

  & course-unit-realisation-info {
    & .form-control-static {
      & strong {
        margin-right: 0.5rem;
        font-weight: 400;
      }
    }

    & sis-popover {
      & span {
        display: inline-block;
      }
    }
  }

  @media (max-width: breakpoints.$screen-xs-max) {
    & .course-unit-info-modal-header {
      & .title-bottom-row {
        & .language-nav {
          & ul.nav-pills-localize {
            margin-left: 0;
          }
        }
      }
    }

    & .tweet-text {
      font-size: 1.4rem;
    }

    & .accordion-panel {
      & .content {
        & .form-group:not(.form-group-help, .form-group-invalid):last-of-type {
          margin-bottom: spacing.$spacing-sm;
        }

        & label.control-label {
          font-size: 1.2rem;
        }
      }
    }
  }

  @media (max-width: breakpoints.$screen-sm-max) {
    & .accordion-panel {
      & .content {
        & .teacher {
          margin-bottom: 0;

          & .teacher-data {
            font-size: 1.4rem !important;
          }
        }
      }
    }
  }
}

.course-unit-info-modal {
  &.cu-color-0 {
    @include cu-modal-outline(colors.$silver);
  }

  &.cu-color-1 {
    @include cu-modal-outline(colors.$appelsiini);
  }

  &.cu-color-2 {
    @include cu-modal-outline(colors.$kaakao);
  }

  &.cu-color-3 {
    @include cu-modal-outline(colors.$minttu);
  }

  &.cu-color-4 {
    @include cu-modal-outline(colors.$flamingo);
  }

  &.cu-color-5 {
    @include cu-modal-outline(colors.$auringonkukka);
  }

  &.cu-color-6 {
    @include cu-modal-outline(colors.$topaasi);
  }

  &.cu-color-7 {
    @include cu-modal-outline(colors.$karviainen);
  }

  &.cu-color-8 {
    @include cu-modal-outline(colors.$kirva);
  }

  &.cu-color-9 {
    @include cu-modal-outline(colors.$ametisti);
  }

  &.cu-color-10 {
    @include cu-modal-outline(colors.$hiekka);
  }

  &.cu-color-11 {
    @include cu-modal-outline(colors.$kesataivas);
  }

  &.cu-color-primary {
    @include cu-modal-outline(colors.$primary-color);
  }
}

sis-completion-methods, sis-selectable-completion-methods {
  & .table-completion-method-matrix {
    margin-bottom: spacing.$pixel-1;
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    color: colors.$dark-gray;

    > thead {
      & tr {
        & th {
          &.completion-method-table-title {
            padding-bottom: 0;
            padding-left: 1rem;
            vertical-align: bottom;

            > h4 {
              display: inline-block;
              margin: 0;
            }

            & .matches-timing-info {
              margin-left: 1rem;
            }
          }

          &.col-completion-method-matrix-completion-selection {
            border: spacing.$pixel-1 solid colors.$primary-color;
            width: 8rem;

            &:nth-child(even) {
              border-right: 0;
              border-left: 0;
            }

            &:first-child {
              border-right: spacing.$pixel-1 solid colors.$primary-color;
              border-top-left-radius: spacing.$spacing-xxs;
            }

            &:nth-last-of-type(3) {
              border-right: spacing.$pixel-1 solid colors.$primary-color;
              border-top-right-radius: spacing.$spacing-xxs;
            }

            &:hover {
              background-color: colors.$primary-color-light;
            }

            & .completion-method-container {
              padding: 1rem 0 0;
              width: 100%;
              text-align: center;

              & .completion-method-header {
                vertical-align: middle;
                text-transform: uppercase;
                text-decoration: none;
                color: colors.$primary-color;
                font-size: 1.4rem;
                font-weight: 600;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
              }

              .selected-method {
                display: block;
                visibility: hidden;
                background-color: colors.$green;
                width: 100%;
                text-transform: uppercase;
                color: colors.$white;
                font-size: 1.2rem;
                font-weight: 400;

                &.active {
                  visibility: visible;
                }
              }
            }

            &.is-highlighted {
              outline: spacing.$pixel-1 solid colors.$white;
              border: 0;
              border-bottom: spacing.$pixel-1 solid colors.$white;

              &:hover {
                background-color: colors.$primary-color;
              }

              & .completion-method-header {
                color: colors.$white;
              }
            }
          }
        }
      }
    }

    > tbody {
      font-weight: 400;

      & tr {
        &:last-child > td.is-highlighted::after {
          position: absolute;
          top: 100%;
          left: 0;
          transform: scaleY(0.4);
          margin-top: -2.1rem;
          border-width: spacing.$spacing-xl;
          border-style: solid;
          border-color: transparent;
          border-top-color: colors.$primary-color;
          content: " ";
          pointer-events: none;
        }

        &.highlighted-completion-methods > td {
          background-color: colors.$primary-color-light;

          &.is-highlighted {
            background-color: colors.$primary-color;
          }
        }

        &.col-completion-method-matrix-assessment-item {
          width: 25rem;
        }

        & td {
          position: relative;
          border-bottom: spacing.$pixel-1 solid colors.$white;
          background-color: colors.$extra-light-gray;
          padding: 1rem;
          vertical-align: middle;

          &:last-of-type {
            border-left: spacing.$pixel-1 solid colors.$white;
          }

          & sis-icon[icon="dot"] svg,
          sis-icon[icon="check"] svg {
            fill: colors.$middle-gray;
          }

          & .control.control-inline.control-checkbox, & .control.control--inline.control--checkbox {
            margin-right: 0;
            padding-left: 1.8rem;

            &.disabled {
              cursor: not-allowed;
            }
          }

          & .course-unit-realisations-list {
            display: block;
            margin-bottom: spacing.$spacing-xs;

            &:last-of-type {
              margin-bottom: 0;
            }
          }

          & button.link-button.expand {
            margin-top: 0.5rem;
            text-decoration-line: underline;
            text-decoration-style: dotted;

            &:hover {
              text-decoration-style: solid;
            }
          }
        }
      }
    }

    & .is-highlighted {
      &.col-completion-method-matrix-completion-selection,
      &.col-completion-method-matrix-entry {
        border: 0;
        border-bottom: spacing.$pixel-1 solid colors.$white;
        background-color: colors.$primary-color;
        color: colors.$white;

        & sis-icon[icon="check"] svg,
        sis-icon[icon="dot"] svg {
          fill: colors.$white;
        }
      }

      &.col-completion-method-matrix-assessment-item,
      &.col-completion-method-matrix-cur-selection {
        background-color: colors.$primary-color-light;
        color: colors.$dark-gray;
      }
    }

    & .col-completion-method-matrix-completion-selection {
      border-right: spacing.$pixel-1 solid colors.$white;
      min-width: 8rem;
      text-align: center;
    }
  }

  .completion-method-info-container {
    border: spacing.$pixel-2 solid colors.$primary-color;
    padding: 2rem;

    & h2:not(.header) {
      display: inline-block;

      & .completion-method-selected {
        margin-left: 1rem;

        & sis-icon[icon="check"] svg {
          fill: colors.$green;
        }

        & > span {
          text-transform: uppercase;
          letter-spacing: spacing.$pixel-1;
          color: colors.$green;
          font-size: 1.4rem;
          font-weight: 500;
        }
      }
    }

    & .assessment-item-info {
      margin-left: 0;
    }

    & button.select-method {
      display: inline-block;
      margin: 0 0 0.6rem 2rem;
    }

    & .guidance-info {
      margin-top: 1.2rem;
      margin-bottom: 0.7rem;
      background-color: colors.$extra-light-gray;
      padding: spacing.$spacing-sm spacing.$spacing-sm spacing.$spacing-xxs;

      & h4 {
        margin: 0;

        + div {
          margin-bottom: 1rem;
        }
      }
    }

    & .accordion-panel {
      & .content-row {
        & .content-text {
          & .nav.nav-pills {
            margin-bottom: 2rem;

            & li:first-child {
              margin-left: 0;
            }
          }

          & teaching-course-unit-realisation-structure {
            & .main-content {
              margin-left: -1.5rem;
              padding-top: 0;
            }
          }
        }
      }
    }
  }

  & .tiny-badge {
    margin-left: 0.5rem;
  }
}

course-unit-info-modal, sis-plan-course-unit-info-modal {
  & course-unit-info-modal-course-unit-realisation, & course-unit-realisation-info, & sis-course-unit-info-modal-course-unit-realisation {
    & .form-control-static {
      & strong {
        font-weight: 400;
      }
    }
  }
}
