@use 'sass:map';
@use '../breakpoints/breakpoints' as breakpoints;
@use 'tokens' as tokens;
@use '../spacing/tokens' as spacing;

// Headings

@mixin heading-xxl {
  hyphens: auto;
  margin-bottom: spacing.$spacing-sm;
  text-transform: none;
  overflow-wrap: break-word;
  line-height: tokens.$heading-lg-line-height;
  font-family: tokens.$font-family;
  font-size: tokens.$heading-xxl-font-size;
  font-weight: tokens.$font-weight-medium;

  @media (max-width: breakpoints.$screen-sm-max) {
    font-size: tokens.$heading-xl-font-size;
  }
}

@mixin heading-xl {
  hyphens: auto;
  margin-bottom: spacing.$spacing-sm;
  text-transform: none;
  overflow-wrap: break-word;
  line-height: tokens.$heading-lg-line-height;
  font-family: tokens.$font-family;
  font-size: tokens.$heading-xl-font-size;
  font-weight: tokens.$font-weight-medium;

  @media (max-width: breakpoints.$screen-sm-max) {
    line-height: tokens.$heading-md-line-height;
    font-size: tokens.$heading-lg-font-size;
  }
}

@mixin heading-lg {
  hyphens: auto;
  margin-bottom: spacing.$spacing-xs;
  text-transform: none;
  overflow-wrap: break-word;
  line-height: tokens.$heading-md-line-height;
  font-family: tokens.$font-family;
  font-size: tokens.$heading-lg-font-size;
  font-weight: tokens.$font-weight-medium;

  @media (max-width: breakpoints.$screen-sm-max) {
    font-size: tokens.$heading-md-font-size;
  }
}

@mixin heading-md {
  hyphens: auto;
  margin-bottom: spacing.$spacing-xs;
  text-transform: none;
  overflow-wrap: break-word;
  line-height: tokens.$heading-md-line-height;
  font-family: tokens.$font-family;
  font-size: tokens.$heading-md-font-size;
  font-weight: tokens.$font-weight-medium;

  @media (max-width: breakpoints.$screen-sm-max) {
    line-height: tokens.$heading-sm-line-height;
    font-size: tokens.$heading-sm-font-size;
  }
}

@mixin heading-sm {
  hyphens: auto;
  margin-bottom: spacing.$spacing-xs;
  text-transform: none;
  overflow-wrap: break-word;
  line-height: tokens.$heading-sm-line-height;
  font-family: tokens.$font-family;
  font-size: tokens.$heading-sm-font-size;
  font-weight: tokens.$font-weight-medium;

  @media (max-width: breakpoints.$screen-sm-max) {
    font-size: tokens.$heading-xs-font-size;
    font-weight: tokens.$font-weight-bold;
  }
}

@mixin heading-xs {
  hyphens: auto;
  margin-bottom: spacing.$spacing-xs;
  text-transform: none;
  overflow-wrap: break-word;
  line-height: tokens.$heading-sm-line-height;
  font-family: tokens.$font-family;
  font-size: tokens.$heading-xs-font-size;
  font-weight: tokens.$font-weight-bold;

  @media (max-width: breakpoints.$screen-sm-max) {
    font-size: tokens.$heading-xxs-font-size;
  }
}

@mixin heading-xxs {
  hyphens: auto;
  margin-bottom: spacing.$spacing-xs;
  text-transform: none;
  overflow-wrap: break-word;
  line-height: tokens.$heading-sm-line-height;
  font-family: tokens.$font-family;
  font-size: tokens.$heading-xxs-font-size;
  font-weight: tokens.$font-weight-bold;
}

// Label

@mixin label {
  text-transform: uppercase;
  overflow-wrap: break-word;
  line-height: tokens.$label-line-height;
  font-family: tokens.$font-family;
  font-size: tokens.$body-text-md-font-size;
  font-weight: tokens.$font-weight-regular;
}

// Description-list component

@mixin description-list-key-typography {
  @include label;
}

@mixin description-list-compact-key-typography {
  display: inline-block;
  text-transform: capitalize;
  line-height: tokens.$body-text-lg-line-height;
  font-family: tokens.$font-family;
  font-size: tokens.$body-text-md-font-size;
  font-weight: tokens.$font-weight-light;
}
