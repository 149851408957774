@use 'sis-assets/foundations/breakpoints/breakpoints' as breakpoints;
@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

// Terms "table" wrapper elements
.terms-table {
  border-width: 0 0 spacing.$pixel-1 0;
  border-style: solid;
  border-color: colors.$middle-gray;
  border-collapse: collapse;
}

// Modifiers
.terms-row-header {
  > .terms-col {
    background-color: colors.$bg-color-alt;
    color: colors.$dark-gray;
  }

  > .terms-col-term {
    padding: .5em 0;
  }

  > .terms-col-period {
    border-top: spacing.$pixel-1 solid colors.$middle-gray;
    border-bottom: spacing.$pixel-1 solid colors.$middle-gray;
    padding: .5em 0;
  }
}

.terms-row-body,
.terms-row-footer {
  > .terms-col {
    background-color: colors.$bg-color-alt;
    padding-bottom: 7rem;

    &.term-courses-col {
      height: 28rem; // $magic: reasonable big value

      // States
      &.is-hovered,
      &.is-active {
        background-color: colors.$bg-color-alt;
      }

      &.is-highlighted {
        background: transparent;
        background-size: 61%;
      }

      // Make individual course boxes line up nice automatically
      > * {
        float: left;
        width: 100%;
      }
    }
  }
}

// Term columns
.terms-col {
  transition: background-color 0.2s linear;
  border-width: 0 0 0 spacing.$pixel-1;
  border-style: dashed;
  border-color: colors.$ui-border-color;
  vertical-align: top;
  text-align: center;

  // Remove borders from first/last
  &:first-child { border-left: 0 !important; }
  &:last-child { border-right: 0; }

  &.timing-target {
    background-color: lighten(colors.$extra-light-gray, 0.5);
    cursor: pointer;

    & .courseunit {
      opacity: 0.5;

      &:hover {
        opacity: 1;
      }
    }
  }

  &.is-first-of-term,
  &.is-first-of-year {
    border-style: solid;
    border-color: colors.$middle-gray;
  }

  &.is-first-of-year {
    border-left-width: spacing.$pixel-3;
  }
}

// Header term columns
.terms-row-header .terms-col {
  overflow: hidden;
  // Disable stronger weight provided by <th>.
  font-weight: inherit;

  // Decrease heading text size in low zoom levels.
  .terms-table-small & {
    font-size: smaller;
  }
}

.terms-col-term {
  // Term titles
  .term-title {
    text-align: left;
    font-size: spacing.$spacing-sm;

    .terms-table-small & {
      font-size: 1.4rem;
    }
  }
}

.zoom-level-0 {
  .terms-col-term {
    border-left: 0;

    &.is-first-of-year {
      border-left: spacing.$pixel-3 solid colors.$middle-gray;
    }
  }
}
