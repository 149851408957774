@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

.completion-method-cell > span.title {
  display: block;
}

.completion-methods-table-container {
  padding: spacing.$spacing-zero;

  & th {
    height: spacing.$spacing-lg;
  }
}

.completion-methods-view-container {
  margin-top: spacing.$spacing-lg;
  border-width: spacing.$pixel-1;
  border-style: solid;
  border-color: colors.$extra-light-gray;
  padding-right: spacing.$spacing-md;
  padding-bottom: spacing.$spacing-md;
  padding-left: spacing.$spacing-md;
}
