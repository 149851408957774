@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

education-structure-preview {
  .education-structure-preview {
    display: flex;
  }

  & .studymodule {
    margin-top: 1rem;

    & .studymodule-top-row {
      & .extra-items-holder {
        & .grade-box {
          sis-icon[icon="achievement"],
          sis-icon[icon="hourglass"] {
            margin-top: 1.2rem;
          }
        }
      }
    }

    &.active {
      & .studymodule-title {
        color: colors.$primary-color-dark;
      }
    }

    &.studymodule-narrow {
      margin-top: spacing.$spacing-xxs;

      &::before {
        display: inline-block;
        position: absolute;
        top: -0.1rem;
        left: 1.2rem;
        border-width: 0 0 spacing.$pixel-1 spacing.$pixel-1;
        border-style: dotted;
        border-color: colors.$dark-gray;
        width: 1rem;
        height: 1.3rem;
        content: "";
      }

      & .studymodule-top-row {
        & .studymodule-title-wrapper > .control {
          margin-left: spacing.$spacing-xs;
          padding-left: spacing.$spacing-md;
        }

        & .extra-items-holder {
          & .credits-box {
            height: spacing.$spacing-md;

            & .credit-amount {
              margin-top: spacing.$pixel-2;
            }

            & .credit-abbreviation {
              opacity: 0;
            }
          }
        }
      }

      & + .studymodule-narrow {
        &::before {
          top: -1.5rem;
          height: 2.7rem;
        }
      }
    }
  }

  & .left-arrows-container {
    display: inline-block;
    width: 7.6rem;

    & canvas {
      margin-top: 3rem;
    }
  }

  & .left-educations {
    display: inline-block;
    width: calc(50% - 11.3rem);
  }

  & .middle-arrows-container {
    display: inline-block;
    width: 15rem;

    & canvas {
      margin-top: 3rem;
    }
  }

  & .right-educations {
    display: inline-block;
    width: calc(50% - 11.3rem);
  }
}

