app-course-unit-selection-info {
  & .course-unit-selection-info {
    & div {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }
  }
}
