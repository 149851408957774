referred-object-multi-select-editor {
    > .row{
        margin-bottom: 0.7rem;
    }

    .input-col{
        padding-right: 0.7rem;
    }

    .button-col {
        padding-left: 0;
    }
}
