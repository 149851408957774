module-selection {
  .any-module-rule,
  .any-course-unit-rule {
    .content-text {
      margin-top: -1rem;

      .courseunit {
        .title-box span {
          margin-top: 1.5rem;
          font-weight: normal !important;
        }

        .credits-box {
          padding-top: 1rem;
          text-align: center;
        }
      }

    }
  }

  .any-course-unit-rule {
    .courseunit {
      width: 28rem;
    }
  }

  & .any-module-rule {
    & .courseunit {
      width: calc(100% - 0.2rem);
    }
  }
}
