@use 'sis-assets/foundations/colors/colors' as colors;

menu-button {
  display: inline-block;
  vertical-align: top;

  & .dropdown-header {
    & > em {
      color: colors.$dark-gray;
    }
  }
}
