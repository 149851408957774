custom-module-attainment-box {
  .studymodule {
    margin-top: 0.5em;

    &.attainment-data-not-found {
      & .credits-box {
        display: inline-flex !important;
        align-items: center;

        & sis-icon {
          margin: auto;
        }
      }

      & .grade-box sis-icon[icon="achievement"] {
        margin-top: 1rem;
      }
    }
  }
}
