@use 'sis-assets/foundations/colors/colors' as colors;

app-any-course-unit-rule-ripa-checkbox {
  .checkbox-wrapper {
    display: flex;
    align-items: center;
    margin-top: 1.5rem;
  }

  sis-checkbox, sis-icon {
    display: inline;
    margin-bottom: 0;
    color: colors.$white;
  }
}
