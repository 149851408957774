@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/focus/mixins' as focus;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

$ball-diameter: 1.3rem; // WITHOUT BORDER
$toggle-height: 1.7rem; // WITHOUT BORDER

// TODO: Convert all toggles to use sis-toggle component and scope the styles inside component

div.checkbox-toggle.sis-toggle {
  position: relative;
  margin-top: 0.5rem;
  line-height: $toggle-height;

  &:not(.form-group) {
    margin-bottom: spacing.$spacing-md;
  }

  & label {
    margin-bottom: 0;
    text-transform: none;
    line-height: 1.8rem;
    font-size: spacing.$spacing-sm;
  }

  & input {
    position: absolute;
    opacity: 0;
    cursor: pointer;

    & + span {
      display: block;
      position: relative;
      cursor: pointer;
      padding-left: 4.5rem;
      min-height: 1.9rem;
      line-height: 1.9rem;

      &::after {
        display: block;
        position: absolute;
        top: spacing.$pixel-2;
        left: 0;
        transition: margin-left 0.1s ease-in-out;
        margin-left: spacing.$pixel-2;
        border: spacing.$pixel-1 solid colors.$primary-color;
        border-radius: 3rem;
        background-color: colors.$white;
        width: $ball-diameter * 1.15;
        height: $ball-diameter * 1.15;
        content: '';
      }

      &::before {
        display: inline-block;
        position: absolute;
        left: 0;
        transition: background .2s ease-out;
        border: spacing.$pixel-1 solid colors.$primary-color;
        border-radius: 3rem;
        background-color: colors.$white;
        padding-bottom: $toggle-height;
        width: 3rem;
        height: 1.3rem;
        content: '';
      }
    }

    &:disabled {
      opacity: 0;
      cursor: default;

      & + span {
        cursor: default;
        color: colors.$silver;
      }
    }

    // Add focus toggle + text surrounding it
    &:focus + span{
      @include focus.fudis-focus-generic;
    }

    &:checked {
      // Selected & disabled
      &:disabled {
        & + span {
          &::before {
            border-color: colors.$light-gray;
            background-color: colors.$light-gray;
          }
        }
      }

      // Selected & not disabled
      &:not(:disabled) {
        & + span {
          &::before {
            background-color: colors.$primary-color;
          }
        }
      }

      & + span {
        &::after {
          margin-left: $ball-diameter;
          border-color: colors.$white;
        }
      }
    }

    &:not(:checked) {
      // Not selected & disabled
      &:disabled {
        & + span {
          &::before {
            border-color: colors.$light-gray;
            box-shadow: 0 0 0 black;
          }

          &::after {
            border-color: colors.$light-gray;
            box-shadow: 0.15rem 0.05rem spacing.$pixel-1 colors.$extra-light-gray;
            background-color: colors.$extra-light-gray;
          }
        }
      }

      // Not selected & not disabled
      &:not(:disabled) {
        & + span {
          &::after {
            box-shadow: 0.15rem 0.05rem spacing.$pixel-1 colors.$light-gray;
          }
        }
      }
    }
  }
}
