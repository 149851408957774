@use 'sis-assets/foundations/spacing/tokens' as spacing;

@mixin attainment-tree-study-selections-loop($counter) {
  @if $counter < 10 {
    & sis-attainment-tree-attainment, sis-editable-attainment-tree-grouping-module {
      @include attainment-tree-study-selections-loop($counter + 1);

      & .name {
        padding-left: 3.5rem + 4rem * $counter;
      }
    }
  }
}

sis-editable-attainment-tree-root {
  display: block;

  & .attainment-tree-header .name {
    display: inline-flex;

    & sis-language-pills ul {
      bottom: spacing.$pixel-2;
    }
  }

  & button {
    outline-offset: -0.1rem;
    overflow: hidden;
  }

  & sis-editable-attainment-tree-study-selections {
    @include attainment-tree-study-selections-loop(0);

    display: block;
  }
}
