@use 'sis-assets/foundations/breakpoints/breakpoints' as breakpoints;
@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

sis-student-workflow-list {
  & table.table.styled-table {
    display: block;
    overflow-x: auto;

    & > thead {
      & > tr {
        & > th {
          vertical-align: middle;
          white-space: nowrap;

          @media (max-width: breakpoints.$screen-xs-max) {
            white-space: normal;
          }
        }
      }
    }

    & tbody {
      & > tr {
        & > td {
          vertical-align: middle;

          & .student-workflow-type {
            display: block;
            line-height: 1.8rem;
            font-size: 1.2rem;

            @media (max-width: breakpoints.$screen-xs-max) {
              hyphens: auto;

              & + span {
                hyphens: auto;
              }
            }
          }

          & .workflow-status {
            align-items: center;
            white-space: nowrap;

            & sis-icon svg {
              margin-left: -0.8rem;
              fill: colors.$middle-gray;
            }

            &__staff {
              & sis-icon[ng-reflect-icon='hourglass'] svg {
                fill: colors.$yellow;
              }
            }

            &__student {
              & sis-icon[ng-reflect-icon="check"] svg {
                fill: colors.$green;
              }

              & sis-icon[ng-reflect-icon="fail"] svg:not(.cancelled) {
                fill: colors.$red;
              }
            }
          }

          & .student-initiator {
            color: colors.$primary-color;
          }

          & .student-workflow-name:hover {
            text-decoration-color: colors.$dark-gray;
          }

          & .student-workflow-handler {
            white-space: nowrap;
          }
        }
      }
    }
  }
}
