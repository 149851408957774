@use 'sis-assets/foundations/breakpoints/breakpoints' as breakpoints;

app-study-sub-group-info {
  & .study-sub-group-container__body__events:not(:last-child) {
    margin-bottom: 0.5rem;
  }

  & .study-sub-group-container__body__events__header {
    font-weight: 600;
  }

  & .study-sub-group-container__body__table__row {
    @media (max-width: breakpoints.$screen-sm-max) {
     display: block;
    }
  }

  & .study-sub-group-container__body__table__row__list-item:not(:last-of-type)::after {
    content: ", ";
  }

  & tbody {
    & tr {
      vertical-align: middle;
    }

    & tr:last-child > * {
      border-style: none;
    }
  }
}
