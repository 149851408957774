@use 'sis-assets/foundations/breakpoints/breakpoints' as breakpoints;

responsibility-infos-editor {
  & .responsibility-row {
    & .not-localized {
      padding-right: 0;
    }

    & .localized {
      white-space: nowrap;

      & .tab-content {
        margin-top: -0.05rem;
      }
    }
  }

  & .validity-row {
    & .form-group-mimic {
      & > span {
        display: inline-block;

        & + button {
          margin-left: 1rem;
        }
      }
    }
  }

  @media (max-width: breakpoints.$screen-sm-max) {
    & .responsibility-row {
      & .not-localized {
        padding-right: 1.5rem;
      }

      & .not-localized:first-of-type {
        margin-bottom: 2.3rem;
      }

      & .localized {
        margin-top: 0;
      }
    }
  }
}
