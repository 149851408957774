@use 'sis-assets/foundations/spacing/tokens' as spacing;

app-outdated-course-units-modal {
  table {
    margin-top: spacing.$spacing-sm;
  }

  & .table-scroll {
    overflow-x: auto;
  }
}
