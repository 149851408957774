@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

sis-validation-errors {
  &:not(:empty) + .help-block {
    display: none;
  }

  & .error-list {
    padding: 0 !important;
    height: 0;
    animation-name: error-appear-delay;
    animation-delay: 0.1s;
    animation-iteration-count: 1;
    animation-direction: normal;
    animation-fill-mode: forwards;

    &__item {
      margin-top: 0;
      margin-bottom: 0;
      line-height: spacing.$spacing-sm;
      color: colors.$red;
      font-size: 1.2rem;

      &:first-child {
        margin-top: 0.6rem;
      }
    }

  }
}

@keyframes error-appear-delay {
  from {
    height: 0;
  }

  to {
    height: auto;
  }
}
