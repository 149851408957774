@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

sis-legacy-localized-string-editor, sis-localized-string-editor {
  & .nav.nav-pills-localize {
    bottom: spacing.$pixel-3;
  }

  .string-input {
    position: absolute;
    left: -500rem;

    &.active {
      position: relative;
      left: auto;
    }

    &[readonly] {
      color: colors.$middle-gray;
      pointer-events: none;

      &.active {
        border-color: colors.$light-gray;
      }
    }
  }

  .actions {
    float: right;

    a:last-child, button:last-child {
      margin-right: 0;
    }
  }

  ul.error-list {
    margin-top: 0.6rem;
    margin-bottom: 0;
    padding: 0;
    list-style-type: none;
    line-height: spacing.$spacing-sm;
    color: colors.$red;
    font-size: 1.2rem;
  }
}
