sis-open-university-product-basic-info {
  & .basic-info {
    margin-top: 1.5rem;
    margin-bottom: 3rem;

    & sis-tooltip ngb-tooltip-window {
      & .tooltip-inner {
        width: 30rem;
      }
    }
  }

  & .loading-errors {
    margin-top: 2rem;
  }
}
