app-study-group-container {
  & app-study-sub-group-container:not(:last-of-type) > div {
    margin-bottom: 1rem;
  }

  h5 {
    margin-bottom: 1rem;
    font-size: 1.6rem;
  }

  & .study-sub-group-container__notification {
    margin-bottom: 1rem;
  }

  sis-contextual-notification {
    text-transform: none;
  }

  fieldset {
    li {
      list-style: none;

      &:not(:last-of-type) {
        margin-bottom: 1rem;
      }
    }

    ul {
      margin: 0;
      padding: 0;
    }
  }
}
