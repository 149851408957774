@use 'sis-assets/foundations/breakpoints/breakpoints' as breakpoints;
@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

sis-plan-module, sis-plan-education, sis-plan-custom-module-attainment, sis-attainment-group-node {
  & .tree-list {
    clear: both;
    margin: 0;
    padding: 0;
    list-style: none;

    & li.tree-list-item-level-1 {
      margin-bottom: 1.5rem;

      @media(max-width: breakpoints.$screen-xs-max) {
        margin-left: -1.5rem;
      }
    }

    & .tree-list-item, .tree-list-item-level-1 {
      & .module-border {
        position: relative;
        margin-top: 2rem;
        padding-left: 0;

        &:not(.module-border-selected, .collapsed)::before {
          position: absolute;
          top: 3.6rem;
          left: 2.5rem;
          border-left: spacing.$pixel-1 dotted colors.$dark-gray;
          height: calc(100% - 2rem);
          content: ' ';
        }

        &:not(.module-border-selected, .collapsed)::after {
          position: absolute;
          bottom: -2rem;
          left: 2.3rem;
          border-radius: 0.5rem;
          background-color: colors.$silver;
          width: 0.5rem;
          max-width: 0.5rem;
          height: 0.5rem;
          max-height: 0.5rem;
          content: '';
        }

        &.module-border-selected {
          margin-bottom: -2rem;
          border: spacing.$pixel-1 solid colors.$primary-color;
          border-radius: 0.6rem 0.6rem 0 0;
          padding-bottom: 2rem;
        }

        &.collapsed {
          margin-bottom: -2rem;
        }

        & .accordion-panel {
          & sis-icon[icon="junction"] {
            position: relative;
            top: spacing.$spacing-xs;
          }

          & .title {
            margin-right: spacing.$spacing-xs;

            &.grouping-module {
              vertical-align: middle;
            }

            > .additional {
              & .additional-alert {
                color: colors.$red;
              }
            }

            @media (max-width: breakpoints.$screen-sm-max) {
              & > span {
                font-size: 1.2rem;
              }
            }
          }

          & sis-icon[icon="rosette"] svg {
            fill: colors.$auringonkukka;
          }

          & .cell {
            display: inline-flex;
            align-items: center;
            font-weight: 400;

            & .plan-course-unit-instructions {
              display: none;
            }
          }

          & .average {
            margin-top: spacing.$spacing-xs;
            min-width: 9rem;

            @media (max-width: breakpoints.$screen-md-max) {
              min-width: 4.5rem;
            }
          }

          & .status {
            line-height: 2rem;
            font-size: 1.2rem;

            & .state-invalid {
              display: flex;
              align-items: center;
              background-color: colors.$white;
              width: 9rem;

              @media (min-width: breakpoints.$screen-lg-min) {
                margin-left: -3.2rem;
              }
            }

            @media (min-width: breakpoints.$screen-lg-min) {
              min-width: 10rem;
            }
          }

          & .module-credits {
            flex-direction: column;
            min-width: 11rem;

            & .credit-range {
              display: block;
              text-align: center;
              line-height: 2rem;
              white-space: nowrap;
            }

            & .planned-attained-credits {
              min-width: 10rem;
              text-align: center;
              line-height: 1.2rem;

              & .planned-credits, .attained-credits {
                display: inline-block;
                font-size: 1rem;
              }

              & .planned-credits {
                padding-right: spacing.$pixel-3;
                min-width: 4.6rem;
                text-align: right;
              }

              & .attained-credits {
                border-width: 0 0 0 spacing.$pixel-1;
                border-style: solid;
                border-color: colors.$middle-gray;
                padding-left: spacing.$pixel-3;
                min-width: 4.7rem;
                text-align: left;
                color: colors.$middle-gray;
              }
            }
          }

          &.accordion-panel-noblock {
            border-radius: spacing.$spacing-xxs spacing.$spacing-xxs 0 0;

            &.clickable:hover {
              background-color: colors.$primary-color-light;

              & .title {
                & .plan-module-name, .additional {
                  background-color: colors.$primary-color-light;
                }
              }

              & .status,
              & .state-invalid {
                background-color: colors.$primary-color-light;
              }
            }

            & > .header {
              & > .collapse {
                position: relative;
                z-index: 10;

                &:active {
                  background-color: colors.$primary-color-light;
                }
              }

              & .title {
                > .additional {
                  & .link-button {
                    color: colors.$primary-color-dark;
                    font-weight: 400;
                  }
                }
              }
            }
          }

          &.accordion-panel-primary {
            margin: 0;

            & .header {
              & .title {
                background: none;

                & .plan-module-name, .additional {
                  background-color: colors.$primary-color;
                }
              }

              & .status,
              & .state-invalid {
                background-color: colors.$primary-color;
              }

              & .additional, button {
                color: colors.$extra-light-gray;

                & .additional-alert {
                  color: colors.$extra-light-gray;
                }
              }

              & sis-icon[icon="rosette"] svg {
                fill: colors.$white;
              }

              & sis-icon[icon="junction"] svg {
                fill: colors.$extra-light-gray;
              }

              & .average, .status, .module-credits {
                color: colors.$white;

                & .attained-credits {
                  border-color: colors.$primary-color-light;
                  color: colors.$primary-color-light;
                }

                & .state-gray {
                  display: flex;
                  align-items: center;
                  margin-left: -3.2rem;
                  color: colors.$white;
                }

                & .btn.btn-secondary.btn-hollow {
                  border-color: colors.$white;
                  color: colors.$white;
                }
              }

              & .title-line-liner {
                border-color: colors.$primary-color;
                background-color: colors.$primary-color;
              }
            }
          }
        }

        & .tree-list-heading {
          border-radius: spacing.$spacing-xxs spacing.$spacing-xxs 0 0;
          overflow: hidden;

          &.no-padding {
            margin-top: 0;
            padding-top: 0;
            padding-left: 0;
          }

          &.is-active {
            background-color: colors.$light-gray;
          }

          &.is-hovered {
            background-color: colors.$light-gray;
          }

          &.tree-list-heading-has-children {
            padding-top: 0;
            padding-right: 0;
            padding-bottom: 0;
          }

          & .module-header {
            span {
              float: left;
            }

            .flat-description {
              display: block;
              clear: both;
              color: colors.$middle-gray;
              font-size: 1.4rem;
            }
          }

          & .state-attained {
            color: colors.$green;

            & span:first-of-type {
              display: block;
            }
          }

          & .state-invalid {
            color: colors.$accent;
          }

          & .state-gray {
            color: colors.$silver;
          }
        }

        & .tree-list-body {
          padding: 0 4.4rem 0 5.4rem;

          & .col-sm-6 {
            &:nth-of-type(2n + 1) {
              clear: both;
            }

            &.left {
              padding-right: 0.1em;
            }

            &.right {
              padding-left: 0.1em;
            }

            & sis-plan-course-unit, sis-plan-custom-course-unit-attainment, sis-plan-custom-study-draft {
              display: block;
              margin-right: 1rem;

              > .courseunit {
                margin-top: 1em;
                margin-right: 1rem;
              }
            }

            & .courseunit-substitution-group {
              margin: 1em 1rem 0 0;

              sis-plan-course-unit {
                margin-right: 0;

                &:first-child {
                  & .courseunit {
                    margin-top: 0;
                  }
                }
              }
            }
          }

          @media (max-width: breakpoints.$screen-sm-max) {
            padding-right: 1rem;
            padding-left: spacing.$spacing-xl;
          }
        }
      }
    }
  }

  & .tree-list-item {
    display: inline-block;
    transition: all 0.2s linear;
    margin-bottom: 2rem;
    padding-left: 4.8rem;
    width: 100%;

    @media (max-width: breakpoints.$screen-sm-max) {
      transition: all 0.2s linear;
      padding-left: 2rem;
    }
  }
}
