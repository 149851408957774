@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/focus/mixins' as focus;
@use 'sis-assets/foundations/spacing/tokens' as spacing;
@use 'sis-assets/foundations/typography/tokens' as typography;
@use '../mixins' as top-navigation;

sis-login {
  & .login {
    &--mobile {
      @include top-navigation.login-mobile;

      &__item {
        padding: spacing.$spacing-sm spacing.$spacing-md !important;
        text-transform: uppercase;
        text-decoration: none;
        line-height: typography.$body-text-sm-line-height;
        color: colors.$primary-color;
        font-size: typography.$body-text-sm-font-size !important;
        font-weight: typography.$font-weight-medium;

        &:active,
        &:hover,
        &:focus {
          color: colors.$primary-color !important;
        }
      }
    }

    &--desktop {
      &__item {
        display: inline-block;
        padding: spacing.$spacing-zero spacing.$spacing-sm;
        text-decoration: none;
        line-height: 5.6rem;
        color: colors.$white;
        font-weight: typography.$font-weight-regular;

        &:hover {
          background-color: colors.$middle-gray;
          color: colors.$white;
        }

        &:focus {
          @include focus.fudis-focus-generic;
        }
      }
    }
  }

  & .login-selection {
    @include top-navigation.login-mobile;

    &__item {
      color: colors.$primary-color-dark;
      font-size: typography.$body-text-md-font-size;

      &:active,
      &:hover,
      &:focus {
        color: colors.$primary-color-dark !important;
      }
    }
  }
}


