@use 'sis-assets/foundations/breakpoints/breakpoints' as breakpoints;
@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

app-open-university-studies-activate-wizard-step1 {
  & .auth-method-box-container {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    width: 100%;
    min-height: 20rem;
  }

  & .auth-method-box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: spacing.$spacing-xs;
    margin-bottom: spacing.$spacing-xs;
    border: spacing.$pixel-1 solid black;
    padding: spacing.$spacing-md;
    width: 48%;
    min-width: 35rem;
  }

  & .cu-row:not(:last-of-type) {
    border-bottom: spacing.$pixel-1 solid colors.$dark-gray;
  }

  & .disable-default-bold {
    margin-bottom: 0;
    font-weight: unset;
  }

  & .center-button {
    display: block;
    margin-top: spacing.$spacing-md;
    text-align: center;
  }

  & .uni-image-container {
    top: 0;
    margin-bottom: spacing.$spacing-md !important;
    margin-left: 0;
    background-position: center;
    width: 100% !important;
    height: 10rem !important;
  }

  & .suomifi-logo {
    display: inline-block;
    position: relative;
    background-image: url('../../../../../../../../sis-assets/webapp/sis-assets/logo/suomifi_logo.svg');
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: contain;
  }
}
