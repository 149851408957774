@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/focus/mixins' as focus;
@use 'sis-assets/foundations/spacing/tokens' as spacing;
@use 'sis-assets/foundations/typography/tokens' as typography;

sis-top-navigation-icon {
  & .top-navigation-icon {
    position: relative;
    border: 0;
    background-color: transparent;
    cursor: pointer;
    width: 100%;
    height: 5.6rem;

    &:focus {
      @include focus.fudis-focus-generic;
    }

    &__desktop {
      display: flex;
      align-content: center;
      align-items: center;
      padding: 0 spacing.$spacing-xs;

      &:hover {
        background-color: colors.$middle-gray;
      }

      & svg {
        border-radius: 50%;
        background: colors.$light-gray;
      }

      &--active {
        background-color: colors.$white;

        & svg {
          background-color: colors.$dark-gray;
        }
      }

      &__badge {
        position: absolute;
        top: spacing.$spacing-xxs;
        right: 0.2rem;
      }
    }

    &__mobile {
      display: block;
      padding: spacing.$spacing-sm spacing.$spacing-md spacing.$spacing-sm spacing.$spacing-md;
      text-align: start;
      text-decoration: none;
      color: colors.$dark-gray;
      font-size: 1.4rem;
      font-weight: typography.$font-weight-regular;

      &:hover {
        background-color: colors.$primary-color-light;
        color: colors.$dark-gray;
      }

      &__badge {
        display: inline-flex;
        margin-left: spacing.$spacing-sm;
      }

      &__check {
        bottom: spacing.$spacing-xxs;
      }
    }
  }
}
