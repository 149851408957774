// OLD COURSE UNIT BOX STUFF - DO NOT SIMPLY COPY PASTE STYLES FROM HERE TO NEW COMPONENTS!!

@use 'sass:color';
@use 'sis-assets/foundations/breakpoints/breakpoints' as breakpoints;
@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/focus/mixins' as focus;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

$small-course-unit-transition: all 0.05s linear;
$white-box-fade-color: rgb(255 255 255 / 50%);

@mixin cu-borders($top, $right, $bottom, $left) {
  border-width: $top $right $bottom $left;
  border-style: solid;
  border-color: colors.$silver;
}

@mixin cu-border-and-bg-color($color, $color-light) {
  & .credits-box {
    border-color: $color;
    background-color: $color;
  }

  & .grade-box {
    border-color: $color;
    background-color: $color-light;
  }

  & .title-box,
  & .additional-box,
  & .cu-notification-bar {
    border-color: $color;
  }
}

@mixin cu-border-color($color) {
  & .courseunit-top-row {
    & .credits-box,
    & .grade-box,
    & .title-box,
    & .additional-box {
      border-color: $color !important;
    }
  }

  & .cu-notification-bar {
    border-color: $color !important;
  }
}

// basic course unit styles
div.courseunit {
  display: inline-block;
  position: relative;
  transition: $small-course-unit-transition;
  width: 100%;
  min-height: 5.2rem;
  vertical-align: top;
  color: colors.$dark-gray;

  // top row main stuff
  & .courseunit-top-row {
    display: flex;
    background: colors.$extra-light-gray;
    height: 5.2rem;
    text-overflow: clip;
    line-height: spacing.$spacing-md;

    & > div {
      margin: 0;
      border: 0;
    }

    // credits box defaults
    & .credits-box {
      @include cu-borders(spacing.$pixel-1, 0, spacing.$pixel-1, spacing.$pixel-1);

      position: relative;
      top: 0;
      transition: $small-course-unit-transition;
      background: colors.$silver;
      width: 3.8rem;
      height: 5.2rem;
      overflow: hidden;
      vertical-align: top;
      text-overflow: clip;
      line-height: spacing.$spacing-md;
      color: colors.$dark-gray;

      & span {
        display: block;
        cursor: default;
        text-align: center;
      }

      // top text - amount of credits
      & .credit-amount {
        margin-top: spacing.$spacing-xs;
        margin-bottom: -0.4rem;
        overflow: hidden;
        white-space: nowrap;
        font-size: 1.4rem;
        font-weight: 500;
      }

      & .partial-credit-amount {
        color: colors.$middle-gray;
      }

      // bottom text - what is the abbreviation of opintopiste in languages
      & .credit-abbreviation {
        opacity: 1;
        line-height: 1.8rem;
        font-size: 1.4rem;
        font-weight: 300;
      }
    }

    // grade box stuff
    & .grade-box {
      @include cu-borders(spacing.$pixel-1, 0, spacing.$pixel-1, 0);

      position: relative;
      top: 0;
      transition: $small-course-unit-transition;
      background: colors.$light-gray;
      width: 3.8rem;
      height: 5.2rem;
      overflow: hidden;
      vertical-align: top;
      text-overflow: clip;
      color: colors.$dark-gray;

      & > * {
        display: block;
        cursor: default;
        width: 3.8rem;
        text-align: center;
      }

      & sis-icon[icon="achievement"] {
        margin-top: spacing.$spacing-xxs;
      }

      // text of the grade
      & .grade-text {
        margin-left: -0.1rem;
        line-height: spacing.$spacing-xxs;
        font-size: 1.4rem;
        font-weight: 300;
      }

      // title box width if there is a grade box
      & + .title-box {
        width: calc(100% - 7.6rem);
      }
    }

    & .substitution-indicator-box {
      @include cu-borders(spacing.$pixel-1, 0, spacing.$pixel-1, 0);

      display: flex;
      align-items: center;
      justify-content: center;
      padding-left: spacing.$spacing-xxs;
      width: 3.8rem;
      height: 100%;

      & ~ span {
        margin-left: 4.4rem;
        width: calc(100% - 4.4rem);
      }
    }

    & .grade-box + .substitution-indicator-box + .title-box {
      width: calc(100% - 11.4rem);
    }

    & .credits-box + .substitution-indicator-box + .title-box {
      width: calc(100% - 7.6rem);
    }



    // title box defaults - width assumes there is no grade box (fixed in grade box)
    & .title-box {
      @include cu-borders(spacing.$pixel-1, spacing.$pixel-1, spacing.$pixel-1, 0);

      top: 0;
      background: colors.$extra-light-gray;
      width: calc(100% - 3.8rem);
      height: 5.2rem;
      vertical-align: top;
      text-overflow: clip;

      // gradient fadeout if text is too long and there is no additional box
      &::after {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        background-color: transparent;
        background-image: linear-gradient(to right, rgb(241 241 241 / 0%) 0%, colors.$extra-light-gray 75%, colors.$extra-light-gray 100%);
        width: 2rem;
        content: "";
      }

      // actual texts of title box
      & .courseunit-code,
      & .courseunit-title {
        display: block;
        padding-left: 0.6rem;
        width: calc(100% - 0.6rem);
        overflow: hidden;
        text-overflow: clip;
        white-space: nowrap;
      }

      & .courseunit-code {
        padding-top: spacing.$pixel-3;
        line-height: 2.6rem;
        color: colors.$dark-gray;
        font-size: 1.2rem;
        font-weight: 400;

        & .link-button {
          box-sizing: border-box;
          display: inline-block;
          line-height: 2.2rem;
          white-space: nowrap;
          color: colors.$primary-color-dark;
          font-weight: 400;

          &:focus {
            @include focus.deprecated-sisu-focus-generic-inner;
          }
        }

        & sis-icon[icon="brochure"] svg {
          margin: -0.4rem -0.6rem -0.4rem -0.8rem;
        }

        & span.no-icon {
          display: inline-block;
          line-height: spacing.$spacing-md;
          color: colors.$middle-gray;
        }
      }

      & .courseunit-title {
        cursor: default;
        overflow: hidden;
        line-height: spacing.$spacing-sm;
        font-size: 1.4rem;
        font-weight: 500;
      }
    }

    // additional box basic stuff
    & .additional-box {
      @include cu-borders(spacing.$pixel-1, spacing.$pixel-1, spacing.$pixel-1, 0);

      position: absolute;
      top: 0;
      right: 0;
      justify-content: center;
      background: colors.$extra-light-gray;
      cursor: pointer;
      min-width: 5.2rem;
      height: 5.2rem;

      // gradient fadeout for title-box if there is an additional box
      &::before {
        display: block;
        position: absolute;
        top: 0;
        left: -0.5rem;
        background-color: transparent;
        background-image: linear-gradient(to right, rgb(241 241 241 / 0%) 0%, colors.$extra-light-gray 48%, colors.$extra-light-gray 100%);
        width: 1rem;
        height: 100%;
        content: "";
        pointer-events: none;
      }

      &.button-box {
        cursor: pointer;
      }

      &.disabled {
        cursor: initial;

        & * {
          cursor: initial;
        }
      }

      & button.button-box.non-button,
      & sis-button.button-box .link-button {
        display: flex;
        align-items: center;
        justify-content: center;
        outline-offset: -0.1rem;
        width: 100%;
        height: 100%;

        &:focus {
          @include focus.fudis-focus-generic;

          // Exception of exception, due the position absolute of this element and parent's before pseudoclass this makes focus more visible
          outline-offset: -0.4rem;
          box-shadow: inset 0 0 0 0.6rem colors.$white;

        }
      }

      // make the buttons a bit tighter because space might be at a premium
      & .btn {
        position: relative;
        top: 1.3rem;
        margin-right: 1rem;
        margin-left: 1rem;
      }

      // toggle switch placement, and fix positioning between background and actual toggle circle
      &.toggle-box {
        width: 5.2rem;

        & .checkbox-toggle.sis-toggle {
          position: absolute;
          padding: 1rem;
        }
      }
    }
  }

  // notification bar defaults
  & .cu-notification-bar {
    @include cu-borders(0, spacing.$pixel-1, spacing.$pixel-1, spacing.$pixel-1);

    display: block;
    position: relative;
    margin-top: -0.1rem; // hide the course-unit-box's bottom-border
    border-bottom-left-radius: 0.7rem;
    text-overflow: clip;
    font-size: 1.2rem;

    // single notification
    & .cu-notification {
      display: flex;
      position: relative;
      height: 2.2rem;
      vertical-align: bottom;

      // hiding the previous notification bar's rounded corner
      &::before {
        display: block;
        position: absolute;
        top: -0.5rem;
        left: 0;
        background-color: colors.$silver;
        width: 0.5rem;
        height: 0.5rem;
        content: "";
      }

      // top border for the first notification bar
      &:first-child {
        // no need to hide the previous notification bar's corner since there's none
        &::before {
          display: none;
        }

        & .cu-notification-header {
          border-top: spacing.$pixel-2 solid color.adjust(colors.$silver, $lightness: -5%);
        }

        & .cu-notification-content {
          border-top: spacing.$pixel-2 solid color.adjust(colors.$light-gray, $lightness: -5%);
        }
      }

      // left block of notification bar - contains icons or a number
      & .cu-notification-header {
        display: flex;
        position: relative;
        top: 0;
        align-items: center;

        // cool rounded corner for every notification bar
        border-bottom-left-radius: spacing.$spacing-xxs;
        background-color: colors.$silver;
        padding-left: spacing.$pixel-3;
        width: 3.7rem;
        height: 2.2rem;
        text-align: center;
      }

      // the text of the notification
      & .cu-notification-content {
        display: block;
        position: relative;
        border-top: spacing.$pixel-1 solid colors.$silver;
        background-color: colors.$light-gray;
        cursor: default;
        padding-left: 1.2rem;
        width: calc(100% - 3.7rem);
        height: 2.2rem;
        overflow: hidden;
        vertical-align: top;
        line-height: 2rem;
        white-space: nowrap;

        // gradient fadeout if text is too long for box
        &::after {
          display: block;
          position: absolute;
          top: 0;
          right: 0;
          background: linear-gradient(to right, rgb(212 212 212 / 0%) 0%, colors.$light-gray 75%, colors.$light-gray 100%);
          width: 2rem;
          height: 100%;
          content: "";
        }
      }
    }

    & sis-tooltip { // these are inside sis-tooltip so we won't break the old styles
      &:last-of-type {
        & .cu-notification {
          & .cu-notification-header {
            border-bottom-left-radius: 0.5rem;
          }
        }
      }

      & .cu-notification {
        & .cu-notification-header {
          border-bottom-left-radius: 0;
        }
      }

      & + .additional-notifications {
        & sis-tooltip:first-of-type {
          position: relative;

          &::before { // hide the last sis-tooltip's rounded corner if there's additional notifications (ng-transclude)
            display: block;
            position: absolute;
            top: -0.5rem;
            left: 0;
            background-color: colors.$silver;
            width: 0.5rem;
            height: 0.5rem;
            content: "";
          }
        }
      }
    }
  }

  // selected courseunit - blue outline, prevent cool rounded border
  &.selected {
    outline: colors.$primary-color solid spacing.$pixel-2;

    & .cu-notification-bar {
      border-bottom-left-radius: 0;

      &::after {
        border-bottom-left-radius: 0 !important;
      }

      & .cu-notification {
        & .cu-notification-header {
          border-bottom-left-radius: 0 !important;
        }
      }
    }
  }

  // dimmed courseunit - consists of several dimmers
  &.dimmed {
    @include cu-border-color(colors.$dark-gray);

    // top row dimmers
    & .courseunit-top-row {
      // credits box dimmer - covers the credits box and nothing else
      & .credits-box {
        &::after {
          display: inline-block;
          position: absolute;
          top: 0;
          left: 0;
          width: 3.8rem;
          height: 100%;
          content: "";
        }
      }

      // grade box dimmer - covers the grade box and nothing else
      & .grade-box {
        &::after {
          display: inline-block;
          position: absolute;
          top: 0;
          left: 0;
          width: 3.8rem;
          height: 100%;
          content: "";
        }
      }

      // disable clicking for everything else apart from what is in the additional box
      & > :not(.additional-box) {
        pointer-events: none;
      }

      // title box dimmer - starts from the left border of the title box and covers more
      // first basic rules & rules for if there is an additional box
      & > .title-box {
        &::before {
          display: inline-block;
          position: absolute;
          top: 0;
          left: 0;
          background: rgb(0 0 0 / 50%);
          width: calc(100% - 0.6rem);
          height: 100%;
          content: "";
        }

        // disable fadeout while dimmer is active
        &::after {
          display: none;
        }

        // if the title box is last (no additional box), cover until the end of the course unit on the right
        &:last-of-type {
          &::before {
            width: 100%;
          }
        }
      }

      & > .grade-box + .title-box {
        &::before {
          left: 0;
        }
      }

      // additional box stuff when dimmed - disable hover, make a dimmer *before* the actual box, so the contents are still available
      & .additional-box {
        position: absolute;
        transition: $small-course-unit-transition, opacity 0.02s;
        opacity: 1;
        cursor: initial;
        max-width: 15rem;

        &::before {
          display: inline-block;
          position: absolute;
          top: 0;
          left: 0;
          background: rgb(121 121 121);
          width: 100%;
          height: 100%;
          content: "";
        }

        &:hover {
          background: colors.$light-gray;
        }

        & * {
          z-index: 1;
        }
      }
    }

    // notification bar dimmer - just covers the whole thingamabob
    & .cu-notification-bar {
      &::after {
        display: inline-block;
        position: absolute;
        top: 0;
        left: 0;
        border-bottom-left-radius: 0.5rem;
        background-color: rgb(0 0 0 / 50%);
        width: 100%;
        height: 100%;
        content: "";
      }
    }

    &.white {
      & .courseunit-top-row {
        & .credits-box,
        & .grade-box {
          &::after {
            background-color: $white-box-fade-color;
          }
        }

        & > .title-box,
        & > .additional-box,
        .additional-box > button {
          &::before {
            background-color: $white-box-fade-color;
          }
        }
      }

      & .cu-notification-bar {
        &::after {
          background-color: $white-box-fade-color;
        }
      }
    }
  }

  // our 11 fabulous colors and their lightened versions for the grade boxes
  &.cu-color-0, & .cu-substitution-popover .cu-color-0 {
    @include cu-border-and-bg-color(colors.$silver, colors.$light-gray);
  }

  &.cu-color-1, & .cu-substitution-popover .cu-color-1 {
    @include cu-border-and-bg-color(colors.$appelsiini, colors.$appelsiini-light);
  }

  &.cu-color-2, & .cu-substitution-popover .cu-color-2 {
    @include cu-border-and-bg-color(colors.$kaakao, colors.$kaakao-light);
  }

  &.cu-color-3, & .cu-substitution-popover .cu-color-3 {
    @include cu-border-and-bg-color(colors.$minttu, colors.$minttu-light);
  }

  &.cu-color-4, & .cu-substitution-popover .cu-color-4 {
    @include cu-border-and-bg-color(colors.$flamingo, colors.$flamingo-light);
  }

  &.cu-color-5, & .cu-substitution-popover .cu-color-5 {
    @include cu-border-and-bg-color(colors.$auringonkukka, colors.$auringonkukka-light);
  }

  &.cu-color-6, & .cu-substitution-popover .cu-color-6 {
    @include cu-border-and-bg-color(colors.$topaasi, colors.$topaasi-light);
  }

  &.cu-color-7, & .cu-substitution-popover .cu-color-7 {
    @include cu-border-and-bg-color(colors.$karviainen, colors.$karviainen-light);
  }

  &.cu-color-8, & .cu-substitution-popover .cu-color-8 {
    @include cu-border-and-bg-color(colors.$kirva, colors.$kirva-light);
  }

  &.cu-color-9, & .cu-substitution-popover .cu-color-9 {
    @include cu-border-and-bg-color(colors.$ametisti, colors.$ametisti-light);
  }

  &.cu-color-10, & .cu-substitution-popover .cu-color-10 {
    @include cu-border-and-bg-color(colors.$hiekka, colors.$hiekka-light);
  }

  &.cu-color-11, & .cu-substitution-popover .cu-color-11 {
    @include cu-border-and-bg-color(colors.$kesataivas, colors.$kesataivas-light);
  }

  &.cu-color-study-draft {
    & .credits-box {
      background-color: colors.$silver;
      color: colors.$dark-gray;
    }
  }
}

.courseunit-substitution-group {
  padding: spacing.$pixel-3;

  & > div.courseunit {
    margin-top: 1em;

    &:first-child {
      margin-top: 0;
    }
  }

  course-unit-box,
  common-course-unit {
    margin-right: 0 !important;

    &:first-child .courseunit {
      margin-top: 0 !important;
    }
  }
}

.substitution-cu-info-popover {
  width: 30rem;
  max-width: 30rem;
  min-height: 10rem;

  course-unit-box,
  common-course-unit {
    display: block;
  }

  & .cu-substitution-popover {
    position: relative;
    padding: spacing.$spacing-xs spacing.$pixel-2 6.5rem spacing.$pixel-2;

    & course-unit-box,
    & common-course-unit,
    & .courseunit {
      width: 25.5rem !important;

      & + p {
        margin-top: 2em;
      }

      & + course-unit-box,
      & + common-course-unit,
      & + .courseunit {
        margin-top: 1em;
      }
    }

    & .guidance {
      position: absolute;
      bottom: 0.6rem;
      left: 0;
      font-size: 1.2rem;
    }

    & > button {
      position: absolute;
      right: 0;
      bottom: 0;
      margin-right: 0;
    }
  }
}
