@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

sis-cooperation-network-details-editor {
  & .cooperation-network-details-editor-divider {
    border-bottom: solid colors.$middle-gray spacing.$pixel-1;
  }

  & label.cooperation-network-details-editor-title {
    text-transform: none !important;
    font-size: 1.6rem;
  }
}
