@use '../../foundations/spacing/tokens' as spacing;
@use '../../foundations/colors/colors' as colors;
@use 'icon-variables' as icons;

/* These are old font icon styles, prefer using Fudis Icon or sis-icon component instead of pseudo icon styles */

@font-face {
    font-family: SISUicons;
    src: url("icon-font-files/SisuIconFonts.eot");
    src: url("icon-font-files/SisuIconFonts.eot#iefix") format('embedded-opentype'),
    url("icon-font-files/SisuIconFonts.woff2") format('woff2'),
    url("icon-font-files/SisuIconFonts.woff") format('woff'),
    url("icon-font-files/SisuIconFonts.ttf") format('truetype'),
    url("icon-font-files/SisuIconFonts.svg#SISUicons") format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="SISUicon-"], [class*=" SISUicon-"] {
    transition: rotate .6s ease;
    vertical-align: -30%;
    text-transform: none;
    line-height: 1;

    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: SISUicons, sans-serif !important;
    font-size: spacing.$spacing-md!important;
    font-weight: normal;


  /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-variant: normal;
    font-style: normal;
    speak: none;

    &::before {
      display: inline-block;
      margin: 0 spacing.$spacing-xxs 0 0;
      text-decoration: none;
      font-size: spacing.$spacing-md !important;
    }
}

a {
    &[class^="SISUicon-"], &[class*=" SISUicon-"] {
        &:hover, &:active, &:visited, &:focus {
                text-decoration: none;
        }
    }
}

a {
    & [class^="SISUicon-"], & [class*=" SISUicon-"] {
        &::before {
            display: inline-block;
        }
    }
}

// Used for styling checkbox and medium editor plugin
@mixin SISUicon-Check {
    &::before {
        content: icons.$SISUicon-Check;
    }
}

// Used for styling medium editor plugin
@mixin SISUicon-Close {
  &::before {
    content: icons.$SISUicon-Close;
  }
}

// TODO: Remove when SISUicon-external is no longer used in literature-pipe or -filter
.SISUicon-External {
  &::before {
    content: icons.$SISUicon-External;
  }
}

