@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

minor-selection-modal {
  & .minor-selection {
    & .show-previous-selections {
      display: inline-block;
      margin-bottom: 2rem;
      overflow: hidden;
    }
  }

  & .previous-selections {
    & > .row {
      position: relative;
    }

    & .separator {
      border-left: spacing.$pixel-1 solid colors.$extra-light-gray;
      padding-left: spacing.$spacing-xl;
    }
  }

  & .new-selection {
    border-top: spacing.$pixel-1 solid colors.$extra-light-gray;

    & .acceptance-help-text {
      margin-top: 2rem;
      font-weight: 600;
    }
  }

  & .contextual-notification {
    margin-top: 2rem;
  }
}
