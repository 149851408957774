@use 'sis-assets/foundations/breakpoints/breakpoints' as breakpoints;
@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;
@use '../../timing-helpers' as helper;

planned-teaching-period {
  display: block;
  margin-top: spacing.$pixel-3;
  border-width: spacing.$pixel-1 0;
  border-style: dashed;
  border-color: colors.$primary-color-light;
  background-color: helper.$planned-box-color;
  height: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: normal;

  &:hover, &.hover {
    border-color: colors.$primary-color;
    background-color: colors.$primary-color-light;

    & .planned-slot-title {
      display: none;
    }

    & .hover {
      display: block;
      position: relative;
      top: 60%;
      transform: translateY(-50%);
      opacity: .6;
      margin: auto;
      height: 100%;

      & span {
        display: block;
        top: 0;
        transform: none;
        line-height: spacing.$spacing-sm;
        font-size: 1rem;
      }
    }
  }

  &.first {
    border-left-width: spacing.$pixel-1;
  }

  &.last {
    border-right-width: spacing.$pixel-1;
  }

  span {
    display: block;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }

  div {
    position: relative;
    height: 100%;
  }

  .small {
    font-size: 1.2rem;
  }

  & .hover {
    display: none;
  }

  &.plannedTeaching-gap {
    border-top: dashed;
    border-bottom: dashed;
    border-width: spacing.$pixel-1;
    border-color: colors.$primary-color-light;
    height: helper.$timing-box-height;
  }

  div span.indicator {
    position: absolute;
    top: spacing.$spacing-xs;
    right: 0.3rem;
    width: 1.3rem;
    height: 1.3rem;
  }
}
