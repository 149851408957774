app-study-event-info-list {
  display: block;

  & > app-study-event-info {
    margin-bottom: 1.5rem;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}
