@use 'sis-assets/foundations/breakpoints/breakpoints' as breakpoints;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

sis-attainment-structure-node {
  .sis-attainment-structure-node {
    @media (max-width: breakpoints.$screen-md-max) {
      &__open-info {
        padding-left: spacing.$spacing-sm;
      }
    }
  }
}
