// OLD course unit realisation styles, DO NOT SIMPLY COPY PASTE STYLES FROM HERE!!

// TODO: Remove these styles when there is no longer native html using the style classes

@use 'sis-assets/foundations/breakpoints/breakpoints' as breakpoints;
@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

@mixin cur-border-and-bg-color($color, $color-light) {
  filter: drop-shadow(spacing.$spacing-zero spacing.$pixel-1 spacing.$spacing-zero $color)
  drop-shadow(spacing.$spacing-zero -0.1rem 0 $color)
  drop-shadow(spacing.$pixel-1 spacing.$spacing-zero spacing.$spacing-zero $color)
  drop-shadow(-0.1rem spacing.$spacing-zero spacing.$spacing-zero $color);

  & .credits-box {
    background-color: $color;
  }

  & .courseunit-realisation-bottom-row {
    &::before {
      background-color: $color-light;
    }
  }
}

/* stylelint-disable */

div.courseunit-realisation {
  @include cur-border-and-bg-color(colors.$silver, colors.$light-gray);

  display: inline-block;
  position: relative;
  margin-left: spacing.$pixel-1; // balance out the left-border
  width: calc(100% - 0.2rem); // 2px is the box's left and right border
  min-height: 5.2rem;
  line-height: 1.8rem;
  color: colors.$dark-gray;
  font-size: 1.2rem;
  font-weight: 400;

  &.selected {
    outline: colors.$primary-color solid spacing.$pixel-2;
  }

  & .courseunit-realisation-top-row {
    background: colors.$extra-light-gray;
    height: 5.2rem;
    clip-path: border-box;
    line-height: spacing.$spacing-md;

    & > div {
      display: inline-block;
      margin: 0;
      border: 0;
    }

    & .credits-box {
      position: relative;
      top: 0;
      background: colors.$silver;
      width: 2rem;
      height: 5.2rem;
      vertical-align: -33%;
      color: colors.$dark-gray;

      & > span {
        display: block;
        cursor: default;
        text-align: center;
      }

      // bottom text - what is the abbreviation of opintopiste in languages
      & .credit-abbreviation {
        margin-top: spacing.$pixel-3;
        line-height: 1.8rem;
        font-size: 1.4rem;
        font-weight: 300;
      }

      & .credit-amount {
        position: relative;
        margin-top: 1.2rem;
        min-width: 2rem;
        max-width: 4rem;
        line-height: 1.1rem;
        white-space: normal;
        font-size: 1.4rem;
        font-weight: 500;

        &.credit-amount-range {
          top: calc(50% + 0.1rem);
          transform: translateY(-50%);
          margin: auto;

          & .credit-range {
            display: block;
          }

          & + .credit-abbreviation {
            opacity: 0.000;
          }
        }
      }
    }

    & .title-box {
      display: inline-block;
      position: absolute;
      top: 0;
      background: colors.$extra-light-gray;
      width: calc(100% - 4.8rem);
      height: 5.2rem;
      vertical-align: top;
      text-overflow: clip;
      white-space: nowrap;

      // gradient fadeout if text is too long and there is no additional box
      &::after {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        background-color: transparent;
        background-image: linear-gradient(to right, rgb(241 241 241 / 0%) 0%, colors.$extra-light-gray 75%, colors.$extra-light-gray 100%);
        width: 2rem;
        height: 100%;
        content: "";
      }

      // actual texts of title box
      & .courseunit-realisation-title,
      & .courseunit-realisation-specifier,
      & .courseunit-realisation-date {
        display: inline-block;
        margin-left: 0.6rem;
        overflow: hidden;
        text-overflow: clip;
        white-space: nowrap;
      }

      & .courseunit-realisation-title {
        display: block;
        cursor: default;
        padding-top: 1rem;
        width: 100%;
        line-height: spacing.$spacing-sm;
        font-size: 1.4rem;
        font-weight: 500;
      }

      & .courseunit-realisation-specifier {
        font-size: 1.3rem;
        font-weight: 400;
      }

      & .courseunit-realisation-date {
        font-size: 1.3rem;
      }

      & .flow-state.tiny-badge {
        margin: 0;
        vertical-align: text-bottom;
      }
    }

    & .additional-box {
      position: absolute;
      top: 0;
      right: 0;
      background: colors.$extra-light-gray;
      cursor: pointer;
      min-width: 5.2rem;
      height: 5.2rem;

      & > .button-box.non-button {
        display: flex;
        align-items: center;
        justify-content: center;
        outline-offset: -0.1rem;
        cursor: pointer;
        width: 100%;
        height: 100%;

        // gradient fadeout for title-box if there is an additional box
        &::before {
          display: block;
          position: absolute;
          top: 0;
          left: -2rem;
          background-color: transparent;
          background-image: linear-gradient(to right, rgb(241 241 241 / 0%) 0%, colors.$extra-light-gray 75%, colors.$extra-light-gray 100%);
          cursor: default;
          width: 2rem;
          height: 100%;
          content: "";
          pointer-events: none;
        }
      }

      &.button-box {
        & .btn.btn-sm.btn-hollow.btn-secondary {
          position: relative;
          top: 1.3rem;
          margin-right: 1rem;
          margin-left: 1rem;
        }
      }
    }
  }

  // notification bar defaults
  & .cu-notification-bar {
    display: block;
    position: relative;
    text-overflow: clip;
    font-size: 1.2rem;

    // single notification
    & .cu-notification {
      display: block;
      height: 2.2rem;
      vertical-align: bottom;

      // left block of notification bar - contains icons or a number
      & .cu-notification-header {
        display: inline-block;
        position: relative;
        top: 0;
        background-color: colors.$silver;
        padding-left: spacing.$pixel-1;
        width: 2rem;
        height: 2.2rem;
        text-align: center;

        & sis-icon[icon="alert-fill"],
        & sis-icon[icon="info"] {
          position: absolute;
          margin: -0.5rem 0 0 -1.5rem;
        }
      }

      // the text of the notification
      & .cu-notification-content {
        display: inline-block;
        position: relative;
        border-top: spacing.$pixel-1 solid colors.$middle-gray;
        background-color: colors.$light-gray;
        cursor: default;
        padding-left: 1.2rem;
        width: calc(100% - 2rem);
        height: 2.2rem;
        overflow: hidden;
        vertical-align: top;
        line-height: 2rem;
        white-space: nowrap;

        // gradient fadeout if text is too long for box
        &::after {
          display: block;
          position: absolute;
          top: 0;
          right: 0;
          background: linear-gradient(to right, rgb(212 212 212 / 0%) 0%, colors.$light-gray 75%, colors.$light-gray 100%);
          width: 2rem;
          height: 100%;
          content: "";
        }
      }

      // top border for the first notification bar
      &:first-child {
        & .cu-notification-header,
        & .cu-notification-content{
          border-top: spacing.$pixel-2 solid colors.$middle-gray;
        }

        & .cu-notification-content {
          border-top: spacing.$pixel-2 solid colors.$silver;
        }
      }

      // disable rounded corner
      &:last-child {
        & .cu-notification-header {
          border-bottom-left-radius: 0;
        }
      }
    }
  }

  & .courseunit-realisation-bottom-row {
    position: relative;
    border-top: spacing.$pixel-1 solid rgb(0 0 0 / 5%);
    background: colors.$extra-light-gray;
    padding: 0 0 0 2rem;
    line-height: 1.8rem;

    &::before {
      display: inline-block;
      position: absolute;
      top: -0.1rem;
      left: 0;
      border-top: spacing.$pixel-1 solid rgb(0 0 0 / 5%);
      background: colors.$light-gray;
      width: 2rem;
      height: calc(100% + 0.1rem);
      content: "";
    }

    & > div {
      border-bottom: spacing.$pixel-1 solid rgb(0 0 0 / 5%);
      padding: 0.5rem 1.5rem spacing.$spacing-zero 1.5rem;

      &:last-of-type {
        border-bottom: none;
      }
    }

    & checkbox {
      & .control {
        display: inline;
        margin-right: 0;
        margin-left: 0.5rem;
      }
    }

    & .button-holder {
      position: relative;
      min-height: 5.5rem;
      text-align: right;

      & > * {
        position: relative;
        top: 1.5rem;

        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
          position: relative;
        }
      }

      @supports (-ms-ime-align:auto) {
        & > * {
          position: relative;
        }
      }

      &.enrol-button {
        & > * {
          top: 0.5rem;
        }

        & sis-external-link {
          display: flex;
          flex-flow: row wrap;
          justify-content: flex-end;
        }

        & .external-link {
          overflow: hidden;
        }
      }

      & .contextual-notification {
        top: 0.5rem;
        text-align: left;
      }
    }

    & .irregular-event {
      margin-bottom: 1rem;
    }

    & .event-detail-row {
      & sis-icon[icon="pin-small"] {
        position: absolute;
      }

      & .location, .no-location {
        display: inline-block;
        padding-left: 1.8rem;
      }

      & .irregular-locations {
        margin-top: 0.5rem;
      }

      & .event-notice {
        margin-bottom: 0.5rem;
      }

      @media(max-width: breakpoints.$screen-md-max) {
        overflow-wrap: break-word;
      }

      @-moz-document url-prefix() { // Firefox
        @media(max-width: breakpoints.$screen-lg-max) {
          overflow-wrap: break-word;
        }
      }
    }

    & .teacher-detail {
      & .teacher, .no-teacher {
        display: inline-block;
      }
    }

    & .single-group-event-titles {
      display: flex;
      align-items: center;
    }

    & .event-title {
      font-size: 1.2rem;
      font-weight: 600;
      overflow-wrap: break-word;

      & .flow-state.tiny-badge {
        margin-left: 0;
        vertical-align: text-bottom;
      }
    }

    & .event-date {
      text-decoration-line: underline;
      color: colors.$primary-color-dark;
      font-weight: 400;
    }

    & .event-groups {
      border-top: spacing.$pixel-1 solid rgb(0 0 0 / 5%);
      padding-right: spacing.$spacing-xs;
      padding-left: 0;

      &:first-of-type {
        border-top-width: 0;
      }

      & .event-info {
        margin-left: 0;

        & > .event-title {
          display: block;
          margin: spacing.$spacing-xs 0 1rem 0;
          font-size: 1.4rem;
        }
      }

      & .single-group {
        position: relative;
        margin-left: 0;
        border-top: spacing.$pixel-2 dashed rgb(0 0 0 / 5%);
        background-color: colors.$white;
        padding-top: spacing.$spacing-xs;
        padding-bottom: spacing.$spacing-xs;

        &.enrolled-show-other-ssg {
          & button {
            overflow: hidden;
            text-decoration: underline;
            color: colors.$dark-gray;
            font-weight: 400;

            & sis-icon[icon="chevron"] svg {
              fill: colors.$dark-gray;
            }
          }
        }

        & .name-with-icon {
          display: inline-block;
          margin-top: spacing.$pixel-3;

          & .enrolled-indicator {
            margin-left: 0.5rem;
            line-height: spacing.$spacing-sm;

            & sis-icon[icon="check"] svg {
              fill: colors.$green;
            }
          }
        }

        & .enrolled-amount {
          text-align: right;
        }

        & dropdown-select {
          display: block;
          margin-top: spacing.$pixel-3;

          & .dropdown-toggle {
            padding: 0.5rem 2.2rem spacing.$pixel-2 0.6rem;
            height: spacing.$spacing-md;
            line-height: 1.2rem;
            font-size: 1.2rem;

            &::after {
              top: 1.3rem;
              right: spacing.$spacing-xs;
            }

            & span.additional-info {
              padding-right: 0.5rem;
              height: auto;
            }
          }
        }

        & .study-event-header {
          margin-top: 1rem;
          font-weight: 600;
        }

        & .checkbox.checkbox-toggle.sis-toggle {
          display: inline-block;
          margin-top: 0.5rem;
          margin-bottom: 1.5rem;

          & label {
            color: colors.$middle-gray;
            font-size: 1.2rem;
            font-weight: 400;

            & span {
              line-height: 2rem;
            }
          }
        }

        & .study-event {
          margin-bottom: 1.5rem;
        }
      }
    }

    & .enrolment-block {
      & .enrol-blocked-info {
        margin-top: 1rem;
        padding-right: 2.7rem;
        text-align: left;
        font-weight: 600;

        &.not-in-my-university {
          display: flex;
          align-items: center;
        }
      }

      & .enrolment-state {
        font-weight: 600;
      }

      & .button-holder {
        &.button-holder-with-border {
          border-bottom: spacing.$pixel-1 solid rgb(0 0 0 / 5%);
        }
      }

      & .enrolment-information {
        padding: 1rem 1.5rem 0.6rem;

        & .realisation-max-selected {
          padding-top: 1.5rem;
          font-weight: 600;
        }

        & processing-state-indicator {
          & + .enrolment-information-help {
            margin-top: -1.2rem;
          }
        }

        & .processing-state {
          padding: 1rem 1.5rem;

          & > sis-icon {
            position: absolute;
            margin: 0;
          }

          & > span {
            display: inline-block;
            position: relative;
            left: 4.5rem;
            margin: 0;
            width: calc(100% - 4.5rem);
          }
        }

        & .enrolment-state {
          & sis-icon[icon="check"] svg {
            fill: colors.$green;
          }
        }

        & .tentative-groups {
          padding-top: 0.5rem;

          & .single-tentative-group {
            font-weight: 600;
          }
        }

        & .questionnaire-answers {
          & button {
            display: inline-block;
            margin-top: 1.4rem;

            &.link-button {
              color: colors.$primary-color-dark;
              font-weight: 400;
            }
          }

          & + .enrolment-state {
            margin-top: 1.4rem;
          }
        }

        & .processing-state-information-rules {
          & .enrolment-failed-requirement-rules {
            & .failed-requirement-rule {
              display: block;
              position: relative;
              margin-left: 1.5rem;
              white-space: pre-line;

              &::before {
                display: inline-block;
                position: absolute;
                left: -0.8rem;
                content: "-";
              }
            }
          }

          & .enrolment-ordering-rules {
            padding-top: 1.2rem;
          }
        }

        & .enrolment-information-help {
          padding-top: 1.2rem;

          & > span {
            display: block;

            &.enrolment-start-end-date {
              font-weight: 600;
            }

            &.enrolments-confirmed-immediately {
              margin: 0.5rem 0;
              font-weight: 600;
            }
          }
        }
      }
    }

    & .links {
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;

      & .links-title {
        display: block;
        font-weight: 600;
      }

      & .links-wrapper {
        display: block;
      }

      & .learning-environments{
        margin-top: 1rem;
      }

      & + .button-holder {
        min-height: 6rem;
      }

      & a {
        display: inline-block;
        text-decoration-line: underline;
        color: colors.$primary-color-dark;

        & sis-icon[icon="brochure"] svg {
          margin: -0.4rem -0.6rem -0.4rem -0.8rem;
        }
      }
    }

    & .enrolment-not-processed {
      padding: 0.5rem 0;
      color: colors.$middle-gray;
    }
  }

  &.collapsed {
    & .courseunit-realisation-bottom-row {
      display: none;
    }

    & .cu-notification-bar {
      & .cu-notification {
        &:last-child {
          & .cu-notification-header {
            border-bottom-left-radius: spacing.$pixel-3;
          }
        }
      }
    }
  }

  &.dimmed {
    // credits box dimmer - covers the credits box and nothing else
    & .credits-box {
      &::after {
        display: inline-block;
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgb(0 0 0 / 50%);
        width: 3.8rem;
        height: 100%;
        content: "";
      }
    }

    // grade box dimmer - covers the grade box and nothing else
    & .grade-box {
      &::after {
        display: inline-block;
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgb(0 0 0 / 50%);
        width: 3.8rem;
        height: 100%;
        content: "";
      }
    }

    // title box dimmer - starts from the left border of the title box and covers more
    // first basic rules & rules for if there is an additional box
    & .title-box {
      position: absolute;

      &::before {
        display: inline-block;
        position: absolute;
        top: 0;
        left: 0;
        background: rgb(0 0 0 / 50%);
        width: calc(100% - 0.6rem);
        height: 100%;
        content: "";
      }
    }

    // additional box stuff when dimmed - disable hover, make a dimmer *before* the actual box, so the contents are still available
    & .additional-box {
      position: absolute;
      transition: all 0.05s linear, opacity 0.02s;
      opacity: 1;
      max-width: 15rem;

      &::before {
        display: inline-block;
        position: absolute;
        top: 0;
        left: 0;
        background: rgb(0 0 0 / 50%);
        width: 100%;
        height: 100%;
        content: "";
      }

      &:hover {
        background: colors.$light-gray;
      }

      & * {
        z-index: 1;
        cursor: default;
        pointer-events: none;
      }

      & .button-box.non-button::before {
        background-image: linear-gradient(to right, rgb(241 241 241 / 0%) 0%, colors.$middle-gray 70%);
      }
    }

    // notification bar dimmer - just covers the whole thingamabob
    & .cu-notification-bar {
      &::after {
        display: inline-block;
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgb(0 0 0 / 50%);
        width: 100%;
        height: 100%;
        content: "";
      }

      // disable cool rounded corner when dimmed
      & .cu-notification {
        &:last-child {
          & .cu-notification-header {
            border-bottom-left-radius: unset;
          }
        }
      }
    }
  }

  &.cu-color-0 {
    @include cur-border-and-bg-color(colors.$silver, colors.$light-gray);
  }

  &.cu-color-1 {
    @include cur-border-and-bg-color(colors.$appelsiini, colors.$appelsiini-light);
  }

  &.cu-color-2 {
    @include cur-border-and-bg-color(colors.$kaakao, colors.$kaakao-light);
  }

  &.cu-color-3 {
    @include cur-border-and-bg-color(colors.$minttu, colors.$minttu-light);
  }

  &.cu-color-4 {
    @include cur-border-and-bg-color(colors.$flamingo, colors.$flamingo-light);
  }

  &.cu-color-5 {
    @include cur-border-and-bg-color(colors.$auringonkukka, colors.$auringonkukka-light);
  }

  &.cu-color-6 {
    @include cur-border-and-bg-color(colors.$topaasi, colors.$topaasi-light);
  }

  &.cu-color-7 {
    @include cur-border-and-bg-color(colors.$karviainen, colors.$karviainen-light);
  }

  &.cu-color-8 {
    @include cur-border-and-bg-color(colors.$kirva, colors.$kirva-light);
  }

  &.cu-color-9 {
    @include cur-border-and-bg-color(colors.$ametisti, colors.$ametisti-light);
  }

  &.cu-color-10 {
    @include cur-border-and-bg-color(colors.$hiekka, colors.$hiekka-light);
  }

  &.cu-color-11 {
    @include cur-border-and-bg-color(colors.$kesataivas, colors.$kesataivas-light);
  }
}
