@use '../colors/colors' as colors;
@use '../spacing/tokens' as spacing;
@use 'tokens' as tokens;

// Fudis focus style mixin
@mixin fudis-focus-generic {
  outline: tokens.$fudis-focus-generic-outline;
  outline-offset: spacing.$pixel-1;
  box-shadow: 0 0 0 spacing.$spacing-xxs colors.$white;
}

// Fudis form field style mixin
@mixin fudis-focus-form-field {
  outline: tokens.$fudis-focus-form-field-outline;
  outline-offset: -0.2rem;
  border: spacing.$pixel-1 solid colors.$primary-color;
  box-shadow: 0 0 0 spacing.$pixel-1 colors.$white;
}

// Used in cases, where we want to hide focus on parent, but might want to show visible focus on child element
@mixin fudis-hide-focus {
  outline: none;
  box-shadow: initial;
}


// TODO: Deprecated focus, remove when the mixin is not needed anymore!

/*
  This focus variant is to be used mostly in older views and components, where generic focus style do not fit that well.
  Please avoid using this variant and while creating new views and components, try to build them, so Fudis generic focus
  style works nicely.
*/
@mixin deprecated-sisu-focus-generic-inner {
  @include fudis-focus-generic;

  outline-offset: -0.3rem;
  box-shadow: 0 0 0 spacing.$pixel-2 colors.$white, inset 0 0 0 spacing.$spacing-xxs colors.$white;
}
