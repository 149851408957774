@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

sis-module-search {
  & .module-search {
    position: relative;

    & .remove {
      position: absolute;
      top: spacing.$pixel-2;
      right: spacing.$spacing-xs;
      background-color: colors.$white;
    }

    & .dropdown-item > .disabled {
      color: colors.$middle-gray;
    }
  }
}
