@mixin attainment-tree-node-loop($counter) {
  @if $counter < 10 {
    & .move-buttons {
      padding-left: 1.5rem + 4rem * $counter;
    }

    & sis-attainment-tree-attainment, sis-attainment-tree-group-node {
      @include attainment-tree-node-loop($counter + 1);

      & .name {
        padding-left: 5.5rem + 4rem * $counter;
      }
    }
  }
}

sis-attainment-tree-attainment {
  display: block;

  & button {
    outline-offset: -0.1rem;
    overflow: hidden;
  }

  & sis-attainment-tree-nodes {
    @include attainment-tree-node-loop(0);

    display: block;
  }
}
