display-selected-parameters-static {
  & sis-tooltip {
    margin-right: 1rem;

    & > span.treat-as-a-block-element {
      height: 4.2rem;

      & .tag {
        margin-right: 0;
      }
    }
  }
}
