@use 'sis-assets/foundations/spacing/tokens' as spacing;

sis-open-university-product-enrolment-right-view {
  & .enrolment-right-add {
    margin-top: spacing.$spacing-md;
  }

  & .enrolment-right-info,
  & .enrolment-right-table {
    margin-top: spacing.$spacing-xs;
  }
}
