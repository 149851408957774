@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

organisation-role-share-editor {
  & .validity-group {
    &:first-of-type {
      & .add-new-block {
        &:first-of-type {
          &:not(.last) {
            margin-top: 0;
            margin-bottom: 3rem;
          }
        }
      }
    }

    & .add-new {
      margin-bottom: 3rem;

      & .last {
        margin-bottom: 0;
      }
    }
  }



    > .row{
        margin-bottom: 0.7rem;
    }

    .role-row{
        > div {
            margin: 0;
            padding-right: 0.7rem;
            padding-left: 0;
        }

        > div:first-child {
            padding-right: 0.7rem;
            padding-left: 1.5rem;
        }
    }

    .form-control-fake{
        padding: 0;

        .form-control{
            transition: none;
            margin: 0;
            border: 0;
            box-shadow: none;
            height: 3.8rem;
        }
    }

    .ui-select-match-text{
        padding-right: spacing.$spacing-xl;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .ui-select-toggle > .btn.btn-link {
        position: absolute;
        top: 0.6rem;
        right: 1rem;
        margin-right: 1rem;
    }

    a.btn.btn-xs {
        /* ui-select clear selection button */
        min-width: 2.5rem;
    }

    .organisation-role-share-row {
        & > .row > button.btn-link {
            margin-right: 0;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            background-color: colors.$extra-light-gray;
            padding: 1rem;
            min-width: 10rem;
            font-weight: 400;

            &:hover {
                text-decoration: none;
            }
        }
    }

  & .add-new {
    margin-top: 2rem;

    & a{
      display: inline-block;
    }
  }
}
