@use 'sis-assets/foundations/breakpoints/breakpoints' as breakpoints;

local-date-time-range-editor {
  & .local-date-time-range-date {
    padding-right: 0;

    &.start-date {
      @media (max-width: breakpoints.$screen-xs-max) {
        margin-bottom: 3.8rem;
      }
    }
  }
}
