@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;
@use 'sis-assets/foundations/typography/tokens' as typography;

app-select-completion-method {
  & .control-label {
    font-size: 1.4rem;
  }

  & .completion-method-box {
    padding: .5rem 1rem;

    &:not(:last-of-type) {
      margin-bottom: spacing.$spacing-sm;
    }

    &:last-child {
      margin-bottom: 0 !important;
    }

    & sis-radio-button {
      & label {
        margin-bottom: 0 !important;
        font-size: typography.$heading-sm-font-size !important;
        font-weight: typography.$font-weight-medium !important;
      }
    }

    & .assessment-item-checkbox {
      display: flex;
      align-items: center;
    }

    & sis-checkbox.invalid {
      & .control-indicator, &__indicator {
        border-color: colors.$red !important;
      }
    }
  }

  & .selected {
    border: spacing.$pixel-2 solid colors.$primary-color;
  }

  & .not-selected {
    border: spacing.$pixel-2 solid colors.$light-gray;
  }

  & .modal-footer fudis-button:first-of-type > button {
    margin-right: spacing.$spacing-sm;
  }

  & .assessment-item-label {
    &:not(:last-child) {
      margin-right: spacing.$spacing-xs;
    }
  }
}
