@use 'sis-assets/foundations/breakpoints/breakpoints' as breakpoints;
@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

common-course-unit-realisation-structure, sis-course-unit-realisation-structure {
  & .hide-headers {
    display: none;
  }

  div.study-sub-group-set {
    & .study-group-set-title {
      margin-bottom: 0.5rem;
      border-bottom: spacing.$pixel-1 solid colors.$extra-light-gray;
    }

    & .study-group-set-content {
      padding-left: 2.5rem;
    }
  }

  @media (max-width: breakpoints.$screen-xs-max) {
    & .study-group-set-content {
      padding-left: 1.5rem;
    }
  }
}
