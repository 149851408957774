sis-location {
  & > div {
    margin-bottom: 0.5rem;

    & sis-icon[icon="pin-small"] {
      position: absolute;
    }

    & .location-name {
      display: inline-block;
      padding-left: 1.8rem;
    }
  }
}
