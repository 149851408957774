@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

sis-contextual-notification {
  margin: 0.5rem 0;
  padding-top: 0.5rem;
  width: 100%;

  & .contextual-notification {
    display: flex;
    align-items: flex-start;
    margin: 0;
    border-width: spacing.$pixel-1 spacing.$pixel-1 spacing.$pixel-1 spacing.$spacing-xs;
    border-style: solid;
    border-radius: spacing.$pixel-2;
    padding: spacing.$pixel-1 spacing.$spacing-sm spacing.$pixel-1 0.5rem;
    min-height: 2rem;
    line-height: spacing.$spacing-sm;
    color: colors.$dark-gray;
    font-size: 1.4rem;
    font-weight: 400;

    & button, & a {
      text-decoration: underline;
      color: colors.$dark-gray;
      font-weight: 600;

      &:hover {
        color: colors.$dark-gray;
      }
    }

    & .link-button svg {
      fill: colors.$dark-gray;
    }

    & .content-text {
      padding: spacing.$spacing-xs 0 0.5rem 0;
      width: 100%;
      word-wrap: break-word;
    }

    &.warning {
      border-color: colors.$yellow;
      background-color: colors.$auringonkukka-extra-light;
    }

    &.danger {
      border-color: colors.$red;
      background-color: colors.$karviainen-extra-light;

    }

    &.success {
      border-color: colors.$green;
      background-color: colors.$minttu-extra-light;
    }

    &.light {
      border-color: colors.$primary-color-dark;
      background-color: colors.$primary-color-light;
      color: colors.$dark-gray;
    }
  }

  /* Switch external-link component's icon to be default (dark-gray) when inside contextual notification */
  & sis-external-link sis-icon .primary-dark {
    fill: colors.$dark-gray;
  }
}
