@use 'sis-assets/foundations/breakpoints/breakpoints' as breakpoints;
@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

sis-plan-edit {
  & .plan-state-notification-container {
    & ~ plan-module {
      & > div {
        padding-top: 2rem;
      }
    }

    & .contextual-notification {
      & + .contextual-notification {
        margin-top: 1rem;
      }

      & .non-button {
        margin-top: -0.4rem;
        color: colors.$dark-gray;
        font-weight: 400;
      }
    }
  }

  .container-fluid {
    padding-top: 3.9rem;
  }

  & .state-credits-header {
    transition: right 0.4s ease-in-out;

    &.collapsed {
      position: fixed;
      top: 8.5rem;
      right: 0;
      z-index: 121;
      padding: 0 4.5rem;

      @media (max-width: breakpoints.$screen-sm-max) {
        top: 7.7rem;
      }

      @media(max-width: breakpoints.$screen-xs-max) {
        display: none;
      }
    }

    & h4 {
      margin: 0;
    }

    & .module-grade-average-header {
      float: left;
      min-width: 12rem;

      > h4 {
        margin: 0;
      }

      @media (max-width: breakpoints.$screen-md-max) {
        min-width: 11rem;
      }
    }

    & .module-state-header {
      float: left;
      min-width: 10rem;

      > h4 {
        margin: 0;
      }

      @media (max-width: breakpoints.$screen-md-max) {
        min-width: 6rem;
      }
    }

    & .module-credits-header {
      display: inline-block;
      min-width: 11rem;
      text-align: center;

      > h4 {
        margin: 0;
      }

      & .sub-headers {
        line-height: 1rem;
        font-size: 1rem;
        font-weight: 400;

        & .credits-selected {
          padding-right: spacing.$pixel-3;
        }

        & .credits-done {
          border-left: spacing.$pixel-1 solid colors.$middle-gray;
          padding-left: spacing.$pixel-3;
          color: colors.$middle-gray;
        }
      }
    }
  }

  & .content-padding {
    padding-right: 3rem;
    padding-left: 3.5rem;

    &.has-active-selection {
      & .tree-list-heading, .tree-list-body, .tree-list-footer {
        opacity: 1;
      }
    }

    &.planning-course-unit {
      & plan-module {
        & .tree-list-heading {
          /* stylelint-disable-next-line selector-class-pattern */
          &.is_target {
            position: relative;
            z-index: 10;
            border-width: spacing.$pixel-2;
            border-style: dashed;
            border-color: colors.$primary-color;

            & .cell.status {
              & .plan-course-unit-instructions {
                display: table-cell;
              }
            }
          }
        }
      }
    }

    & .white-layer {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 9;
      /* stylelint-disable-next-line color-function-notation */
      background-color: rgba(255, 255, 255, 60%);
      width: 100vw;
      height: 100vh;
    }

    @media(max-width: breakpoints.$screen-sm-max) {
      padding-right: 1rem;
      padding-left: 0;
    }
  }

  .side-bar-open {
    .content-padding {
      padding-right: 3rem;
      padding-left: 3rem;
    }
  }

  .main-bar {
    &.col-6 {
      .content-padding {
        padding: 0 1.2rem;
      }
    }

    &.col-9 {
      .content-padding {
        padding-left: 1.2rem;
      }

      &.right-column-is-open {
        .content-padding {
          padding-right: 1.2rem;
          padding-left: 3.5rem;
        }
      }
    }

    &.col-6, &.col-9 {
      & .state-credits-header {
        @media (max-width: breakpoints.$screen-sm-max) {
          display: none;
        }
      }
    }

    & .study-plan-container {
      transition: padding-top 0s;
    }
  }

  & .right-column-is-open {
    & .state-credits-header.collapsed {
      @media (min-width: breakpoints.$screen-md-min) {
        right: calc(25% - 0.8rem);
      }
    }
  }

  .collapsible-columns-container .collapsible-column-header {
    height: 5.6rem;
  }

  & .plan-state {
    position: fixed;
    top: 10.6rem;
    transition: top 0.4s ease, height 0.1s linear;
    z-index: 120;
    width: 100%;
    height: 3rem;
    overflow: hidden;
    text-align: center;
    line-height: 3rem;
    color: colors.$white;

    & > span {
      white-space: nowrap;
    }

    &.danger {
      background-color: colors.$red;
    }

    &.success {
      background-color: colors.$green;
    }

    + .container-fluid {
      & .right-column, & .left-column {
        top: 13.6rem;
        transition: top 0.4s ease;
        height: calc(100% - 13.6rem);

        & .right-column {
          right: 0;
        }

        & .left-column {
          left: 0;
        }
      }
    }

    &.collapsed {
      top: 5.3rem;
      height: spacing.$pixel-3;
      overflow: hidden;

      &:hover {
        height: 3rem;
        overflow: visible;
      }

      + .container-fluid {
        & .right-column, .left-column {
          top: 12.8rem;
          transition: top 0.4s ease;
          height: calc(100% - 12.8rem);
        }
      }
    }
  }

  .course-unit-box-adding {
    transition: opacity 300ms;
    opacity: 1;
  }

  .course-unit-box-adding-add {
    transition: opacity 150ms;
    opacity: 0.5;
  }

  .course-unit-box-adding-opaque {
    transition: opacity 300ms;
    opacity: 0.5;
  }

  .course-unit-box-adding-active {
    transition: opacity 300ms;
    opacity: 1;
  }
}
