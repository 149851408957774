@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;
@use 'sis-assets/foundations/typography/tokens' as typography;

$tooltip-arrow-width: 0.5rem;

.tooltip {
  line-height: 1.2857;
  font-family: typography.$font-family;
  font-size: typography.$body-text-sm-font-size;
  font-weight: typography.$font-weight-regular;

  &.show {
    opacity: 1;
  }

  & .tooltip-inner {
    border: spacing.$pixel-1 solid rgb(255 255 255);
    background-color: colors.$dark-gray;
  }

  & .tooltip-arrow {
    display: none;
  }

  &.tooltip-wide {
    & > .tooltip-inner {
      min-width: 20rem;
      max-width: 50rem;
    }
  }

  &.line-breaks {
    & > .tooltip-inner {
      white-space: pre-line;
    }
  }

  &.tooltip-text {
    & > .tooltip-inner {
      max-width: 30rem;
      text-align: left;
      white-space: break-spaces;
    }
  }

  & .tooltip-hierarchy {
    text-align: left;

    & > .tooltip-hierarchy {
      position: relative;
      top: spacing.$pixel-3;
      padding-left: 1.5rem;

      &::before {
        display: inline-block;
        position: absolute;
        top: -0.2rem;
        left: 0.5rem;
        margin-right: 0.4rem;
        border-width: 0 0 spacing.$pixel-1 spacing.$pixel-1;
        border-style: none none dotted dotted;
        border-color: colors.$middle-gray;
        width: 0.7rem;
        height: 1rem;
        content: "";
      }

      &:last-of-type {
        margin-bottom: 0.5rem;
      }
    }
  }
}

sis-tooltip {
  & > span.treat-as-a-block-element {
    display: inline-block;
  }

  &.with-language-pills {
    position: relative;
    bottom: spacing.$pixel-1;
    margin-right: -0.5rem;
    margin-left: 0.4rem;

    & > span {
      display: inline-block;
      height: 2.4rem;
    }
  }

  &.tooltip-trigger-word, & .tooltip-trigger-word {
    cursor: default;
    text-decoration: underline;
    text-decoration-color: colors.$primary-color;
    text-decoration-style: dotted;
  }
}

.sis-tooltip-content {
  & ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
}

// Workaround for NG Bootstrap issue https://github.com/ng-bootstrap/ng-bootstrap/issues/3997
ngb-tooltip-window {
  pointer-events: none;

  .tooltip-inner {
    pointer-events: auto;
  }
}
