/*
 * NOTE: In root.scss the root font-size is set to 62.5% which means 1rem = 10px
 * thus rem typography values are set as follows
 */

// Heading font sizes
$heading-xxl-font-size: calc(2.25rem / var(--fudis-rem-multiplier));    // 36px
$heading-xl-font-size: calc(1.75rem / var(--fudis-rem-multiplier));     // 28px
$heading-lg-font-size: calc(1.5rem / var(--fudis-rem-multiplier));       // 24px
$heading-md-font-size: calc(1.25rem / var(--fudis-rem-multiplier));      // 20px
$heading-sm-font-size: calc(1.125rem / var(--fudis-rem-multiplier));     // 18px
$heading-xs-font-size: calc(1rem / var(--fudis-rem-multiplier));        // 16px
$heading-xxs-font-size: calc(0.875rem / var(--fudis-rem-multiplier));   // 14px

// Body text font sizes
$body-text-lg-font-size: calc(1rem / var(--fudis-rem-multiplier));       // 16px
$body-text-md-font-size: calc(0.875rem / var(--fudis-rem-multiplier));   // 14px
$body-text-sm-font-size: calc(0.75rem / var(--fudis-rem-multiplier));    // 12px

// Heading line heights
$heading-lg-line-height: calc(2.5rem / var(--fudis-rem-multiplier));     // 40px
$heading-md-line-height: calc(1.75rem / var(--fudis-rem-multiplier));    // 28px
$heading-sm-line-height: calc(1.125rem / var(--fudis-rem-multiplier));   // 18px

// Label line heights
$label-line-height: calc(1.5rem / var(--fudis-rem-multiplier));         // 24px

// Body text line heights
$body-text-lg-line-height: calc(1.25rem / var(--fudis-rem-multiplier));  // 20px
$body-text-md-line-height: calc(1.125rem / var(--fudis-rem-multiplier)); // 18px
$body-text-sm-line-height: calc(1rem / var(--fudis-rem-multiplier));     // 16px

// Font weights
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-bold: 600;

// Font families
$font-family: 'Fira Sans', sans-serif;
