@use 'sis-assets/foundations/breakpoints/breakpoints' as breakpoints;
@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

/* stylelint-disable selector-type-no-unknown */
plans {
  .no-plans {
    padding: 1.5rem;
    text-align: center;
    font-weight: 600;
  }

  & .page-title-container {
    margin-top: 4.1rem;
    min-height: 7.8rem;
  }

  .plan-title {
    display: inline-block;
    width: 100%;

    .plan-header {
      display: inline-block;

      & h2.dropdown-toggle {
        margin-bottom: 0;
      }

      .dropdown-menu li {
        border-bottom: spacing.$pixel-1 solid colors.$extra-light-gray;
      }

      .dropdown-menu li:last-child {
        border-bottom: 0;
      }
    }

    .buttons {
      display: inline-block;
      padding: 1.8rem 0;
      text-align: right;

      & a:last-of-type {
        margin: 0;
      }

      @media (max-width: breakpoints.$screen-xs-max) {
        padding: 1rem 0;
      }
    }
  }
}
