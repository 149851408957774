.module-content-application-structure {
  margin-bottom: 3.8rem;

  .mca-border {
    & .accordion-panel > .content > .content-row {
      & .content-text {
        & .studymodule, .courseunit {
          margin-bottom: 1em;
        }

        & .grouping-module {
          margin-top: 1.3rem;
          min-height: 5.2rem;
        }
      }
    }
  }
}
