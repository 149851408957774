sis-legacy-local-time-range-editor {
  & .en-dash-col {
    padding: 0;
    width: 0.7rem;
    text-align: center;
    line-height: 4.1rem;
  }

  & .error-list {
    margin-bottom: -3.8rem;
  }
}
