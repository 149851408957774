local-date-time-editor {
  & .local-date-time-row {
    padding-bottom: 0.5rem;
  }

  & .errors {
    ul.error-list:first-child {
      margin-top: -0.2rem;
    }

    & + ng-transclude {
      & ul.error-list:first-child {
        margin-top: -0.9rem;
      }
    }
  }

  .local-date-time-date {
    padding-right: 0;
  }
}
