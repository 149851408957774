@use '../breakpoints/breakpoints' as breakpoints;
@use '../colors/colors' as colors;
@use '../spacing/tokens' as spacing;
@use '../typography/tokens' as typography;

// TODO: Refactor table classes to sis-table

@mixin checkbox-cell {
  align-content: center;

  & sis-checkbox,
  & sis-form-checkbox {
    display: inline-block;
    margin-bottom: 0;
  }

  > sis-tooltip {
    > * {
      display: inline-block;
    }
  }

  ~ th,
  ~ td {
    align-content: center;
  }
}

@mixin clickable-disclaimer-text {
  > b {
    background-color: colors.$primary-color-light;
    font-weight: typography.$font-weight-light;
  }
}

// Table

.sis-table,
.table {
  caption {
    caption-side: top;
    text-transform: uppercase;
    color: colors.$dark-gray;
    font-size: typography.$heading-xxs-font-size;
    font-weight: typography.$font-weight-regular;

    & h3 {
      text-transform: capitalize;
    }
  }

  // Default styled table

  &.table-search-results, &.styled-table {
    > thead {
      > tr {
        > th {
          line-height: typography.$body-text-sm-line-height;
          font-weight: typography.$font-weight-regular;
          -webkit-font-smoothing: initial;
          -moz-osx-font-smoothing: initial;
        }

        > td,
        > th {
          border-top-width: 0;
          border-bottom-width: 0;
          padding: 1.5rem spacing.$spacing-xs;
          text-transform: uppercase;
          color: colors.$dark-gray;
          font-size: typography.$heading-xxs-font-size;

          &.checkbox-cell {
            @include checkbox-cell;

            width: 4.8rem;
            max-width: 4.8rem;

            > .control-checkbox {
              margin-top: 0.5rem;
            }
          }

          @media (max-width: breakpoints.$screen-sm-max) {
            font-size: typography.$body-text-sm-font-size;
          }
        }
      }
    }

    > tbody {
      > tr {
        background-color: colors.$white;
        
        &:hover,
        &:focus {
          background-color: colors.$extra-light-gray;
        }

        > td {
          border-top: spacing.$pixel-1 solid colors.$extra-light-gray;
          background-color: transparent;
          padding: 1.5rem spacing.$spacing-xs;
          line-height: typography.$body-text-sm-line-height;
          color: colors.$dark-gray;
          font-size: typography.$body-text-md-font-size;
          font-weight: typography.$font-weight-light;

          &.invalid {
            color: colors.$red;
          }

          &.sorted-cell {
            font-weight: typography.$font-weight-bold;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
          }

          &.table-course-code {
            vertical-align: middle;
            word-break: break-word;
          }

          &.checkbox-cell {
            @include checkbox-cell;
          }

          & a:not([disabled], .disabled, .dropdown-item) {
            &.clickable-rows-link {
              color: colors.$dark-gray;
            }

            & span {
              @include clickable-disclaimer-text;

              &.additional {
                color: colors.$middle-gray;
              }
            }

            & .sis-disclaimer {
              > span {
                @include clickable-disclaimer-text;
              }
            }
          }

          &.sorted-cell a:not([disabled], .disabled, .dropdown-item) span > b {
            font-weight: inherit;
          }

          > mark,
          & span > mark {
            border-width: spacing.$pixel-1;
            border-style: dotted;
            background-color: colors.$primary-color-light;
            padding: 0;
            font-weight: typography.$font-weight-light;
          }

          & .attainment-status,
          & .application-status,
          & .workflow-status {
            display: flex;
            align-items: flex-start;

            & sis-icon[icon="check"] svg,
            & sis-icon[icon="fail"] svg,
            & sis-icon[icon="hourglass"] svg {
              margin-top: -0.7rem;
            }

            & .tiny-badge {
              margin-top: spacing.$pixel-1;
              margin-bottom: spacing.$pixel-1;
              vertical-align: text-bottom;
            }
          }

          & ul.dropdown-menu {
            > .active {
              & a,
              & a:hover,
              & a:focus {
                color: colors.$white;
              }
            }
          }

          @media (max-width: breakpoints.$screen-sm-max) {
            font-size: typography.$body-text-sm-font-size;
          }
        }

        &.active {
          > td {
            background-color: colors.$extra-light-gray;
          }
        }
      }
    }

    // Small tables

    &.table-condensed {
      > thead {
        > tr {
          > th {
            padding: 1.5rem 0.5rem;

            &.checkbox-cell {
              align-content: center;
            }
          }
        }
      }

      > tbody {
        > tr {
          > td {
            padding: 0.5rem;
            min-height: 3.1rem;
            line-height: calc(3.1rem - 1rem); // height - paddings

            &.checkbox-cell {
              padding-bottom: 0;
            }
          }
        }
      }
    }

    &.table-semi-condensed {
      > tbody {
        > tr {
          > td {
            padding: 1rem spacing.$spacing-xs;

            &.checkbox-cell {
              align-content: center;
            }
          }
        }
      }
    }

    // Summary table

    &.summary-table {
      > thead {
        > tr {
          > th {
            text-transform: none;

            &:last-child {
              text-align: right;
            }
          }
        }
      }

      > tbody {
        > tr {
          &:last-child > td {
            border-top: spacing.$pixel-1 solid colors.$middle-gray;
            font-weight: typography.$font-weight-regular;
          }

          > td {
            border-top: spacing.$pixel-1 solid colors.$light-gray;

            &:last-child {
              text-align: right;
            }
          }
        }
      }
    }

    &.no-hover-color {
      & > tbody {
        & > tr {
          & > td:hover,
          &:hover,
          &:focus {
            background-color: colors.$white;
          }
        }
      }
    }
  }

  &.wide-table {
    width: 100%;
  }
}
