@use 'sis-assets/foundations/breakpoints/breakpoints' as breakpoints;
@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;
@use '../../../timing-helpers' as helper;

app-timeline-note {
  @include helper.fix-dropdown-button;

  display: block;
  background-color: colors.$extra-light-gray;

  button {
    border: none;
  }

  .note-box {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    height: helper.$timing-box-height;

    .box-content {
      position: relative;
      max-width: 100%;
    }
  }

  .note-box .box-bottom {
    position: relative;
    overflow: visible;
  }

  &.hover .note-box {
    border: spacing.$pixel-2 solid colors.$primary-color;

    &.first {
      border-right-width: 0;
    }

    &.middle {
      border-right-width: 0;
      border-left-width: 0;
    }

    &.last {
      border-left-width: 0;
    }

    &.first.last {
      border-width: spacing.$pixel-1;
    }
  }

  .box-top {
    padding: 0 spacing.$spacing-xxs 0 spacing.$spacing-xxs;
    max-height: spacing.$spacing-xl;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

timeline-note-modal {
  ul.periods {
    padding: 0;
    list-style: none;
  }
}
