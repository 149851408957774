/**
   CSS imports used in all applications. These should be loaded before any other stylesheet, e. g. student.scss.
 */

// Overridden tokens must be loaded before bootstrap
@import 'sis-assets/foundations/utilities/bootstrap-override-tokens';
@import 'bootstrap/scss/bootstrap';
@import 'angular/angular-csp.css';
@import 'ui-select/dist/select.css';
@import 'medium-editor/dist/css/medium-editor.css';
@import 'medium-editor/dist/css/themes/bootstrap.css';
