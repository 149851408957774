@use '@funidata/ngx-fudis' as fudis;
@use 'sis-assets/foundations/breakpoints/breakpoints' as breakpoints;
@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;
@use './mixins' as mixin;

// Ui-select's styles are the same as other dropdowns'
// If you make changes in this file, make sure the same changes are done in dropdown.scss

.ui-select-bootstrap.dropdown {
  &.open {
    & .dropdown-menu {
      display: block;
    }
  }

  & .ui-select-match {
    background-color: colors.$white;
    height: 4rem;

    &.btn-default-focus {
      outline: spacing.$pixel-2 solid colors.$primary-color;
      outline-offset: -0.2rem;
      border: spacing.$pixel-1 solid colors.$primary-color;
      box-shadow: 0 0 0 spacing.$pixel-1 colors.$white;

      & > .form-control {
        border: none;
      }
    }

    & .ui-select-toggle { // ui-select's equivalent to .dropdown-toggle
      display: block;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      margin: 0;
      border: spacing.$pixel-1 solid colors.$middle-gray;
      border-radius: 0;
      box-shadow: none;
      padding-left: spacing.$spacing-xs;
      height: 100%;
      text-transform: none;
      line-height: 4rem;
      letter-spacing: 0;
      color: colors.$silver;
      font-size: 1.6rem;
      font-weight: 400;

      & .ui-select-match-text {
        display: inline-block;
        padding-right: spacing.$spacing-lg;
        overflow: hidden;
        text-overflow: ellipsis;
        color: colors.$dark-gray;

        & span {
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        & .additional-info {
          position: absolute;
          width: auto;
          height: 3.8rem;
          overflow: visible;
        }
      }

      & .ui-select-placeholder {
        display: inline-block;
        padding-right: spacing.$spacing-lg;
        width: inherit;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: colors.$middle-gray !important;

        &:not(.ng-hide) {
          & + .ui-select-match-text {
            display: none;
          }
        }
      }

      & .caret {
        top: spacing.$pixel-2;
        width: 2rem;
        height: 100%;

        &::after {
          display: inline-block;
          position: absolute;
          right: spacing.$pixel-3;
          vertical-align: middle;
          line-height: 3.8rem;
          color: colors.$dark-gray;
          font-family: SISUicons, sans-serif;
          font-size: 2.4rem !important;
          font-weight: 300;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          font-style: normal;
          content: "W";
        }
      }

      & a { // blue remove icon
        position: absolute;
        top: 1rem;
        right: 0.5rem;
        margin: 0;
        background-color: colors.$white;
        padding: 0;
        width: 2.7rem;
        min-width: unset;
        height: 2.6rem;
        text-align: left;

        & i.glyphicon-remove::before {
          text-transform: none;
          color: colors.$primary-color;
          font-family: SISUicons, sans-serif;
          font-size: 2.4rem;
          font-style: normal;
          content: 'c';

          @supports (content: 'x' / 'y') {
            content: 'c' / 'remove';
          }
        }
      }
    }
  }

  & .ui-select-choices { // ui-select's equivalent to .dropdown-menu
    margin-top: 0;
    border: spacing.$pixel-1 solid colors.$light-gray;
    border-radius: 0;
    box-shadow: 0 spacing.$pixel-2 0 rgb(0 0 0 / 10%);
    padding: 0;
    width: unset;
    min-width: 100%;
    max-height: 30vh;
    overflow-x: hidden;
    overflow-y: auto;

    &.multiple-language-versions {
      & .ui-select-choices-row-inner {
        white-space: normal;
      }
    }

    & .ui-select-choices-row {
      padding: 0;

      & .ui-select-choices-row-inner { // ui-select's equivalent to .dropdown-item
        display: flex;
        position: relative;
        justify-content: space-between;
        padding: 0.5rem 2rem;
        min-height: fudis.$spacing-lg;
        line-height: fudis.$body-text-md-line-height;
        font-size: 1.4rem;

        & .ui-select-choices-row-inner-container {
          display: flex;
          justify-content: space-between;
          width: 100%;
        }

        & .ui-select-highlight,
        & b {
          background-color: colors.$primary-color-light;
          font-weight: 400;
        }

        & .additional-info {
          right: 0;
          margin-left: spacing.$spacing-xs;
          padding: 0;
        }
      }

      &.active {
        & .ui-select-choices-row-inner {
          background-color: colors.$primary-color;
          color: colors.$white;

          & .ui-select-highlight,
          & b {
            background-color: colors.$primary-color;
            font-weight: 400;
          }

          & .additional-info {
            background-color: colors.$primary-color;
            color: colors.$white;

            &::before {
              @include mixin.gradient(rgb(241 241 241 / 0%) 0%, colors.$primary-color);
            }
          }
        }

        &.disabled {
          & .ui-select-choices-row-inner {
            @include mixin.dropdown-option-disabled;

            & .ui-select-highlight,
            & b {
              background-color: colors.$primary-color-light;
            }

            & .additional-info {
              @include mixin.dropdown-option-disabled;

              &::before {
                @include mixin.gradient(rgba(241, 241, 241, 0.00), colors.$white);
              }
            }
          }
        }
      }

      &.disabled {
        & .ui-select-choices-row-inner {
          @include mixin.dropdown-option-disabled;

          & .additional-info {
            @include mixin.dropdown-option-disabled;
          }
        }
      }

      & .info-too-many-results {
        flex: 0 0 100%;
        width: 100%;
        color: colors.$silver;
        pointer-events: none;
      }
    }

    &.multiple-line-items {
      & .ui-select-choices-row {
        margin: 0;
        border-bottom: spacing.$pixel-1 solid colors.$extra-light-gray;

        & .additional-data {
          display: block;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          color: colors.$middle-gray;
          font-size: 1.2rem;
        }

        &.active, &:active {
          & .additional-data {
            color: colors.$primary-color-light;
          }
        }

        & .ui-select-choices-row-inner {
          display: block;
          white-space: normal;
        }

        &.disabled {
          & .ui-select-choices-row-inner {
            @include mixin.dropdown-option-disabled;

            & .additional-data {
              @include mixin.dropdown-option-disabled;
            }
          }
        }
      }
    }

    & .ui-select-choices-row:hover {
      & .ui-select-choices-row-inner {
        background-color: colors.$primary-color-light;
        color: colors.$dark-gray;

        & .additional-data{
          color: colors.$dark-gray;
        }

        & .additional-info {
          background-color: colors.$primary-color-light;

          &::before {
            @include mixin.gradient(rgba(241, 241, 241, 0.00), colors.$primary-color-light);
          }
        }
      }

      &.disabled {
        & .ui-select-choices-row-inner {
          background-color: colors.$white;

          & .additional-info {
            background-color: colors.$white;

            &::before {
              @include mixin.gradient(rgba(241, 241, 241, 0.00), colors.$white);
            }
          }
        }
      }
    }
  }

  & mark {
    border-width: spacing.$pixel-1;
    border-style: dotted;
    background-color: colors.$primary-color-light;
    padding: 0;

  }
}
