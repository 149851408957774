common-show-module-content-application-modal {
  & .modal-body {
    & .show-with-decision {
      & .situation-description {
        & h3 {
          margin-top: 0;
        }
      }

      & .mca-border {
        & .content-text {
          padding-top: 0;
        }
      }
    }

    & em {
      font-weight: 600;
    }
  }
}
