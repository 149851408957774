sis-property-editor {
  display: block;
  position: relative;
  width: 100%;

  .toggle-editable {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
  }
}
