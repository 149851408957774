@use 'sis-assets/foundations/spacing/tokens' as spacing;

sis-plan-structure-education {
  .sis-plan-structure-education {
    &__header {
      padding: spacing.$spacing-xxs 0;

      &__additional-info {
        line-height: 1.4rem;
        font-size: 1.4rem;
        font-weight: 400;
      }
    }
  }
}
