@use 'sis-assets/foundations/breakpoints/breakpoints' as breakpoints;
@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

sis-bulk-edit-container {
  display: block;
  border: spacing.$pixel-1 solid colors.$light-gray;
  padding: 0 3rem 1rem;

  & .container-header {
    display: flex;
    position: relative;
    margin: 0 -3rem 1rem;
    background-color: colors.$extra-light-gray;
    padding: 2rem 3rem;
    min-height: 7.5rem;

    & .back-button {
      margin-right: 0;
    }

    & .container-title {
      flex: 1;
      padding: 0 2rem;

      &.has-additional-info {
        position: relative;
        top: 1rem;
        margin-top: -2rem;
      }

      & h3 {
        position: relative;
        top: 0.5rem;
        margin: 0;
        padding-bottom: 0.5rem;
      }

      @media (max-width: breakpoints.$screen-xs-max) {
        padding-top: spacing.$spacing-sm;
        padding-bottom: spacing.$spacing-sm;
      }
    }

    & .forward-button {
      margin-right: 0;
    }

    & .btn {
      vertical-align: top;
    }

    @media (max-width: breakpoints.$screen-xs-max) {
      flex-direction: column;
    }
  }
}
