@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

sis-local-date-editor {
  & label {
    width: 100%;

    & label {
      display: inline-block;
    }

    & .inner-addon {
      display: block;
      position: relative;

      & input.form-control {
        padding-right: spacing.$spacing-lg;
        text-overflow: ellipsis;

        &::-ms-clear { //IE, hide cross that clears input field
          display: none;
        }
      }

      & .addon-button {
        position: absolute;
        top: spacing.$pixel-3;
        right: spacing.$spacing-xxs;
        background-color: colors.$white;
        padding: 0;

        &:disabled {
          background-color: colors.$extra-light-gray;
          cursor: default;

          & svg {
            fill: colors.$middle-gray;
          }
        }

        & svg {
          fill: colors.$primary-color;
        }
      }
    }
  }
}
