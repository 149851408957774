@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;
@use 'sis-assets/foundations/typography/tokens' as typography;

@mixin notification-box-outer-wrap-styles {
  display: flex;
  align-items: center;
  width: 100%;
}

@mixin notification-box-inner-wrap-styles {
  display: inline-block;
  align-self: center;
  padding: 0 spacing.$spacing-xxs 0 0;
  width: 100%;

  & button {
    margin: 0 0 spacing.$spacing-xxs spacing.$spacing-xxs;
  }
}

sis-study-notification-box {
  & .notification-box {
    border-top: spacing.$pixel-1 solid colors.$light-gray;
    background-color: colors.$extra-light-gray;
    font-size: typography.$body-text-sm-font-size;

    &__info {
      @include notification-box-outer-wrap-styles;

      &__text {
        @include notification-box-inner-wrap-styles;

        padding: spacing.$spacing-xs 0;
        line-height: typography.$body-text-sm-line-height;

        &--alert {
          color: colors.$red;
          font-weight: typography.$font-weight-regular;
        }
      }
    }

    &__actions {
      @include notification-box-outer-wrap-styles;

      &__buttons {
        @include notification-box-inner-wrap-styles;

        padding: spacing.$spacing-xxs spacing.$spacing-xxs 0 0;

        & .float-right-preserve-tab-order {
          float: right;
        }
      }
    }
  }
}
