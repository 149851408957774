@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

app-select-widgets {
  & .dropdown-multiselect {
    display: grid;
    position: relative;
    grid-template-rows: max-content max-content;
    grid-template-columns: auto max-content;
  }

  & .option-menu {
    position: absolute;
    top: 100%;
    left: 0;
    float: left;
    z-index: 1000;
    margin: spacing.$pixel-2 0 0;
    outline: spacing.$pixel-1 solid colors.$middle-gray;
    box-shadow: 0 spacing.$pixel-2 0 rgb(0 0 0 / 30%);
    background-clip: padding-box;
    background-color: colors.$white;
    padding: 0.5rem;
    min-width: 100%;
    max-height: 30vh;
    overflow: auto;
    list-style: none;
    text-align: left;
    color: colors.$dark-gray;
    font-size: 1.4rem;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
}
