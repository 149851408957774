@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

@mixin gradient($colorFrom, $colorTo) {
  background-color: transparent;
  background-image: linear-gradient(to right, $colorFrom 0%, $colorTo 75%, $colorTo 100%);
}

@mixin dropdown-chevron($position, $right, $top) {
  position: $position;
  top: $top;
  right: $right;
  margin-right: -0.4rem;
  margin-left: spacing.$spacing-xs;
  line-height: 0;
}

@mixin dropdown-option-disabled {
  background-color: colors.$extra-light-gray;
  cursor: not-allowed;
  color: colors.$middle-gray;

  & sis-icon svg {
    fill: colors.$middle-gray;
  }
}
