@use 'sis-assets/foundations/spacing/tokens' as spacing;

sis-plan-custom-course-unit-attainment {
  .courseunit {
    &.attainment-data-not-found {
      & .courseunit-top-row {
        & .grade-box sis-icon[icon="achievement"] {
          margin-top: 1rem;
        }

        & .credits-box sis-icon {
          display: block;
          margin-top: 1rem;
          margin-left: spacing.$pixel-3;
        }
      }

      & .courseunit-code {
        height: 1.9rem;
      }
    }
  }
}
