@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

sis-search-filter-options-menu {
  & [ngbdropdown] {
    & .dropdown-toggle.form-control.btn-search {
      margin-right: 0;
      border-width: spacing.$pixel-1 spacing.$pixel-1 spacing.$pixel-1 0;
      border-color: colors.$light-gray;
      background-color: colors.$extra-light-gray;
      padding: 0 0 0 spacing.$spacing-sm;
      width: auto;
      color: colors.$dark-gray;
      font-weight: 400;

      &:hover, &:active {
        border-color: colors.$light-gray;
        background-color: colors.$extra-light-gray;
        color: colors.$dark-gray;
      }

      &:disabled {
        pointer-events: none;
      }

      > sis-icon[icon="chevron"] {
        top: 0.2rem;
        right: 0.4rem;
      }
    }
  }
}
