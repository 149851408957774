@use 'sis-assets/foundations/breakpoints/breakpoints' as breakpoints;
@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

sis-file-upload {
  & .previous-files {
    margin-bottom: 1rem;
  }

  & .previous-files-list {
    margin-bottom: 2rem;
    margin-left: spacing.$spacing-lg;

    & .file-name {
      display: block;
      margin-top: 0.5rem;
    }
  }

  & #selectedFile {
    display: none;
  }

  & .choose-files {
    margin: 1.5rem 0 1rem;
  }

  & .custom-dropzone {
    margin-bottom: 1rem;
    border: spacing.$pixel-1 dashed colors.$middle-gray;
    border-radius: 0;
    background-color: colors.$extra-light-gray;
    height: 7rem;
    color: colors.$dark-gray;
    font-weight: 400;
  }

  & .table.styled-table {
    margin-top: 2rem;

    & .file-comment-field {
      margin: 1rem 0 0 2rem;
      width: calc(100% - 2rem);

      & + .help-block {
        margin-left: 2rem;
      }

      & + .error-list {
        margin: 0.6rem 0 1.1rem 2rem;
      }

      & textarea {
        margin-top: 0.5rem;
        padding-top: spacing.$spacing-xs;
        height: spacing.$spacing-xl;
        min-height: spacing.$spacing-xl;
      }
    }

    & .remove-file {
      position: relative;
      top: -0.4rem;
    }
  }

  & .files-not-editable {
    margin-top: 2rem;
    text-align: right;
  }
}

.file-preview {
  & .file-name {
    font-size: spacing.$spacing-sm;
    font-weight: 400;
    overflow-wrap: break-word;
  }

  & .file-comment {
    font-size: spacing.$spacing-sm;
  }
}
