sis-confirm-dialog {
  & .modal-body {
    & em {
      font-weight: 600;
    }
    & .modal-notification .contextual-notification {
      margin-bottom: 1.5rem;
    }
    & p {
      white-space: pre-line;
    }
  }
}
