search-employee {
  display: block;
  position: relative;

  & input.form-control {
    &::-ms-clear {  //IE, hide cross that clears input field
      display: none;
    }
  }

  & .ui-select-bootstrap {
    & > .ui-select-choices, & > .ui-select-no-choice {
      margin-top: 1.1rem;
    }
  }

  & ngb-typeahead-window.dropdown-menu {
    width: auto;
  }
}
