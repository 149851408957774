@use 'sis-assets/foundations/breakpoints/breakpoints' as breakpoints;
@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

app-open-university-cur-selection-step {
  & .wizard-step-group-container {
    padding-top: spacing.$spacing-sm;

    &:not(:last-child) {
      border-bottom: spacing.$pixel-1 solid colors.$dark-gray;
      padding-bottom: spacing.$spacing-sm;
    }
  }

  & .cur-selection-boxes-container {
    display: grid;
    grid-template-columns: 1fr;
    gap: spacing.$spacing-md;
    row-gap: spacing.$spacing-sm;
    margin-top: spacing.$spacing-lg;

    &-wide{
      display: grid;
      grid-template-columns: 1fr;
      row-gap: spacing.$spacing-sm;
    }

  }

  & .enrolment-question-container {
    &:not(:last-of-type) {
      margin-bottom: spacing.$spacing-xl;
    }

    @media (max-width: breakpoints.$screen-xs-max) {
      margin-left: spacing.$spacing-md;
    }
  }
}
