@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

app-create-degree-programme-attainment-application {
  & .contextual-notification {
    margin-top: 2rem;
  }

  & contact-info-for-graduation {
    & p.form-control-static {
      & > span {
        display: block;
      }
    }
  }

  & .delivery {
    & label {
      margin-top: 1rem;
    }

    & sis-radio-button:last-of-type {
      /* stylelint-disable-next-line */
      & > label.control.control-radio, & > label.control.control--radio {
        margin-bottom: -0.3rem;
      }
    }
  }

  & .delivery-by-mail {
    position: relative;
    margin-left: 1.2rem;
    border-left: spacing.$pixel-1 dotted colors.$silver;
    padding-bottom: 0.5rem;
    padding-left: 3rem;

    &::after {
      position: absolute;
      bottom: -0.3rem;
      left: -0.3rem;
      border-radius: 0.5rem;
      background-color: colors.$silver;
      width: 0.5rem;
      max-width: 0.5rem;
      height: 0.5rem;
      max-height: 0.5rem;
      content: '';
    }

    & .guidance {
      margin-left: -0.8rem;
    }

    /* stylelint-disable */
    & sis-radio-button {
      & > label.control.control-radio, & > label.control.control--radio {
        margin-top: 1.5rem;
        margin-bottom: 0.5rem;
      }

      & ~ span {
        display: block;
        margin-left: 3.7rem;
      }
    }
    /* stylelint-enable */
  }

  & .button-row {
    text-align: right;

    & button:last-of-type {
      margin-left: spacing.$spacing-sm;
    }
  }

  & .formatted-address {
    white-space: pre-line;
  }

  & .alumni-info {
    & .description {
      margin-bottom: 3rem;
    }

    & .guidance {
      margin-left: 3.4rem;
    }
  }
}
