/* stylelint-disable color-no-hex */
@use 'sis-assets/foundations/breakpoints/breakpoints' as breakpoints;
@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/focus/mixins' as focus;
@use 'sis-assets/foundations/focus/tokens' as tokens;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

sis-alerts {
 & .alerts {
   position: absolute;
   right: 0;
   z-index: 1004; // on top of the title banner after scrolling
   width: 100%;
   overflow: visible;

   &--sticky {
     position: fixed;
     top: 0;
     z-index: 1021; // always on top of the white layer
     &:not(:hover) {
       & .single-alert:not(.appeared, .closed) {
         height: spacing.$spacing-xxs !important; // !important to override element.style
         overflow: hidden;

         & + .single-alert:not(.appeared, .closed) {
           height: 0 !important; // !important to override element.style
         }
       }
     }
   }

   & sis-icon {
     & .lg {
       padding-bottom: spacing.$pixel-3 !important;
     }
   }

   & .single-alert {
     transition: all 0.2s ease, height 0.2s ease 0.5s; // lingers while after hover and after topNav collapses
     height: 100% !important; // Override fixed height that probably comes from ngb-alert
     animation-name: drop-alert;
     animation-duration: 0.2s;
     animation-timing-function: ease-out;
     animation-iteration-count: 1;
     text-align: center;

     &.closed {
       transition: all 0.2s ease; // closing should be faster than collapse
       opacity: 0.99; // fallback, DO NOT remove: property that has the dismissAlert() 'transitionend' eventListener
       height: 0 !important; // !important to override element.style
       // otherwise it would be in some cases 'height: 0; -> height: 0;' so no transition will occur
     }

     & + .single-alert {
       & .alert-danger {
         border-top: spacing.$pixel-1 solid #a43529;
       }

       & .alert-info {
         border-top: spacing.$pixel-1 solid #0e69c3;
       }

       & .alert-success {
         border-top: spacing.$pixel-1 solid #19723f;
       }

       & .alert-warning {
         border-top: spacing.$pixel-1 solid #E5BA0E;
       }
     }

     & .alert {
       padding-right: spacing.$spacing-lg;
       letter-spacing: spacing.$pixel-1;
       color: colors.$white;
       font-weight: 400;

       & .alert-button {
         border: spacing.$spacing-zero;
         background-color: transparent;
         cursor: pointer;
         padding: 0.5rem spacing.$spacing-sm;
         width: 100%;
         text-align: center;
         text-decoration: underline;
         color: colors.$white;
         font-family: inherit;

         &:focus, &:focus-visible {
           @include focus.deprecated-sisu-focus-generic-inner;
         }
       }

       &.alert-danger {
         background-color: colors.$red;
       }

       &.alert-info {
         background-color: colors.$primary-color;
       }

       &.alert-success {
         background-color: colors.$green;
       }

       &.alert-warning {
         background-color: colors.$yellow;
         color: colors.$dark-gray;

         & .close::after {
           color: colors.$dark-gray;
         }

         & .alert-button {
           color: colors.$dark-gray;
         }
       }

       & .alert-text {
         display: block;
         margin-bottom: spacing.$spacing-zero; // Override ngb-alert p style
         border: spacing.$spacing-zero;
         padding: 0.5rem spacing.$spacing-sm;
       }

       & .btn-close {
         opacity: 1;
         background-color: rgb(255 255 255 / 45%);
         background-size: 40%;
         width: spacing.$spacing-lg;
         height: 100%;

         &:focus {
           @include focus.fudis-focus-generic;
         }
       }
     }

     @media (max-width: breakpoints.$screen-sm-max) {
       text-align: left;
     }
   }
  }
}

@keyframes drop-alert { // alert's appearing animation
  0% {
    transform: translateY(-3rem);
  }

  100% {
    transform: translateY(0);
  }
}

body.modal-open {
  & sis-alerts {
    & .alerts {
      top: 0;
      z-index: 9999;
    }
  }
}

