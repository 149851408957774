sis-assessment-item-cur-selection {
  sis-radio-button > label.control.control-radio {
    margin-bottom: 0;
  }

  .cur-selection-row {
    & > * {
      display: inline-block;
      vertical-align: middle;
    }
  }
}
