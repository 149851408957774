@use 'sis-assets/foundations/colors/colors' as colors;

sis-mark-string {
 & mark {
   border-width: 0.1rem;
   border-style: dotted;
   background-color: colors.$primary-color-light;
   padding: 0;
   font-weight: 300;
 }
}
