@use '@funidata/ngx-fudis' as fudis;
@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

label.sis-radio.control,
.control {
  &.control-radio, &.control--radio {
    align-items: end;
    margin-bottom: 1.1rem;
    padding-left: 3.5rem;
    text-transform: initial;
    line-height: 2.9rem;
    color: colors.$dark-gray;

    &.control-inline, &.control--inline {
      padding-top: spacing.$pixel-3;

      & .control-indicator, & .control__indicator {
        top: 0.5rem;
      }
    }

    & .control-indicator, & .control__indicator {
      border-radius: 50%;
      width: spacing.$spacing-md;
      height: spacing.$spacing-md;
    }

    & input {
      &:checked {
        // To align radiobutton selection better when it is selected and focused
        &:focus {
          & ~ .control-indicator, & ~ .control__indicator {
            @include fudis.focus-form-field;
          }
        }

        & ~ .control-indicator, & ~ .control__indicator {
          line-height: 2.1rem;

          &::after {
            display: block;
            position: relative;
            top: 0.5rem;
            left: 0.5rem;
            border-radius: 50%;
            background-color: colors.$dark-gray;
            width: 1.2rem;
            height: 1.2rem;
            content: "";
          }
        }
      }

      &:disabled ~ .control-indicator::after, &:disabled ~ .control__indicator::after {
        border-color: colors.$middle-gray;
      }
    }
  }

  & .control-radio > .guidance, .control-indicator {
    display: block;
  }
  & .sis-guidance {
    display: block;
  }
}

sis-radio-button:not(:has(.control-inline)),
radio:not(:has(.control-inline)) {
  display: block;
}
