@use 'sis-assets/foundations/colors/colors' as colors;

sis-any-course-unit-rule {
  .ripa-checkbox-wrapper {
    display: flex;
    align-items: center;
    margin-top: 1.5rem;

    sis-checkbox, sis-icon {
      display: inline;
    }

    sis-checkbox {
      color: colors.$white;
    }

  }
}
