@use 'sis-assets/foundations/spacing/tokens' as spacing;

sis-course-unit-substitution {
  .courseunit-substitution-group
    > sis-course-unit-box:not(:first-child) {
      div.courseunit {
        margin-top: spacing.$spacing-xxs;
      }
    }
}
