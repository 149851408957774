@forward './prerequisiteGroupEditModal';
@forward './prerequisiteGroupEditor';

prerequisite-groups, prerequisite-group-editor, sis-prerequisites {
  & .prerequisite-group-container {
    & .prerequisites:last-of-type {
      margin-bottom: 1.5rem;
    }

    & sis-course-unit-box, sis-module-box {
      & > div {
        margin-bottom: 0.5em;
      }
    }

    & > .grouping-panel {
      & + h4 {
        position: relative;
        top: -1.5rem;
        left: -1.5rem;
        margin-bottom: 0;
      }
    }
  }
}
