@forward './description-list-compact/description-list-compact';
@use 'sis-assets/foundations/breakpoints/breakpoints' as breakpoints;
@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

sis-description-list,
.sis-description-list {
  dl.fill-by-column {
    column-gap: spacing.$spacing-lg;
    columns: 35rem 2;
    margin-bottom: 0;
  }

  dl.fill-by-row {
    display: grid;
    grid-template-columns: 50% 50%;
    column-gap: spacing.$spacing-sm;
  }

  dl.fill-by-column.one-column {
    column-count: 1;
  }

  dl.fill-by-row.one-column {
    display: grid;
    grid-template-columns: 100%;
  }

  dt {
    text-transform: uppercase;
    line-height: spacing.$spacing-md;
    font-size: 1.4rem;
    font-weight: 400;
  }

  div.form-group-mimic {
    break-inside: avoid-column;
  }

  .form-group-mimic {
    color: colors.$dark-gray;

    > dt.label,
    > dt.sis-label {
      display: block;
      margin: 0 0 spacing.$spacing-xs;
    }

    > dt.label-with-tooltip-icon {
      display: inline-block;
      margin-top: 0;
      margin-right: 1rem;
      margin-bottom: spacing.$spacing-xs;
    }

    > dd {
      line-height: 2rem;
      font-size: spacing.$spacing-sm;
      font-weight: 300;
      overflow-wrap: break-word;

      & p {
        margin-bottom: 0;
      }

      /* Prevents components from having additional margins inside */

      & .form-group-mimic {
        margin-bottom: 0;

        & .form-group-mimic {
          margin-bottom: 3.8rem;
        }
      }

      /* Following bold and compact styles are used in some of the templates for mimicin sub-heading in dd element */

      > span.bold, & > p.bold {
        font-weight: 400;
      }

      > span.compact, & > p.compact, & {
        padding-bottom: spacing.$spacing-xxs;
        min-height: 2rem;
      }

      li.compact {
        padding-bottom: spacing.$spacing-xxs;
        min-height: 2rem;
      }

      @media (max-width: breakpoints.$screen-sm-max) {
        font-size: 1.4rem;
      }
    }
  }

  @media (max-width: breakpoints.$screen-lg-max) {
    dl.fill-by-row {
      grid-template-columns: 100%;
    }
  }
}
