@use 'sis-assets/foundations/colors/colors' as colors;

.dropdown {
  & .dropdown-toggle {
    &__light {
      color: colors.$light-gray;
    }

    &__disabled {
      border-color: colors.$light-gray;
      background-color: colors.$extra-light-gray;
      pointer-events: none;

      // Disabled dropdown is focusable with screen reader so let's follow the same look anyways
      &:focus {
        border-color: colors.$light-gray;
        background-color: colors.$extra-light-gray;
      }
    }
  }

  & .dropdown-menu {
    & .dropdown-item.disabled {
      // Disabled dropdown is focusable with screen reader so let's follow the same look anyways
      &:focus {
        background-color: colors.$extra-light-gray;
      }
    }
  }
}

