$timing-box-height: 5.6rem;
$planned-box-color: #EEF3F9;
$planned-box-border-color: #A0BDE3;

// mixin to fix dropdown button to match timing layout
@mixin fix-dropdown-button(){
  & .btn-group {
    & button.btn-hollow {
      margin: 0 !important;
      border-width: 0;
      padding: 0 !important;
      min-width: unset;

      &:hover {
        border-width: 0;
        background-color: transparent !important;
      }
    }

    &.dropdown ul.dropdown-menu {
      margin-right: 0 !important;
      min-width: auto;
    }
  }
}
