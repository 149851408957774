@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/focus/mixins' as focus;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

sis-frontpage-message-conversations, app-student-frontpage-message-conversations {
  & ul.message-conversations,
  & ul.frontpage-notifications {
    padding: 0;

    & li {
      border-bottom: spacing.$pixel-1 solid colors.$extra-light-gray;
      list-style: none;

      &.button-holder {
        border-width: 0;
        padding-top: 2rem;
        padding-bottom: 1rem;
        text-align: center;

        & > button {
          margin-right: 0;
        }
      }

      & > a.message {
        position: relative;
        margin: 0;
        cursor: pointer;
        padding: 0.8em 0 0;
        height: 6rem;
        line-height: 1.8rem;
        color: colors.$dark-gray;

        &::before {
          position: absolute;
          top: 2.1rem;
          left: 1.5rem;
          color: colors.$dark-gray;
          font-family: SISUicons;
          font-size: 2.6rem;
          -webkit-font-smoothing: antialiased;
        }

        &:hover, &:active {
          background-color: colors.$extra-light-gray;
          text-decoration: none;
        }

        &:focus {
          @include focus.deprecated-sisu-focus-generic-inner;
        }

        &.unread-message {
          border-bottom: spacing.$pixel-1 solid colors.$extra-light-gray;
          background-color: colors.$extra-light-gray;

          & .message-sender, & .message-title {
            font-weight: 600;
          }
        }
      }

      & .message-preview-header {
        display: inline-block;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        & .message-sender {
          font-weight: 400;
        }

        & .message-type {
          color: colors.$dark-gray;
          font-size: 1.2rem;
        }
      }

      & .message-snipped {
        display: inline-block;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        & .message-title {
          font-weight: 400;
        }

        & .message-comment {
          font-weight: 300;
        }
      }

      & .notification-additional {
        text-align: right;
        font-weight: 400;

        & .message-conversation-info {
          display: inline-block;

          & .badge.badge-secondary {
            border: spacing.$pixel-1 solid colors.$middle-gray;
            background-color: colors.$white;
            color: colors.$middle-gray;
          }
        }

        & .message-date {
          display: inline-block;
          width: 7rem;
        }

        & .message-time {
          display: block;
          color: colors.$middle-gray;
          font-size: 1.2rem;
        }
      }
    }
  }
}
