@use 'sis-assets/foundations/breakpoints/breakpoints' as breakpoints;
@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

search-filter-menu-item, search-filter-tag, sis-search-filter-tag {
  & sis-popover {
    display: block;
    width: 100%;

    & > span {
      display: inline-block;
      width: 100%;
      height: 100%;
    }

    & .popover {
      border-radius: 0;
      box-shadow: none;
      background: colors.$extra-light-gray none repeat scroll 0 0;
      min-width: 34rem;
      filter: drop-shadow(spacing.$pixel-1 spacing.$pixel-2 rgb(0 0 0 / 20%));

      &.bs-popover-top {
        & .arrow::after {
          border-top-color: colors.$extra-light-gray;
        }
      }

      &.bs-popover-bottom {
        & .arrow::after {
          border-bottom-color: colors.$extra-light-gray;
        }
      }

      &.bs-popover-left {
        & .arrow::after {
          border-left-color: colors.$extra-light-gray;
        }
      }

      &.bs-popover-right {
        & .arrow::after {
          border-right-color: colors.$extra-light-gray;
        }
      }

      & .popover-body {
        margin: 0;
        padding: 0 spacing.$spacing-xxs;

        & .search-filter {
          &.education-filter,
          &.course-unit-filter {
            & li {
              &.parent-value {
                position: relative;
                min-height: 2.5rem;
                font-weight: 400;

                & > button {
                  position: absolute;
                  margin-right: 0;
                  width: 10%;
                }

                & > .parent-value-text {
                  position: relative;
                  top: 0.5rem;
                  margin-left: 3.6rem;
                  padding-bottom: spacing.$pixel-2;
                }

                & + .child-value::before {
                  top: spacing.$pixel-2;
                  height: 1.2rem;
                }
              }

              &.child-value {
                position: relative;
                padding-top: spacing.$pixel-2;
                font-weight: 400;

                &::before {
                  position: absolute;
                  top: -0.9rem;
                  left: 3.5rem;
                  border-width: spacing.$pixel-1;
                  border-style: none none dotted dotted;
                  border-color: colors.$silver;
                  width: 0.6rem;
                  height: 2.3rem;
                  content: "";
                }

                & > button {
                  margin-top: -0.3rem;
                  width: 10%;

                  & sis-icon[icon="delete"] svg {
                    width: spacing.$spacing-md;
                    height: spacing.$spacing-md;
                  }
                }
              }
            }
          }

          &.organisation-filter {
            & .open-organisation-selector-with-text {
              margin: 0;
              border-radius: spacing.$pixel-1; // fix odd transparency caused by border-radius: 0;
            }
          }

          &.education-filter {
            & .open-education-selector-with-text {
              margin: 0.5rem 0 0;
            }
          }

          & .filter-guidance {
            margin: 0 1.5rem spacing.$spacing-xs 0;
            max-width: 28rem; // so guidance won't unnecessarily grow the popover's width
            font-size: 1rem;
          }

          & .dropdown-menu {
            bottom: unset;
            max-width: 100%;
            overflow-x: hidden;
          }

          & ngb-datepicker.dropdown-menu {
            max-width: unset;
            max-height: unset;
          }

          & ul.filter-selectbox {
            margin: 0;
            border: spacing.$pixel-1 solid colors.$light-gray;
            background-color: colors.$white;
            cursor: default;
            padding: 0;
            height: 19.5rem;
            overflow: auto;

            &.selectbox-multi > li > ul.filter-with-heading {
              padding-left: 0;
              list-style: none;
            }

            &.selectbox-multi > li,
            &.selectbox-multi > li > ul.filter-with-heading > li {
              & > label.control.control-checkbox {
                margin-bottom: 0;
                padding: spacing.$spacing-xxs 1rem spacing.$spacing-xxs 3.6rem;
                line-height: spacing.$spacing-sm;
                font-size: 1.4rem;

                &.active, &:active, &:hover {
                  background-color: colors.$primary-color-light;
                }

                & > .control-indicator, & > .control__indicator {
                  top: spacing.$spacing-xxs;
                  margin-left: 1rem;
                  line-height: 1.4rem;

                  &::after {
                    margin-top: spacing.$pixel-3;
                    margin-left: spacing.$pixel-3;
                    width: 2rem;
                    height: 2rem;
                    font-size: 2.1rem;
                  }
                }
              }
            }

            & > li.dropdown-header {
              padding: spacing.$spacing-xxs 0 spacing.$spacing-xxs 1rem;
            }

            & > li.filter-divider {
              position: relative;

              & hr {
                position: absolute;
                right: 0;
                bottom: 50%;
                left: 0;
                margin: 0;
              }
            }
          }

          & .help-block {
            line-height: spacing.$spacing-sm;

            & + .filter-preview-row {
              padding-top: 0.5rem;
            }

            & p {
              margin: 0;
            }
          }

          & ul.error-list {
            margin: 0.5rem 0 -0.5rem;
            padding: 0;
            list-style-type: none;
            color: colors.$red;
            font-size: 1.2rem;

            & li {
              margin-bottom: spacing.$pixel-2;
            }
          }

          & .filter-preview-row {
            padding-top: 1rem;

            & .filter-preview-holder {
              margin-right: 0.9rem;
              border: spacing.$pixel-3 solid colors.$white;
              padding: spacing.$pixel-3;
              height: 100%;
              min-height: 3.5rem;
              text-overflow: ellipsis;

              & .filter-preview-title {
                display: block;
                line-height: 1.2rem;
                color: colors.$middle-gray;
                font-size: 1.0rem;
              }

              & .filter-preview-content {
                display: block;
                width: 17.2rem;
                height: 1.2rem;
                overflow: hidden;
                text-overflow: ellipsis;
                line-height: 1.3rem;
                white-space: nowrap;
                color: colors.$dark-gray;
                font-size: 1.1rem;

                & .item-separator {
                  text-overflow: ellipsis;

                  &::before {
                    content: ", ";
                  }
                }
              }
            }

            & .filter-submit {
              width: 100%;
              height: 3.6rem;
            }
          }
        }
      }

      @media (max-width: breakpoints.$screen-sm-max) {
        max-width: 31rem;
      }
    }
  }
}
