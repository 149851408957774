@use 'sis-assets/foundations/breakpoints/breakpoints' as breakpoints;
@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

app-plan-select {
  & .choose-plan {
    & .dropdown {
      display: inline-block;
    }

    & h1 {
      margin-bottom: 0;
    }

    &__button {
      overflow: hidden;
      white-space: pre-line;

      &__title {
        flex: 1;

        & .tiny-badge {
          display: inline-block;
          vertical-align: 50%;
        }
      }

      &__icon {
        flex: 0;
      }
    }
  }

  & .plan-description {
    color: colors.$middle-gray;
    font-size: 1.2rem;
  }
}

.collapsed {
  & app-plan-select {
    & .choose-plan {
      &__button {
        display: inline-block;
        padding-right: spacing.$spacing-md;
        max-width: calc(100vw - 3rem);
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  plan {
    & app-plan-select {
      & .choose-plan {
        & .dropdown-menu {
          @media (min-width: breakpoints.$screen-sm-min) {
            max-width: calc(100vw - 30rem);
          }
        }
      }
    }
  }
}
