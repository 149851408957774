@use 'sis-assets/foundations/spacing/tokens' as spacing;

calendar-event-tooltip, .calendar-event-tooltip, app-student-calendar-event-tooltip {
  text-align: left;

  & .tooltip-inner {
    max-width: 25rem;
  }

  & .tooltip-header {
    margin-bottom: spacing.$pixel-3;
    font-weight: 400;

    & .tiny-badge {
      margin-left: 0;
    }
  }

  & .study-group-set-name, .tooltip-event-own {
    margin-top: 0.5rem;
  }

  & .event-details {
    margin-top: 1rem;

    & > div {
      clear: both;
      line-height: 2rem;
    }

    & sis-icon[icon="person-small"], sis-icon[icon="info-small"], sis-icon[icon="pin-small"]  {
      float: left; // NOSONAR
      margin-left: -0.4rem;
    }

    & .event-info-missing {
      font-style: italic;
    }
  }
}
