@use "@funidata/ngx-fudis" as fudis;
@use 'sis-assets/foundations/breakpoints/breakpoints' as breakpoints;
@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;
@use './button-mixins' as buttons;

$chevron-left: "data:image/svg+xml,%3Csvg width='32' height='32' fill='%231076db' class='hidden' preserveAspectRatio='xMidYMin' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' id='chevron'%3E%3Cpath d='M13.414 10.133a.619.619 0 0 0-.434.18.61.61 0 0 0 0 .865L17.818 16l-4.838 4.822a.61.61 0 0 0 0 .865.615.615 0 0 0 .868 0l5.102-5.085a.848.848 0 0 0 0-1.204l-5.102-5.085a.617.617 0 0 0-.434-.18' transform='rotate(180 16 16)'/%3E%3C/svg%3E";
$chevron-right: "data:image/svg+xml,%3Csvg width='32' height='32' fill='%231076db' class='hidden' preserveAspectRatio='xMidYMin' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' id='chevron'%3E%3Cpath d='M13.414 10.133a.619.619 0 0 0-.434.18.61.61 0 0 0 0 .865L17.818 16l-4.838 4.822a.61.61 0 0 0 0 .865.615.615 0 0 0 .868 0l5.102-5.085a.848.848 0 0 0 0-1.204l-5.102-5.085a.617.617 0 0 0-.434-.18Z'/%3E%3C/svg%3E%0A";

app-full-calendar {
  full-calendar.fc {
    .fc-toolbar-title {
      display: inline;
      margin-inline-start: spacing.$spacing-md;
      vertical-align: middle;
    }

    .fc-toolbar.fc-header-toolbar {
      margin-bottom: 0;
      padding-bottom: 1.5em;
    }

    .fc-prev-button {
      margin-inline-start: spacing.$spacing-md;
    }

    .fc-next-button {
      margin-inline-start: spacing.$spacing-xs;
    }

    // custom chevrons for the previous and next buttons to browse dates
    .fc-button.fc-button-primary.fc-next-button,
    .fc-button.fc-button-primary.fc-prev-button {
      &::before {
        position: relative;
        right: 0;
        bottom: -10%;
      }
    }

    .fc-button.fc-button-primary.fc-next-button::before {
      content: url($chevron-right);
    }

    .fc-button.fc-button-primary.fc-prev-button::before {
      content: url($chevron-left);
    }

    // replace FC primary button styles with Sisu styles using mixins
    .fc-button.fc-button-primary {
      @include buttons.sis-button-lg;
      @include buttons.sis-button-hollow;

      // hollow buttons don't change colors when active
      &:active {
        @include buttons.sis-button-hollow;
      }

      // except for view (month, week) buttons - they turn primary blue when active
      &.fc-dayGridMonth-button,
      &.fc-timeGridWeek-button {
        &.fc-button-active {
          @include buttons.sis-button-primary;
        }
      }
    }

    // remove outline and shading native to FC - either we don't want it, or we apply our own focus styles
    .fc-button.fc-button-primary:active:focus,
    .fc-button.fc-button-primary:active:hover,
    .fc-button-primary:not(:disabled).fc-button-active:focus,
    *:focus:not(:focus-visible){
      outline: none;
      box-shadow: none;
    }

    // week and month buttons: underline the selected choice
    .fc-timeGridWeek-button.fc-button-active, .fc-dayGridMonth-button.fc-button-active {
      text-decoration: underline;
    }

    table {
      line-height: 1.2857; // 18/14
      font-size: 1.4rem;

      // specific selectors for overriding FC's styling of dates, time, numbers
      a.fc-col-header-cell-cushion, a.fc-daygrid-week-number, a.fc-timegrid-axis-cushion, a.fc-daygrid-day-number, .fc-timegrid-slot-label-cushion,
      a.fc-col-header-cell-cushion:hover, a.fc-daygrid-week-number:hover, a.fc-timegrid-axis-cushion:hover, a.fc-daygrid-day-number:hover {
        text-decoration: none;
        color: colors.$dark-gray;
        font-weight: 400;

        // make table headers (dates) to wrap vertically on small screen to avoid height differences
        @include fudis.breakpoint-max('sm') {
          display: table-caption;
        }
      }

      td .fc-day-today a.fc-daygrid-day-number,
      th.fc-day-today a.fc-col-header-cell-cushion {
        text-decoration: underline; // underline today's date

      }

      th.fc-day {
        overflow: hidden; // weekday names overflow on smaller screens
      }

      td {
        border: spacing.$pixel-1 solid colors.$light-gray;

        .fc-daygrid-day.fc-day-today,
        .fc-timegrid-col.fc-day-today {
          background-color: colors.$primary-color-light;
        }

        .fc-day-sat, .fc-day-sun, .fc-day-other { // saturday, sunday, days outside current month are gray
          background-clip: padding-box; // makes cell borders appear on top of the cells, otherwise they're not visible
          background-color: colors.$extra-light-gray;
        }
      }
    }

    // the event boxes in the calendar
    .fc-event {
      .event-button {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        border: none;
        background: none;
        cursor: pointer;
        padding: 0 0 0 spacing.$spacing-xxs;
        width: 100%;
        height: 100%;
        font-size: 1.2rem;

        &.time-grid-week {
          justify-content: start;
          overflow: hidden;
        }

        &.day-grid-month {
          justify-content: center;
        }

        // text overflow control for content rows
        & .event-info-row {
          max-width: 100%;
          min-height: spacing.$spacing-sm;
          overflow: hidden;
          text-overflow: "";

          // hide any overflow in event information rows
          white-space: nowrap;

          &.time-grid-week {
            &__alert {
              min-height: spacing.$spacing-lg;
            }
          }

          &.time-grid-week:last-child { // but if there is any space left in the event box after the last row, use it
            overflow-y: hidden;
            text-align: left;
            white-space: normal;
          }
        }
      }

      &.study-event,
      &.own-calendar-event {
        border-radius: spacing.$pixel-2;
      }

      &.own-calendar-event {
        border-color: colors.$dark-gray;
        background-color: colors.$light-gray;

        & .event-button {
          color: colors.$dark-gray;
        }
      }

      &.study-event {
        &.scheduled {
          border-color: colors.$white;
          background-color: colors.$primary-color;

          & .event-button {
            color: colors.$white;
          }
        }

        &.cancelled {
          border-style: dashed;
          border-color: colors.$red;
          background-color: colors.$karviainen-extra-light;

          & .event-button {
            color: colors.$dark-gray;
          }
        }

        &.not-enrolled {
          border-style: dashed;
          border-color:  colors.$primary-color-dark;
          background-color: colors.$primary-color-light;

          & .event-button {
            color: colors.$dark-gray;
          }
        }
      }
    }

    .fc-timegrid-col-events .fc-timegrid-event-harness:has(button.event-button:focus-visible),
    .fc-daygrid .fc-event:has(button.event-button:focus-visible) {
      z-index: 99 !important; // The z-index of events grows for each event. This guarantees that the focus-outline is fully visible.
    }

    .fc-timegrid-event-harness-inset .fc-event.fc-timegrid-event {
      box-shadow: none; // Removes shading applied by FullCalendar https://github.com/fullcalendar/fullcalendar/issues/6512
    }
  }

  @media(max-width: breakpoints.$screen-md-max) {
    .fc-toolbar.fc-header-toolbar {
      display: flex;
      flex-direction: column-reverse;
    }

    .fc-toolbar-chunk {
      padding-bottom: spacing.$spacing-xxs;
      text-align: center;

      & h2 {
        display: block !important;
        padding-top: spacing.$spacing-xs;
      }
    }
  }
}
