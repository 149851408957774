sis-local-date-time-editor {
  & .local-date-time-date {
    padding-right: 0;
  }

  & .local-date-time-time {
    & sis-local-time-editor {
      & label {
        margin-bottom: 0;
      }
    }
  }
}
