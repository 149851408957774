@use 'sis-assets/foundations/breakpoints/breakpoints' as breakpoints;
@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;
@use 'sis-assets/foundations/focus/mixins' as focus;

// TODO: Refactor all tab styles

/* stylelint-disable */

@mixin nav-tabs-to-nav-wide {
  margin-left: 0;
  border: 0;

  & li {
    margin: 0;

    &:first-child {
      margin-left: 0;
    }

    & a,
    & button {
      margin: 0;
      border-radius: 0;
      height: auto;
    }
  }
}

@mixin nav-wide {
  & li {
    width: 100%;
    text-align: center;
    font-weight: 400;

    &:first-child {
      border-radius: 0.5rem 0.5rem 0 0;

      & a,
      & button {
        border-radius: 0.5rem 0.5rem 0 0;
      }
    }

    &:last-child {
      border-radius: 0 0 0.5rem 0.5rem;

      & a,
      & button {
        border-bottom: spacing.$pixel-1 solid colors.$primary-color;
        border-radius: 0 0 0.5rem 0.5rem;
      }
    }

    & a,
    & button {
      display: block;
      position: relative;
      border: spacing.$pixel-1 solid colors.$primary-color;
      border-bottom-width: 0;
      background-color: colors.$white;
      padding: 0.6rem 1.5rem;
      width: 100%;
      text-align: center;
      text-decoration: none;
      line-height: 1.7rem;
      color: colors.$primary-color;

      &:focus {
        @include focus.deprecated-sisu-focus-generic-inner;

        border: spacing.$pixel-1 solid transparent;
      }
    }

    &.active {
      & a,
      & button {
        background-color: colors.$primary-color;
        cursor: default;
        text-decoration: underline;
        color: colors.$white;

        &::before {
          position: absolute;
          top: 0.9rem;
          left: 1.0rem;
          border-width: 0.6rem;
          border-style: solid;
          border-color: transparent;
          border-left-color: colors.$white;
          width: 0;
          height: 0;
          content: " ";
          pointer-events: none;
        }
      }
    }

    &:not(.active) {
      & a,
      & button {
        &:hover, &:active {
          background-color: colors.$extra-light-gray;
        }
      }
    }
  }
}

.nav.nav-wide {
  @include nav-wide;
}

ul.nav.nav-tabs {
  border-bottom: spacing.$pixel-1 solid colors.$dark-gray;
  font-size: 1.4rem;
  font-weight: 500;

  &.second-level {
    margin-top: 0.5rem;
  }

  &.nav-invert {
    background-color: rgb(0 0 0 / 0%);

    & li {
      &:not(.active) {
        & > a,
        & > button {
          &:hover,  &:active {
            background-color: colors.$primary-color-dark;
          }
        }
      }

      &.active {
        & a,
        & button {
          outline: none;
          border: spacing.$pixel-1 solid colors.$dark-gray;
          background-color: colors.$dark-gray;
          text-decoration: underline;
        }
      }

      & > a,
      & > button {
        outline: none;
        border: spacing.$pixel-1 solid colors.$extra-light-gray;
        border-bottom-color: colors.$primary-color;
        background-color: colors.$primary-color;
        color: colors.$extra-light-gray;
      }
    }
  }

  & li {
    margin-right: spacing.$pixel-3;
    margin-left: spacing.$pixel-3;

    &:not(.active) {
      & > a,
      & > button {
        &:hover, &:active {
          background-color: colors.$extra-light-gray;
        }
      }
    }

    &:first-child {
      margin-left: 1.8rem;
    }

    & a,
    & button {
      display: block;
      position: relative;
      margin-right: spacing.$pixel-2;
      border: spacing.$pixel-1 solid colors.$dark-gray;
      border-bottom-width: 0;
      border-radius: spacing.$pixel-3 spacing.$pixel-3 0 0;
      padding: 1.0rem 1.5rem;
      height: 3.9rem;
      text-decoration: none;
      line-height: 1.8rem;
      color: colors.$dark-gray;
      font-size: 1.4rem;
      font-weight: 500;
    }

    &.active {
      & a,
      & button {
        z-index: 9;
        border: spacing.$pixel-1 solid colors.$primary-color;
        border-bottom-width: 0;
        background-color: colors.$primary-color;
        cursor: default;
        text-decoration: underline;
        color: colors.$white;

      }
    }
  }

  &.nav-wide-xxs {
    @media (max-width: breakpoints.$screen-xs-max) {
      @include nav-wide;
      @include nav-tabs-to-nav-wide;
    }
  }

  &.nav-wide-xs {
    @media (max-width: breakpoints.$screen-xs-max) {
      @include nav-wide;
      @include nav-tabs-to-nav-wide;
    }
  }

  &.nav-wide-sm {
    @media (max-width: breakpoints.$screen-md-max) {
      @include nav-wide;
      @include nav-tabs-to-nav-wide;
    }
  }

  &.nav-wide-md {
    @media (max-width: breakpoints.$screen-lg-max) {
      @include nav-wide;
      @include nav-tabs-to-nav-wide;
    }
  }
}

.nav.nav-pills {
  margin-left: spacing.$spacing-sm;
  font-size: 1.2rem;
  font-weight: 600;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  & li {
    margin-right: spacing.$pixel-3;
    margin-bottom: 1.5rem;
    margin-left: spacing.$pixel-3;
    min-height: 2.5rem;

    &:not(.active) {
      & a,
      & button {
        &:hover, &:active {
          background-color: colors.$light-gray;

          & .tail {
            border-left: spacing.$pixel-1 solid colors.$middle-gray;
          }
        }
      }
    }

    &.has-tail {
      &.active {
        & a,
        & button {
          display: table;
          text-decoration: underline;

          & .tail {
            display: table-cell;
            float: right;
            border-left: spacing.$pixel-1 solid colors.$silver;
          }
        }
      }

      & a,
      & button {
        display: table;
        padding: 0 spacing.$spacing-xs 0 1.5rem;

        & .tail {
          display: table-cell;
          float: right;
          margin-left: 1.2rem;
          border-left:  spacing.$pixel-1 solid colors.$light-gray;
          padding-left: spacing.$spacing-xs;
          height: 100%;
          font-weight: 600;
        }
      }
    }

    &.active {
      & a,
      & button {
        background-color: colors.$middle-gray;
        cursor: default;
        text-decoration: underline;
        color: colors.$white;
      }
    }

    &.add-new {
      & a,
      & button {
        border: spacing.$pixel-1 dashed colors.$primary-color;
        background-color: colors.$white;
        height: spacing.$spacing-md;
        color: colors.$primary-color;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;

        &:hover, &:active {
          background-color: colors.$extra-light-gray !important;
          overflow: hidden;
        }

        & sis-icon[icon="plus"] svg {
          margin: -0.4rem 0 0 -0.8rem;
          vertical-align: top;
        }
      }
    }

    & a,
    & button {
      display: block;
      position: relative;
      border-radius: spacing.$pixel-2;
      background-color: colors.$extra-light-gray;
      padding: 0 2.5rem;
      text-decoration: none;
      color: colors.$dark-gray;

      & .badge {
        position: absolute;
        top: -1.4rem;
        right: 0;
        background-color: colors.$white;

        &.badge-accent {
          border: spacing.$pixel-1 solid colors.$accent;
          color: colors.$accent;
        }
      }
    }
  }


  // Styles for nav-pills on mobile
  @media (max-width: breakpoints.$screen-xs-max) {
    @include nav-tabs-to-nav-wide;

    & li {
      border-top: spacing.$pixel-1 solid colors.$middle-gray;
      border-right: spacing.$pixel-1 solid colors.$middle-gray;
      border-left: spacing.$pixel-1 solid colors.$middle-gray;
      width: 100%;
      min-height: 3rem;

      &:hover {
        & a,
        & button {
          border: spacing.$pixel-1 solid colors.$light-gray;
        }
      }

      &:first-of-type {
        border-radius: 0.5rem 0.5rem 0 0;

        & a,
        & button {
          border-radius: 0.5rem 0.5rem 0 0;
        }
      }

      &:last-of-type {
        border-bottom: spacing.$pixel-1 solid colors.$middle-gray;
        border-radius: 0 0 0.5rem 0.5rem;

        & a,
        & button {
          border-radius: 0 0 0.5rem 0.5rem;
          height: 100%;
        }
      }

      & a,
      & button {
        border: spacing.$pixel-1 solid colors.$extra-light-gray;
        width: 100%;
        height: 100%;
        text-align: center;

        & .tail {
          width: spacing.$spacing-lg;
        }
      }

      &.active {
        & a,
        & button {
          border: spacing.$pixel-1 solid colors.$middle-gray;
          text-decoration: underline;
        }
      }
    }
  }
}
