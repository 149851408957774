@use '@funidata/ngx-fudis' as fudis;
@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

sis-study-box {
  & .study-wrapper {
    border: spacing.$pixel-1 solid colors.$dark-gray;
    max-width: 40rem;
    line-height: 2rem;

    &.dotted {
      border: dotted;
    }

    &.selected {
      @include fudis.focus-form-field;
    }

    & .study-header {
      background-color: colors.$primary-color-light;
      padding: spacing.$spacing-xxs spacing.$spacing-xs;

      &.attained {
        background-color: colors.$extra-light-gray;
      }
    }

    & .study-content {
      padding: spacing.$spacing-xxs spacing.$spacing-xs;
      word-wrap: break-word;
      font-size: 1.2rem;

      &-header {
        display: flex;
        justify-content: space-between;
      }

      &-badges {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        margin-left: spacing.$spacing-xxs;
      }

      & .tiny-badge {
        float: right;
        margin-top: spacing.$pixel-1;
        margin-right: 0;
      }

      & sis-icon[icon="achievement"] svg,
      & sis-icon[icon="info"] svg {
        margin-top: -0.6rem;
        margin-left: -0.5rem;
        width: 2.6rem;
        height: 2.6rem;
      }

      & .attainment-info {
        display: flex;
        justify-content: space-between;

        @include fudis.breakpoint-max('sm') {
          display: block;
        }
      }
    }
  }
}
