@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

ng-select {
  &.ng-select {
    &:not(.ng-select-disabled) > .ng-select-container {
      cursor: pointer;

      & .ng-value-container .ng-input > input {
        cursor: pointer;
      }
    }

    &.ng-select-opened > .ng-select-container {
      border: spacing.$pixel-2 solid colors.$primary-color;
    }

    &.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
      border: spacing.$pixel-2 solid colors.$primary-color;
      box-shadow: none;
    }

    &.ng-select-clearable {
      & .ng-has-value > .ng-value-container::after {
        display: none;
      }
    }

    &.ng-select-single {
      & .ng-select-container {
        height: spacing.$spacing-xl;
      }
    }

    & .ng-select-container {
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      border: spacing.$pixel-1 solid colors.$middle-gray;
      border-radius: 0;
      padding: spacing.$pixel-1;
      font-size: spacing.$spacing-sm;
      font-weight: 400;

      & .ng-arrow-wrapper .ng-arrow {
        border: 0;
      }

      & .ng-value-container {
        &::after {
          display: inline-block;
          position: absolute;
          right: 1.3rem;
          color: colors.$dark-gray;
          font-family: SISUicons;
          font-size: spacing.$spacing-md !important;
          font-weight: 300;
          -webkit-font-smoothing: antialiased;
          font-style: normal;
          content: "W";
        }
      }

      & .ng-clear-wrapper {
        left: spacing.$spacing-md;
        z-index: 1;
        background-color: colors.$white;
        padding: 1rem 1.2rem;
        width: 3.5rem;
        min-width: unset;
        height: 4.2rem;
        text-align: left;

        &::before {
          text-transform: none;
          color: colors.$primary-color;
          font-family: SISUicons;
          font-size: spacing.$spacing-md;
          font-style: normal;
          content: 'c';

          @supports (content: 'x' / 'y') {
            content: 'c' / 'remove';
          }
        }

        & .ng-clear {
          display: none;
        }
      }
    }

    & .ng-dropdown-panel {
      border: spacing.$pixel-2 solid colors.$light-gray;
      border-radius: 0;
      box-shadow: 0 spacing.$pixel-2 0 rgb(0 0 0 / 30%);
      min-width: min-content;

      & .ng-dropdown-panel-items {
        padding: 0.5rem 0;
        max-height: 30vh;

        & .ng-option {
          padding: 1rem 2rem;
          min-height: spacing.$spacing-lg;
          line-height: 1.125rem;
          color: colors.$dark-gray;

          &:active:not(.ng-option-disabled) {
            outline: -webkit-focus-ring-color auto spacing.$pixel-1;
            background-color: colors.$primary-color;
            text-decoration: underline;
            color: colors.$white;
          }

          &:last-child {
            border-radius: 0;
          }

          &.ng-option-marked:hover, &:hover {
            background-color: colors.$primary-color-light;
          }

          &.ng-option-marked {
            background-color: transparent;
          }

          &.ng-option-selected {
            background-color: colors.$primary-color;
            color: colors.$white;

            &:hover {
              background-color: colors.$primary-color;
            }
          }

          &.ng-option-disabled {
            cursor: default;
            color: colors.$light-gray !important;
          }

          & .multiple-language-versions {
            white-space: normal;
          }

          & .ng-option-label {
            font-weight: 400 !important;
          }
        }
      }
    }

    & .additional-info {
      right: 0;
      margin-left: 1rem;
      padding: 0;
    }
  }
}

