@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;
@use 'sis-assets/foundations/typography/tokens' as typography;
@use '../mixins' as top-navigation;

sis-logout {
  & .logout {
    @include top-navigation.settings-menu-group;

    border: spacing.$spacing-zero;

    &__item {
      padding: spacing.$spacing-sm spacing.$spacing-md !important;
      text-transform: uppercase;
      text-decoration: none;
      line-height: typography.$body-text-sm-line-height;
      color: colors.$primary-color;
      font-size: typography.$body-text-sm-font-size !important;
      font-weight: typography.$font-weight-medium;

      &:active,
      &:hover,
      &:focus {
        color: colors.$primary-color !important;
      }
    }
  }
}
