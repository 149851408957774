@use 'sis-assets/foundations/breakpoints/breakpoints' as breakpoints;
@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/typography/tokens' as typography;

sis-sortable-table-column-header {
  & .non-button.sortable-table-column-header {
    display: flex;
    align-items: center;
    text-transform: uppercase;
    color: colors.$dark-gray;
    font-size: typography.$heading-xxs-font-size;
    font-weight: typography.$font-weight-regular;

    @media (max-width: breakpoints.$screen-sm-max) {
      font-size: typography.$body-text-sm-font-size;
    }
  }
}
