@use 'sis-assets/foundations/colors/colors' as colors;

sis-loading-screen {
  & .loading-screen-container {
    position: fixed;
    top: 0;
    left: 0;
    opacity: 0.9;
    z-index: 99999;
    background-color: colors.$white;
    width: 100vw;
    height: 100vh;
  }

  & .loading-screen-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  & .spinner-border {
    margin-bottom: 2rem;
    border: 0.6em solid colors.$primary-color;
    border-right-color: transparent;
    width: 6rem;
    height: 6rem;
  }
}
