@use 'sis-assets/foundations/breakpoints/breakpoints' as breakpoints;
@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

app-profile-enrolments {
  display: block;
  margin-top: spacing.$spacing-md;

  & table.styled-table.table {
    display: block;
    overflow-x: auto;

    & > thead {
      & > tr {
        & > th {
          padding-top: 0.5rem;

          &.without-sorter {
            vertical-align: middle;
            line-height: 2.3rem;
          }
        }
      }
    }

    & td {
      word-break: break-word;
    }
  }

  .spinner-border {
    margin-bottom: 2rem;
    border: 0.6em solid colors.$primary-color;
    border-right-color: transparent;
    width: 6rem;
    height: 6rem;
  }
}
