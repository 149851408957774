@use '@funidata/ngx-fudis' as fudis;
@use 'sis-assets/fonts/icon-fonts/icons' as font;
@use 'sis-assets/foundations/focus/tokens' as focus;
@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;
@use 'sis-assets/foundations/typography/tokens' as typography;

// Local styles for the medium-editor plugin used in localized- & markup string editors

.medium-editor-toolbar {
  display: none;
  float: left;
  visibility: visible;
  margin-top: -0.2rem;
  border: none;

  & button:focus {
    @include fudis.focus-generic;

    outline: focus.$fudis-focus-generic-outline !important;
  }

  & .medium-editor-toolbar-actions {
    background: colors.$primary-color;

    & li {
      & .medium-editor-action {
        display: flex;
        align-items: center;
        justify-content: center;
        border-right: spacing.$pixel-1 solid colors.$white;
        padding: 1rem;
        min-width: spacing.$spacing-xl;
        height: spacing.$spacing-xl;
        font-family: typography.$font-family;

        &:hover {
          background: colors.$middle-gray;
        }

        & .subscript {
          vertical-align: sub;
          font-size: spacing.$spacing-xs;
        }

        & .superscript {
          vertical-align: super;
          font-size: spacing.$spacing-xs;
        }

        &.medium-editor-button-active {
          background: colors.$middle-gray;
        }

        &.medium-editor-button-last {
          border-right-width: 0;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }

        &.medium-editor-button-first {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
      }
    }
  }

  &.medium-toolbar-arrow-under {
    &::after {
      display: none;
    }
  }

  & .medium-editor-toolbar-form {
    background: colors.$primary-color;

    & .medium-editor-toolbar-input {
      background: colors.$primary-color;
      height: spacing.$spacing-xl;
      font-family: typography.$font-family;

      &:active {
        background: colors.$primary-color-dark;
      }
    }

    & a {
      position: relative;
      margin: spacing.$pixel-1 0 0 0;
      width: spacing.$spacing-xl;
      height: 3.9rem;
      vertical-align: top;
      line-height: typography.$body-text-lg-line-height;

      &:hover, &:active, &:focus, &:visited {
        text-decoration: none;
        color: colors.$white;
      }

      &:active {
        background: colors.$primary-color-dark;
      }
    }

    & .medium-editor-toolbar-save {
      @include font.SISUicon-Check;

      font-family: SISUicons, sans-serif;
      font-size: 0;

      &::before {
        position: absolute;
        top: 0.9rem;
        left: 1.2rem;
        font-size: spacing.$spacing-md;
      }
    }

    & .medium-editor-toolbar-close {
      @include font.SISUicon-Close;

      font-family: SISUicons, sans-serif;
      font-size: 0;

      &::before {
        position: absolute;
        top: 0.9rem;
        left: 1.5rem;
        font-size: spacing.$spacing-md;

      }
    }
  }
}

.medium-editor-toolbar-active {
  display: inline-block;
}

.medium-editor-anchor-preview,
.medium-editor-anchor-preview-active {
  &::before {
    border-color: transparent transparent colors.$dark-gray transparent !important;
  }

  & .medium-editor-toolbar-anchor-preview {
    opacity: 0.9;
    border-radius: spacing.$pixel-3;
    background: colors.$dark-gray;
    padding: 0.6rem 1.2rem;
    font-family: typography.$font-family;
    font-size: 1.4rem;
  }

  & .medium-editor-toolbar-anchor-preview-inner {
    margin: 0;
  }

  a:active, a:hover, a:visited {
    color: colors.$extra-light-gray;
  }
}

[data-medium-editor-element] {
  min-height: 3rem;
  overflow-wrap: break-word;
}
