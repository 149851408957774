@use 'sis-assets/foundations/breakpoints/breakpoints' as breakpoints;
@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

sis-editable-attainment-tree-grouping-module {
  & .editable-attainment-tree-group-node-row {
    margin: 0;
    border-bottom: spacing.$pixel-1 solid colors.$extra-light-gray;
    padding: 1rem 0;

    & .name {
      text-transform: uppercase;
      font-weight: 400;

      &.removed-grouping-module {
        & > .grouping-node-name {
          text-decoration: line-through;
          color: colors.$middle-gray;
        }
      }

      & sis-localized-string {
        display: inline-block;
        height: 1.7rem;
        text-transform: none;
        white-space: nowrap;

        & > .row > .col-md-12 {
          margin-top: -0.3rem;
          margin-left: 1.5rem;
          padding-left: 0;
        }

        & .nav.nav-pills-localize {
          bottom: spacing.$pixel-3;
        }

        & .form-control-static {
          display: none;
        }
      }

      & > button, & > sis-button {
        display: inline-block;
        margin-left: 1.5rem;
        text-transform: none;
        font-weight: 300;
        -webkit-font-smoothing: auto;
        -moz-osx-font-smoothing: auto;
      }

      & sis-language-pills button {
        margin-left: 0;
      }

      & .form-in-edit {
        & form {
          display: inline-block;
          padding-top: 1rem;
          width: 50%;
          vertical-align: -1.6rem;

          & sis-localized-string-editor {
            text-transform: none;

            & .nav.nav-pills-localize {
              display: none;
            }
          }
        }

        & > .save-name {
          position: absolute;
          margin: spacing.$spacing-sm 0 0 spacing.$spacing-sm;
          text-transform: none;
          font-weight: 300;
        }
      }
    }
  }
}
