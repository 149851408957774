cancel-or-abort-enrolment-modal {
  & .modal-body {
    & .contextual-notification {
      margin-bottom: 2rem;
    }

    & .courseunit-realisation {
      margin-bottom: 3.8rem;
    }
  }
}
