@use 'sis-assets/foundations/spacing/tokens' as spacing;

sis-contact-info-for-graduation {
  & .grouping-panel-content {
    & .form-control-static {
      padding-bottom: 0;
      padding-top: 0;
      min-height: spacing.$spacing-md;
    }
    & .form-label {
      margin-bottom: 0;
    }
  }
}
