@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

app-course-unit-enrolment {
  & .enrolment-course-unit-box {
    border: spacing.$pixel-1 solid black;
    background-color: colors.$light-gray;
  }

  & .enrolment-course-unit-text {
    padding: 1.5rem;
  }
}
