sis-personalized-selection-rationale {
  & .modal-body {
    & em {
      font-weight: 600;
    }

    & p {
      overflow-wrap: break-word;
    }
  }
}
