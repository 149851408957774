@use 'sis-assets/foundations/spacing/tokens' as spacing;

sis-code-multi-selector {
  & .not-leaf-node {
    font-weight: bold;
  }

  & .code-close-button {
    position: absolute;

    & sis-icon[icon="close"] svg {
      margin-top: -0.3rem;
    }
  }
}
