@use '@funidata/ngx-fudis' as fudis;
@use 'mixins' as typography;
@use 'tokens' as tokens;
@use '../breakpoints/breakpoints' as breakpoints;
@use '../colors/colors' as colors;
@use '../spacing/tokens' as spacing;

// HEADINGS

// Use fudis-heading class when using Fudis Heading component is not possible
.page-teacher,
.page-staff,
.page-admin,
.page-tutor,
.page-student {
  .fudis-heading {
    &__xxs {
      @include typography.heading-xxs;
    }

    &__xs {
      @include typography.heading-xs;
    }

    &__sm {
      @include typography.heading-sm;
    }

    &__md {
      @include typography.heading-md;
    }

    &__lg {
      @include typography.heading-lg;
    }

    &__xl {
      @include typography.heading-xl;
    }

    &__xxl {
      @include typography.heading-xxl;
    }
  }
}

// Headings in student application use Fudis typography heading styles
.page-student {
  h1 {
    @include typography.heading-xxl;
  }

  h2 {
    @include typography.heading-xl;
  }

  h3 {
    @include typography.heading-lg;
  }

  h4 {
    @include typography.heading-md;
  }

  h5 {
    @include typography.heading-sm;
  }

  h6 {
    @include typography.heading-xs;
  }
}

// TODO: Remove when all apps are converted to use fudis-headings & -styles
// Headings in other applications use old heading styles
.page-teacher,
.page-staff,
.page-admin,
.page-tutor {
  h1 {
    margin-top: 1.8rem;
    margin-bottom: spacing.$spacing-sm;
    line-height: 3.6rem;
    font-size: spacing.$spacing-lg;
    font-weight: tokens.$font-weight-medium;
    overflow-wrap: break-word;
  }

  h2 {
    margin-top: 1.8rem;
    margin-bottom: spacing.$spacing-sm;
    line-height: tokens.$label-line-height;
    font-size: 2.2rem;
    font-weight: tokens.$font-weight-medium;
    overflow-wrap: break-word;
  }

  h3 {
    margin-top: 1.8rem;
    margin-bottom: spacing.$spacing-xs;
    line-height: tokens.$label-line-height;
    font-size: tokens.$heading-sm-font-size;
    font-weight: tokens.$font-weight-medium;
    overflow-wrap: break-word;
  }

  h4 {
    text-transform: uppercase;
    line-height: tokens.$label-line-height;
    font-size: tokens.$heading-xxs-font-size;
    font-weight: tokens.$font-weight-regular;
    overflow-wrap: break-word;
  }

  // SMALLER DEVICES
  @media (max-width: breakpoints.$screen-sm-max) {
    h1 {
      line-height: spacing.$spacing-md;
      font-size: tokens.$heading-lg-font-size;
    }

    h2 {
      font-size: tokens.$heading-sm-font-size;
    }

    h3 {
      font-size: tokens.$heading-xxs-font-size;
    }

    h4 {
      font-size: tokens.$body-text-sm-font-size;
    }
  }
}

h1 > button.non-button,
h2 button.dropdown-toggle.non-button {
  color: colors.$dark-gray;
  font-weight: tokens.$font-weight-medium;
}

// LEGEND

legend {
  @include typography.heading-xs;
}

// LABEL

label:not([class*="fudis"]),
.label,
.sis-label {
  @include typography.label;
}

// EMPTY STATE
.sis-emptystate {
  @include fudis.text-emptystate;
}

// ** Deprecated **
// Use sis-emptystate instead
.guidance,
.sis-guidance {
  line-height: tokens.$label-line-height;
  color: colors.$middle-gray;
  font-size: tokens.$body-text-md-font-size;
  font-weight: tokens.$font-weight-regular;

  &__primary {
    text-transform: none;
    font-style: normal;
  }

  &__inverted {
    color: colors.$white;
  }
}

// DISCLAIMER

.sis-disclaimer {
  line-height: tokens.$body-text-md-line-height;
  font-size: tokens.$body-text-sm-font-size;
}

// PLACEHOLDERS

.sis-placeholder {
  line-height: tokens.$body-text-lg-line-height;
  color: colors.$middle-gray;
  font-size: tokens.$body-text-md-font-size;
  font-weight: tokens.$font-weight-light;
  font-style: italic;
}

.sis-form-placeholder {
  color: colors.$middle-gray;

  &__light {
    color: colors.$light-gray;
  }
}

// HELP BLOCK

.help-block {
  text-transform: none;
  line-height: tokens.$body-text-md-line-height;
  color: colors.$middle-gray;
  font-size: tokens.$body-text-sm-font-size;
}

// ERROR TEXT

.sis-error-text {
  color: colors.$red;

  &__small {
    font-size: tokens.$body-text-sm-font-size;
  }
}

// EM

em {
  font-weight: tokens.$font-weight-bold;
  font-style: normal;
}

// LINK

a {
  text-decoration-line: underline;
  color: colors.$primary-color-dark;

  /* Overwrite Bootstrap hover effect on anchor elements */
  &:hover {
    color: colors.$primary-color-dark;
  }

  &.anchor-button {
    text-decoration: none;
  }
}

// CLICKABLE

.sis-clickable,
.clickable {
  cursor: pointer;
}

// UNTOUCHABLE

.sis-untouchable {
  -webkit-touch-callout: none;
  user-select: none;
}

// TEXT ALIGNMENTS

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

@media (max-width: breakpoints.$screen-sm-max) {
  legend,
  label:not([class*="fudis"]),
  .label,
  .sis-label,
  .guidance,
  .sis-guidance {
    font-size: tokens.$body-text-sm-font-size;
  }

  .sis-disclaimer {
    font-size: 1.1rem;
  }
}

