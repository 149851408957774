@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

sis-carousel {
  .glider-track {
    display: flex;
    z-index: 1;
    margin: 0;
    padding: 0;
    width: 100%;
  }

  .glider::-webkit-scrollbar {
    opacity: 0;
    height: 0;
  }

  .glider {
    position: relative;
    margin: 0 auto;
    overflow-x: auto;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;
    scrollbar-width: none; // for Firefox
  }

  .glider-controls.hide {
    display: none !important;
  }

  .glider-prev, .glider-next {
    position: absolute;
    top: 40%;
    transition: opacity .2s cubic-bezier(.17,.67,.83,.67),
    color .5s cubic-bezier(.17,.67,.83,.67);
    opacity: 1;
    z-index: 2;
    outline: none;
    border: 0;
    background: none;
    cursor: pointer;
    width: 5rem;
    height: 5rem;
    text-decoration: none;
    line-height: 1;
    color: colors.$primary-color;
    font-size: 3rem;
    user-select: none;

    &:active sis-icon::after {
      position: absolute;
      inset: 0.5rem 0.5rem 0.6rem;
      outline: spacing.$spacing-xxs solid colors.$primary-color;
      content: '';
    }

    sis-icon {
      border: none;

      &::after {
        position: absolute;
        inset: 0.5rem 0.5rem 0.6rem;
        outline: spacing.$pixel-2 solid colors.$primary-color;
        content: '';
      }
    }

    sis-icon svg {
      position: relative;
      transition: unset;
    }

    sis-icon svg.ccw-90 {
      top: -0.2rem;
    }
  }

  .glider-prev {
    left: -4.6rem;
  }

  .glider-next {
    right: -4.5rem;
  }

  .glider-next.disabled,
  .glider-prev.disabled {
    transition: opacity .2s cubic-bezier(.17,.67,.83,.67);
    opacity: .25;
    cursor: default;
  }

  .glider-slide {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    margin: 1rem 1rem 2.5rem;
    border-bottom: 0;
    background: colors.$white;
    min-height: 30rem;
  }

  .glider-hide {
    opacity: 0;
  }

  // carousel container sizes for different SlideWidths (only default=220 for now) and numbers of slides (1-8)
  section.glider-contain {
    position: relative;
    margin: 0 spacing.$spacing-xl;

    &.default-1 {
      width: 22rem;
    }

    &.default-2 {
      width: 44rem;
    }

    &.default-3 {
      width: 66rem;
    }

    &.default-4 {
      width: 88rem;
    }

    &.default-5 {
      width: 110rem;
    }

    &.default-6 {
      width: 132rem;
    }

    &.default-7 {
      width: 154rem;
    }

    &.default-8 {
      width: 176rem;
    }

    // mobile is a special case
    &.mobile {
      margin: 0 auto;
      width: 95%;

      // additional styles for mobile view
      .glider-prev, .glider-next {
        position: relative;
      }

      .glider-prev {
        left: -1rem;
      }

      .glider-next {
        left: 1rem;
      }

      .glider-controls {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .glider-controls .browsing-details {
        text-align: center;
        line-height: 1.8rem;
        font-weight: 500;
      }

      sis-carousel-selection-box-slide {
        align-items: flex-start;

        .slide-input {
          background-color: colors.$primary-color;
          color: colors.$white;
        }

        .selected-highlight::after {
          display: none;
        }

        sis-radio-button label.control.control-radio {
          padding-left: 1rem;
        }

        sis-radio-button .control-indicator {
          visibility: hidden;
        }
      }
    }
  }

  .glider-contain.content {
    width: 100%;
  }
}
