@use 'sis-assets/foundations/breakpoints/breakpoints' as breakpoints;
@use 'sis-assets/foundations/colors/colors' as colors;

sis-message-conversation {
  & .message-list {
    list-style-type: none;
  }

  & .message-top-border {
    border-top: 0.1rem solid colors.$light-gray;
  }

  & .conversation-details-information {
    @media (max-width: breakpoints.$screen-md-max) {
      flex-direction: column-reverse;
    }
  }
}
