@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

prerequisite-group-edit-modal {
  & .modal-body {
    & .single-prerequisite-in-editor {
      border-bottom: spacing.$pixel-1 solid colors.$extra-light-gray;
      margin-bottom: 1.5rem;

      & sis-course-unit-box, sis-module-box {
        display: block;
        padding-bottom: 1.5rem;
      }

      & .btn.form-control.simulate-label {
        &.not-after-input-field {
          margin-top: 0.6rem;
        }
      }
    }

    & .guidance {
      padding-top: 2rem;
      display: block;
    }
  }
}
