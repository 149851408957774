@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

app-study-sub-group-view {
  .selected {
    border: spacing.$pixel-2 solid colors.$primary-color;
  }

  .not-selected {
    border: spacing.$pixel-2 solid colors.$middle-gray;
  }

  .selected-header {
    background-color: colors.$primary-color-light;
  }

  .not-selected-header {
    background-color: colors.$extra-light-gray;
  }

  .study-sub-group-view {
    &__header {
      h5 {
        font-size: 1.5rem;
        font-weight: 400;
      }
    }
  }
}
