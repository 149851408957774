// Common styles for Angular's Reactive Forms
// SEMI OLD STUFF - DO NOT SIMPLY COPY PASTE STYLES FROM HERE TO NEW COMPONENTS!!

@use 'sis-assets/foundations/breakpoints/breakpoints' as breakpoints;
@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;
@use 'sis-assets/foundations/typography/tokens' as typography;

// Label state pseudo icons

label,
h4,
legend {
  &.required,
  &.valid,
  &.invalid {
    &::before {
      display: inline-block;
      position: relative;
      margin-left: -1.9rem;
      width: 1.9rem;
      height: 1.9rem;
      vertical-align: middle;
      text-transform: none;
      line-height: 1.9rem;
      font-family: SISUicons;  /* stylelint-disable-line */
      font-size: typography.$heading-lg-font-size;
    }
  }

  &.required {
    &::before {
      left: -0.5rem;
      text-align: center;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      content: 'j';

      @supports (content: 'x' / 'y') {
        content: 'j' / 'required';
      }
    }
  }

  &.valid {
    &::before {
      left: -0.4rem;
      padding-top: spacing.$pixel-1;
      text-align: center;
      color: colors.$green;
      content: 'x';

      @supports (content: 'x' / 'y') {
        content: 'x' / 'valid';
      }
    }
  }

  &.invalid {
    &::before {
      left: -0.5rem;
      color: colors.$red;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      content: '9';

      @supports (content: 'x' / 'y') {
        content: '9' / 'invalid';
      }
    }
  }
}

// Label with info tooltip

.label-with-tooltip-icon {
  margin-right: 1rem;

  & + sis-tooltip {
    > span {
      display: inline-block;

      & sis-icon[icon="info-circle"] svg {
        margin-top: -0.6rem;
      }
    }
  }
}

// Form group

.form-group {
  position: relative;
  margin-bottom: 3.8rem;

  & .search-with-svg-icon {
    display: block;
    position: absolute;
    top: spacing.$spacing-xxs;
    right: 3.5rem;
    z-index: 1;
    margin-right: -3.2rem;
    pointer-events: none;
    fill: colors.$middle-gray;

    &::before {
      position: absolute;
      right: 0;
      z-index: -1;
      background-color: transparent;
      background-image: linear-gradient(to right, rgb(241 241 241 / 0%) 0%, colors.$white 40%);
      width: 6rem;
      height: 2.8rem;
      content: '';
    }
  }

  ul.error-list {
    margin-top: 1.1rem;
    padding: 0;

    &:first-child {
      margin-top: 0.6rem;
    }

    & li {
      margin-top: 0;
      margin-bottom: 0;
      list-style: none;
      line-height: spacing.$spacing-sm;
      color: colors.$red;
      font-size: 1.2rem;
    }

    & + ul.error-list {
      margin-top: -1.1rem;
    }

    &[ng-messages] {
      & li:last-child {
        margin-bottom: 1.2rem;
      }
    }
  }
}

// Form control

.form-control {
  border: spacing.$pixel-1 solid colors.$middle-gray;
  padding: 0.9rem 0.6rem;
  width: 100% !important;
  height: spacing.$spacing-xl;

  &[disabled],
  &:disabled {
    color: colors.$middle-gray;

    & .sis-form-placeholder {
      color: colors.$middle-gray;
    }

    & ~ .search-with-svg-icon {
      &::before {
        background-image: linear-gradient(to right, rgb(241 241 241 / 0%) 0%, colors.$extra-light-gray 40%);
      }
    }
  }

  &.invalid,
  &.ng-invalid.ng-dirty,
  &.ng-invalid.ng-touched {
    border-color: colors.$red;
  }
}

// Static form data display classes - prefer using Fudis- and Sis-Description-List components whenever possible!

// Form group mimic

.form-group-mimic {
  margin-bottom: 3.8rem;
  line-height: typography.$body-text-lg-line-height;
  font-size: typography.$body-text-lg-font-size;
  font-weight: typography.$font-weight-light;

  > h4.label-with-tooltip-icon {
    display: inline-block;
    margin-top: 0;
    margin-right: 1rem;
    margin-bottom: 0.6rem;
  }

  & p {
    margin-bottom: 0;
  }

  > div,
  > p,
  > span {
    display: block;
    min-height: spacing.$spacing-lg;
    overflow-wrap: break-word;

    &:not(:last-child) {
      padding-bottom: 0.7rem;
    }
  }

  & ul > li,
  & ol > li {
    min-height: spacing.$spacing-lg;
    overflow-wrap: break-word;

    // :not pseudoselector added because without it sis-wizard-progress component will get wrong CSS display value
    &:not(.sis-wizard-progress__step) { /* stylelint-disable-line */
      display: list-item;
    }
  }

  @media (max-width: breakpoints.$screen-sm-max) {
    font-size: typography.$body-text-md-font-size;
  }

  @media(max-width: breakpoints.$screen-xs-max) {
    margin-bottom: spacing.$spacing-sm;
  }
}

// Form-control-static

.form-control-static {
  margin-bottom: 0;
  padding-top: 0;
  padding-bottom: 0.7rem;
  min-height: spacing.$spacing-lg;
  line-height: 2rem;
  font-size: typography.$body-text-lg-font-size;
  font-weight: typography.$font-weight-light;
  overflow-wrap: break-word;

  &.invalid {
    color: colors.$red;
  }

  & strong {
    font-weight: typography.$font-weight-bold;
  }

  @media (max-width: breakpoints.$screen-sm-max) {
    line-height: typography.$label-line-height;
    font-size: typography.$body-text-md-font-size;
    font-weight: typography.$font-weight-light;
  }
}

// Input

input[type="number"],
input[type="text"].form-control {
  line-height: 1.125rem;
}

// Textarea

textarea {
  display: block;
  outline: none;
  box-shadow: none;
  min-width: 90%;
  min-height: 8rem;
  overflow: auto;
  resize: vertical;

  &:focus {
    border: spacing.$pixel-2 solid colors.$primary-color;
  }

  /* Text-area height should not get the spacing.$spacing-xl value from form-control, thus set to initial here */
  &.form-control {
    height: initial;
  }
}

[textarea-length] {
  & .textarea-length-indicator {
    display: block;
    position: relative;
    top: -2.2rem;
    left: spacing.$pixel-2;
    float: left;
    backface-visibility: hidden;
    transition: opacity linear 100ms;
    opacity: 1;
    background-color: colors.$extra-light-gray;
    padding: spacing.$pixel-2 spacing.$spacing-xxs;
    text-align: left;
    line-height: typography.$body-text-sm-line-height;
    color: colors.$dark-gray;
    font-size: typography.$body-text-sm-font-size;

    &.indicator-hidden {
      backface-visibility: hidden;
      transition: opacity linear 1000ms 400ms;
      opacity: 0;
    }

    &.max-length-reached {
      opacity: 1;
      background-color: colors.$red;
      color: colors.$extra-light-gray;
    }
  }

  & textarea {
    padding-bottom: spacing.$spacing-sm;
  }

  & div[ng-transclude="additional"] {
    display: block;
    clear: both;
  }
}

.text-area-static {
  white-space: pre-line;
}
