@use 'sis-assets/foundations/spacing/tokens' as spacing;

app-empty-cart {
  & .empty-cart-title {
    margin-top: spacing.$spacing-lg;
  }

  & .search-link-button {
    margin-top: spacing.$spacing-sm;
  }
}
