@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/focus/mixins' as focus;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

// TODO: Convert all tags to use sis-search-filter-tag component and scope styles inside component

.tag {
  display: inline-block;
  position: relative;
  margin-right: 1rem;
  border: spacing.$pixel-1 solid colors.$primary-color;
  border-radius: spacing.$pixel-2;
  cursor: pointer;
  padding: 0;
  max-width: 35rem;
  height: 4.2rem;
  overflow: hidden;
  color: colors.$white;
  font-size: 1em;

  &.no-editing {
    cursor: default;

    &.secondary-color {
      & .tag-content:hover {
        background-color: colors.$extra-light-gray;
      }
    }

    & .tag-content {
      padding-right: spacing.$spacing-xs;

      &__pen-icon {
        display: none;
      }

      &:hover {
        background-color: colors.$white;
      }
    }
  }

  &.disabled {
    border-color: colors.$silver;
    background-color: colors.$silver;
    cursor: default;

    & .tag-content {
      margin-right: 0;
      background-color: colors.$silver;
      padding-right: spacing.$spacing-xs;

      &__pen-icon {
        display: none;
      }

      &:hover {
        background-color: colors.$silver;
      }

      & .tag-title, .tag-description {
        color: colors.$white;
      }
    }
  }

  &.with-toggle {
    cursor: default;

    &.secondary-color {
      & .tag-content:hover {
        background-color: colors.$extra-light-gray;
      }
    }

    & .tag-content {
      margin-right: spacing.$spacing-md !important;
      padding-right: 5rem !important;

      &__pen-icon {
        display: none;
      }

      &:hover {
        background-color: colors.$white;
      }
    }
  }

  &.search-string {
    cursor: default;
  }

  & .tag-content {
    display: inline-block;
    position: relative;
    margin-right: spacing.$spacing-md;
    background-color: colors.$white;
    padding: spacing.$spacing-xxs 4.4rem spacing.$spacing-xxs spacing.$spacing-xs;
    height: spacing.$spacing-xl;
    color: colors.$primary-color;

    &__pen-icon {
      position: absolute;
      top: spacing.$spacing-xxs;
      right: 0;
    }

    &.no-action {
      margin-right: 0;
    }

    & .tag-title {
      display: block;
      overflow: hidden;
      line-height: 1.4rem;
      white-space: nowrap;
      color: colors.$primary-color;
      font-size: 1.2rem;
      font-weight: 300;
    }

    & .tag-description {
      position: relative;
      top: spacing.$spacing-xxs;
      max-width: 27rem;
      height: 1.8rem;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: spacing.$spacing-sm;
      white-space: nowrap;
      color: colors.$primary-color;
      font-size: 1.4rem;
      font-weight: 400;
    }
  }

  & .link-button {
    position: absolute;
    top: 0;
    right: 0;
    border: none;
    border-left: spacing.$pixel-1 solid colors.$primary-color;
    background-color: colors.$primary-color-light;
    width: spacing.$spacing-md;
    height: spacing.$spacing-xl;

    &:hover {
      background-color: colors.$primary-color;

      & svg {
        fill: colors.$white;
      }
    }

    &:focus {
      @include focus.deprecated-sisu-focus-generic-inner;

      border-left-color: colors.$white;
    }

    & sis-icon {
      margin-left: -0.4rem;

      & svg {
        fill: colors.$primary-color;
      }
    }
  }

  &.secondary-color {
    border: spacing.$pixel-1 solid colors.$silver;
    background-color: colors.$extra-light-gray;
    cursor: default;
    color: colors.$dark-gray;

    & .tag-content {
      background-color: colors.$extra-light-gray;
      padding-right: spacing.$spacing-xs;

      &__pen-icon {
        display: none;
      }

      & .tag-title {
        color: colors.$dark-gray;
      }

      & .tag-description {
        color: colors.$dark-gray;
      }
    }

    & .link-button {
      border-left: spacing.$pixel-1 solid colors.$silver;
      background-color: colors.$light-gray;

      &:hover {
        background-color: colors.$middle-gray;

        & sis-icon[icon="close"] svg {
          fill: colors.$white;
        }
      }

      & sis-icon[icon="close"] svg {
        fill: colors.$dark-gray;
      }
    }
  }

  &.permanent {
    & .tag-content {
      margin-right: 0;
    }
  }
}

.new-tag-inline-form {
  & .inline-input {
    margin-top: 0.5rem;
  }

  & .inline-buttons {
    margin-top: 0.5rem;

    button {
      margin-right: 0;
    }
  }
}
