@use "@funidata/ngx-fudis" as fudis;

app-plan-structure-edit-modal {
  & sis-plan-structure-select-rule {
    @include fudis.breakpoint('sm') {
      order: -1;
    };
  }

  & sis-menu-button {
    & .btn {
      margin-right: 0;
    }
  }
}
