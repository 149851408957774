@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

sis-breadcrumbs {
  .breadcrumb {
    align-items: center;
    margin: 0;
    background-color: transparent;
    padding: 0;
    font-size: 1.2rem;
  }
}
