sis-select-organisation {
  .reset-button-container {
    display: flex;
    align-items: center;
    justify-content: end;
  }

  .reset-selection-button {
    margin: 0;
  }
}
