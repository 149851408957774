sis-label-with-info-popover {
  & sis-popover {
    & span {
      display: inline-block;
    }
  }

  & h4 {
    display: inline-block;
    margin-right: 1rem;
    margin-bottom: 0.6rem;
  }

  & .sis-popover-popover-content {
    overflow-wrap: break-word;
    inline-size: 30rem;
  }
}
