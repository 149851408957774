@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

sis-external-link {
  & .sis-external-link {
    word-break: break-word;

    &__icon-wrapper {
      display: inline-block;
      position: relative;
      padding-right: spacing.$spacing-lg;
      text-decoration: underline;

      /* Overwrite Bootstrap hover effect on anchor elements */
      a:hover {
        color: colors.$primary-color-dark;
      }

      & sis-icon > svg {
          position: absolute;
          top: 50%;
          right: 0;
          transform: translateY(-50%);
      }
    }
  }
}
