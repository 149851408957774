@use 'sis-assets/foundations/breakpoints/breakpoints' as breakpoints;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

app-course-cart {
  & .sis-table {
    display: block;
    overflow-x: auto;

    &__header {
      &__last {
        // Override padding from tables.scss to align th and button
        padding-left: spacing.$spacing-md !important;
      }
    }

    &__body {
      &__row {
        &__button {
          overflow: hidden;

          &__text {
            &__long {
              @media (max-width: breakpoints.$screen-md-max) {
                display: none;
              }
            }

            &__short {
              @media (min-width: breakpoints.$screen-lg-min) {
                display: none;
              }
            }

            @media (max-width: breakpoints.$screen-sm-max) {
              display: none;
            }
          }
        }
      }
    }
  }
}
