@use '@funidata/ngx-fudis' as fudis;
@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

markup-string-editor {
  display: block;
  position: relative;
  font-family: inherit;

  & .focusable-element {
    top: 3.4rem;
    left: auto;
    border: spacing.$pixel-1 solid colors.$dark-gray;
    background-color: colors.$white;
    cursor: default;
    max-width: inherit;
    min-height: spacing.$spacing-xl;
    color: colors.$dark-gray;
    font-weight: 400;
    pointer-events: auto;

    &.disabled {
      border: spacing.$pixel-1 solid colors.$light-gray;
      background-color: colors.$extra-light-gray;
    }
  }

  & .box {
    background: colors.$white none repeat scroll 0 0;
    padding: 0.375rem 0.9rem 0.375rem 1.4rem;
    line-height: 2.0rem;
    font-size: spacing.$spacing-sm;

    &:focus {
      @include fudis.focus-form-field;
    }
  }

  & .markup-indicator {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: spacing.$spacing-xxs 0 0 spacing.$spacing-xxs;
    background-color: colors.$light-gray;
    width: 1.3rem;
    height: 100%;
    line-height: 3.3rem;
    color: colors.$white;

    & sis-icon[icon="editor"] svg {
      margin-left: -0.8rem;
    }
  }
}
