@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

app-calendar-enrolments {
  & .app-calendar-enrolments {
    &__filters {
      border: colors.$white spacing.$spacing-xs solid;
      border-radius: 0.5em;
      background-color: colors.$white;
    }
  }
}
