@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/focus/mixins' as focus;
@use 'sis-assets/foundations/spacing/tokens' as spacing;
@use 'sis-assets/foundations/typography/tokens' as typography;
@use '../mixins' as top-navigation;

sis-language-selection-menu {
  & .language-selection {
    &__toggle {
      padding: spacing.$spacing-xs;
      line-height: typography.$body-text-lg-line-height;
      color: colors.$dark-gray;
      font-weight: 400;

      &:focus {
        @include focus.fudis-focus-generic;
      }
    }

    &__menu {
      @include top-navigation.settings-menu-group;

      z-index: 1005; // opens the menu on top of alerts
      border-color: colors.$middle-gray;
      width: max-content;

      &__item {
        padding: 0 spacing.$spacing-sm 0 spacing.$spacing-md;
        font-size: typography.$body-text-md-font-size;
      }
    }
  }
}
