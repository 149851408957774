@use 'sis-assets/foundations/colors/colors' as colors;

sis-file-upload-info-modal {
  & .modal-body {
    & .object {
      & .error-message {
        display: flex;
        align-items: center;
        color: colors.$middle-gray;
        font-size: 1.2rem;
      }
    }

    & .status {
      margin-top: 2rem;
    }
  }
}
