@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

app-course-unit-info-modal-prior-learning {
  & .sis-guidance {
    margin: 1rem 0;
  }

  & .cancel-box {
    background-color: colors.$extra-light-gray;
    padding: 2.5rem;

    & .button-holder {
      text-align: right;
    }
  }

  & .contextual-notification {
    margin: 1rem 0;
  }

  & .prior-learning-list,
  & .attainment-workflow-decision-summary {
    margin-top: spacing.$spacing-md;
  }
}
