@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

timeline-study-right-indicator {
  .study-right {
    &.active .text-box,
    &.active .line {
      background-color: colors.$green;
    }

    &.over-time .text-box,
    &.over-time .line {
      background-color: colors.$yellow;
    }

    &.request .line,
    &.request .text-box {
      background-color: colors.$accent;
    }
  }

  .line {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: spacing.$pixel-3;
  }

  .text-box {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 0 spacing.$pixel-3;
    max-width: 100%;
    height: 1.3rem;
    overflow: hidden;
    text-transform: uppercase;
    text-overflow: ellipsis;
    line-height: 1.5rem;
    white-space: nowrap;
    color: colors.$white;
    font-size: 1.1rem;
  }
}
