@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

sis-module-rule {
  .box-left {
    color: colors.$dark-gray;
  }
}

sis-course-unit-rule,
sis-module-rule,
sis-plan-custom-study-draft,
sis-any-course-unit-rule,
sis-any-module-rule {
  display: block;
  clear: both;

  & .courseunit,
  & .studymodule {
    margin-top: 0.5em;
  }
}

.rule-header {
  text-transform: uppercase;
  color: colors.$extra-light-gray;

  & .selected {
    margin-top: spacing.$pixel-2;
    color: colors.$white;
    font-size: 1.2rem;
    font-weight: 600;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  & .badge {
    display: flex; // Align icon and text inside the badge
    align-items: center;
    margin-right: spacing.$pixel-2;
    margin-bottom: spacing.$pixel-1;
    border-radius: 0;
    background-color: rgb(255 255 255 / 10%);
    padding-right: 0.6rem;
    padding-left: 0.6rem;
    min-width: min-content;
    max-width: max-content;
    height: 2.5rem;
    text-transform: uppercase;
    line-height: 1.4rem;
    font-weight: 400;
  }
}

.rule-body {
  clear: both;

  & .sub-rule-block {
    display: block;
    padding-top: 2.2rem;
    width: 100%;

    &.sub-rule-block-odd {
      & > sis-rule-select {
        & > sis-any-module-rule,
        & > sis-any-course-unit-rule {
          display: block;
          margin: 2rem -2rem 0;
          border-top: spacing.$pixel-1 solid colors.$middle-gray;
          padding: 2rem 2rem 0;
          width: calc(100% + 4.0rem);
        }
      }
    }

    & .rule-body {
      margin-left: 1.3rem;
      border-width: 0 0 0 spacing.$pixel-1;
      border-style: solid;
      border-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAAGCAYAAAACEPQxAAAAEklEQVQYV2PYv3//fwYEQOUCAIC+BnkBk+rzAAAAAElFTkSuQmCC') 0 6 0 1 round;
      padding: 0 1.7rem;

      &::after {
        display: inline-block;
        position: relative;
        bottom: -0.6rem;
        left: -2rem;
        border-radius: 0.5rem;
        background-color: colors.$light-gray;
        width: 0.5rem;
        max-width: 0.5rem;
        height: 0.5rem;
        max-height: 0.5rem;
        content: '';
      }
    }
  }
}

sis-credit-phrase,
sis-count-phrase,
sis-course-unit-count-phrase {
  display: block;
  clear: both;
  padding-top: 1rem;
  min-height: 3.6rem;

  .selected {
    span {
      display: block;
      width: 100%;
      text-align: center;
    }
  }
}
