app-grade-average {
  display: block;

  & .grade-container {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    font-size: 1.4rem;
    font-weight: 400;
  }

  & .big-font {
    font-size: 5rem;
  }
}
