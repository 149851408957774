@use 'sis-assets/foundations/breakpoints/breakpoints' as breakpoints;

person-title-selector {
  & .title,
  & .title-string {
    & localized-string-editor {
      & ul.nav.nav-pills-localize {
        margin-left: 0;
      }
    }

    @media (min-width: breakpoints.$screen-md-min) {
      padding-right: 0;
    }
  }
}
