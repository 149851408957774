sis-rule-custom-module-attainment-box {
  .studymodule {
    &.attainment-data-not-found {
      & .studymodule-top-row {
        & .grade-box sis-icon[icon="achievement"] {
          margin-top: 1rem;
        }

        & .credits-box sis-icon {
          display: block;
          margin-top: 1rem;
          margin-left: 1.3rem;
        }
      }
    }
  }
}
