attainment-misregistration-rationale-modal {
  & #attainment-misregistration-modal-body {
    & > span {
      display: block;
      margin-bottom: 3rem;
    }

    & .misregistration-rationale {
      display: block;
      white-space: pre-line;
    }
  }
}
