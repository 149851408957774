sis-select-assessment-item {
  & .ng-dropdown-panel {
    min-width: 100% !important;
    max-width: 100%;
  }

  & .ng-option {
    width: 100%;
    white-space: normal !important;
  }
}
