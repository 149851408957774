@use 'sis-assets/foundations/breakpoints/breakpoints' as breakpoints;
@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

sis-workflow-data-change-modal {
  .description {
    margin-top: spacing.$spacing-xxs;
  }

  dt {
    margin-top: spacing.$spacing-lg;
    margin-bottom: spacing.$spacing-xxs;
    text-transform: uppercase;
    line-height: spacing.$spacing-md;
    font-size: 1.4rem;
    font-weight: 400;
  }

  dd {
    & >, & > div {
      display: block;
      margin-bottom: 0.5rem;
      padding-top: 0;
      padding-bottom: 0;
      line-height: 2rem;
      font-size: spacing.$spacing-sm;
      font-weight: 300;
      overflow-wrap: break-word;

      @media (max-width: breakpoints.$screen-sm-max) {
        font-size: 1.4rem;
      }
    }
  }
}
