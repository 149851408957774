@use 'sis-assets/foundations/colors/colors' as colors;

app-module-content-workflow-modal {
  & .modal-body {
    & .situation-description {
      & em {
        font-weight: 600;
      }
    }

    & .module-content-suggestion {
      color: colors.$middle-gray;
    }
  }
}
