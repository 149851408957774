@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/focus/mixins' as focus;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

sis-carousel-selection-box-slide {
  &:has(sis-radio-button input:focus) {
    @include focus.fudis-focus-generic;

    & .selected-highlight::after {
      height: 2rem;
      clip-path: polygon(10% 5%, 50% 100%, 90% 5%);
    }
  }

  & .selection-box-slide {
    position: relative;
    z-index: 99;
    border: spacing.$pixel-2 solid colors.$primary-color;
    border-radius: 0.5rem;
    cursor: pointer;
    width: 100%;
    height: 100%;
  }

  & .slide-input {
    display: flex;
    align-items: center;
    margin-top: -0.1rem;
    margin-left: -0.1rem;
    border-bottom: spacing.$pixel-2 solid colors.$primary-color;
    border-radius: 0.5rem 0.5rem 0 0;
    padding: 1.2rem 1rem;
    width: calc(100% + 0.2rem);
    min-height: 5.6rem;
    color: colors.$primary-color;

    &.height-adjust-1 {
      min-height: 8rem;
    }

    &.height-adjust-2 {
      min-height: 10.5rem;
    }

    &.height-adjust-3 {
      min-height: 13rem;
    }

    &.height-adjust-4 {
      min-height: 15.5rem;
    }

    & .control-radio {
      overflow-wrap: anywhere;
      line-height: spacing.$spacing-md;

      & .control-indicator {
        top: calc(50% - 1.2rem);
        left: 0;
        border-radius: 5rem;
        width: spacing.$spacing-md;
        height: spacing.$spacing-md;
      }
    }
  }

  & .slide-content {
    padding: 1rem;
    width: 100%;
  }

  & .banner {
    display: flex;
    justify-content: center;
    width: 100%;
    color: colors.$white;

    &.danger {
      background-color: colors.$red;
    }

    &.success {
      background-color: colors.$green;
    }
  }

  & sis-radio-button label.control.control-radio {
    margin: 0;
    font-size: 1.7rem;
  }

  & .font-adjust-1 sis-radio-button label.control.control-radio {
    font-size: spacing.$spacing-sm;
  }

  & .font-adjust-2 sis-radio-button label.control.control-radio {
    font-size: 1.5rem;
  }

  & .font-adjust-3 sis-radio-button label.control.control-radio {
    font-size: 1.4rem;
  }

  & .selected-highlight::after {
    position: absolute;
    bottom: -2.6rem;
    background: colors.$primary-color;
    width: 100%;
    height: 2.5rem;
    content: " ";
    pointer-events: none;
    clip-path: polygon(0% 1%, 50% 100%, 100% 1%);
  }

  & .selected-highlight .slide-input {
    background-color: colors.$primary-color;

    & sis-radio-button label.control.control-radio {
      color: colors.$white;
    }
  }
}

section.glider-contain.mobile {
  .selection-box-slide {
    height: fit-content;
  }
}
