@use 'sis-assets/foundations/breakpoints/breakpoints' as breakpoints;
@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

student-application-list {
  & table.table {
    & > thead {
      & > tr {
        & > th {
          vertical-align: middle;

          & .sorting-button {
            display: flex;
            align-items: center;
          }

          & > button {
            text-transform: uppercase;
            line-height: spacing.$spacing-md;
            color: colors.$dark-gray;
            font-weight: 400;

            &:disabled {
              cursor: default;
            }

            & > span {
              vertical-align: top;
            }
          }

          @media (max-width: breakpoints.$screen-xs-max) {
            white-space: normal;
          }

          @media (min-width: breakpoints.$screen-sm-min) {
            white-space: nowrap;
          }
        }
      }
    }

    & .student-application-type {
      display: block;
      margin-bottom: spacing.$pixel-2;
      color: colors.$middle-gray;
      font-size: 1.2rem;
    }
  }
}

.staff-main-content {
  & student-application-list {
    & table {
      & tbody {
        & .application-status {
          white-space: nowrap;

          & sis-icon[icon="hourglass"] svg {
            fill: colors.$yellow;
          }

          & sis-icon[icon="check"] svg, sis-icon[icon="fail"] svg {
            fill: colors.$middle-gray;
          }
        }
      }
    }
  }
}
