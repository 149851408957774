@use '@funidata/ngx-fudis' as fudis;
@use 'sis-assets/foundations/breakpoints/breakpoints' as breakpoints;
@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/focus/mixins' as focus;
@use 'sis-assets/foundations/spacing/tokens' as spacing;
@use 'sis-assets/foundations/typography/tokens' as typography;

sis-user-settings-menu {
  & .user-settings-menu {
    &__toggle {
      display: flex;
      align-items: center;
      padding: spacing.$spacing-xs spacing.$spacing-xs spacing.$spacing-xs spacing.$spacing-sm;
      color: colors.$white;

      &:hover {
        background-color: colors.$middle-gray;
      }

      &:focus {
        @include fudis.focus-generic;
      }

      &__name,
      &__role {
        transition: max-width 0.4s ease;
        max-width: 20rem;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &__name {
        position: relative;
        top: spacing.$spacing-xxs;
        margin-bottom: 0.6rem;
        line-height: typography.$heading-sm-line-height;
        font-size: typography.$heading-xxs-font-size;
        font-weight: typography.$font-weight-medium;
      }

      &__role {
        text-transform: uppercase;
        line-height: typography.$body-text-sm-line-height;
        color: colors.$extra-light-gray;
        font-size: typography.$body-text-sm-font-size;
        font-weight: typography.$font-weight-light;
      }
    }

    // NgbDropdown overrides for user settings menu

    & .dropdown-menu {
      right: 0;
      left: unset !important;
      z-index: 1015;
      margin: 0;
      padding-bottom: 0;
      width: max-content;
      max-height: calc(100vh - 12rem); // Viewport height - (university iframe + $top-nav-height + some slack)
      overflow: auto;
      line-height: 2.2rem;

      & .dropdown-item {
        align-items: center;
        cursor: pointer;
        padding: spacing.$spacing-xs spacing.$spacing-md;
        height: 3.6rem;
        line-height: typography.$body-text-md-line-height;
        font-size: typography.$body-text-md-font-size;

        &:focus {
          @include focus.deprecated-sisu-focus-generic-inner;
        }

        &:not(.disabled) {
          &:active {
            background-color: colors.$primary-color-light;
            color: colors.$dark-gray;
          }

          &.active {
            background-color: colors.$white;
            color: colors.$dark-gray;

            &:hover {
              background-color: colors.$primary-color-light;
            }
          }
        }
      }
    }
  }
}
