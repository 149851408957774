@use 'sis-assets/foundations/spacing/tokens' as spacing;
@use 'sis-assets/foundations/colors/colors' as colors;

app-add-own-calendar-event-modal {
  .app-add-own-calendar-event-modal {
    &__event-list {
      list-style-type: none;

      &__event {
        &--excluded{
          text-decoration: line-through;
        }

        &__day {
          display: inline-block;
          position: relative;
          width: spacing.$spacing-xl;
          text-decoration: inherit;
        }

        &__date {
          display: inline-block;
          position: relative;
          width: 8rem;
          text-decoration: inherit;
        }

        &__time {
          display: inline-block;
          position: relative;
          width: 8rem;
          text-decoration: inherit;
        }
      }
    }
  }
}
