@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

app-open-university-offering {
  display: block;

  .product-container {
    border: spacing.$pixel-2 solid colors.$primary-color;
    padding: spacing.$spacing-sm;
  }
}
