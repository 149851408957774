@media print {
  body {
    margin: 0 !important;

    page-header div,
    sis-breadcrumbs div,
    footer,
    top-nav,
    .nav {
      display: none;
    }

    & .hidden-print {
      display: none;
    }
  }
}
