@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;
@use '@funidata/ngx-fudis' as fudis;

sis-plan-structure-composite-rule {
  .sis-plan-structure-composite-rule {
    &__body {
       .rule-group {
        border-left: spacing.$pixel-1 solid colors.$light-gray;

        &--active {
          border-left: spacing.$pixel-2 solid colors.$primary-color;
        }
      }
    }
  }
  .plan-structure-composite-single-rule-radio-button > sis-simple-radio-button > .simple-radio-button > .simple-radio-button__content > .simple-radio-button__label {
    text-transform: uppercase;
  }
}
