@use "@funidata/ngx-fudis" as fudis;

app-study-progress-graph {
  display: block;

  & .big-font {
    font-size: 5rem;

    @include fudis.breakpoint-max('sm') {
      font-size: 3rem;
    }
  }

  & .fudis-heading__variant__xxs {
    font-weight: 400 !important;
  }

  .plan-container {
    display: flex;
    justify-content: space-around;
  }

  & .separator {
    border-right: fudis.$pixel-1 solid fudis.$color-gray-light;
  }

  & .plan-section {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    font-size: 1.4rem;
    font-weight: 400;
  }

  & .planned-section::after {
    background-color: fudis.$color-gray-light;
    width: fudis.$pixel-1;
  }
}
