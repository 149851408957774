sis-create-plan {
  & .modal-header {
    display: block;

    & ul.nav.nav-tabs {
      margin: 2rem -5.6rem 2.5rem;
      padding-left: 5.6rem;

      & li:first-child {
        margin-left: 0;
      }
    }
  }
}
