@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

search-filter-options-menu {
  & button.btn.btn-search {
    margin-right: 0;
    border-width: spacing.$pixel-1 spacing.$pixel-1 spacing.$pixel-1 0;
    border-color: colors.$light-gray;
    background-color: colors.$extra-light-gray;
    padding: 0 0 0 1.5rem;
    width: auto;
    color: colors.$dark-gray;
    font-weight: 400;

    &::after {
      margin: 0 0 spacing.$pixel-3 0.5rem;
      vertical-align: middle;
    }

    &:hover, &:active {
      border-color: colors.$light-gray;
      background-color: colors.$extra-light-gray;
      color: colors.$dark-gray;
    }

    &:disabled {
      pointer-events: none;
    }
  }
}
