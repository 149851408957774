@use 'sis-assets/foundations/breakpoints/breakpoints' as breakpoints;
@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

app-teaching-not-selected {
  & .teaching-not-selected-container {
    margin-top: spacing.$spacing-md;
    margin-left: calc(-1 * #{spacing.$spacing-xs});

    & .grid-container {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 2rem;
      align-items: flex-start;
      padding-bottom: 3.2rem;

      @media (max-width: breakpoints.$screen-md-max) {
        grid-template-columns: repeat(2, 1fr);
      }

      @media (max-width: breakpoints.$screen-sm-max) {
        grid-template-columns: repeat(1, 1fr);
      }
    }

    & h2 {
      margin: 2rem 0;
    }

    & h3 {
      margin-top: spacing.$spacing-xl;
      margin-bottom: spacing.$spacing-xs;
    }
  }

  & .guidance {
    margin: 0 0 2rem;
    padding-left: 0;
    font-style: normal;
  }

  & .course-units {
    row-gap: 1rem;
    column-gap: 1rem;
    padding-left: 0;

  }

  & .enrolment-course-unit-box {
    border: spacing.$pixel-1 solid black;
    background-color: colors.$light-gray;
  }

  & .enrolment-course-unit-text {
    padding: 1.5rem;
  }

  & .icon-down {
    & svg {
      transform: rotate(90deg);
    }
  }

  & .icon-up {
    & svg {
      transform: rotate(-90deg);
    }
  }
}
