@use 'sis-assets/foundations/breakpoints/breakpoints' as breakpoints;
@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

app-course-unit-realisation-info {
  & .hide-headers {
    display: none;
    visibility: hidden;
  }

  & .time-series-border {
    margin-bottom: 0.5rem;
    border-bottom: spacing.$pixel-1 solid colors.$extra-light-gray;
  }

  & .time-series-border:last-child {
    margin-bottom: 0;
    border-bottom: 0;
  }

  & .study-sub-group-info {
    padding-bottom: 3rem;

    & .event-detail-row {
      margin-bottom: 1rem;
      line-height: 1.4rem;

      & .day, .date, .time {
        font-weight: 400;
      }
    }

    & .locations {
      box-sizing: content-box;
      display: flex;

      & .building-name {
        padding-left: 0.5rem;
        font-weight: 400;
      }

      & .irregularities, .no-location, .building-name {
        padding-left: 0.5rem;
        font-weight: 400;

        @media (max-width: breakpoints.$screen-md-max) {
          padding-left: 0;
        }
      }

      @media (max-width: breakpoints.$screen-md-max) {
        display: inline-block;
      }
    }

    & .teachers {
      display: flex;

      & .teacher-summary {
        padding-left: 0.5rem;
        white-space: nowrap;
        font-weight: 400;

        @media (max-width: breakpoints.$screen-md-max) {
          padding-left: 0;
        }
      }

      @media (max-width: breakpoints.$screen-md-max) {
        display: inline-block;
        padding-left: 0;
      }
    }

    & .exceptions-expandable-link-button {
      & > button .title {
        font-weight: 400;
      }
    }

    & .row-align-left {
      padding-left: 1.5rem;
    }

    & .event-detail-table {
      margin-top: 1rem;
      padding: 0 !important;
      width: 100%;

      & tr, td:first-child {
        padding-left: 0;
      }

      & tr {
        display: flex;
        align-items: flex-start;
        border-top: spacing.$pixel-1 solid colors.$extra-light-gray;
        padding: 0.5rem 0;
        line-height: 1.4rem;

        &:hover {
          background-color: colors.$extra-light-gray;
        }
      }

      & .teacher {
        padding-left: 0.5rem;
        white-space: normal;
        font-weight: 400;

        @media (max-width: breakpoints.$screen-md-max) {
          display: block;
          padding-left: 0;
        }
      }

      & .cancelled-event {
        text-decoration: line-through;
      }
    }

    & .study-event-table {
      width: 100%;

      & .study-event-body {
        vertical-align: baseline;

        & .table-justify-left {
          padding-left: 0;
        }

        & .study-event-row {
          display: flex;
          align-items: baseline;
        }

        & .cancelled-events {
          display: flex !important;
          align-items: center;
        }
      }
    }
  }
}
