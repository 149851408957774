@use 'sis-assets/foundations/spacing/tokens' as spacing;

.app-attainment-info {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  padding: spacing.$spacing-xxs 0 !important;
  width: 100%;
  color: unset;

  &__icon {
    margin-right: spacing.$spacing-sm;
  }

  &__description {
    margin: 0;
    line-height: spacing.$spacing-sm;
    word-break: break-all;
    font-size: 1.4rem;
    font-weight: 400;

    &__date {
      word-break: keep-all;
    }
  }
}
