@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

sis-plan-structure-module-selection-state {
  .sis-plan-structure-module-selection-state {
    &__status {
      color: colors.$middle-gray;

      &--invalid {
        color: colors.$accent;
      }

      &--attained {
        color: colors.$green
      }

      & sis-icon svg {
        margin-top: -0.8rem;
        height: spacing.$spacing-md;

      }
    }
  }
}
