@use 'sis-assets/foundations/spacing/tokens' as spacing;

app-course-unit-attainment-details {
  & .grade-raise-info {
    & fudis-heading {
      margin-bottom: spacing.$spacing-lg !important;
    }

    & fudis-button {
      & button {
        padding: 0 !important;
      }
    }
  }
}
