@use '../colors/colors' as colors;
@use '../spacing/tokens' as spacing;
@use '../typography/tokens' as typography;

// Bootstrap 4 customization: https://getbootstrap.com/docs/4.4/getting-started/theming/#sass-options
// Touching these will break the components' styles, so avoid it if you don't know what you are doing!

// COMMON
$enable-shadows: false;
$enable-caret: false;
$enable-button-pointers: false;

// ALERTS
$alert-padding-y: 0;
$alert-padding-x: 0;
$alert-margin-bottom: 0;
$alert-border-radius: 0;
$alert-link-font-weight: typography.$font-weight-regular;
$alert-border-width: 0;

// BADGES
$badge-font-size: 1.2rem;
$badge-font-weight: typography.$font-weight-bold;
$badge-color: colors.$white;
$badge-padding-y: spacing.$pixel-3;
$badge-padding-x: 0.6rem;
$badge-border-radius: 1rem;

// DROPDOWNS
$dropdown-font-size: 1.4rem;
$dropdown-color: colors.$dark-gray;
$dropdown-bg: colors.$white;
$dropdown-border-color: colors.$light-gray;
$dropdown-border-radius: 0;
$dropdown-border-width: spacing.$pixel-1;
$dropdown-inner-border-radius: 0;
$dropdown-divider-bg: colors.$white;
$dropdown-divider-margin-y: 0.6rem;
$dropdown-box-shadow: none; // $enable-shadows false
$dropdown-link-color: colors.$dark-gray;
$dropdown-link-hover-color: colors.$dark-gray;
$dropdown-link-hover-bg: colors.$primary-color-light;
$dropdown-link-active-color: colors.$white;
$dropdown-link-active-bg: colors.$primary-color;
$dropdown-link-disabled-color: colors.$light-gray;
$dropdown-item-padding-y: 0.5rem;
$dropdown-item-padding-x: 2rem;
$dropdown-header-color: colors.$middle-gray;
$dropdown-header-padding: spacing.$pixel-2 0.5rem;

// FORMS
$label-margin-bottom: 0;
$input-padding-x: 0.9rem;
$input-font-size: 1.6rem;
$input-font-weight: 400;
$input-bg: colors.$white;
$input-disabled-bg: colors.$extra-light-gray;
$input-disabled-border-color: colors.$light-gray;
$input-color: colors.$dark-gray;
$input-border-color: colors.$light-gray;
$input-border-width: 0.1rem;
$input-box-shadow: none;
$input-border-radius: 0;
$input-focus-border-color: colors.$primary-color;
$input-focus-box-shadow: none;
$input-placeholder-color: colors.$middle-gray;
$input-height: 4rem;
$form-group-margin-bottom: 3.8rem;

// MODALS
$modal-xl: 145rem;
$modal-lg: 110rem;
$modal-md: 90rem;
$modal-sm: 60rem;
$zindex-modal-backdrop: 1050 !important; // dirty hack to add whitelayer when a modal opens over another modal

// BUTTONS

// default btn
$btn-border-radius: spacing.$pixel-2;
$btn-border-width: spacing.$pixel-2;
$btn-font-family: typography.$font-family;
$btn-font-weight: typography.$font-weight-bold;
$btn-line-height: 3.5rem;
$btn-padding-y: 0;
$btn-padding-x: 1.6rem;
$btn-white-space: nowrap;

// lg
$btn-border-radius-lg: spacing.$pixel-2;
$btn-line-height-lg: 3.3rem;
$btn-padding-y-lg: 0;
$btn-padding-x-lg: 1.6rem;

// sm
$btn-border-radius-sm: spacing.$pixel-2;
$btn-line-height-sm: spacing.$spacing-md;
$btn-padding-y-sm: 0;
$btn-padding-x-sm: 1.4rem;
