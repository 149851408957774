@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

app-print-transcript-modal{
  & .modal-header {
    margin-bottom: 1rem;
  }

  & .modal-body {
    & sis-radio-button {
      & .control-indicator {
        z-index: 1;
      }

      & + .guidance {
        margin-left: 3.7rem;
      }
    }

    & h3 {
      margin-top: 0;
      margin-bottom: 2rem;
    }

   & .course-assessments {
     margin-top: 3rem;
     margin-bottom: -2rem;
     margin-left: spacing.$pixel-1;

     & sis-form-checkbox {
       margin-top: 0.5rem;
     }

     & .guidance {
       margin-left: 3.7rem;
     }
   }

    & fieldset {
      padding-bottom: 1rem;
    }

    & legend:not(.visually-hidden) {
      display: contents;
    }

    & .study-rights-container {
      padding-bottom: 0.5rem;

      & .study-rights {
        position: relative;
        margin-left: 2.2rem;

        &::before {
          position: absolute;
          top: -1.4rem;
          left: -1rem;
          border-left: spacing.$pixel-1 dotted colors.$middle-gray;
          height: calc(100% + 5.2rem);
          content: '';
        }

        &::after {
          position: absolute;
          bottom: -3.8rem;
          left: -1.2rem;
          border-radius: 0.5rem;
          background-color: colors.$middle-gray;
          width: 0.5rem;
          max-width: 0.5rem;
          height: 0.5rem;
          max-height: 0.5rem;
          content: '';
        }
      }
    }
  }
}
