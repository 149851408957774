@use "@funidata/ngx-fudis" as fudis;
@use 'sis-assets/foundations/breakpoints/breakpoints' as breakpoints;
@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;
@use './mixins' as mixin;

/* stylelint-disable */
// Common dropdown styles (except for ui-select, which's styles are in uiSelect.scss)
// If you make changes in this file, make sure the same changes are done in uiSelect.scss

sis-typeahead {
  & .dropdown-menu {
    & .dropdown-item {
      &:not(.disabled) {
        &.active {
          background-color: colors.$primary-color-light;
          color: colors.$dark-gray;

          & .additional-info {
            background-color: colors.$primary-color-light;
            color: colors.$dark-gray;

            &:not(.compact)::before {
              @include mixin.gradient(rgba(241, 241, 241, 0.00), colors.$primary-color-light);
            }
          }

          &:active {
            background-color: colors.$primary-color;
            color: colors.$white;

            & .additional-info {
              background-color: colors.$primary-color;
              color: colors.$primary-color-light;

              &:not(.compact)::before {
                @include mixin.gradient(rgba(241, 241, 241, 0.00), colors.$primary-color);
              }
            }
          }
        }
      }
    }
  }

  & .multiple-line-items {
    &.dropdown-menu,
    & + .dropdown-menu {
      & .dropdown-item {
        &.active {
          & .additional-data {
            color: colors.$middle-gray;
          }

          &:active {
            & .additional-data {
              color: colors.$primary-color-light;
            }
          }
        }
      }
    }
  }

  &.with-result-template {
    & .dropdown-menu {
      & .dropdown-item {
        display: block;
        justify-content: normal;

        & > * {
          display: flex;
          justify-content: space-between;
        }
      }
    }
  }

  & > .form-control {
    & + .dropdown-menu {
      margin-top: -0.1rem;
    }
  }

  & mark {
    border-width: spacing.$pixel-1;
    border-style: dotted;
    background-color: colors.$primary-color-light;
    padding: 0;
  }
}

sis-typeahead,
[ngbdropdown],
.dropdown, .dropup { // .dropdown && .dropup to support the dropdowns with container="body"
  & .multiple-line-items {
    &.dropdown-menu,
    & + .dropdown-menu {
      & .dropdown-item {
        display: block;
        border-bottom: spacing.$pixel-1 solid colors.$extra-light-gray;

        & .additional-data {
          display: block;
          color: colors.$middle-gray;
          font-size: 1.2rem;
        }

        &.active, &:active {
          & .additional-data {
            color: colors.$primary-color-light;
          }
        }
      }
    }
  }

  & .dropdown-toggle {
    &:hover {
      text-decoration: none;
    }

    &:not(.btn) {
      &:not(:disabled) {
        &:not([disabled]) {
          cursor: pointer;
        }
      }
    }

    > sis-icon[icon="chevron"] {
      @include mixin.dropdown-chevron(relative, 0, 0);
    }

    &.text-dropdown {
      border: 0;
      background-color: transparent;
      padding: 0;
      font-size: 1.4rem;
      font-weight: 300;
    }

    &.btn {
      & + .dropdown-menu {
        margin: 0;
      }
    }

    &.form-control {
      padding-right: spacing.$spacing-lg;
      overflow: hidden;
      text-align: left;
      text-overflow: ellipsis;
      white-space: nowrap;
      line-height: fudis.$body-text-md-line-height;

      > sis-icon[icon="chevron"] {
        @include mixin.dropdown-chevron(absolute, 0.6rem, 0.3rem);
      }

      &.item-has-additional-info {
        padding-right: spacing.$spacing-lg;
      }

      & + .dropdown-menu {
        margin: -0.1rem 0 0;
      }
    }

    &.form-control-inverted {
      border: spacing.$pixel-1 solid colors.$dark-gray;
      background-color: colors.$middle-gray;
      color: colors.$extra-light-gray;

      &.nothing-selected {
        color: colors.$extra-light-gray;
      }

      &.dropdown-toggle::after {
        color: colors.$extra-light-gray;
      }
    }

    & .additional-info {
      position: absolute;
      right: 3rem;
      height: 2.6rem;
    }

    & .compact-additional-info {
      display: inline;
      margin-left: 0.25em;
      color: colors.$middle-gray;
      font-size: 1.2rem;
      font-weight: 400;
    }
  }

  &.show {
    & .dropdown-toggle {
      &.btn-primary:not(.btn-hollow) {
        border-color: colors.$primary-color;
        background-color: colors.$primary-color;
      }

      &.btn-secondary:not(.btn-hollow) {
        border-color: colors.$dark-gray;
        background-color: colors.$dark-gray;

        & sis-icon[icon="three-dots"] svg {
          fill: colors.$white;
        }
      }
    }
  }

  & .clear-value { // blue remove icon for dropdown-select
    position: absolute;
    top: 0.2rem;
    right: 0.8rem;
    background-color: colors.$white;
  }

  & .dropdown-menu {
    box-shadow: 0 spacing.$pixel-2 0 rgba(0, 0, 0, 30%);
    min-width: 100%;
    max-height: 30vh;
    overflow: auto;

    &.dropdown-menu-right {
      right: 0;
      left: auto !important;
    }

    & .dropdown-header {
      padding: spacing.$pixel-2 1rem;
      font-size: 1.2rem;
      font-weight: 400;

      & .dropdown-group {
        &__option {
          font-size: 1.4rem;
        }
      }
    }

    & .dropdown-item {
      display: flex;
      position: relative;
      justify-content: space-between;
      margin: 0;
      cursor: pointer;
      line-height: fudis.$body-text-md-line-height;
      min-height: fudis.$spacing-lg;

      @media (max-width: breakpoints.$screen-sm-max) {
        white-space: normal;
      }

      &:not(.disabled) {
        &.active, &:active {
          background-color: colors.$primary-color;
          color: colors.$primary-color-light;

          & .additional-info {
            background-color: colors.$primary-color;
            color: colors.$primary-color-light;

            &:not(.compact)::before {
              @include mixin.gradient(rgba(241, 241, 241, 0.00), colors.$primary-color);
            }
          }
        }

        &:not(.active) {
          &:not(:active) {
            &:hover {
              background-color: colors.$primary-color-light;

              & .additional-info {
                background-color: colors.$primary-color-light;

                &::before {
                  @include mixin.gradient(rgba(241, 241, 241, 0.00), colors.$primary-color-light);
                }
              }
            }
          }
        }
      }

      &.disabled {
        &.menu-tooltip-holder {
          cursor: default;
          pointer-events: auto;
        }
      }

      & + .dropdown-header {
        margin-top: spacing.$spacing-xs;
      }

      & .additional-info {
        right: 0;
        margin-left: spacing.$spacing-xs;
        padding: 0;
        max-width: 100%;
        text-align: right;
      }

      & .ngb-highlight, b {
        background-color: colors.$primary-color-light;
        color: colors.$dark-gray;
        font-weight: 400;
      }
    }
  }

  & .additional-info {
    position: relative;
    right: spacing.$spacing-md;
    background-color: colors.$white;
    padding-right: 1rem;
    max-width: 30%;
    color: colors.$middle-gray;

    &:not(.compact)::before {
      @include mixin.gradient(rgba(241, 241, 241, 0.00), colors.$white);

      display: block;
      position: absolute;
      left: -2.5rem;
      width: 2.5rem;
      height: 1.8rem;
      content: "";
    }

    & .additional-info-text {
      display: inline-block;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  & .dropdown-item.disabled,
  & .dropdown-item:disabled {
    @include mixin.dropdown-option-disabled;

    & .additional-info,
    & .additional-data {
      @include mixin.dropdown-option-disabled;

      &::before {
        @include mixin.gradient(rgba(241, 241, 241, 0.00), colors.$extra-light-gray);
      }
    }
  }
}

.select-wrapper { // styles for native HTMl select
  position: relative;

  & select {
    appearance: none;
    border: spacing.$pixel-1 solid colors.$middle-gray;
    padding-top: 0.6rem;
    padding-right: spacing.$spacing-lg;
    overflow: hidden !important;
    text-overflow: ellipsis;
    white-space: nowrap;
    height: spacing.$spacing-xl;
  }

  @-moz-document url-prefix() { // overwrites Firefox styles like focus border
    select, select:-moz-focusring, select::-moz-focus-inner {
      padding-left: 0.5rem;
      text-shadow: 0 0 0 black !important;
      color: transparent !important;
    }
  }
}
/* stylelint-enable */
