@use 'sis-assets/foundations/breakpoints/breakpoints' as breakpoints;
@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

common-plan-module {
  width: 100%;

  & .module-credits {
    min-width: 10rem;
    font-weight: 400;

    & div.credit-range {
      display: block;
      text-align: center;
      line-height: 1.8rem;
      white-space: nowrap;
    }

    & .planned-attained-credits {
      min-width: 10rem;
      text-align: center;
      line-height: 1.2rem;

      & .planned-credits, .attained-credits {
        display: inline-block;
        font-size: 1rem;
      }

      & .planned-credits {
        min-width: 4.3rem;
        text-align: right;
      }

      & .attained-credits {
        border-width: 0 0 0 spacing.$pixel-1;
        border-style: solid;
        border-color: colors.$middle-gray;
        padding-left: spacing.$pixel-3;
        min-width: 4.7rem;
        text-align: left;
        color: colors.$middle-gray;
      }
    }
  }

  & .accordion-panel {
    & .header {
      & .cell {
        text-align: left;
        white-space: normal;

        &.info-box {
          min-width: 23rem;

          @media (max-width: breakpoints.$screen-sm-max) {
            min-width: auto;
          }
        }

        &.action-button button {
          margin-right: -8.7rem;
        }
      }
    }

    &.accordion-panel-noblock-dotted-content {
      & .header {
        & > .collapse {
          vertical-align: middle;
        }
      }

      &.highlighted {
        border: spacing.$pixel-2 solid colors.$primary-color-light;

        & > .header {
          background-color: colors.$primary-color-light;

          & .cell.average {
            background-color: colors.$primary-color-light;
          }
        }
      }

      & .content {
        & .content-row {
          & .content-text {
            display: flex;
            flex-wrap: wrap;
            padding: 1rem 0 1rem 5rem;

            &::before {
              top: -2.2rem;
              z-index: -1;
              height: calc(100% + 1.1rem);
            }
          }
        }
      }
    }
  }

  & .tree-list-body-inner {
    display: inline-block;
    vertical-align: top;

    & div.courseunit {
      margin-top: 0;
    }
  }

  & common-course-unit {
    vertical-align: top;
  }
}
