@use '@funidata/ngx-fudis' as fudis;
@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

.combo {
  display: block;
  position: relative;
  margin-bottom: 1.5em;
  max-height: spacing.$spacing-xl;
}

// the chevron in the input
.select-combobox-chevron::after {
  display: block;
  position: absolute;
  top: 10%;
  right: spacing.$spacing-sm;
  width: 1.2rem;
  height: 1.2rem;
  font-family: SISUicons, serif; // serif is fallback to avoid sonarqube bug issue
  font-size: spacing.$spacing-md !important;
  content: "W";
  pointer-events: none;
}

.form-control.combobox-select-input {
  display: block;
  border: spacing.$pixel-1 solid colors.$light-gray;
  border-radius: 0;
  background-clip: padding-box;
  background-color: colors.$white;
  cursor: pointer;
  padding: spacing.$spacing-xs spacing.$spacing-lg spacing.$spacing-xs spacing.$spacing-xs;
  height: spacing.$spacing-xl;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:focus {
    @include fudis.focus-form-field;
  }
}

.combobox-select-label {
  display: block;
  margin-bottom: 0.25em;
  font-size: 2rem;
  font-weight: 100;
}

.combo-menu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  float: left;
  z-index: 1000;
  margin: -0.1rem 0 0;
  border: spacing.$pixel-1 solid colors.$light-gray;
  border-radius: 0;
  box-shadow: 0 spacing.$pixel-2 0 rgb(0 0 0 / 30%);
  background-clip: padding-box;
  background-color: colors.$white;
  cursor: pointer;
  padding: 0.5rem 0;
  min-width: 100%;
  max-height: 30vh;
  overflow: auto;
  list-style: none;
  text-align: left;
  color: colors.$dark-gray;
  font-weight: 400;
}

.open .combo-menu {
  display: block;
}

.combo-option {
  padding: 0.5rem 2rem;
}

.combo-option:hover {
  background-color: colors.$primary-color-light;
}

.combo-option.option-current {
  background-color: colors.$primary-color-light;
}

.combo-option[aria-selected="true"] {
  background-color: colors.$primary-color;
  text-decoration: none;
  color: colors.$primary-color-light;
}

.input-label {
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.info-container {
  display: flex;
  justify-content: space-between;
}

.additional-info-text {
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.additional-info {
  margin-left: 1rem;
  max-width: 100%;
  text-align: right;

  &.input {
    max-width: 30%;
  }
}
