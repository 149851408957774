@use '../mixins' as top-navigation;

sis-university-menu {
  & .university-menu {
    @include top-navigation.settings-menu-group;

    margin-top: 0;
    border-top: 0;
  }
}
