@use 'sis-assets/foundations/breakpoints/breakpoints' as breakpoints;
@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

app-student-module-info-modal {
  & .degree-programme-application-requested-block {
    position: relative;
    background-color: colors.$primary-color;
    padding: spacing.$spacing-xs spacing.$spacing-md;
    color: colors.$white;

    & a.application-link {
      margin-left: spacing.$spacing-xxs;
      text-decoration: underline;
      color: colors.$white;
    }
  }

  & .module-attainment-application-block {
    position: relative;
    background-color: colors.$primary-color;
    padding: spacing.$spacing-xs spacing.$spacing-md;
    color: colors.$white;

    & .warning-guidance {
      font-style: italic;
    }

    & .button-holder {
      position: absolute;
      top: 60%;
      right: spacing.$spacing-zero;
      transform: translate(0%, -70%);
      margin: spacing.$spacing-zero;
      padding-right: spacing.$spacing-md;
      text-align: right;

      & > button {
        margin-right: spacing.$spacing-zero;
      }
    }

    @media (max-width: breakpoints.$screen-xs-max) {
      padding: spacing.$spacing-sm;

      & .button-holder {
        position: relative;
        transform: none;
        margin-top: spacing.$spacing-sm;
        padding-right: spacing.$spacing-sm;
        text-align: left;
      }
    }
  }
}
