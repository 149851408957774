sis-attained-qualification {
  & .summary-data,
  & .associated-study {
    margin-bottom: 3rem;
  }

  & .studymodule,
  & .courseunit {
    margin-bottom: 0.5em;
  }
}
