@use '@funidata/ngx-fudis' as fudis;

/* Spacing tokens in 8px base unit */
$spacing-zero: fudis.$spacing-none; // 0px
$spacing-xxs: fudis.$spacing-xxs; // 4px
$spacing-xs: fudis.$spacing-xs; // 8px
$spacing-sm: fudis.$spacing-sm; // 16px
$spacing-md: fudis.$spacing-md; // 24px
$spacing-lg: fudis.$spacing-lg; // 32px
$spacing-xl: fudis.$spacing-xl; // 40px
$spacing-xxl: fudis.$spacing-xxl; // 64px

/* Pixel tokens - use sparingly */
$pixel-1: fudis.$pixel-1; // 1px
$pixel-2: fudis.$pixel-2; // 2px
$pixel-3: fudis.$pixel-3; // 3px
