@use 'sis-assets/foundations/colors/colors' as colors;

.page-studyplan-print {
  /* Remove padding added for navigation bar (in layout.scss) because
   * the study plan print view does not show the header */
  padding-top: 0;
}

.study-plan-print {
  margin: 1em 5em;
}

.study-plan-print table tr {
  border: thin solid black;
}

.study-plan-print table tr.collection {
  /* Use font weight instead of background color for
   * highlighting, because browsers by default seem
   * to ignore background colors when printing */
  font-weight: bold;
}

.study-plan-print table tr td, .study-plan-print table tr th {
  padding: 0.5em;
}

.study-plan-print .is-planned       { color: colors.$green; }
.study-plan-print .is-imperfect     { color: colors.$red; }
.study-plan-print .is-invalid       { color: colors.$red; }
.study-plan-print .is-started       { color: colors.$dark-gray; }

@media print {
  .study-plan-print {
    /* Use small font size to avoid the course unit names wrapping onto
     * separate lines */
    font-size: xx-small;
  }

  .study-plan-print .no-print {
    display: none !important;
  }
}
