@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

sis-typeahead {
  & .sis-typeahead {
    position: relative;

    & .search-with-svg-icon {
      position: absolute;
      top: spacing.$pixel-3;
      right: spacing.$pixel-2;
      margin-right: 0;
    }

    & .clear-value {
      top: spacing.$pixel-2;
      right: spacing.$pixel-2;
      background-color: transparent;
      background-image: linear-gradient(to right, rgb(241 241 241 / 0%) 0%, colors.$white 40%);
    }
  }
}
