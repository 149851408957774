@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

sis-study-substitution-box {
  & .substitution-box {
    max-width: 40rem;
    line-height: 2rem;

    &.multiple-substitutions {
      border: spacing.$pixel-1 solid colors.$dark-gray;
      background-color: colors.$extra-light-gray;

      & .study-wrapper {
        margin: spacing.$spacing-xs;
        background-color: colors.$white;
      }
    }
  }
}
