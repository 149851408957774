@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

sis-checkbox-tree {
  .checkbox-tree {
    display: flex;
    align-items: center;
    margin-top: spacing.$pixel-1;
    min-height: spacing.$spacing-lg;

    & .checkbox-tree-button-container {

      // The SVG icon component has vertical whitespace that messes up the margins -> unset with negative margins.
      margin-top: -1rem;
      margin-bottom: -1rem;
      min-width: spacing.$spacing-lg;
    }
  }

  .checkbox-children {
    display: none;
    margin-left: spacing.$spacing-sm;
    border-left: spacing.$pixel-1 dotted colors.$middle-gray;
    padding-left: spacing.$spacing-xs;
  }

  .checkbox-expanded {
    display: block;
  }

  .checkbox-tree-contents {
    flex-direction: row-reverse;
    justify-content: space-between;
    border-bottom: spacing.$pixel-1 solid colors.$light-gray;
    padding-right: 0.5rem;
    padding-bottom: spacing.$spacing-xxs;

    .labels {
      position: relative;
      top: spacing.$pixel-2;
    }
  }

  .checkbox-tree-top-level {
    .labels {
      font-weight: normal;
    }
  }

  .checkbox-tree-control {
    margin-right: 0;
  }

  ul {
    list-style: none;
  }
}

sis-checkbox-iconified {
  flex-grow: 1;
}
