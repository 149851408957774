@use 'sis-assets/foundations/breakpoints/breakpoints' as breakpoints;
@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

sis-message-banner {
  & .message-banner {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    border-width: spacing.$pixel-1;
    border-style: solid;
    background-color: colors.$white;
    padding: spacing.$spacing-xs spacing.$spacing-sm;
    line-height: 1.4;

    & a {
      color: colors.$primary-color;
      font-weight: 400;
    }

    & sis-icon {
      margin-right: spacing.$spacing-sm;

      @media (max-width: breakpoints.$screen-xs-max) {
        margin-right: spacing.$spacing-sm;
      }
    }

    & .message-container {
      position: relative;
      top: spacing.$pixel-2;
      flex-grow: 1;
    }

    & .message-content {
      font-size: 1.4rem;
      font-weight: 500;

      & em {
        font-style: italic;
      }
    }

    & .message-banner-additional-info sis-icon {
      margin-right: spacing.$spacing-xs;
    }

    & .non-button {
      & sis-icon {
        margin: 0;
      }
    }

    @media (max-width: breakpoints.$screen-xs-max) {
      padding: spacing.$spacing-xs;
    }
  }
}
