@use 'sis-assets/foundations/spacing/tokens' as spacing;

sis-localized-string-editor {
  & label {
    margin-right: 1.2rem;
  }

  & ul.nav.nav-pills-localize {
    margin: 0 0 spacing.$spacing-xxs;
  }

  & p.help-block {
    margin-bottom: -2.3rem;
  }
}
