@use 'sis-assets/foundations/spacing/tokens' as spacing;

app-carousel-product-info {
  display: block;

  sis-description-list {
    dl {
      .form-group-mimic {
        margin-bottom: 0 !important;

        &:not(:first-of-type) {
          margin-top: spacing.$spacing-sm;
        }

        dt {
          margin-bottom: 0.6rem;
          text-transform: none;
          line-height: 1.8rem;
          font-size: 1.2rem;
        }

        dd {
          font-weight: 400;
        }
      }
    }
  }
}
