@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

calendar-settings-modal {
  & .modal-body {
    & div.content {
      margin-bottom: 3rem;
      border-bottom: spacing.$pixel-1 solid colors.$extra-light-gray;
      padding-bottom: 2rem;
    }

    & .calendar-settings-link {
      display: flex;

      & input {
        flex-grow: 1;
        padding: 0 0 0 0.5rem;
        height: 2.5rem;
        font-size: 1.4rem;
      }

      & button.btn-hollow {
        flex-grow: 1;
        margin-right: 0;
        margin-left: 1.5rem;
      }
    }
  }
}
