@use 'sis-assets/foundations/spacing/tokens' as spacing;

sis-localized-string {
  & h4,
  & .sis-label {
    display: inline-block;
    vertical-align: middle;
  }

  & sis-tooltip {
    position: relative;
    bottom: spacing.$pixel-1;
    left: 0.5rem;

    & .sis-tooltip-trigger-content {
      display: inline-block;
    }
  }
}
