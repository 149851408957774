@use '@funidata/ngx-fudis' as fudis;

// Main colors
$primary-color: fudis.$color-primary; // #1076db;
$primary-color-dark: fudis.$color-primary-dark; // #1D65B8
$primary-color-light: fudis.$color-primary-light; // #DAEDFF

// Grays
$dark-gray: fudis.$color-gray-dark; // #484848
$middle-gray: fudis.$color-gray-middle; // #727272
$light-gray: fudis.$color-gray-light; // #d4d4d4
$extra-light-gray: fudis.$color-gray-extra-light; // #f1f1f1
$white: fudis.$color-white; // #ffffff

$silver: hsl(0deg 0% 74%); // #bfbfbf

$secondary-color: $middle-gray;

// State colors
$green: fudis.$color-green; // #1E874B
$yellow: fudis.$color-yellow; // #F1C40F
$red: fudis.$color-red; // #B83C2E

/* stylelint-disable color-no-hex */

// Accent colors
$accent: #E67E22;

// Study colors (2020)
$karviainen: #E6A6A6;
$flamingo: #F1A7BA;
$ametisti: #D0B1E5;
$kesataivas: #99BAF0;
$topaasi: #94C0D3;
$minttu: #9BC8B2;
$kirva: #B9C979;
$auringonkukka: #E0D073;
$kaakao: #D3B891;
$hiekka: #C3B7A5;
$appelsiini: #F2AF74;

// Study colors light version (2020)
$karviainen-light: #E9B9B9;
$flamingo-light: #EFB8C6;
$ametisti-light: #D4C0E2;
$kesataivas-light: #AFC8EF;
$topaasi-light: #ACCFDE;
$minttu-light: #AED8C3;
$kirva-light: #D1DE9D;
$auringonkukka-light: #EEDF88;
$kaakao-light: #DBC5A4;
$hiekka-light: #D3C8B8;
$appelsiini-light: #F8C08E;

// Study colors extra light versions (2021)
$karviainen-extra-light: #E7C9C9;
$auringonkukka-extra-light: #FDF3B9;
$minttu-extra-light: #CDE3D8;
$bg-color: $extra-light-gray;
$bg-color-alt: $white;

// Generic UI colors
$ui-border-color: #cccccc;
