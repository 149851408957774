// Animation delay mixin
@mixin sis-animation-delay() {
  height: 0;
  animation-name: error-appear-delay;
  animation-delay: 0.1s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: forwards;
}

@keyframes error-appear-delay {
  from {
    height: 0;
  }

  to {
    height: auto;
  }
}
