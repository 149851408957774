@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/focus/mixins' as focus;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

search-filter-single {
  & ul.filter-selectbox {
    &.selectbox-single > li {
      padding: spacing.$spacing-xxs 0 spacing.$spacing-xxs 1rem;
      font-size: 1.4rem;

      &:focus {
        @include focus.deprecated-sisu-focus-generic-inner;
      }

      &:hover {
        background-color: colors.$primary-color-light;
      }

      &.active, &:active {
        outline: none;
        background-color: colors.$primary-color;
        color: colors.$white;

        &:hover {
          background-color: colors.$primary-color;
        }
      }
    }
  }
}
