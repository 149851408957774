@use 'sis-assets/foundations/colors/colors' as colors;

sis-referred-object-select-editor {
  & .disable-clear {
    & a.btn.btn-xs {
      display: none;
    }
  }

  .ng-select.invalid {
    .ng-select-container {
      border-color: colors.$red;
    }
  }
}
