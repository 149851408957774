@use 'sis-assets/foundations/breakpoints/breakpoints' as breakpoints;

app-contact-information {
  & .address-label {
    margin-bottom: 0.5rem;
  }

  & .grouping-panel-content {
    padding-left: 2rem !important;
  }

  & .grouping-panel {
    padding-bottom: 0;

    & .col-12 {
      padding-bottom: 0;
    }
  }

  & .grouping-panel-content-grid {
    display: grid;
    grid-template-columns: 1fr 3fr;
    margin-bottom: 0;

    & dd {
      padding-left: 1.5rem;
    }

    @media (max-width: breakpoints.$screen-xs-max) {
      grid-template-columns: 1fr 1fr;
    }

  }
}
