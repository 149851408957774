@use 'sis-assets/foundations/spacing/tokens' as spacing;

sis-disclosure-authorizations {
  & .disclosure-authorizations {
    & p.authorization-text {
      margin-bottom: 0;
    }
    & ul.authorizations {
      & sis-popover {
        margin-left: 0.5rem;
      }
    }
  }
  & .contextual-notification {
    clear: both;
    margin-bottom: 2rem;
    & ul {
      margin-bottom: 0;
      padding-left: spacing.$spacing-sm;
    }
  }
  & .checkbox-group {
    margin-bottom: 1rem;
  }

}
