sis-search {
  & sis-university-select-editor {
    display: block;
    left: 0;
  }

  & .search-trigger-button {
    & .btn {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      height: 3.6rem;
    }
  }

  & .clear-search-filters-button {
    & .btn {
      margin-top: 3.1rem;
    }
  }
}
