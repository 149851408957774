@use 'sis-assets/foundations/colors/colors' as colors;

student-application-print {
  display: block;

  & .hidden-print {
    margin-top: 3rem;

    & .print {
      margin-right: 0;
    }
  }

  & h1 {
    & .tiny-badge {
      vertical-align: 50%;
    }
  }

  & .application-container {
    & .application-information {
      margin-bottom: 2rem;
    }

    & .application-attributes {
      padding: 0;
    }

    & .decision-description {
      display: block;
    }

    & em {
      font-weight: 600;
      font-style: italic;
    }
  }

  @media print {
    @page {
      margin-top: 10mm;
      margin-right: 5mm;
      margin-left: 5mm;
      size: 210mm 297mm;
    }

    & #printable-application {
      position: relative;
      top: 0;
      left: 0;
      max-width: 99.5%;
      overflow-wrap: break-word;

      & h1 {
        margin-top: 0;
      }

      & .application-container {
        & .application-attributes, .decision-information {
          page-break-inside: avoid;
        }

        & button.link-button {
          color: colors.$dark-gray;

          & sis-icon[icon="brochure"] {
            display: none !important;
          }
        }
      }
    }
  }
}
