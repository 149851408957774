@use 'sis-assets/foundations/breakpoints/breakpoints' as breakpoints;
@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;
@forward './minorSelectionModal/minorSelectionModal.scss';
@forward './freeEdit/freeEdit.scss';
@forward './module/planModule.scss';
@forward './rules/header/ruleHeaderText.scss';
@forward './rules/anyModuleRule.scss';
@forward './rules/any-course-unit-rule-ripa-checkbox/any-course-unit-rule-ripa-checkbox.scss';
@forward './rules/rule.scss';
@forward './selectEducation/selectEducationModal.scss';
@forward './selectCourseUnitAndCompletionMethodModal.scss';
@forward './planStudyRightModal/planStudyRightModal.scss';

plan { /* stylelint-disable-line */
  & .plan-state-notification-container {
    & ~ plan-module {
      & > div {
        padding-top: 2rem;
      }
    }

    & sis-contextual-notification .contextual-notification {
      margin-bottom: 0.5rem;
    }

    & sis-contextual-notification .outdated-cu-modal-link {
      color: colors.$primary-color;
      font-weight: 400;
      display: inline-block;
    }

    & sis-contextual-notification {
      &.education-path-notification .content-text {
        padding: 0;

        & .d-flex {
          align-items: center;
          justify-content: space-between;

          & button {
            white-space: nowrap;
          }
        }
      }
    }
  }

  .courseunit-substitution-group {
    margin: 1em 1rem 0 0;
  }

  & .course-unit-planning-state {
    position: sticky;
    top:0;
    transition: top 0.2s ease, height 0.1s linear;
    z-index: 120;
    margin: 0 0 -0.8rem;
    background-color: colors.$middle-gray;
    height: 3rem;
    line-height: 3rem;
    color: colors.$white;
    font-style: italic;

    &.alert-shown {
      top: 13.5rem;
      transition: top 0.1s ease;
    }

    &__section {
      overflow: hidden;
      text-align: center;
      text-overflow: ellipsis;
      white-space: nowrap;


      &--active {
        background-color: colors.$primary-color;
        font-weight: 400;

        &::after {
          position: absolute;
          border-width: 1rem;
          border-style: solid;
          border-color: transparent;
          border-top-color: colors.$primary-color;
          width: 0;
          height: 0;
          content: " ";
          pointer-events: none;
        }
      }
    }

    @media (max-width: breakpoints.$screen-sm-max) {
      font-size: 1.2rem;
    }
  }

  & .state-credits-header {
    justify-content: end;
    transition: right 0.4s ease-in-out;
    margin-right: spacing.$spacing-sm;

    & h4 {
      margin: 0;
    }

    & .module-state-header {
      min-width: 8rem;

      > h4 {
        margin: 0;
      }
    }

    & .module-credits-header {
      display: inline-block;
      min-width: 11rem;
      text-align: center;

      > h4 {
        margin: 0;
      }

      & .sub-headers {
        line-height: 1rem;
        font-size: 1rem;
        font-weight: 400;

        & .credits-done {
          border-left: spacing.$pixel-1 solid colors.$middle-gray;
          padding-left: spacing.$pixel-3;
          color: colors.$middle-gray;
        }
      }
    }
  }

  & .content-padding {
    padding: spacing.$spacing-xs 3rem spacing.$spacing-zero 3.5rem;

    &.study-plan-container {
      @media(max-width: breakpoints.$screen-xs-max) {
        padding-right: spacing.$spacing-zero;
      }
    }

    &.has-active-selection {
      & .tree-list-heading, .tree-list-body, .tree-list-footer {
        opacity: 1;
      }
    }

    &.planning-course-unit {
      & plan-module {
        & .tree-list-heading {
          &.is_target { /* stylelint-disable-line */
            position: relative;
            z-index: 10;
            border-width: spacing.$pixel-2;
            border-style: dashed;
            border-color: colors.$primary-color;

            & .plan-course-unit-instructions {
              display: flex;
            }
          }
        }
      }
    }

    & .white-layer {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 9;
      background-color: rgb(255 255 255 / 60%);
      width: 100vw;
      height: 100vh;
    }

    @media(max-width: breakpoints.$screen-md-max) {
      padding: spacing.$spacing-xs;
    }
  }

  .side-bar-open {
    .content-padding {
      padding-right: 3rem;
      padding-left: 3rem;
    }
  }

  .main-bar {
    &.col-md-6 {
      .content-padding {
        padding: 0 1.2rem;
      }
    }

    &.col-md-9 {
      .content-padding {
        padding-left: 1.2rem;
      }

      &.right-column-is-open {
        .content-padding {
          padding-right: 1.2rem;
          padding-left: 3.5rem;
        }
      }
    }

    &.col-md-6, &.col-md-9 {
      & .state-credits-header {
        @media (max-width: breakpoints.$screen-sm-max) {
          display: none;
        }
      }
    }

    & .study-plan-container {
      transition: padding-top 0s;
    }
  }

  .collapsible-columns-container .collapsible-column-header {
    height: 5.6rem;
  }

  .course-unit-box-adding {
    transition: opacity 300ms;
    opacity: 1;
  }

  .course-unit-box-adding-add {
    transition: opacity 150ms;
    opacity: 0.5;
  }

  .course-unit-box-adding-opaque {
    transition: opacity 300ms;
    opacity: 0.5;
  }

  .course-unit-box-adding-active {
    transition: opacity 300ms;
    opacity: 1;
  }
}
