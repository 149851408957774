@use 'sis-assets/foundations/breakpoints/breakpoints' as breakpoints;
@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

// This is SCSS placeholder class, needed for @extend uses
%column-transitions {
  transition-duration: 0.4s;
  transition-property: width, padding;
  transition-timing-function: ease-in-out;

  &:last-of-type {
    & .collapsible-column-header {
      border-radius: spacing.$spacing-xxs 0 0 0;
    }
  }

  // Collapsible bar column header
  &.left-column {
    & .collapsible-column-header {
      border-radius: 0 spacing.$spacing-xxs 0 0;
    }
  }

  & .collapsible-columns-sidebutton {
    position: fixed;
    top: 60rem;
    left: 0;
    transform: rotate(270deg);
    transform-origin: left top 0;
    z-index: 100;
    margin: 0;

    & button {
      transition: background-color 350ms;
      border: none;
      border-radius: 0 0 spacing.$spacing-xxs spacing.$spacing-xxs;
      background-color: colors.$primary-color;
      cursor: pointer;
      padding: spacing.$spacing-xs 1rem;
      letter-spacing: 0.03rem;
      color: colors.$white;
      font-weight: 600;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }

    @media (max-width: breakpoints.$screen-sm-max) {
      z-index: 11;
    }
  }
}

.collapsible-columns-container {
  @extend %column-transitions;

  // Collapsible bar column header
  & .collapsible-column-header {
    @extend %column-transitions;

    position: relative;
    background-color: colors.$primary-color;
    padding: spacing.$spacing-sm;
    height: 5.6rem;
    text-transform: uppercase;
    white-space: nowrap;
    color: colors.$white;
    font-weight: 600;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    &.additional-small-header {
      padding: 0.5rem spacing.$spacing-sm;
      height: 5.6rem;
    }

    & .header-text, & .upper-additional-header {
      display: block;
      margin-top: 0.5rem;
      width: calc(100% - 4.5rem);
      overflow: hidden;
      text-transform: uppercase;
      text-overflow: ellipsis;
    }

    & .upper-additional-header {
      color: colors.$primary-color-light;
      font-size: 1.2rem;
    }

    // Collapsible bar column dismiss, i.e. close button
    & .collapsible-column-dismiss {
      display: inline-block;
      position: absolute;
      top: 1.3rem;
      right: 1.5rem;
      z-index: 50;
      border: spacing.$pixel-1 solid colors.$white;
      border-radius: spacing.$pixel-2;
      background-color: colors.$primary-color;
      cursor: pointer;
      vertical-align: middle;
      line-height: 1.8rem; // $magic
      font-size: 2.8rem;
    }
  }

  & .add-study-craft {
    margin: 1rem 0;
  }

  & .free-edit-mode-banner {
    display: flex;
    align-items: center;
    background-color: colors.$accent;
    padding: 0.5rem 2rem;
    width: 100%;
    overflow: hidden;
    color: colors.$white;

    & span.float-end {
      margin-left: auto;

      & button {
        text-decoration: underline;
        color: colors.$white;
        font-weight: 400;
      }
    }

    + .collapsible-column-content {
      height: calc(100% - 5.6rem - 3.4rem);  // 3.4rem being the height of .free-edit-mode-banner + some padding
    }
  }

  & show-view-module-content-application-link {
    + .collapsible-column-content {
      height: calc(100% - 5.6rem - 3.4rem);  // 3.4rem being the height of show-view-module-content-application-link + some padding
    }

    & + .free-edit-mode-banner {
      + .collapsible-column-content {
        height: calc(100% - 5.6rem - 3.4rem - 3.4rem);  // 3.4rem being the height of show-view-module-content-application-link and .free-edit-mode-banner + some padding
      }
    }
  }

  & .education-option-banner {
    background-color: colors.$primary-color-dark;
    padding: 0.5rem 2rem;
    width: 100%;
    overflow: hidden;
    color: colors.$white;

    & + .education-option-banner {
      border-top: spacing.$pixel-1 solid colors.$primary-color;

      + .collapsible-column-content {
        height: calc(100% - 5.6rem - 3.4rem - 3.4rem); // 3.4rem being the height of .education-option-banner and .education-option-banner + some padding
      }

      + show-view-module-content-application-link {
        border-top: spacing.$pixel-1 solid colors.$primary-color;

        + .collapsible-column-content {
          height: calc(100% - 5.6rem - 3.4rem - 3.4rem - 3.4rem); // 3.4rem being the height of show-view-module-content-application-link, .education-option-banner and .education-option-banner + some padding
        }

        + .free-edit-mode-banner {
          + .collapsible-column-content {
            height: calc(100% - 5.6rem - 3.4rem - 3.4rem - 3.4rem - 3.4rem); // 3.4rem being the height of show-view-module-content-application-link, .free-edit-mode-banner, .education-option-banner and .education-option-banner + some padding
          }
        }
      }
    }

    button {
      text-decoration: underline;
      color: colors.$white;
      font-weight: 400;
    }

    + .collapsible-column-content {
      height: calc(100% - 5.6rem - 3.4rem);  // 3.4rem being the height of .education-option-banner + some padding
    }

    + show-view-module-content-application-link {
      + .collapsible-column-content {
        height: calc(100% - 5.6rem - 3.4rem - 3.4rem); // 3.4rem being the height of show-view-module-content-application-link and .education-option-banner + some padding
      }

      + .free-edit-mode-banner {
        + .collapsible-column-content {
          height: calc(100% - 5.6rem - 3.4rem - 3.4rem - 3.4rem); // 3.4rem being the height of show-view-module-content-application-link and .free-edit-mode-banner and .education-option-banner + some padding
        }
      }
    }

    + .free-edit-mode-banner {
      + .collapsible-column-content {
        height: calc(100% - 5.6rem - 3.4rem - 3.4rem);  // 3.4rem being the height of .free-edit-mode-banner and .education-option-banner + some padding
      }
    }
  }

  & .collapsible-column-content {
    background-color: colors.$dark-gray;
    padding: 1.2rem 2rem 50rem; // with 50rem the collapsible column content stays fully visible and scrollable in cases when there is a lot of content and the screen width is narrow
    height: 100%;
    overflow-y: auto;
    color: colors.$white;

    & h3 {
      margin-top: 3rem;
    }

    & hr {
      margin: 2rem -2rem;
      border-color: colors.$middle-gray;

      & + h3 {
        margin-top: 0;
      }
    }

    & .form-group {
      margin-bottom: 2rem;

      & h3 {
        margin-top: 0;
        margin-bottom: 0.5rem;
      }
    }

    & .checklist {
      & .unplanned-sidebar {
        padding-bottom: 1rem;

        & unplanned-course-unit-realisation {
          display: block;
          margin-top: 0.5em;
        }
      }
    }

    & .dropdown-menu {
      max-width: 100%;
    }
  }

  & .left-column {
    position: fixed;
    top: 15.1rem;
    left: 0;
    transition: top 0.4s ease, opacity 0.1s linear, width 0.05s ease-in-out;
    z-index: 100;
    background: colors.$white;
    padding-right: 0;
    padding-left: 0;
    height: 100%;

    &.collapsed {
      top: auto;
      transition: top 0.4s ease, opacity 0.1s linear, width 0.05s ease-in-out;
      margin-top: 4.2rem;
      height: 100%;
    }

    & .collapsible-column-content {
      @media (max-width: breakpoints.$screen-xs-max) {
        border-bottom-right-radius: spacing.$spacing-xxs;
      }
    }

    &.placing-course-unit-instructions-open {
      top: 17.5rem;
      height: calc(100% - 17.5rem);

      @media (max-width: breakpoints.$screen-sm-max) {
        top: 16rem;
        height: calc(100% - 16rem);
      }
    }

    &.placement-active {
      @media (max-width: breakpoints.$screen-sm-max) {
        left: -100%;

        & course-unit-box, & custom-course-unit-attainment-box {
          & .courseunit.selected {
            position: fixed;
            top: 16rem;
            left: 12vw;
            width: 76vw;

            &.collapsed {
              top: 40rem;
            }
          }
        }

        & unplanned-course-unit-realisation {
          & .courseunit-realisation.selected {
            position: fixed;
            top: 16rem;
            left: 12vw;
            width: 76vw;

            &.collapsed {
              top: 40rem;
            }
          }
        }
      }
    }
  }

  & .left-column-placeholder {
    height: 5rem;
  }

  & .main-bar {
    padding-top: spacing.$spacing-md;
  }

  & .right-column {
    position: fixed;
    top: 15.1rem;
    right: 0;
    transition: top 0.4s ease, opacity 0.1s linear, width 0.05s ease-in-out;
    z-index: 100;
    background: colors.$white;
    padding-right: 0;
    padding-left: 0;
    height: 100%;

    & .header-text {
      &.header-text-free-edit {
        width: calc(100% - 12rem);
      }

      & + sis-menu-button {
        position: absolute;
        right: 5rem;
        bottom: 1rem;
        text-transform: none;

        & .btn.btn-sm.btn-secondary.btn-hollow {
          transition: none;
          border-color: colors.$white;
          padding: 0;
          height: spacing.$spacing-lg;
          text-transform: none;
          line-height: spacing.$spacing-lg;
          letter-spacing: normal;
          color: colors.$white;
        }
      }
    }

    &.collapsed {
      top: auto;
      transition: top 0.4s ease, opacity 0.1s linear, width 0.05s ease-in-out;
      margin-top: 4.2rem;
      height:  100%;
    }

    & .collapsible-column-content {
      @media (max-width: breakpoints.$screen-xs-max) {
        border-bottom-left-radius: spacing.$spacing-xxs;
      }
    }

    & .contextual-notification.cn-success.inverted {
      display: block;
      margin-bottom: 2rem;

      & .graduate-text {
        display: block;
        margin-bottom: 2rem;
      }
    }
  }

  .right-column-placeholder.closed, .left-column-placeholder.closed {
    flex: 0;
    visibility: hidden;
    padding: 0;
    width: 0;
    height: 0;
    overflow: hidden;
  }

  .right-column.closed, .left-column.closed {
    transition: top 0.4s ease, opacity 0.1s linear, width 0.05s ease-in-out;
    visibility: hidden;
    opacity: 0;
    padding: 0;
    width: 0;
    overflow: hidden;

  }

  &.alert-shown {
    & .right-column, & .left-column {
      &.placing-course-unit-instructions-open:not(.collapsed) {
        @media (max-width: breakpoints.$screen-sm-max) {
          top: 18rem;

          &.placement-active {
            & course-unit-box {
              & .courseunit.selected {
                top: 18rem;
              }
            }
          }
        }
      }
    }
  }
}
