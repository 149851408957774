@use 'sis-assets/foundations/spacing/tokens' as spacing;
@use 'sis-assets/foundations/colors/colors' as colors;

sis-attainment-list-for-study {
  display: block;

  & .header {
    margin-top: 3.8rem;
  }

  & .right-content {
    position: absolute;
    right: 0;
    z-index: 10;
    margin-top: 2rem;
    border-radius: spacing.$spacing-xxs spacing.$spacing-xxs 0 0;
    background-color: colors.$dark-gray;
    padding: 0;
    height: 100vh;

    & attainment-side-nav {
      display: block;
      position: sticky;
      top: 9.5rem;
      right: 0;
    }
  }
}
