@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

sis-free-edit {
  & .approval-request {
    display: inline-block;
    width: 100%;
  }

  & .free-edit-selectable-studies {
    margin-bottom: 1rem;

    & .selectable-header {
      height: 2.7rem;
      color: colors.$extra-light-gray;

      & .selected {
        margin-top: spacing.$pixel-2;
        color: colors.$white;
        font-size: 1.2rem;
        font-weight: 600;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }

      & .functional-badge {
        display: inline-block;
        position: relative;
        border-radius: spacing.$pixel-2;
        background-color: rgb(255 255 255 / 10%);
        padding-right: 2rem;
        padding-left: 0.6rem;
        width: 100%;
        height: 2.5rem;
        overflow: hidden;
        text-transform: uppercase;
        text-decoration-color: colors.$extra-light-gray;
        text-overflow: ellipsis;
        line-height: 2.7rem;
        white-space: nowrap;
        color: colors.$extra-light-gray;
        font-size: 1.2rem;
        font-weight: 400;

        & sis-icon {
          position: absolute;
          right: 0;

          & svg {
            fill: colors.$extra-light-gray;
            margin-top: -0.4rem;
          }
        }
      }
    }

    & .selectable-body {
      margin-left: 1.3rem;
      border-width: 0 0 0 spacing.$pixel-1;
      border-style: solid;
      border-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAAGCAYAAAACEPQxAAAAEklEQVQYV2PYv3//fwYEQOUCAIC+BnkBk+rzAAAAAElFTkSuQmCC') 0 6 0 1 round;
      padding: 1rem 0 0 1.7rem;

      &::after {
        display: inline-block;
        position: relative;
        bottom: -0.6rem;
        left: -2rem;
        border-radius: 0.5rem;
        background-color: colors.$light-gray;
        width: 0.5rem;
        max-width: 0.5rem;
        height: 0.5rem;
        max-height: 0.5rem;
        content: '';
      }
    }
  }

  & sis-rule-module-box .studymodule-top-row .extra-items-holder {
    & .additional-box {
      &:hover {
        & a.additional-box {
          background-color: colors.$primary-color-light;
        }
      }

      & a.additional-box {
        display: block;
      }
    }
  }

  & sis-plan-course-unit,
  & sis-plan-custom-course-unit-attainment {
    & .courseunit {
      margin-top: 0.5em;
    }
  }
}
