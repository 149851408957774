/* stylelint-disable property-no-vendor-prefix */
@use 'sis-assets/foundations/spacing/tokens' as spacing;
@use 'sis-assets/foundations/colors/colors' as colors;

sis-icon {
  svg {
    transition: all .6s ease;

    &.flip-180 {
      -moz-transform: rotate(180deg);
      -webkit-transform: rotate(180deg);
      -o-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      transform: rotate(180deg);
    }

    &.cw-90 {
      -moz-transform: rotate(90deg);
      -webkit-transform: rotate(90deg);
      -o-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
      transform: rotate(90deg);
    }

    &.ccw-90 {
      -moz-transform: rotate(-90deg);
      -webkit-transform: rotate(-90deg);
      -o-transform: rotate(-90deg);
      -ms-transform: rotate(-90deg);
      transform: rotate(-90deg);
    }
  }

  & .sm {
    width: spacing.$spacing-sm;
    height: spacing.$spacing-sm;
  }

  & .lg {
    width: spacing.$spacing-lg;
    height: spacing.$spacing-lg;
  }

  & .default {
    fill: colors.$dark-gray;
  }

  & .primary {
    fill: colors.$primary-color;
  }

  & .primary-dark {
    fill: colors.$primary-color-dark;
  }

  & .white {
    fill: colors.$white;
  }

  & .danger {
    fill: colors.$red;
  }

  & .success {
    fill: colors.$green;
  }

  & .attention {
    fill: colors.$yellow;
  }

  & .light {
    fill: colors.$silver;
  }
}
