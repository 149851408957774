@use 'sis-assets/foundations/breakpoints/breakpoints' as breakpoints;
@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

$popover-arrow-width: 2rem;
$popover-arrow-height: 1rem;

sis-popover {
  & .popover-trigger-text:not(.dropdown-item) {
    display: inline-block;
    border: none;
    background: none;
    padding: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: 300;

    &.underline {
      border-bottom: spacing.$pixel-1 dotted colors.$primary-color;
    }
  }

  & .popover {
    box-shadow: rgb(0 0 0 / 10%) spacing.$pixel-1 spacing.$pixel-1 spacing.$spacing-xxs 0;
    cursor: default;
    padding: 1rem;
    line-height: 1.28571429; /* stylelint-disable-line */
    color: colors.$dark-gray;
    font-family: "Fira Sans", sans-serif;
    font-size: 1.4rem;

    & .arrow {
      margin: 0;
    }

    &.bs-popover-bottom,
    &.bs-popover-top {
      & .arrow {
        margin-left: -$popover-arrow-height;
      }
    }

    &.bs-popover-right,
    &.bs-popover-left {
      & .arrow {
        margin-top: -$popover-arrow-height;
      }
    }

    &.bs-popover-top-right, &.bs-popover-bottom-right {
      & .arrow {
        margin-right: -$popover-arrow-width;
        margin-left: 0;
      }
    }

    &.bs-popover-top-left, &.bs-popover-bottom-left {
      & .arrow {
        margin-left: -$popover-arrow-width;
      }
    }

    & .popover-body {
      margin-right: 1.2rem;

      & .popover-close {
        position: absolute;
        top: 0;
        right: 0;

        & sis-icon[icon="close"] svg {
          fill: colors.$silver;
        }
      }
    }

    &.dark {
      border-color: transparent;
      background-color: colors.$dark-gray;

      &.bs-popover-bottom .popover-arrow {
        &::before {
          border-bottom-color: transparent;
        }

        &::after {
          border-bottom-color: colors.$dark-gray;
        }
      }

      &.bs-popover-top .popover-arrow {
        &::before {
          border-top-color: transparent;
        }

        &::after {
          border-top-color: colors.$dark-gray;
        }
      }

      &.bs-popover-end .popover-arrow {
        &::before {
          border-right-color: transparent;
        }

        &::after {
          border-right-color: colors.$dark-gray;
        }
      }

      &.bs-popover-start .popover-arrow {
        &::before {
          border-left-color: transparent;
        }

        &::after {
          border-left-color: colors.$dark-gray;
        }
      }

      & .popover-body {
        color: colors.$white;

        & sis-icon[icon="close"] svg {
          fill: colors.$white;
        }
      }
    }
  }

  & .popover-trigger-word {
    cursor: pointer;
    text-decoration: underline;
    text-decoration-color: colors.$primary-color;
    text-decoration-style: dotted;
  }
}
