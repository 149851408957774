@use 'sis-assets/foundations/breakpoints/breakpoints' as breakpoints;
@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

study-sub-group-structure, .study-sub-group-structure, sis-study-sub-group-structure {
  & .ssg-name {
    display: flex;
    align-items: baseline;
    margin-bottom: 0.5rem;
    border-bottom: spacing.$pixel-1 solid colors.$extra-light-gray;
    font-weight: 600;
  }

  & .teacher-time-header {
    font-size: 1.2rem;
    font-weight: 400;
  }

  & .no-teacher, .no-location, .no-events {
    font-style: italic;
  }

  & .no-events {
    margin-left: 1rem;
  }

  & .teachers {
    & .teacher-name {
      margin-right: 1rem;
    }
  }

  & sis-icon[icon="pin-small"] {
    position: relative;
    margin-right: spacing.$spacing-xxs;
  }

  & .irregularities {
    margin-top: spacing.$pixel-3;
    line-height: 1.4rem;
    font-size: 1.2rem;
    font-style: italic;
  }

  & .irregular-events {
    width: 100%;

    & .event-content {
      & > *:not(:first-child) {
        font-weight: 400;
      }
    }

    & .exceptions-table {
      margin-top: 0.5rem;
    }

    & .location {
      padding-left: 0.5rem;
      font-weight: 400;

      @media (max-width: breakpoints.$screen-md-max) {
        padding-left: 0;
      }
    }

    & .has-notice {
      & > td {
        border-top: 0 !important;
        vertical-align: top;
      }
    }

    & td {
      & .notice {
        display: flex !important;
        align-items: center;
        border-top: 0 !important;
      }

      &.time {
        padding-right: 0;
        padding-left: spacing.$spacing-md;
      }

      & .day, & .date {
        display: inline-block;
      }

      & .day, & .date, & .time {
        line-height: spacing.$spacing-md;
      }

      & .day {
        padding-left: 2rem;
        min-width: 2em;
      }

      & .date {
        min-width: 6em;
      }

      &.teacher-and-place {
        padding-left: 2.7rem;
      }

      &.notice {
        padding-top: 0;
        padding-left: 1.5rem;
      }

      & .tiny-badge, .no-location {
        margin-bottom: 0.5rem;
      }
    }
  }
}
