@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

app-cart-price {
  & dl {
    display: grid;
    grid-template-columns: 50% 50%;
  }

  & dt {
    grid-column-start: 1;
    font-weight: 300;

    &:not(:first-of-type) {
      border-top: spacing.$pixel-1 solid colors.$light-gray;
    }
  }

  & dd {
    grid-column-start: 2;
    font-weight: 300;

    &:not(:first-of-type) {
      border-top: spacing.$pixel-1 solid colors.$light-gray;
    }
  }

  & .summary-text {
    font-weight: 400;
  }
}
