@use 'sis-assets/foundations/breakpoints/breakpoints' as breakpoints;

sis-description-list-compact,
.sis-description-list-compact {
  dl.fill-by-column {
    columns: 35rem 2;
  }

  dl.fill-by-row {
    display: grid;
    grid-template-columns: 50% 50%;
  }

  dl.fill-by-column.one-column {
    column-count: 1;
  }

  dl.fill-by-row.one-column {
    display: grid;
    grid-template-columns: 100%;
  }

  @media (max-width: breakpoints.$screen-lg-max) {
    dl.fill-by-row {
      grid-template-columns: 100%;
    }
  }
}
