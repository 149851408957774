@use 'sis-assets/foundations/breakpoints/breakpoints' as breakpoints;
@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

app-student-applications {
  display: block;
  margin-top: spacing.$spacing-md;

  & table.styled-table.table {
    & thead > tr > th {
      padding-top: 0.5rem;
    }
  }
}

prior-learning-application, custom-attainment-application, extension-application {
  & h1 {
    margin-bottom: 0 !important;
  }

  & .guidance {
    display: block;
    margin-bottom: 1rem;
  }

  & .application-container {
    margin-top: 1.5rem;

    & .button-row {
      position: relative;
      top: -1rem;
      right: -1.2rem;
      text-align: right;
    }

    & .contextual-notification.cn-warning {
      margin-bottom: 1rem;
    }

    & .accordion-panel {
      &.no-expander {
        margin-top: 0;
      }

      & .content {
        & em {
          font-weight: 600;
          font-style: italic;
        }
      }
    }
  }
}

app-prior-learning-application-confirmation {
  & .confirmation-button-wrapper {
    @media(min-width: breakpoints.$screen-md-min) {
      float: right;
    }

    @media(max-width: breakpoints.$screen-sm-max) {
      display: flex;
      flex-direction: column;
      align-items: center;

      & sis-button {
        margin-bottom: spacing.$spacing-xs;
      }
    }
  }
}
