@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

.search-and-results {
  & .search-input-field {
    margin-top: 0.6rem;
  }

  & .search-group {
    display: flex;

    & + .error-list {
      margin: 0;
      padding: 0;
      list-style-type: none;
      color: colors.$red;
      font-size: 1.2rem;
    }

    & sis-input-with-debounce {
      flex: 1;
    }

    & button.btn.btn-search,
      sis-button.btn-search .btn.btn-lg {
      padding-right: 3rem;
      height: spacing.$spacing-xl;

      & + .dropdown-menu {
        max-height: unset;
        overflow: visible;
      }
    }
  }

  & .search-filters {
    margin-top: 2rem;

    & .filter-tag-holder {
      display: inline-block;
      line-height: 0;
    }
  }

  & search-result-info, .search-result-info {
    display: block;
    margin-top: 1.9rem;

    & .guidance {
      display: block;
      margin-bottom: 1rem;

      &[data-cy="search-result-none"] { // Do not remove! It'll break e2e-tests
        display: block;
        height: spacing.$pixel-1;
      }
    }
  }

  & .search-with-svg-icon {
    display: block;
    position: relative;
    top: spacing.$pixel-2;
    right: 3.5rem;
    z-index: 1;
    margin-right: -3.2rem;
    pointer-events: none;
    fill: colors.$middle-gray;

    &::before {
      position: absolute;
      right: 0;
      z-index: -1;
      background-color: transparent;
      background-image: linear-gradient(to right, rgb(241 241 241 / 0%) 0%, colors.$white 40%);
      width: 6rem;
      height: 2.8rem;
      content: '';
    }
  }
}
