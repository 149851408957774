@use 'sis-assets/foundations/breakpoints/breakpoints' as breakpoints;
@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

sis-attainment-list {
  & table {
    & tr {
      & > th {
        vertical-align: middle;

        & > span {
          line-height: spacing.$spacing-md;
        }
      }

      @media (max-width: breakpoints.$screen-lg-max) {
        sis-icon {
          display: block;
        }

        & > th {
          vertical-align: top;
        }
      }
    }

    & tbody {
      & td {
        & .attainment-status {
          white-space: nowrap;

          & sis-icon {
            margin-top: -0.6rem;
          }

          & sis-icon[icon="hourglass"] svg {
            fill: colors.$yellow;
          }

          & sis-icon[icon="check"] svg, sis-icon[icon="fail"] svg {
            fill: colors.$middle-gray;
          }
        }

        & sis-tooltip {
          margin-left: 0.5rem;
        }

        & .sorted-cell {
          font-weight: 600;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
        }
      }
    }

  }
}
