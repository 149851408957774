@use 'sis-assets/foundations/spacing/tokens' as spacing;

sis-local-date-range-editor,
local-date-range-editor {
  & input.form-control {
    &::-ms-clear { // IE, hide cross that clears input field
      display: none;
    }
  }

  & .inner-addon {
    position: relative;
  }

  & .local-date-range-editor {
    &__first-input {
      & .inner-addon {
        position: relative;
      }

      & .inner-addon::after {
        position: absolute;
        top: 50%;
        left: 100%;
        transform: translate(spacing.$spacing-sm, -50%);
        width: spacing.$spacing-xs;
        height: spacing.$spacing-md;
        content: "\2013";
      }
    }
  }

  & .date-range-gutter {
    display: block;
    width: spacing.$spacing-md;
    height: 100%;
  }
}
