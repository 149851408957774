@use 'sis-assets/foundations/breakpoints/breakpoints' as breakpoints;
@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;
@use 'sis-assets/foundations/typography/mixins' as typography;

sis-tiny-static-form-group {
  display: block;
}

.tiny-static-form-group {
  position: relative;
  padding: spacing.$pixel-2 0 spacing.$pixel-2 spacing.$spacing-xs;
  line-height: 2rem;

  &::before {
    display: inline-block;
    position: absolute;
    top: spacing.$pixel-3;
    left: 0;
    background: colors.$silver;
    width: spacing.$pixel-1;
    height: calc(100% - 0.6rem);
    content: "";
  }

  &.plain {
    padding-left: 0;

    &::before {
      display: none;
    }
  }

  & .sis-label,
  & .label {
    &.colon {
      @include typography.description-list-compact-key-typography;

      &::after {
        display: inline;
        padding-right: spacing.$spacing-xxs;
        padding-left: spacing.$pixel-1;
        width: spacing.$spacing-xxs;
        height: 100%;
        content: ":";
      }
    }
  }

  & a.sis-value,
  & a.value {
    color: colors.$primary-color;
  }

  & .sis-value,
  & .value {
    display: inline;
    color: colors.$dark-gray;
    font-size: 1.4rem;
    font-weight: 400;
  }

  &.indentation {
    margin-left: 3rem;

    & .sis-label,
    & .label {
      font-size: 1.2rem;
    }

    & .sis-value,
    & .value {
      font-size: 1.2rem;
      font-weight: 300;
    }
  }

  & sis-tooltip {
    & .sis-tooltip-trigger-content {
      display: inline-block;

      & sis-icon[icon="info-circle"] svg {
        margin: -0.4rem 0;
      }
    }
  }

  @media only screen and (max-width: breakpoints.$screen-xs-max) {
    .sis-label,
    .sis-value,
    .label,
    .value {
      font-size: 1.2rem;
    }
  }
}
