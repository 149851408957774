@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

is-editable-attainment-tree-study-selections {
  & .study-selection-item {
    display: inline-flex;
    position: relative;
  }

  .study-selection-item,
  sis-attainment-tree-attainment,
  sis-attainment-tree-grouping-module,
  sis-editable-attainment-tree-grouping-module {
    width: 100%;
  }
}

sis-attainment-tree-attainment,
sis-editable-attainment-tree-root,
sis-editable-attainment-tree-grouping-module {
  & .attainment-tree-header {
    margin: 0;
    border-bottom: spacing.$pixel-1 solid colors.$light-gray;

    & > * {
      text-align: right;

      &.name {
        text-align: left;
      }
    }
  }

  & .move-buttons {
    display: flex;
    position: relative;
    top: 1rem;
    justify-content: center;
    width: 4.1rem;
    height: spacing.$spacing-lg;

    sis-button, sis-button:last-of-type {
      margin: 0 !important;
    }

    & sis-button:first-child {
    right: 1.3rem;
    }
  }

  & .attainment-row {
    margin: 0;
    border-bottom: spacing.$pixel-1 solid colors.$extra-light-gray;
    padding: 1rem 0;

    & > * {
      text-align: right;

      &.name {
        text-align: left;

        & .subheading {
          overflow: hidden;
          text-transform: uppercase;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-weight: 400;
        }

        & .additional-info-block {
          padding-left: 2rem;
          text-transform: none;
          font-weight: 300;

          & .content {
            display: flex;
            align-items: baseline;
            padding-left: 2rem;
          }

          & > sis-tooltip {
            display: inline-block;
            margin-left: 0.7rem;

            & .sis-tooltip-trigger-content sis-icon {
              margin-left: spacing.$pixel-3;
            }
          }

          & .additional-info-text {
            display: block;
          }
        }
      }
    }
  }
}
