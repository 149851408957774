app-enrolments-main-container {
  & .column-container {
    display: flex;
    flex-direction: row;
  }

  & .column-container > :first-child {
    flex-grow: 1;
  }

  & .vertical-divider {
    margin-top: 1rem;
    margin-right: 1rem;
    margin-left: 2rem;
    border-top: 0;
    border-right: 0.1rem solid;
  }

  & .refresh-button-container {
    align-self: flex-end;
  }
}
