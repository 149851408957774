@use 'sis-assets/foundations/breakpoints/breakpoints' as breakpoints;
@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;
@use 'sis-assets/foundations/typography/tokens' as typography;

app-course-unit-info-modal-header {
  & .ng-course-unit-info-modal-header {
    @media (min-width: breakpoints.$screen-md-min) {
      padding: 0 spacing.$spacing-sm;
    }
  }

  & .course-unit-code {
    font-weight: typography.$font-weight-regular;
  }

  & button.link-button svg {
    fill: colors.$dark-gray;
  }
}
