show-module-content-application {
  & em {
    font-weight: 600;
  }

  & .button-holder {
    margin-top: 2rem;
    text-align: right;
  }
}
