@use 'sis-assets/foundations/colors/colors' as colors;

module-rule {
  .box-left {
    color: colors.$dark-gray;
  }
}

course-unit-rule,
module-rule,
any-course-unit-rule,
any-module-rule {
  display: block;
  clear: both;

  & .courseunit,
  & .studymodule {
    margin-top: 0.5em;
  }

  & .form-group .search-with-svg-icon {
    top: 8.5rem;
  }
}
