@use 'sis-assets/foundations/spacing/tokens' as spacing;

page-header {
  .page-header {
    padding-top: spacing.$spacing-md;
  }

  .top-row {
    display: flex;

    sis-breadcrumbs {
      flex-grow: 1;
      margin-left: 0;

      & ~ * {
        flex-shrink: 0;
      }
    }
  }
}
