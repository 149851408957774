/* stylelint-disable color-no-hex */
@use "@funidata/ngx-fudis" as fudis;
@use './breakpoints/breakpoints' as breakpoints;
@use './colors/colors' as colors;
@use './typography/tokens' as typographyTokens;
@use './spacing/tokens' as spacingTokens;

/**
* These are projects common root styles, including Fudis primary colors and rem converter that
* sets Fudis rem values to Sisu 10 pixel base system.
*
*
* DO NOT ADD any component specific styles to this file in any case
*/

:root {
  --fudis-rem-multiplier: 0.625;
  --fudis-color-primary-light: #daedff;
  --fudis-color-primary: #1076db;
  --fudis-color-primary-dark: #1d65b8;
}

html {
  line-height: typographyTokens.$body-text-sm-line-height;
  font-size: 62.5% !important;
  font-weight: typographyTokens.$font-weight-regular;
  scroll-behavior: auto !important;

  body {
    background: colors.$bg-color-alt;
    overflow-x: hidden;
    line-height: spacingTokens.$spacing-md;
    color: colors.$dark-gray;
    font-family: typographyTokens.$font-family;
    font-size: typographyTokens.$body-text-md-font-size;
    font-weight: typographyTokens.$font-weight-light;
    -ms-overflow-style: scrollbar; // prevent IE scrollbar auto hide

    @media (max-width: breakpoints.$screen-sm-max) {
      font-size: typographyTokens.$body-text-sm-font-size;
    }
  }
}
