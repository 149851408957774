@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

show-module-content-application-modal {
  & .modal-body {
    & .module-content-suggestion {
      color: colors.$middle-gray;
    }

    & hr {
      margin: 0 0 spacing.$spacing-xl 0;
      border-color: colors.$extra-light-gray;
    }

    & .show-with-decision {
      .mca-border {
        &.content-text {
          padding-top: 0 !important;
        }
      }
    }

    & .has-error {
      color: colors.$red;
    }
  }
}
