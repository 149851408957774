@use '../colors/colors' as colors;
@use 'mixins' as mixins;

// Add universal focus style to all elements except form inputs
*:not(.form-control), *:not(.form-select) {
  &:focus, &:focus-visible {
    // ^-wildcard for classes with the word fudis.
    // This is necessary to avoid redundant visual focus on mouse clicks.
    &:not([class^="fudis"]) {
      @include mixins.fudis-focus-generic;
    }
  }
}

// Hide focus for mouse users, https://css-tricks.com/the-focus-visible-trick/
*:focus:not(:focus-visible) { /* stylelint-disable-line */
  // ^-wildcard for classes with the word fudis.
  // This is necessary in order to maintain visual focus on mouse clicks in Error Summary with non-interactive focus elements like RadioButtonGroup and CheckboxGroup legends.
  &:not([class^="fudis"]) {
    outline: none;
    box-shadow: none;
  }
}

// Form focus style includes input fields, text areas, radio buttons and checkboxes.
.form-control, .form-select, div[role="checkbox"] {
  &:focus, &:focus-visible {
    @include mixins.fudis-focus-form-field;
  }
}

// To override various styles from Bootstrap

.dropdown-item:focus {
  @include mixins.deprecated-sisu-focus-generic-inner;
}

.dropdown-item:not(.active):focus {
  background-color: initial;
}

ng-select {
  .ng-dropdown-panel {
    .ng-dropdown-panel-items {
      & .ng-option-marked {
        @include mixins.deprecated-sisu-focus-generic-inner;
      }
    }
  }
}

.btn-primary:focus, .btn-primary.focus {
  border-color: colors.$primary-color;
  background-color: colors.$primary-color;
}

button.non-button:not:focus, .ngb-dp-day:focus {
  @include mixins.deprecated-sisu-focus-generic-inner;
}

// At least used in ngb-datepicker
.custom-select:focus {
  @include mixins.fudis-focus-form-field;

  border: none;
}

// Example usage university iFrame
.hidden-focus-trap-guide {
  display: flex;
  opacity: 0;
  width: 0;
  height: 0;
}
