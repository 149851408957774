sis-show-more {
  & > button {
    display: flex;
    align-items: center;
    overflow: hidden;
  }

  .before {
    flex-direction: row;
  }

  .after {
    flex-direction: row-reverse;
  }

  .top, .bottom {
    margin: 0 1.5rem;
    width: 100%;
    text-align: center;
  }

  .top {
    flex-direction: column;
    margin-top: -0.8rem;
  }

  .bottom {
    flex-direction: column-reverse;
  }
}
