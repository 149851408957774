@use '@funidata/ngx-fudis' as fudis;
@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

sis-localized-markup-string-editor {
  display: block;
  position: relative;

  & + * {
    clear: both;
  }

  & .focusable-element {
    position: absolute;
    top: 3.4rem;
    left: -500rem;
    max-width: 50rem;

    &.active {
      position: relative;
      top: auto;
      left: auto;
      border-color: colors.$middle-gray;
      background-color: colors.$extra-light-gray;
      cursor: not-allowed;
      max-width: inherit;
      min-height: spacing.$spacing-xl;
      color: colors.$middle-gray;
      pointer-events: none;

      &:focus {
        @include fudis.focus-form-field;
      }

      &[contenteditable='true'] {
        background-color: colors.$white;
        cursor: default;
        color: colors.$dark-gray;
        pointer-events: auto;

        &:focus {
          @include fudis.focus-form-field;
        }
      }
    }
  }

  & .box {
    outline: none;
    border: spacing.$pixel-1 solid colors.$middle-gray;
    background: colors.$white none repeat scroll 0 0;
    padding: 0.9rem 1rem;
    line-height: 2.0rem;
    font-size: spacing.$spacing-sm;

    &:focus {
      @include fudis.focus-form-field;
    }
  }

  & .tab-content {
    position: relative;
  }

  & .markup-indicator {
    position: absolute;
    top: 0;
    left: -1.3rem;
    border-radius: spacing.$spacing-xxs 0 0 spacing.$spacing-xxs;
    background-color: colors.$light-gray;
    width: 1.3rem;
    height: 3.3rem;
    line-height: 3.3rem;
    color: colors.$white;

    & sis-icon[icon="editor"] svg {
      margin-left: -0.8rem;
    }
  }
}
