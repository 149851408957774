/* stylelint-disable */
@use '@funidata/ngx-fudis' as fudis;
@use './breakpoints/breakpoints' as breakpoints;
@use './colors/colors' as colors;
@use './spacing/tokens' as spacingTokens;
@use './typography/tokens' as typographyTokens;

// ATTENTION: This file is ONLY for overwriting styles coming from Angular Material.
// Such cases should only occur where Fudis has used Angular Material as its component's base, i.e. Dialog, Tooltip and Datepicker.

// Overwrite Dialog content typography styles (same as in root.scss body)
.mat-mdc-dialog-container .mdc-dialog__content {
  line-height: spacingTokens.$spacing-md;
  color: colors.$dark-gray;
  font-family: typographyTokens.$font-family;
  font-size: typographyTokens.$body-text-md-font-size;
  font-weight: typographyTokens.$font-weight-light;

  @media (max-width: breakpoints.$screen-sm-max) {
    font-size: typographyTokens.$body-text-sm-font-size;
  }
}
