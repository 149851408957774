@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

@mixin print-plan-loop($counter) {
  @if $counter > 0 {
    @include print-plan-loop($counter - 1);
    & .indent-#{$counter} {
      padding-left: 2em * $counter !important;
    }
  }
}

print-plan {
  @include print-plan-loop(5);

  display: block;
  padding-top: 4.1rem;

  & #printable-plan {
    padding-left: 6rem;
  }

  & .student-details {
    margin: 0.5rem 0 1.5rem;
  }

  & h3.study-plan {
    margin: 0;
  }

  & .table.styled-table {
    & tbody tr {
      &.printable-row-bottom {
        border-bottom: spacing.$pixel-2 solid colors.$middle-gray;
      }

      td {
        overflow-wrap: break-word;
      }
    }
  }

  @media print {
    padding: 0;

    @page {
      margin-top: 10mm;
      margin-right: 3mm;
      margin-left: 3mm;
      size: 210mm 297mm;
    }

    #printable-plan {
      position: relative;
      top: 0;
      left: 0;
      padding-left: 3rem;

      & .student-details {
        & h4,
        & p {
          font-size: 1.2rem !important;
        }
      }

      & .table.styled-table {
        max-width: 97%;

        & tr {
          & td,
          & th {
            line-height: 1.2rem;
            font-size: 1rem !important;
          }

          & td {
            border-color: colors.$middle-gray;
            font-weight: 400 !important;
          }
        }
      }
    }
  }
}
