sis-local-date-time-range-editor {
  .multi-error {
    .error-list li:first-child {
      margin-top: 2.8rem !important;
    }
  }

  & sis-local-time-editor {
    & sis-validation-errors {
      ul.error-list:first-child {
        hyphens: auto;
      }
    }
  }
}
