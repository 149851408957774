@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

app-course-unit-info-modal-substitutions-correspondences-select-form {
  & .contextual-notification {
    margin: 1rem 0;
  }

  & .equivalent-courses-info {
    color: colors.$middle-gray;

    &:first-of-type {
      display: block;
      margin-top: spacing.$spacing-sm;
    }
  }

  & legend {
    margin-top: spacing.$spacing-sm;
    margin-bottom: spacing.$spacing-sm;
  }

  .substitution-container-no-radio {
    margin-bottom: spacing.$spacing-md;

    .course-unit-substitution-group {
      margin-top: spacing.$spacing-xxs;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .substitution-container {
    display: flex;
    margin-top: spacing.$spacing-md;
    width: 75%;

    & .substitution-course-units {
      margin-left: spacing.$spacing-xxs;
      width: 50rem;

      .course-unit-substitution-group {
        margin-top: spacing.$spacing-xxs;

        &:first-child {
          margin-top: 0;
        }
      }

      & .contextual-notification {
        margin: 0.5rem 0 0;
        padding: spacing.$spacing-lg;
      }
    }

    .radio-button-substitution-option {
      display: flex;
      align-items: center;
      width: spacing.$spacing-lg;

      & label.control.control-radio {
        margin-bottom: 0;
      }

      .control input[type="checkbox"], .control input[type="radio"] {
        position: relative;
      }
    }
  }
}
