@use 'sis-assets/foundations/spacing/tokens' as spacing;

sis-number-input {
  & .input-and-type {
    display: flex;
    gap: spacing.$spacing-xs;
    align-items: center;
  }

  & .type-block {
    text-transform: lowercase;
    white-space: nowrap;
  }
}
