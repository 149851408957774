@use 'sis-assets/foundations/breakpoints/breakpoints' as breakpoints;
@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

app-cart-accordions {
  & > sis-expandable .expandable {
    margin-bottom: spacing.$spacing-xs;

    & .title {
      & sis-tiny-badge {
        margin-left: spacing.$spacing-xl;
      }
    }
  }

  & .course-unit-cart-expandable-info {
    display: flex;
    flex-wrap: wrap;

    & .price-text {
      float: right;
      margin-left: spacing.$spacing-xl;
    }
  }

  & .contextual-notification {
    margin-top: spacing.$spacing-sm;
  }

  & .delete-icon-button {
    & > button {
      background-color: colors.$white !important;
      padding: 0;
      width: 3.5rem;
      height: 3.5rem;
    }
  }

  & .delete-text-button {
    margin: spacing.$spacing-sm 0 0 0 !important;
    text-align: center;
  }

  & .choose-groups-button {
    margin: spacing.$spacing-sm 0 0 0 !important;
    text-align: center;

    @media (min-width: breakpoints.$screen-sm-min) {
      text-align: right;
    }
  }
}
