@use 'sis-assets/foundations/typography/mixins' as typography;

// TODO: Remove this when STAFF and TUTOR study rights are fixed to use Fudis heading styles
.page-staff,
.page-tutor {
  sis-read-only-study-right {
    h5 {
      margin-top: 1.8rem;
    }
  }
}
