@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/typography/tokens' as typography;
@use '../mixins' as top-navigation;

sis-help-and-feedback-menu {
  & .help-and-feedback-menu {
    @include top-navigation.settings-menu-group;

    &__item {
      color: colors.$primary-color-dark;
      font-size: typography.$body-text-md-font-size;

      &:active,
      &:hover,
      &:focus {
        color: colors.$primary-color-dark !important;
      }
    }
  }
}
