@use 'sis-assets/foundations/colors/colors' as colors;

sis-course-unit-version-selector {
  display: inline-block;
  max-width: 100%;
  white-space: nowrap;
  font-size: 1.4rem;

  & > div {
    max-width: inherit;

    & sis-dropdown-select {
      display: inline-block;
      max-width: inherit;
    }

    & .dropdown {
      max-width: 100%;

      & .dropdown-toggle {
        max-width: 100%;
        white-space: nowrap;
        color: colors.$dark-gray;

        & > span {
          display: inline-block;
          max-width: inherit;
          overflow: hidden;
          vertical-align: middle;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      & .dropdown-menu {
        min-width: 30rem;
      }
    }
  }
}
