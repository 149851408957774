@use 'sis-assets/foundations/breakpoints/breakpoints' as breakpoints;
@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

completion-methods {
  & .table-completion-matrix {
    width: 100%;
    border-collapse: separate;
    border-spacing: spacing.$spacing-zero;
    color: colors.$dark-gray;

    > thead {
      & tr {
        & th {
          &.completion-method-table-title {
            padding-bottom: spacing.$spacing-zero;
            padding-left: 1rem;
            vertical-align: bottom;

            & .flex-title {
              display: flex;
              align-items: flex-end;
            }

            & .matches-timing-info {
              margin-left: 1rem;
              text-transform: lowercase;
            }
          }

          &.col-completion-matrix-completion-selection {
            border: spacing.$pixel-1 solid colors.$primary-color;
            width: 8rem;

            &:nth-child(even) {
              border-right: spacing.$spacing-zero;
              border-left: spacing.$spacing-zero;
            }

            &:first-child {
              border-right: spacing.$pixel-1 solid colors.$primary-color;
              border-top-left-radius: spacing.$spacing-xxs;
            }

            &:nth-last-of-type(3) {
              border-right: spacing.$pixel-1 solid colors.$primary-color;
              border-top-right-radius: spacing.$spacing-xxs;
            }

            &:hover {
              background-color: colors.$primary-color-light;
            }

            & .completion-method-container {
              padding: 1rem spacing.$spacing-zero spacing.$spacing-zero;
              width: 100%;
              text-align: center;

              .selected-method {
                display: block;
                visibility: hidden;
                background-color: colors.$green;
                width: 100%;
                text-transform: uppercase;
                color: colors.$white;
                font-size: 1.2rem;
                font-weight: 400;

                &.active {
                  visibility: visible;
                }
              }

              & .completion-matrix-completion-header {
                vertical-align: middle;
                text-transform: uppercase;
                text-decoration: none;
                color: colors.$primary-color;
                font-size: 1.4rem;
                font-weight: 600;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
              }
            }

            &.is-highlighted {
              outline: spacing.$pixel-1 solid colors.$white;
              border: spacing.$spacing-zero;
              border-bottom: spacing.$pixel-1 solid colors.$white;

              &:hover {
                background-color: colors.$primary-color;
              }

              & .completion-matrix-completion-header {
                color: colors.$white;
              }
            }
          }
        }
      }
    }

    > tbody {
      font-weight: 400;

      & tr {
        &:last-child > td.is-highlighted::after {
          position: absolute;
          top: 100%;
          left: spacing.$spacing-zero;
          transform: scaleY(0.4);
          margin-top: -2.1rem;
          border-width: spacing.$spacing-xl;
          border-style: solid;
          border-color: transparent;
          border-top-color: colors.$primary-color;
          content: " ";
          pointer-events: none;
        }

        &.highlighted-completion-methods > td {
          background-color: colors.$primary-color-light;

          &.is-highlighted {
            background-color: colors.$primary-color;
          }
        }

        & .col-completion-matrix-assessment-item {
          width: 25rem;
        }

        & td {
          position: relative;
          border-bottom: spacing.$pixel-1 solid colors.$white;
          background-color: colors.$extra-light-gray;
          padding: 1rem;
          vertical-align: middle;
          line-height: 1.7rem;

          &:last-of-type {
            border-left: spacing.$pixel-1 solid colors.$white;
          }

          & sis-icon[icon="dot"] svg,
            sis-icon[icon="check"] svg {
            fill: colors.$middle-gray;
          }

          & .assessment-item-calendar-entry {
            display: table;
            margin-bottom: spacing.$spacing-xs;
            width: 100%;

            &:last-of-type {
              margin-bottom: spacing.$spacing-zero;
            }

            & .name-container {
              display: table-cell;
              width: 50%;
              vertical-align: middle;

              & .cancelled {
                text-decoration: line-through;
              }

              & .cur-enrolment-notification {
                margin-bottom: 0.5rem;
                line-height: 1.4rem;
                color: colors.$accent;
                font-weight: 600;

                & > div {
                  margin-top: spacing.$pixel-3;

                  & a {
                    font-weight: 300;
                  }
                }
              }
            }

            & .button-container {
              display: table-cell;
              width: 50%;
              vertical-align: middle;
              text-align: right;
              white-space: nowrap;

              & > sis-tooltip {
                & .button-disabled-tooltip {
                  display: inline-block;

                  & > button {
                    pointer-events: none;
                  }
                }

                & > .tooltip {
                  top: -0.5rem !important;
                }
              }
            }
          }

          & .no-upcoming-cur {
            color: colors.$silver;
          }

          & .completion-method-title {
            & .assessment-item-grade {
              display: inline-block;
              color: colors.$green;
              font-weight: 600;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: grayscale;

              & sis-icon[icon="achievement"] {
                margin-right: -0.4rem
              }

            }
          }

          & .control.control-inline.control-checkbox, & .control.control--inline.control--checkbox {
            margin-right: spacing.$spacing-zero;
            padding-left: 1.8rem;

            &.disabled {
              cursor: not-allowed;
            }
          }

          & ngb-tooltip-window {
            & .tooltip-inner {
              width: 18rem;
            }
          }
        }
      }
    }

    /* Make completion methods table horizontally scrollable on small screen, otherwise the table can't be reached in any way.
    *  By adding theses styles other content is not affected by the scroll.
    *  This is supposed to be modified/removed if the completion methods table is re-designed mobile friendly.
    */
    @media(max-width: breakpoints.$screen-xs-max) {
      display: block;
      overflow-x: scroll;
      overflow-y: hidden;
      white-space: nowrap;
    }
  }

  & .completion-method-details-visible {
    border: spacing.$pixel-2 solid colors.$primary-color;
  }

  & .completion-method-details {
    padding: 2rem 2rem spacing.$spacing-zero;
    overflow-wrap: break-word;

    & .guidance-info {
      margin-top: 1.2rem;
      margin-bottom: 0.7rem;
      background-color: colors.$extra-light-gray;
      padding: spacing.$spacing-sm spacing.$spacing-sm spacing.$spacing-xxs;
    }

    & .completion-method-details-heading {
      display: inline-block;
      margin-top: spacing.$spacing-sm;

      & .completion-method-selected {
        margin-left: 1rem;

        & sis-icon[icon="check"] svg {
          fill: colors.$green;
        }

        & > span {
          text-transform: uppercase;
          letter-spacing: spacing.$pixel-1;
          color: colors.$green;
          font-size: 1.4rem;
          font-weight: 500;
        }
      }
    }

    & .select-method {
      display: inline-block;
      margin: spacing.$spacing-zero spacing.$spacing-zero 0.6rem 2rem;
    }

    & .accordion-panel {
      & .content-row {
        & .content-text {
          & .ai-no-information {
            margin-bottom: 2rem;
          }

          & .cur-used-elsewhere {
            margin-bottom: 1.5rem;

            & .cur-used-elsewhere-location {
              font-weight: 600;
            }
          }

          course-unit-realisation-info {
            & .study-sub-group-set {
              & .study-group-set-title {
                margin-bottom: 0.5rem;
                text-transform: uppercase;
              }

              & .study-group-set-content {
                padding-left: 2.5rem;

                &.justify {
                  padding-left: spacing.$spacing-zero;
                }
              }
            }

            @media (max-width: breakpoints.$screen-xs-max) {
              & .study-group-set-content {
                padding-left: 1.5rem;
              }
            }
          }
        }
      }
    }
  }

  .col-completion-matrix-completion-selection {
    border-right: spacing.$pixel-1 solid colors.$white;
    min-width: 8rem;
    text-align: center;
  }

  .col-completion-matrix-assessment-item,
  .col-completion-matrix-cur-selection {
    &:hover {
      background-color: colors.$primary-color-light;
      color: colors.$dark-gray;
    }
  }

  .is-highlighted {
    &.col-completion-matrix-completion-selection,
    &.col-completion-matrix-entry {
      border: spacing.$spacing-zero;
      border-bottom: spacing.$pixel-1 solid colors.$white;
      background-color: colors.$primary-color;
      color: colors.$white;

      & sis-icon[icon="check"] svg,
        sis-icon[icon="dot"] svg {
        fill: colors.$white;
      }
    }

    &.col-completion-matrix-assessment-item,
    &.col-completion-matrix-cur-selection {
      background-color: colors.$primary-color-light;
      color: colors.$dark-gray;
    }
  }

  tr.last-row {
    td.empty-cell {
      border: spacing.$spacing-zero;
      padding: spacing.$spacing-zero;

      .border {
        display: block;
        border-bottom: 2rem dashed transparent;
        width: 50%;

        &.left {
          float: left;
          border-right: 6rem solid colors.$primary-color;
        }

        &.right {
          float: right;
          border-left: 6rem solid colors.$primary-color;
        }
      }
    }
  }

  & .tiny-badge {
    vertical-align: middle;
  }

  & button.link-button {
    margin-top: 1rem;
    text-decoration: underline colors.$primary-color dotted;
  }
}
