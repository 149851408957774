@use 'sis-assets/foundations/breakpoints/breakpoints' as breakpoints;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

sis-plan-structure-module-content {
  & .sis-plan-structure-module-content-list {
    padding-left: spacing.$spacing-xs;

    @media (max-width: breakpoints.$screen-md-max) {
      padding-left: spacing.$spacing-md;
    }
  }
}
