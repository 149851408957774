@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

.app-widget-container {
  border: spacing.$pixel-1 solid colors.$middle-gray;
  background-color: colors.$white;

  &__title-container {
    padding: spacing.$spacing-sm;

    &__title {
      margin: 0;
    }

    &__subtitle {
      margin: 0;
      padding-top: spacing.$spacing-xxs;
      line-height: 1.68rem;
      font-size: 1.4rem;
      font-weight: 400;
    }
  }

  &__content {
    border-top: spacing.$pixel-1 solid colors.$middle-gray;
    padding: spacing.$spacing-sm;

    &__info-text {
      line-height: 1.92rem;
      font-size: 1.6rem;
    }
  }
}
