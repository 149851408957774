@use 'sis-assets/foundations/breakpoints/breakpoints' as breakpoints;
@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

app-cart-contact-information {
  & .guidance-div {
    position: relative;
  }

  & .asterisk-required-guidance {
    display: flex;
    align-items: center;
    margin-bottom: spacing.$spacing-sm;

    & sis-icon svg {
      fill: colors.$middle-gray;
    }

    @media (min-width: breakpoints.$screen-sm-min) {
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  & .buyer-details {
    margin-left: spacing.$spacing-sm;

    @media (min-width: breakpoints.$screen-sm-min) {
      margin-left: 0;

      & .form-label {
        margin-left: spacing.$spacing-sm;
      }
    }
  }

  & .terms {
    background-color: colors.$extra-light-gray;
    padding: spacing.$spacing-sm;

    & sis-form-checkbox {
      & .sis-form-checkbox-label-content {
        font-size: smaller;
      }

      & input {
        width: 2rem;
        height: 2rem;
      }

      & .control-indicator {
        position: absolute;
        top: spacing.$spacing-xxs;
        width: 2rem;
        height: 2rem;
      }
    }
  }

  & .accept-terms-error {
    margin-bottom: spacing.$spacing-xs;
  }
}
