@use 'sass:color';
@use '../breakpoints/breakpoints' as breakpoints;
@use '../colors/colors' as colors;
@use '../spacing/tokens' as spacing;

ngb-modal-window.modal {
  & .modal-dialog {
    margin: 10rem auto 1.75rem;
    width: calc(100% - 2rem);

    & .modal-content {
      border: spacing.$pixel-1 solid colors.$middle-gray;
      border-radius: 0;
      box-shadow: 0 spacing.$pixel-2 0 rgb(0 0 0 / 30%);
      padding: spacing.$spacing-md 5.6rem 0 5.6rem;

      & .modal-header {
        border-bottom: 0;
        padding: 0;

        & .modal-close {
          position: absolute;
          top: spacing.$spacing-sm;
          right: spacing.$spacing-sm;
          border: 0;
          border-radius: 100%;
          background-color: colors.$white;
          cursor: pointer;
          width: 2.6rem;
          height: 2.6rem;

          & svg {
            position: absolute;
            top: -0.2rem;
            left: -0.2rem;
            fill: colors.$middle-gray;
          }

        }
      }

      & .modal-body {
        margin-bottom: 3rem;
        padding: 0;
        min-height: 10rem;
      }

      & .modal-footer {
        display: block;
        align-items: baseline;
        margin-top: -1.9rem; // cancels modal-body's padding-bottom
        border-top: 0;
        padding: 1.1rem 0 0;
        min-height: 6.5rem;
        text-align: right;

        & > *,
        & sis-external-link > * {
          margin: 0;
        }

        & > button,
        & a.btn {
          position: relative;
          margin-bottom: 1.9rem;
          margin-left: 1.5rem;
        }
      }

      @media (max-width: breakpoints.$screen-sm-max) {
        padding: spacing.$spacing-sm spacing.$spacing-lg spacing.$spacing-xxs spacing.$spacing-lg;
      }
    }

    @media (max-width: breakpoints.$screen-sm-max) {
      margin-top: 3rem; // so nothing's hidden by alerts
    }
  }

  &.brochure-modal .modal-xl {
    & .modal-content {
      border: spacing.$pixel-1 solid colors.$light-gray;
      box-shadow: 0 spacing.$pixel-2 0 rgb(0 0 0 / 10%);
      padding: 0;

      & h2.modal-title {
        margin: 0;
      }

      & .modal-body {
        padding: spacing.$spacing-xl 2rem 2rem;
      }
    }
  }
}

.modal-backdrop {
  background-color: color.adjust(colors.$extra-light-gray, $lightness: 0.5);

  &.show {
    opacity: 0.95;
  }
}

/* Hack for modal "backdrop" to work if two modals are open on top of each other */
.modal ~ .modal {
  background-color: color.adjust(colors.$extra-light-gray, $lightness: 0.5);
}

// TODO: Remove this when TEACHER, STAFF, ADMIN and TUTOR modals are fixed to use Fudis heading styles
.page-teacher,
.page-staff,
.page-admin,
.page-tutor {
  ngb-modal-window.modal {
    & .modal-dialog {
      & .modal-content {
        & .modal-header {
          & h2 {
            margin-top: 0;
            margin-bottom: spacing.$spacing-sm;
          }
        }
      }
    }
  }
}

// Override ngMaterial backdrop (used in dialog and tooltip), so it is above top-nav's z-index, alerts and ngbModal.
// TODO: rethought all z-index cases in SISU, now some of the values used are inconsistent and arbitrary
.page-teacher,
.page-staff,
.page-admin,
.page-tutor,
.page-student {
  // Just above ngbModal, so ngMaterial dialogs and tooltips are always on top of ngbModal stuff
  .cdk-overlay-container {
    z-index: 1056;

    // ngbModal element is always rendered to the bottom of DOM, so we can assume it is after ngMaterial dialog. So if ngbModal with System Error appears, z-index of ngMaterial dialog is lowered.
    &:has(~ ngb-modal-window sis-system-error-modal),
    &:has(~ ngb-modal-window sis-validation-error-modal),
    &:has(~ ngb-modal-window sis-access-denied-modal),
    &:has(~ ngb-modal-window sis-confirm-dialog),
    &:has(~ ngb-modal-window app-student-module-info-modal),
    &:has(~ ngb-modal-window app-student-course-unit-info-modal) {
      z-index: 1055;
    }
  }
}
