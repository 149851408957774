/* stylelint-disable selector-type-no-unknown */
@use 'sis-assets/foundations/spacing/tokens' as spacing;

learning-environment-editor {
  checkbox {
    label {
      white-space: nowrap;
    }
  }

  .learning-environment-row {
    margin-top: 1rem;
    max-width: inherit;

    & checkbox {
      & .control {
        margin-top: spacing.$spacing-xs;
        margin-bottom: 0;
      }
    }

    & button.btn.form-control.simulate-label {
      margin-top: spacing.$spacing-md;
      margin-right: 0;
    }
  }
}

learning-environment {
  & .learning-environment-wrapper {
    align-items: center;
  }
}
