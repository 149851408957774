@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

timeline-course-unit, timeline-custom-course-unit-attainment, timeline-custom-study-draft {
  display: block;
  cursor: default;
  text-align: left;
}

.selected-course-unit, .implicitly-selected-course-unit,
.selected-module, .implicitly-selected-module {
  opacity: 0.5;
}

button.selected-course-unit {
  color: inherit;
}

.unselected-course-unit, .implicitly-selected-course-unit,
.unselected-module, .implicitly-selected-module,
.implicit {
  box-shadow: spacing.$pixel-1 spacing.$pixel-2 0.5rem colors.$light-gray;
}

.unit-minimal {
  transition: all 0.1s linear;

  &:hover {
    z-index: 2;
    width: 20rem;
  }

  &:not(:hover) {
    .box-left {
      width: spacing.$spacing-xs;;
    }

    .unit-minimal-hide {
      display: none;
    }
  }
}
