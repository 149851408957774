@use 'sis-assets/foundations/breakpoints/breakpoints' as breakpoints;
@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

sis-study-realisation-box {
  display: flex;
  justify-content: center;
  width: 100%;

  & .study-wrapper-max-width {
    max-width: 40rem;
  }

  & .study-wrapper {
    border: 0.1rem solid colors.$dark-gray;
    width: 100%;

    & .study-header {
      padding: 0.2rem spacing.$spacing-xs 0 spacing.$spacing-xs;
    }

    & .study-content {
      padding: 0 spacing.$spacing-xxs;
      word-wrap: break-word;
      font-size: 1.2rem;

      & sis-icon[icon="calendar"] svg,

      & sis-icon[icon="achievement"] svg {
        margin: -0.4rem -0.4rem -0.2rem;
      }

      & .attainment-info {
        display: flex;
        justify-content: space-between;

        @media (max-width: breakpoints.$screen-xs-max) {
          display: block;
        }
      }
    }
  }
}
