@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

plan-selector {
  & .accordion-panel {
    position: relative;

    & .content {
      & .content-row {
        & .content-text {
          padding-right: 0;
        }
      }
    }

    &.open::before {
      display: block;
      position: absolute;
      top: spacing.$spacing-xl;
      left: 2.5rem;
      border-right: spacing.$pixel-1 dotted colors.$silver;
      padding: 0;
      width: spacing.$pixel-1;
      max-width: spacing.$pixel-1;
      height: calc(100% - 4.4rem);
      content: '';
    }

    &.open::after {
      display: block;
      position: absolute;
      bottom: 0;
      left: 2.2rem;
      border-radius: 50%;
      background-color: colors.$silver;
      width: 0.7rem;
      height: 0.7rem;
      content: "";
    }
  }

  [selectable-element]:not(.selection-disabled) {
    & .credits-box, .title-box {
      &, & > * {
        cursor: pointer !important;
      }
    }
  }

  .header:not(.selection-disabled):hover {
    background-color: colors.$silver;
    cursor: pointer;
  }

  .substituted-course-unit {
    display: inline;
  }

  common-course-unit, custom-study-draft {
    display: inline-block;

    .courseunit {
      width: 28rem;
    }
  }

  common-course-unit {
    margin: 0 2.1rem 1em 0;
  }

  custom-study-draft {
    margin: 0 2.1rem 1em 0;

    .courseunit {
      margin-top: 0;
    }
  }

  common-course-unit:hover, custom-study-draft:hover {
    outline: colors.$middle-gray solid spacing.$pixel-3;
  }

  common-course-unit.selected, custom-study-draft.selected {
    outline: colors.$primary-color solid spacing.$pixel-3;
  }

  & .courseunit-substitution-group {
    display: inline-block;
    margin: 0 2.1rem 1em 0;
    width: 28rem;
    vertical-align: top;

    common-course-unit, sis-plan-course-unit {
      &:last-child {
        margin-bottom: 0;
      }

      & .courseunit {
        width: 27.2rem;
      }
    }
  }

  .header.selected {
    background-color: colors.$primary-color !important;

    .title {
      color: colors.$extra-light-gray !important;
    }
  }

  .header > .collapse {
    background-color: transparent !important;
  }
}
