@use 'sis-assets/foundations/colors/colors' as colors;

app-module-attainment-application {
  & .button-row {
    position: relative;
    top: -1rem;
    text-align: right;

    & #open-cancel-application-modal-button {
      margin-right: 0;
    }
  }

  & .accordion-panel {
    & h2 {
      display: none;
    }

    &.no-expander {
      & h2 {
        display: block;
      }

      & .header {
        background-color: colors.$white;
      }
    }
  }

  & sis-module-attainment {
    & h2 {
      display: none;
    }
  }

  & .rejected-rationale {
    display: block;
    margin-top: 1rem;
  }
}
