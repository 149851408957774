@use 'sis-assets/foundations/breakpoints/breakpoints' as breakpoints;
@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

edit-mode .white-layer {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  background: rgb(255 255 255 / 85%);
  width: 100vw;
  height: 100vh;
}

.edit-mode {
  margin-bottom: spacing.$spacing-md;

  & .buttons, .edit-toggle-holder {
    text-align: right;

    & sis-button {
      margin-right: 1.2rem;
    }
  }

  & .container-content {
    padding: 0 3.5rem 2rem;

    @media (min-width: breakpoints.$screen-md-min) {
      & .first-col {
        padding-right: spacing.$spacing-xl;
      }

      & .second-col {
        padding-left: spacing.$spacing-xl;
      }
    }
  }

  & .header-read-mode {
    display: inline-block;
    width: 100%;
  }

  &.editing {
    position: relative;
    opacity: 1;
    z-index: 20;
    border: spacing.$pixel-1 solid colors.$primary-color;
    background: colors.$white;

    & .header-edit-mode,
      .header-edit-mode-with-title {
      margin-bottom: 1.4rem;
      background: repeating-linear-gradient(-45deg, colors.$white, colors.$white 0.6rem, rgb(48 145 240 / 5%) 0.6rem, rgb(48 145 240 / 5%) 1.1rem);

      & .btn {
        vertical-align: text-bottom;
      }
    }

    & .header-edit-mode-with-title {
      & .row {
        display: flex;
        justify-content: space-between;
        margin-right: spacing.$spacing-xs;
        margin-left: spacing.$spacing-sm;
        padding-top: spacing.$spacing-sm;

        & > * {
          width: auto;
        }
      }
    }

    &.application-editing {
      border-radius: spacing.$spacing-xxs spacing.$spacing-xxs 0 0;

      & .header-edit-mode, .header-edit-mode-with-title {
        border-radius: spacing.$spacing-xxs spacing.$spacing-xxs 0 0;
        background-color: colors.$primary-color-light;
        background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAABCAYAAAASC7TOAAAAGklEQVQYV2M0mPjhPwMDA8OFfAFGEA0CMDEAb8wGslbXfp4AAAAASUVORK5CYII=');
        background-repeat: repeat-x;
        padding-bottom: 1.5rem;
        background-position-y: 100%;
      }

      & .edit-mode-footer {
        text-align: right;
      }
    }
  }

  & .form-title-row {
    padding-top: 1rem;
    padding-bottom: 1rem;
    width: auto;

    &.header-edit-mode {
      width: 100%;
    }
  }
}

.page-teacher,
.page-staff,
.page-admin,
.page-tutor {
  .edit-mode {
    & .buttons,
    & .edit-toggle-holder {
      align-self: center;
      padding-bottom: spacing.$spacing-xs;
    }

    &.editing {
      & .header-edit-mode-with-title {
        & .title {
          margin-top: 0.5rem;

          & h2, h3, h4 {
            margin-top: 0;
          }
        }
      }
    }
  }
}
