@use 'sis-assets/foundations/spacing/tokens' as spacing;

app-enrolment-wizard {
  & .enrolment-wizard__divider {
    padding-top: 1.5rem;
  }

  & .enrolment-wizard__consent {
    h2 {
      margin-bottom: spacing.$spacing-sm;
    }

    sis-contextual-notification > div {
      margin-bottom: spacing.$spacing-sm;
    }

    sis-form-checkbox > label {
      margin-top: spacing.$spacing-xs;
      font-size: 1.4rem;
      font-weight: 300;
    }
  }

  & .enrolment-wizard__questionnaire-item:not(:last-child) {
    margin-bottom: 1rem;
  }

  & sis-validation-errors {
    text-transform: none !important;
    font-weight: 300;
  }

  & .sis-form-checkbox-label-content {
    padding-left: spacing.$spacing-sm
  }

  & .normal-text {
    text-transform: none;
  }
}
