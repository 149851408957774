@use 'sis-assets/foundations/breakpoints/breakpoints' as breakpoints;
@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

sis-service-break-banner {
  & .service-break {
    display: flex;
    border-width: spacing.$pixel-1;
    border-style: solid;
    background-color: colors.$white;
    padding: spacing.$spacing-xs spacing.$spacing-sm;
    line-height: 1.4;

    &:not(:last-of-type) {
      border-bottom-width: 0;
    }

    &--passed {
      border-color: colors.$primary-color-dark;
    }

    &--not-passed {
      border-color: colors.$red;
    }

    & a,
    & button {
      font-weight: 400;
    }

    &__exclamation-mark-icon {
      margin-right: spacing.$spacing-sm;

      @media (max-width: breakpoints.$screen-xs-max) {
        margin-right: spacing.$spacing-xs;
      }
    }

    &__info-text {
      padding-top: spacing.$spacing-xxs;
      font-size: spacing.$spacing-sm;
      font-weight: 500;

      @media (max-width: breakpoints.$screen-xs-max) {
        font-size: 1.4rem;
      }
    }

    &__secondary {
      display: flex;
      flex-shrink: 0;
      align-items: baseline;
      padding-left: spacing.$spacing-md;

      &:empty {
        display: none;
      }

      &__additional-info,
      &__release-notes {
        flex-shrink: 0;

        &:not(:first-child) {
          padding-left: spacing.$spacing-md;
        }

        @media (max-width: breakpoints.$screen-xs-max) {
          &:not(:first-child) {
            padding-top: spacing.$spacing-xxs;
            padding-left: 0;
          }
        }
      }

      @media (max-width: breakpoints.$screen-xs-max) {
        position: relative;
        top: -0.1rem;
        flex-direction: column;
      }
    }

    @media (max-width: breakpoints.$screen-xs-max) {
      padding: spacing.$spacing-xs;
    }
  }
}
