@use 'sis-assets/foundations/breakpoints/breakpoints' as breakpoints;
@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/focus/mixins' as focus;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

sis-message-conversations,
student-message-conversations-list {
  & .title-container {
    padding-top: spacing.$spacing-md;
  }

  & .stack-header-menu {
    margin-bottom: 2rem;
    border-radius: 0.5rem;
    background-color: colors.$white;

    & *:first-child {
      & .menu-option {
        border-radius: 0.5rem 0.5rem 0 0;
      }
    }

    & *:last-child {
      & .menu-option {
        border-radius: 0 0 0.5rem 0.5rem;
      }
    }

    & *:only-child {
      & .menu-option {
        border-radius: 0.5rem;
      }
    }

    & h2, h3 {
      margin: 0;
    }

    & .menu-option {
      border: 0;
      border-left: 0.5rem solid colors.$white;
      background: colors.$white;
      cursor: pointer;
      padding: 1rem 1rem 1rem 2rem;
      width: 100%;
      text-align: left;
      color: colors.$dark-gray;
      font-weight: 500;

      &:focus {
        @include focus.deprecated-sisu-focus-generic-inner;
      }

      &.selected {
        border-left: 0.5rem solid colors.$primary-color;
      }

      &.menu-child-option {
        padding-left: spacing.$spacing-xl;
      }
    }
  }

  & .messages-container {
    margin-bottom: 3rem;
    background-color: colors.$white;
  }

  & .results-header {
    padding-top: spacing.$spacing-xs;
    padding-bottom: spacing.$spacing-sm;
    padding-left: 1.2rem;

    & .checkbox-container {
      height: spacing.$spacing-md;
    }

    & .action-container {
      display: flex;
    }

    & sis-menu-button {
      padding-left: 2rem;
    }

    & .filter-container {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      & [ngbdropdown] {
        display: inline-block;

        & .link-button {
          margin: 0;

          &[ngbdropdowntoggle] {
            white-space: nowrap;
          }
        }
      }

      & .link-button {
        margin-left: spacing.$spacing-xs;
      }
    }

    & + .results-container {
      & .message-conversations {
        margin-bottom: 0;
        padding-top: 0;

        & li:first-child {
          border-top: spacing.$pixel-1 solid colors.$extra-light-gray;
        }
      }

      & ul.pagination {
        margin-top: spacing.$spacing-xs;
      }
    }
  }

  @media (max-width: breakpoints.$screen-xs-max) {
    & .stack-header-menu {
      & h2, h3 {
        margin: 0;
        white-space: normal;
        word-break: break-word;
      }

      & .menu-option {
        padding-left: 1rem;

        &.menu-child-option {
          padding-left: 2rem;
        }
      }
    }
  }
}

sis-message-conversations {
  & .search-and-results {
    margin-top: 6rem;

    & .search-filter-tag-holder {
      margin-bottom: 2rem;
    }

    @media (max-width: breakpoints.$screen-xs-max) {
      margin-top: 0;
    }
  }
}
