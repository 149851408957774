@use '@funidata/ngx-fudis' as fudis;
@use 'sis-assets/foundations/breakpoints/breakpoints' as breakpoints;
@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/focus/tokens' as tokens;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

sis-combobox {
  & .hidden {
    position: absolute;
    top: 0;
    left: -999.9rem;
    visibility: hidden;
  }

  & .combobox {
    display: grid;
    position: relative;
    grid-template-rows: max-content max-content;
    grid-template-columns: auto max-content;
    border: spacing.$pixel-1 solid colors.$dark-gray;
    min-height: spacing.$spacing-xl;
  }

  & .focused {
    @include fudis.focus-form-field;
  }

  & .combobox-buttons {
    grid-row: 1 / 3;
    grid-column: 2;
    place-self: center;
    order: 3;
  }

  & .cut-text > button:first-child {
    display: inline-block;
    min-width: 0;
    max-width: calc(100% - 5rem);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  & .selected-items {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: spacing.$pixel-3;

    & .selected-item {
      outline: none;
      border: spacing.$pixel-1 solid colors.$white;
      background-color: colors.$dark-gray;
      padding: spacing.$pixel-2 1rem spacing.$pixel-2 1rem;
      min-height: spacing.$spacing-lg;
      word-break: normal;
      color: colors.$white;
      overflow-wrap: anywhere;

      &:not(:last-child) {
        margin-right: spacing.$pixel-3;
      }

      &:focus {
        @include fudis.focus-generic;
      }

      & .selected-item-icon {
        transform: translate(-1rem, -0.4rem);
        width: 2.5rem;
        height: 2.5rem;
        fill: colors.$white;
      }

      & .secondary-label {
        font-size: 1.2rem;
      }
    }

    & .hidden-item-count {
      margin: spacing.$spacing-xxs;
      background-color: colors.$white;
      color: colors.$dark-gray;
      font-weight: bold;
    }
  }

  & .combobox-input {
    margin: 0;
    outline: none !important;
    border: none;
    box-shadow: none !important;
    padding: spacing.$pixel-3;
    width:0;
    min-width: 100%;
    min-height: spacing.$spacing-lg;
    line-height: spacing.$spacing-lg;
    color: colors.$dark-gray;
    font-size: spacing.$spacing-sm;
    font-weight: 400;

    &::placeholder {
      font-size: 1.4rem;
    }
  }

  & button {
    outline: none;
    border: none;
    background-color: transparent;
    cursor: pointer;
    padding: 1rem;
  }

  & button:hover {
    background-color: colors.$light-gray;
  }

  & .option-header {
    padding-bottom: 1.2rem;
    color: colors.$middle-gray;
    font-size: 1.2rem;
    font-weight: 400
  }

  & .dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    float: left;
    z-index: 1000;
    margin: spacing.$pixel-2 0 0 0;
    outline: spacing.$pixel-1 solid colors.$middle-gray;
    box-shadow: 0 spacing.$pixel-2 0 rgb(0 0 0 / 30%);
    background-clip: padding-box;
    background-color: colors.$white;
    padding: 0.5rem;
    min-width: 100%;
    max-height: 30vh;
    overflow: auto;
    list-style: none;
    text-align: left;
    color: colors.$dark-gray;
    font-size: 1.4rem;
  }
}
