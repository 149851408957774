@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

sis-add-button {
  & sis-icon {
    margin-right: spacing.$spacing-xxs;
  }

  & .dashed-add-box {
    display: block;
    border: spacing.$pixel-1 dashed colors.$middle-gray;
    padding: spacing.$spacing-xs;
    text-align: center;
    font-weight: 300;
  }
}
