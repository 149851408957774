sis-text-field {
  & label {
    margin-bottom: 0;
    width: 100%;

    & .input-and-type {
      align-items: center;
      margin-top: 0.5rem;
    }

    & .type-block {
      text-transform: lowercase;
    }
  }
}
