@use 'sis-assets/foundations/breakpoints/breakpoints' as breakpoints;
@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

sis-attainment-application-structure {
  & .state-credits-header {
    margin-bottom: -2rem;

    & h4 {
      margin: 0;
    }

    & .module-grade-average-header {
      float: left;
      min-width: 12rem;

      @media (max-width: breakpoints.$screen-md-max) {
        min-width: 11rem;
      }
    }

    & .module-state-header {
      float: left;
      min-width: 10rem;

      @media (max-width: breakpoints.$screen-md-max) {
        min-width: 6rem;
      }
    }

    & .module-credits-header {
      display: inline-block;
      min-width: 11rem;
      text-align: center;

      & .sub-headers {
        line-height: 1rem;
        font-size: 1rem;
        font-weight: 400;

        & .credits-done {
          border-left: spacing.$pixel-1 solid colors.$middle-gray;
          padding-left: spacing.$pixel-3;
          color: colors.$middle-gray;
        }
      }
    }
  }

  & common-plan-module {
    & .accordion-panel {
      margin-top: spacing.$spacing-md;

      & .header {
        padding: 0;

        & > .title-line {
          margin-right: spacing.$spacing-lg;
        }

        & .title {
          @media (max-width: breakpoints.$screen-sm-max) {
            max-width: 30rem;
          }
        }

        & .cell {
          display: inline-flex;
          align-items: center;
          font-weight: 400;

          &.action-button {
            display: none;
          }

          &.average {
            min-width: 9rem;

            @media (max-width: breakpoints.$screen-md-max) {
              min-width: 4.5rem;
            }
          }

          &.status {
            line-height: 2rem;
            font-size: 1.2rem;

            & .state-attained {
              text-align: left;
              color: colors.$green;

              > span:first-of-type {
                display: block;
              }
            }

            & .state-invalid {
              display: flex;
              align-items: center;
              width: 9rem;
              color: colors.$accent;

              @media (min-width: breakpoints.$screen-lg-min) {
                margin-left: -3.2rem;
              }
            }

            @media (min-width: breakpoints.$screen-lg-min) {
              min-width: 10rem;
            }
          }
        }

        & .module-credits {
          flex-direction: column;
          min-width: 11rem;

          & .credit-range {
            display: block;
          }
        }
      }

      & .content {
        & .content-row {
          padding-bottom: 2rem;

          & .content-text {
            display: inline-block;
            padding: 0 0 0 4.4rem;

            & common-course-unit,
            & custom-course-unit-attainment-box,
            & custom-study-draft,
            & course-unit-with-substitutions > common-course-unit {
              display: inline-block;
              margin: 0 spacing.$spacing-md spacing.$spacing-md 0;
              width: 28rem;
            }

            & .courseunit-substitution-group {
              display: inline-block;
              margin: 0 spacing.$spacing-md spacing.$spacing-md 0;
              width: 28rem;

              common-course-unit {
                &:first-child {
                  margin-top: 0;
                }

                &:last-child {
                  margin-bottom: 0;
                }

                & .courseunit {
                  width: 27.2rem;
                }
              }
            }

            .substituted-course-unit {
              display: inline;
            }
          }
        }
      }

      &.accordion-panel-noblock-dotted-content {
        & > .content {
          & > .content-row {
            & > .content-text {
              &::before {
                left: spacing.$spacing-sm;
              }

              &::after {
                left: 1.3rem;
              }
            }
          }
        }
      }
    }
  }
}
