@use 'sis-assets/foundations/breakpoints/breakpoints' as breakpoints;
@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

.back-button-container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  margin-top: spacing.$spacing-xl;
  border-top: spacing.$pixel-1 solid colors.$middle-gray;
  padding: spacing.$spacing-sm;

  @media (max-width: breakpoints.$screen-sm-min) {
    justify-content: center;
  }
}
