sis-open-university-confirm-batch-action-modal {
  & .nav.nav-tabs {
    margin-bottom: 2rem;
  }

  & .tab-content {
    & .link-button {
      overflow: hidden;
    }

    & .table.styled-table {
      & thead {
        & tr {
          & th {
            padding: 1.5rem;
          }
        }
      }

      & tbody {
        & tr {
          & td {
            padding: 1.5rem;

            & .product-name {
              margin-right: 1rem;
              font-weight: 400;
            }

            & .tiny-badge {
              margin-left: 0;
              vertical-align: 10%;
            }
          }
        }
      }
    }
  }

  & .modal-info {
    & .modal-notification {
      margin-bottom: 2rem;
    }
  }
}
