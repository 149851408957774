@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/typography/tokens' as typography;

sis-study-right-states {
  & .show-decision-details-button {
    font-weight: typography.$font-weight-regular;
  }

  & table.styled-table > tbody > tr.past-study-rights > td {
    color: colors.$middle-gray;
  }
}

// TODO: Remove this when STAFF and TUTOR study rights are fixed to use Fudis heading styles
.page-staff,
.page-tutor {
  sis-study-right-states {
    h5 {
      margin-top: 1.8rem;
    }
  }
}

