@use 'sis-assets/foundations/breakpoints/breakpoints' as breakpoints;
@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

ngb-datepicker.dropdown-menu {
  z-index: 1050;
  margin-top: 0;
  outline: spacing.$spacing-xs solid colors.$extra-light-gray;
  outline-offset: -0.9rem;
  border: spacing.$pixel-1 solid colors.$light-gray;
  border-radius: 0;
  box-shadow: spacing.$pixel-1 spacing.$pixel-2 0 rgb(0 0 0 / 10%);
  background-color: colors.$white;
  padding: spacing.$spacing-xs !important;

  & .ngb-dp-header {
    border: spacing.$pixel-1 solid colors.$light-gray;
    border-bottom-width: 0;
    border-radius: 0;
    background-color: colors.$white;
    padding: 0.7rem;

    & .ngb-dp-arrow {
      width: unset;
      height: 3.5rem;

      &.ngb-dp-arrow-prev {
        transform: rotate(180deg);
      }

      &.ngb-dp-arrow-next {
        justify-content: end;
      }

      & .ngb-dp-arrow-btn {
        display: block; /* this style is added in order to prevent display flex inherited from button.scss */
        margin: 0;
        border: spacing.$pixel-2 solid colors.$white;
        padding: 0;
        width: 3.5rem;
        min-width: 3.5rem;
        line-height: 3.8rem;

        & .ngb-dp-navigation-chevron {
          position: relative;
          top: -0.3rem;
          right: spacing.$pixel-3;
          transform: none;
          margin: 0;
          border: 0;

          &::before {
            text-transform: none;
            font-family: SISUicons;
            font-size: spacing.$spacing-md;
            content: 'v';
          }
        }
      }
    }

    & ngb-datepicker-navigation-select {
      flex: 15 1 9rem;

      & > select {
        margin: 0 0.5rem;
        height: 3.5rem;
        font-size: 1.4rem;
      }
    }
  }

  & .ngb-dp-months {
    border-right: spacing.$pixel-1 solid colors.$light-gray;
    border-left: spacing.$pixel-1 solid colors.$light-gray;
    border-radius: 0;
    padding: 0 0.7rem;
    font-family: "Fira Sans", sans-serif;
    font-size: 1.4rem;
    font-weight: 400;

    & .ngb-dp-month {
      &:first-child, &:last-child {
        & .ngb-dp-week {
          padding: 0;
        }
      }
    }

    & .ngb-dp-weekdays {
      border: 0;
      background-color: colors.$white;

      & .ngb-dp-weekday {
        border-radius: spacing.$pixel-2;
        width: 3rem;
        height: 3rem;
        line-height: 3rem;
        color: colors.$middle-gray;
        font-size: 1.4rem;
        font-weight: 600;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-style: normal;
      }
    }

    & .ngb-dp-week {
      & .ngb-dp-week-number {
        cursor: default;
        width: 3rem;
        height: 3rem;
        line-height: 3.1rem;
        color: colors.$light-gray !important;
        font-size: 1.2rem;
        font-style: normal;
      }

      & .ngb-dp-day {
        width: 3rem;
        height: 3rem;

        &:last-of-type, &:nth-last-of-type(2) {
          & .btn-light {
            background-color: colors.$extra-light-gray;
          }
        }

        &.ngb-dp-today {
          & > [ngbdatepickerdayview]:not(.bg-primary) {
            position: relative;
            border: spacing.$pixel-2 solid colors.$silver;
            line-height: 2.7rem;
          }
        }

        & > [ngbdatepickerdayview] {
          width: 3rem;
          height: 3rem;
          line-height: 3rem;

          &:hover, &:active {
            background-color: colors.$extra-light-gray;
          }

          &.text-muted {
            opacity: 1;
            color: colors.$light-gray !important;
          }

          &.bg-primary {
            background-color: colors.$primary-color !important;
          }
        }

        & .btn-light {
          background-color: colors.$white;
        }
      }
    }
  }

  & .ng-dp-footer {
    border: spacing.$pixel-1 solid colors.$light-gray;
    border-top-width: 0;
    padding: 0.7rem;
    text-align: center;

    & .btn.btn-today {
      margin-right: 0;
      border-radius: spacing.$pixel-2;
      background-color: colors.$extra-light-gray;
      padding-top: 0;
      padding-bottom: 0;
      text-transform: lowercase;
      line-height: unset;
      letter-spacing: normal;
      color: colors.$dark-gray;
      font-size: 1.4rem;
    }
  }
}
