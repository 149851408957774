app-course-unit-grade-raise-info {
  & .course-unit-grade-raise-info {
    & div {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}
