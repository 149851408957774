@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;
@use 'sis-assets/foundations/focus/mixins' as focus;

/*
 Button related SCSS mixins

 These were created in order to easily overwrite new Full Calendar button styles.
 These mixins DO NOT cover all the possible Sisu button styles.
 If you need something more, add new mixin for it.
*/

/* Button typography */
@mixin button-typography {
  display: inline-flex;
  align-items: center;
  text-transform: uppercase;
  letter-spacing: spacing.$pixel-1;
  font-family: "Fira Sans", sans-serif;
  font-weight: 600;
}

/* Button sizes */
@mixin sis-button-lg {
  @include button-typography;

  padding: 0 spacing.$spacing-sm;
  height: 3.5rem;
  font-size: 1.4rem;
}

@mixin sis-button-sm {
  @include button-typography;

  padding: 0 1.4rem;
  height: 2.5rem;
  font-size: 1.2rem;
}

/* Button variants */

/* Primary button */
@mixin sis-button-primary {
  border: spacing.$pixel-2 solid colors.$primary-color;
  border-radius: spacing.$pixel-2;
  background-color: colors.$primary-color;
  color: colors.$white;

  &:focus-visible {
    @include focus.fudis-focus-generic;
  }

  & svg {
    fill: colors.$white;
  }
}

/* Hollow button */
@mixin sis-button-hollow {
  border: spacing.$pixel-2 solid colors.$primary-color;
  border-radius: spacing.$pixel-2;
  background-color: colors.$white;
  color: colors.$primary-color;

  &:focus-visible {
    @include focus.fudis-focus-generic;
  }

  & svg {
    fill: colors.$primary-color;
  }
}
