@use 'sis-assets/foundations/breakpoints/breakpoints' as breakpoints;
@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

// This is SCSS placeholder class, needed for @extend uses
%column-transitions {
  transition-duration: 0.4s;
  transition-property: width, padding;
  transition-timing-function: ease-in-out;

  // Collapsible bar column header
  &:last-of-type {
    & .collapsible-column-header {
      border-radius: spacing.$spacing-xxs 0 0 0;
    }
  }
}

sis-plan-edit {
  .collapsible-columns-container {
    @extend %column-transitions;

    // Collapsible bar column header
    & .collapsible-column-header {
      @extend %column-transitions;

      position: relative;
      background-color: colors.$primary-color;
      padding: spacing.$spacing-sm;
      height: 5.6rem;
      text-transform: uppercase;
      white-space: nowrap;
      color: colors.$white;
      font-weight: 600;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;

      & .header-text {
        display: block;
        width: calc(100% - 12rem);
        overflow: hidden;
        text-transform: uppercase;
        text-overflow: ellipsis;
      }

      // Collapsible bar column dismiss, i.e. close button
      & .collapsible-column-dismiss {
        position: absolute;
        top: 1.3rem;
        right: 1.5rem;
        z-index: 50;
        border: spacing.$pixel-1 solid colors.$white;
        border-radius: spacing.$pixel-2;
        background-color: colors.$primary-color;
        cursor: pointer;
        padding: 0;
        width: spacing.$spacing-lg;
        height: spacing.$spacing-lg;
        overflow: hidden;
      }
    }

    & .free-edit-mode-banner {
      display: flex;
      align-items: center;
      background-color: colors.$accent;
      padding: 0.5rem 2rem;
      width: 100%;
      overflow: hidden;
      color: colors.$white;

      & .add-study-draft {
        display: block;
        margin: 1rem 0;

        & button {
          margin: 0;
        }
      }

      & span.float-end {
        margin-left: auto;

        & button {
          text-decoration: underline;
          color: colors.$white;
          font-weight: 400;
        }
      }

      & .collapsible-column-content {
        background-color: colors.$dark-gray;
        padding: 1.2rem 2rem 2rem;
        max-height: calc(100% - 5.6rem - 10rem);
        overflow-y: auto;
        color: colors.$white;

        & h4 {
          margin-top: 3rem;
          margin-bottom: 0;
        }

        & hr {
          margin: 2rem -2rem;
          border-color: colors.$middle-gray;

          & + h4 {
            margin-top: 0;
          }
        }

        & .form-group {
          margin-bottom: 2rem;

          & h4 {
            margin-top: 0;
            margin-bottom: 0.5rem;
          }
        }
      }

      + .collapsible-column-content {
        height: calc(100% - 10rem - 5.6rem - 3.4rem);  // 34px being the height of .free-edit-mode-banner + some padding
      }
    }

    & .main-bar {
      padding-top: 2rem;
    }

    & .right-column {
      position: absolute;
      top: 6rem;
      right: -4.6rem;
      z-index: 100;
      background: colors.$white;
      padding-right: 0;
      padding-left: 0;
      min-width: 39.8rem;
      max-width: 30%;
      height: 100%;

      &:not(.collapsed) .collapsible-column-content{
        height: calc(100% - 5.6rem - 3.4rem);
      }

      & .header-text {
        &.header-text-free-edit {
          width: calc(100% - 4.5rem);
        }

        & + sis-menu-button {
          position: absolute;
          right: 5rem;
          bottom: 1.3rem;
          text-transform: none;

          & .btn.btn-sm.btn-secondary.btn-hollow {
            transition: none;
            border-color: colors.$white;
            padding: 0;
            height: 3rem;
            text-transform: none;
            line-height: 3rem;
            letter-spacing: normal;
          }
        }
      }

      &.collapsed {
        position: fixed;
        top: 10rem;
        right: 0;
        transition: top 0.4s ease, opacity 0.1s linear, width 0.05s ease-in-out;
        min-width: 39.8rem;
        max-width: 39.8rem;
        height: calc(100% - 10rem);

        @media (max-width: breakpoints.$screen-md-max) {
          min-width: 29.8rem;
          max-width: 29.8rem;
        }

        @media (max-width: breakpoints.$screen-xs-max) {
          top: 8.8rem;
          height: calc(100% - 8.8rem);
        }
      }

      /* stylelint-disable-next-line */
      & .collapsible-column-content {
        & ngb-typeahead-window.dropdown-menu {
          width: 100%;
        }

        @media (max-width: breakpoints.$screen-xs-max) {
          border-bottom-left-radius: spacing.$spacing-xxs;
        }
      }

      @media (max-width: breakpoints.$screen-md-max) {
        min-width: 29.8rem;
        max-width: 29.8rem;
      }
    }

    .right-column.closed, .left-column.closed {
      transition: top 0.4s ease, opacity 0.1s linear, width 0.05s ease-in-out;
      visibility: hidden;
      opacity: 0;
      padding: 0;
      width: 0;
      overflow: hidden;

    }
  }
}

