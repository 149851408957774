@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

app-search-main, app-search-cooperation-network, app-search-open-university {
  & .search-page {
    & label {
      text-transform: none !important;
    }

    & sis-search {
      display: block;
      border-bottom: spacing.$pixel-1 solid colors.$dark-gray;
      padding-bottom: spacing.$spacing-xs;
    }

    & .below-searchbar {
      & .results-info {
        margin-top: spacing.$spacing-md;
        margin-bottom: spacing.$spacing-xs;

        & .choose-sort-type {
          margin-bottom: 0;
        }
      }

      & .query-notifications {
        & .results-no-show {
          margin-top: spacing.$spacing-xs;
        }
      }
    }

    & .search-result-pagination {
      padding-top: spacing.$spacing-md
    }
  }
}
