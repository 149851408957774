@use 'sis-assets/foundations/breakpoints/breakpoints' as breakpoints;
@use 'sis-assets/foundations/focus/mixins' as focus;
@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

responsible-assessor,
acceptor-persons {
  & choose-employees {
    & .ui-select-container.ui-select-multiple {
      clear: both;
      border-width: 0;
      background-color: transparent !important;
      padding: 0;

      & .ui-select-search {
        margin-bottom: 0;
        border: spacing.$pixel-1 solid colors.$light-gray;
        background-color: colors.$white !important;
        padding: 0.9rem 1.5rem 0.7rem 0.9rem;
        height: spacing.$spacing-xl;
        font-size: spacing.$spacing-sm;

        &:focus {
          @include focus.deprecated-sisu-focus-generic-inner;
        }
      }
    }

    & .ui-select-match {
      & > span {
        display: block;
      }
    }

    & .ui-select-match-item {
      margin-bottom: spacing.$spacing-xxs !important;
      border: spacing.$pixel-1 solid colors.$light-gray;
      border-radius: 0;
      background-color: colors.$white;
      cursor: default;
      width: 100%;
      text-align: left;
      text-transform: none;
      line-height: 2.5rem;
      white-space: normal;
      font-size: 1.4rem;
      font-weight: 400;
      overflow-wrap: break-word;

      &.btn-primary, &:hover, &:active {
        border-color: colors.$light-gray !important;
        box-shadow: none !important;
        background-color: colors.$white !important;
        color: colors.$dark-gray !important;
      }

      & .close.ui-select-match-close {
        font-family: SISUicons;
        font-size: 2.1rem !important;
        font-weight: 400;
        content: '\63';
      }
    }
  }

  ul {
    clear: both;
    margin-left: 0;
    padding-left: 0;
    list-style-type: none;

    li {
      margin-left: 0;
      padding-left: 0;
    }
  }

  h3, label {
    display: inline-block;
  }

  .empty-selection {
    font-style: italic;
  }

  & .title-container {
    margin-top: 1.8rem;
    text-align: right;

    & .guidance-text {
      text-align: left;
    }

    & h3, label {
      float: left;
      margin-top: 0;
      margin-bottom: 0;
    }

    & .check-custom-assessors {
      display: inline-block;
      padding-bottom: 1.5rem;

      & button {
        margin-right: 0;
      }
    }
  }

  & .reset-link {
    margin-right: 0;
  }
}
